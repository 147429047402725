<script lang="ts" setup>
import { ModelInstanceDto } from "@masta/generated-model";
import { Ref, ref, toRef } from "vue";
import { GridWrapperComponent } from "@/components/Grid/GridWrapperComponent";
import GridWrapper from "@/components/Grid/GridWrapper.vue";
import { ColDef, GridApi, ICellEditorParams } from "ag-grid-community";
import { useScenariosStore } from "@/store/ScenariosStore";
import { storeToRefs } from "pinia";
import { $t } from "@/i18n";
import SystemEnumService from "@/services/system-enum.service";
import { translateModelInstanceStatus, translateTemplateStatus } from "@/composables/translateEnum";
import { getModelInstanceStatusCellStyle, getProductTemplateStatusCellStyle } from "@/components/Tasks/TaskUtils";

const props = defineProps<{
  modelInstances: ModelInstanceDto[];
  resourceAssignment?: boolean;
  taskAssignment?: boolean;
}>();

const emit = defineEmits(["selection-changed"]);

const gridWrapperRef = ref<GridWrapperComponent>();
const scenariosStore = useScenariosStore();
const { selectedScenario } = storeToRefs(scenariosStore);

const basicColumnDefs = ref<ColDef[]>([
  {
    field: "businessId",
    editable: false,
    resizable: true,
    headerValueGetter: (_: any) => $t("modelInstance-list-businessId-label", { $: "BusinessId" })
  },
  {
    field: "schemaId",
    editable: false,
    resizable: true,
    headerValueGetter: (_: any) => $t("modelInstance-list-schemaId-label", { $: "SchemaId" })
  },
  {
    field: "version",
    editable: false,
    resizable: true,
    headerValueGetter: (_: any) => $t("modelInstance-list-schemaVersion-label", { $: "Schema Version" })
  },
  {
    field: "revisionNumber",
    editable: false,
    resizable: true,
    sortable: true,
    headerValueGetter: (_: any) => $t("modelInstance-list-revisionNumber-label", { $: "Revision" })
  },
  {
    field: "status",
    editable: false,
    resizable: true,
    sortable: true,
    valueFormatter: (params: any) => translateModelInstanceStatus(params.value),
    cellStyle: (params: any) => getModelInstanceStatusCellStyle(params.value),
    headerValueGetter: (_: any) => $t("modelInstance-list-status-label", { $: "Status" })
  }
]);
let columnDefs: Ref<any> = null!;

function onPrepareColumns(_columnDefs: any) {
  _columnDefs.value = basicColumnDefs.value;
  columnDefs = _columnDefs;
}

function onSelectionChanged({ api }: { api: GridApi }) {
  const selectedRows = api.getSelectedRows();
  emit("selection-changed", selectedRows);
}
</script>

<template>
  <grid-wrapper
    ref="gridWrapperRef"
    identifier="model-instances"
    row-selection="single"
    :enable-range-selection="false"
    :grid-data="modelInstances"
    @prepare-columns="onPrepareColumns"
    @selection-changed="onSelectionChanged"
  />
</template>
