import type { IRowContainer } from "@masta/gantt2/core";
import { ActivityBounds, ActivityPosition, ActivityRef, ActivityRenderer, GanttSettings, IActivityRenderingRequest, IocSymbols, TimelineManager } from "@masta/gantt2/core";
import { inject, injectable } from "inversify";
import { AvailabilityRuleResourceCapacityActivity } from "src/components/Gantt/Common/Model";
import { CustomSettingKeys } from "@/components/Gantt/ResourcesGantt/CustomSettingKeys";
import { GanttActivityColorsSetting } from "@masta/generated-model";

@injectable()
export class AvailabilityRuleActivityRenderer extends ActivityRenderer<AvailabilityRuleResourceCapacityActivity> {
  private _startDateColor: string = "rgba(0,0,0,1)";
  private _endDateColor: string = "rgba(0,0,0,1)";

  private _defaultFillColor: string = "rgba(0,0,0,0.2)";

  constructor(
    @inject(TimelineManager) timelineManager: TimelineManager,
    @inject(IocSymbols.RowContainer) rowContainer: IRowContainer,
    @inject(GanttSettings) protected readonly _settings: GanttSettings
  ) {
    super(timelineManager, rowContainer, AvailabilityRuleActivityRenderer.name);
    this.paddingInsets.left = 4;
    this.paddingInsets.right = 4;
    this._minWidth = 6;
  }

  async afterInitialize(): Promise<void> {
    await super.afterInitialize();
    const fillSetting = this._settings.getSetting<GanttActivityColorsSetting>(CustomSettingKeys.ACTIVITY_FILL_COLOR_AVAILABILITY_RULE);
    const textColorSetting = this._settings.getSetting<GanttActivityColorsSetting>(CustomSettingKeys.ACTIVITY_TEXT_COLOR_AVAILABILITY_RULE);
    if (fillSetting) {
      this._defaultFillColor = fillSetting.default;
      this.fill = fillSetting.default;
      this.fillHighlight = fillSetting.highlight;
      this.fillSelected = fillSetting.selected;
      this.fillHover = fillSetting.hover;
      this.fillPressed = fillSetting.pressed;
    }
    if (textColorSetting) {
      this.textColor = textColorSetting.default;
      this.textColorHighlight = textColorSetting.highlight;
      this.textColorSelected = textColorSetting.selected;
      this.textColorHover = textColorSetting.hover;
      this.textColorPressed = textColorSetting.pressed;
      this._startDateColor = textColorSetting.startDate ?? this._startDateColor;
      this._endDateColor = textColorSetting.endDate ?? this._endDateColor;
    }
  }

  draw(
    activityRef: ActivityRef<AvailabilityRuleResourceCapacityActivity>,
    position: ActivityPosition,
    rowCanvasContext: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D,
    x: number,
    y: number,
    w: number,
    h: number,
    offsetTop: number,
    selected: boolean,
    hover: boolean,
    highlighted: boolean,
    pressed: boolean
  ): ActivityBounds | undefined {
    if (w <= this._minWidth) return;

    y = Math.round(y + h / 2 - h / 16);
    h = Math.round(h / 8);

    return super.draw(activityRef, position, rowCanvasContext, x, y, w, h, offsetTop, selected, hover, highlighted, pressed);
  }

  drawActivity(request: IActivityRenderingRequest<AvailabilityRuleResourceCapacityActivity>): void {
    const { activityBounds, activityRef, position, canvas, context, x, y, h, offsetTop, selected, hover, highlighted, pressed } = request;
    const { w } = request;

    context.clearRect(0, 0, context.canvas.width, context.canvas.height);

    // const font = "normal 10px Roboto";
    // context.font = font;
    // (context as any).letterSpacing = "0.3px";
    context.globalAlpha = this.opacity;

    const availabilityRule = activityRef.activity.userObject.availabilityRule;

    this.fill = availabilityRule?.color ?? this._defaultFillColor;

    this.drawBackground(activityRef, position, context, x, y, w, h, offsetTop, selected, hover, highlighted, pressed);

    if (availabilityRule) {
      const businessId = availabilityRule.businessId ?? "";

      if (w > 80) {
        context.textAlign = "center";
        context.textBaseline = "middle";
        context.fillText(businessId, Math.round(x + w / 2), Math.round(y + h / 2));
      }
    }
  }
}
