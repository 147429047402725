import { GetContextMenuItemsParams, GridReadyEvent, MenuItemDef } from "ag-grid-community";
import { Ref, UnwrapRef } from "vue";
import { $t } from "@/i18n";

export const useContextMenu = (
  gridWrapperProps: {
    createBtn?: boolean;
    deleteBtn?: boolean;
    editBtn?: boolean;
    refreshBtn?: boolean;
    contextMenuItems?: (params: GetContextMenuItemsParams) => (string | MenuItemDef)[] | undefined;
  },
  dynamicAttrs: any,
  crudActions: {
    onGridReady: (event: GridReadyEvent) => void;
    onCancelAction: () => void;
    deleteDialog: Ref<UnwrapRef<boolean>>;
    onCreateAction: () => void;
    onRefreshAction: () => void;
    actionsControl: Ref<
      UnwrapRef<{ cancelActionEnabled: boolean; editDisabled: boolean; saveActionEnabled: boolean; createDisabled: boolean; refreshDisabled: boolean; deleteDisabled: boolean }>
    >;
    onEditAction: () => void;
    onDeleteConfirm: () => Promise<void>;
    onDeleteAction: () => void;
    onSaveAction: () => void;
  }
) => {
  dynamicAttrs.getContextMenuItems = (params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
    let customMenuItems: (string | MenuItemDef)[] = [];
    if (gridWrapperProps.contextMenuItems) {
      const cm = gridWrapperProps.contextMenuItems(params);
      if (cm && cm.length > 0) customMenuItems = [...cm, "separator"];
    }

    const crudActionsMenuItems: (string | MenuItemDef)[] = [];
    if (gridWrapperProps.createBtn) {
      crudActionsMenuItems.push({
        name: $t("menu-contextButton-create-label", { $: "Create" }),
        icon: '<i class="mdi mdi-plus"/>',
        disabled: crudActions.actionsControl.value.createDisabled,
        action: crudActions.onCreateAction
      });
    }
    if (gridWrapperProps.editBtn) {
      crudActionsMenuItems.push({
        name: $t("menu-contextButton-edit-label", { $: "Edit" }),
        icon: '<i class="mdi mdi-pencil"/>',
        disabled: crudActions.actionsControl.value.editDisabled,
        action: crudActions.onEditAction
      });
    }
    if (gridWrapperProps.deleteBtn) {
      crudActionsMenuItems.push({
        name: $t("menu-contextButton-delete-label", { $: "Delete" }),
        icon: '<i class="mdi mdi-delete"/>',
        disabled: crudActions.actionsControl.value.deleteDisabled,
        action: crudActions.onDeleteAction
      });
    }
    if (crudActions.actionsControl.value.saveActionEnabled) {
      crudActionsMenuItems.push({
        name: $t("menu-contextButton-save-label", { $: "Save" }),
        icon: '<i class="mdi mdi-content-save"/>',
        action: crudActions.onSaveAction
      });
    }
    if (crudActions.actionsControl.value.cancelActionEnabled) {
      crudActionsMenuItems.push({
        name: $t("menu-contextButton-cancel-label", { $: "Cancel" }),
        icon: '<i class="mdi mdi-cancel"/>',
        action: crudActions.onCancelAction
      });
    }

    const menu = [...customMenuItems, ...(crudActionsMenuItems.length > 0 ? crudActionsMenuItems : [])];

    return [...menu];
  };
};