<script lang="ts" setup>
import GridWrapper from "@/components/Grid/GridWrapper.vue";
import { computed, inject, onMounted, Ref, ref, watch } from "vue";
import { GridWrapperComponent } from "@/components/Grid/GridWrapperComponent";
import { GridApi, KeyCreatorParams, ValueFormatterParams } from "ag-grid-community";
import { useModelInstancesStore } from "@/store/ModelInstancesStore";
import { storeToRefs } from "pinia";
import { useSnackbarsStore } from "@/store/SnackbarsStore";
import { useScenariosStore } from "@/store/ScenariosStore";
import { $t } from "@/i18n";
import { translateTemplateStatus } from "@/composables/translateEnum";
import { getProductTemplateStatusCellStyle } from "@/components/Tasks/TaskUtils";
import { $dateTimeFormatterSymbol, DateFormatter } from "@masta/shared";
import { useTagsStore } from "@/store/TagsStore";
import { DocumentUpdatedNotificationEvent, ModelInstanceDto, ModelSchemaDto, TemplateStatus } from "@masta/generated-model";
import { DocumentUpdatedNotification, useNotification } from "@/notifications";
import { useDebounceFn } from "@vueuse/core";
import { enumToEditorEntries, enumValueEntryWithLocaleComparator, translateEditorEntries } from "@/components/Grid/ColumnTypes";
import { joinArrayOfStrings } from "@/components/ValueCellEditor/CommonFormatters";
import { tagsTypeColumnFilterParams } from "@/components/Grid/Filters/TagsTypeColumnFilters";

const props = defineProps<{
  schema: ModelSchemaDto;
}>();
const emit = defineEmits<{
  (e: "instanceSelected", instance: ModelInstanceDto | null): void;
  (e: "detail", instance: ModelInstanceDto): void;
  (e: "create"): void;
  (e: "release", instance: ModelInstanceDto): void;
  (e: "archive", instance: ModelInstanceDto): void;
  (e: "newRevision", instance: ModelInstanceDto): void;
  (e: "copy", instance: ModelInstanceDto): void;
}>();

defineExpose({ onFetchData });

const $dateTimeFormatter = inject<DateFormatter>($dateTimeFormatterSymbol)!;

const selectedModelInstance = ref<ModelInstanceDto | null>(null);
const miStore = useModelInstancesStore();
const tagStore = useTagsStore();
const snackbarsStore = useSnackbarsStore();
const scenariosStore = useScenariosStore();
const gridWrapperRef = ref<GridWrapperComponent>();
const { selectedScenario } = storeToRefs(scenariosStore);
const { instances } = storeToRefs(miStore);

onMounted(async () => {
  await tagStore.fetch();
});

const copySupported = computed(() => {
  if (props.schema?.schemaKey === "model.schema.json.documentDefinition" && props.schema?.schemaName === "attachment") return false;
  return true;
});

const defaultColumnDef = ref({
  floatingFilter: true,
  filterParams: {
    applyMiniFilterWhileTyping: true
  }
});

const basicColumnDefs = ref([
  {
    field: "businessId",
    type: ["textFloatingFilterColumnType"],
    editable: false,
    resizable: true,
    sortable: true,
    filter: "agTextColumnFilter",
    headerValueGetter: (_: any) => $t("schemaInstance-list-businessId-label", { $: "BusinessId" }),
    floatingFilterComponentParams: {
      placeholder: $t("schemaInstance-list-businessId-label", { $: "BusinessId" })
    }
  },
  {
    field: "revisionNumber",
    type: ["textFloatingFilterColumnType"],
    editable: false,
    resizable: true,
    sortable: true,
    filter: "agTextColumnFilter",
    headerValueGetter: (_: any) => $t("schemaInstance-list-revisionNumber-label", { $: "Revision" }),
    floatingFilterComponentParams: {
      placeholder: $t("schemaInstance-list-revisionNumber-label", { $: "Revision" })
    }
  },
  {
    field: "status",
    type: ["setFloatingFilterColumnType"],
    editable: false,
    resizable: true,
    sortable: true,
    valueFormatter: (params: any) => translateTemplateStatus(params.value),
    cellStyle: (params: any) => getProductTemplateStatusCellStyle(params.value),
    headerValueGetter: (_: any) => $t("modelInstance-list-status-label", { $: "Status" }),
    filter: "agSetColumnFilter",
    filterParams: {
      valueFormatter: (params: ValueFormatterParams) => params.value.key,
      keyCreator: (params: KeyCreatorParams) => {
        //this is for object from filterParams.values
        if (typeof params.value === "object") {
          return params.value?.value.toString();
        }

        //this is for field from row data
        if (typeof params.value === "number") {
          return params.value.toString();
        }

        return params.value;
      },
      values: translateEditorEntries(enumToEditorEntries(TemplateStatus), translateTemplateStatus),
      comparator: enumValueEntryWithLocaleComparator
    }
  },
  {
    field: "tags",
    type: ["tagsPickerTypeColumn", "setFloatingFilterColumnType"],
    headerValueGetter: (_: any) => $t("modelInstance-list-tags-label", { $: "Tags" }),
    filter: "agSetColumnFilter",
    filterParams: tagsTypeColumnFilterParams(tagStore),
    editable: false,
    resizable: true,
    valueFormatter: (params: ValueFormatterParams) => joinArrayOfStrings(params.data.tags),
    cellEditorParams: {
      placeholder: $t("modelInstance-edit-tags-label", { $: "Tags" })
    }
  },
  {
    field: "createdBy",
    type: ["textFloatingFilterColumnType"],
    editable: false,
    resizable: true,
    sortable: true,
    filter: "agTextColumnFilter",
    headerValueGetter: (_: any) => $t("modelInstance-list-createdBy-label", { $: "Created By" }),
    floatingFilterComponentParams: {
      placeholder: $t("modelInstance-list-createdBy-label", { $: "Created By" })
    }
  },
  {
    field: "createdAt",
    type: ["dateTimeTypeColumn"],
    editable: false,
    resizable: true,
    sortable: true,
    filter: "agDateColumnFilter",
    valueFormatter: (params: any) => {
      return $dateTimeFormatter(params.data.createdAt);
    },
    headerValueGetter: (_: any) => $t("modelInstance-list-createdAt-label", { $: "Created At" })
  },
  {
    field: "modifiedBy",
    type: ["textFloatingFilterColumnType"],
    editable: false,
    resizable: true,
    sortable: true,
    filter: "agTextColumnFilter",
    headerValueGetter: (_: any) => $t("modelInstance-list-modifiedBy-label", { $: "Modified By" }),
    floatingFilterComponentParams: {
      placeholder: $t("modelInstance-list-modifiedBy-label", { $: "Modified By" })
    }
  },
  {
    field: "modifiedAt",
    type: ["dateTimeTypeColumn"],
    editable: false,
    resizable: true,
    sortable: true,
    filter: "agDateColumnFilter",
    valueFormatter: (params: any) => {
      return $dateTimeFormatter(params.data.modifiedAt);
    },
    headerValueGetter: (_: any) => $t("modelInstance-list-modifiedAt-label", { $: "Modified At" })
  },
  {
    type: ["setFloatingFilterColumnType"],
    editable: false,
    resizable: true,
    sortable: true,
    filter: true,
    valueGetter: () => (selectedScenario.value ? selectedScenario.value.name : ""),
    headerValueGetter: (_: any) => $t("schemaInstance-list-scenario-label", { $: "Scenario" })
  }
]);
let columnDefs: Ref<any> = null!;

watch(() => props.schema, onFetchData);

async function onFetchData() {
  if (!props.schema) {
    miStore.clearInstances();
    return;
  }
  await miStore.fetchSchemaInstancesBySchema(props.schema);
  columnDefs.value = [...basicColumnDefs.value];
  if (props.schema && props.schema && props.schema.properties) {
    for (const propKey in props.schema.properties) {
      const property = props.schema.properties[propKey];
      if (property["x-grid-column"] === true) {
        columnDefs.value.push({
          field: `value.${propKey}`,
          headerName: property.title ? property.title : propKey,
          editable: false,
          resizable: true
        });
      }
    }
  }
  gridWrapperRef.value?.gridApi?.setColumnDefs(columnDefs.value);
  gridWrapperRef.value?.gridApi?.sizeColumnsToFit();
}

async function onRefreshAction() {
  await onFetchData();
}

function onCreateAction() {
  emit("create");
}

function onRowDoubleClicked() {
  if (gridWrapperRef.value?.isEditing()) return;
  if (selectedModelInstance.value) {
    emit("detail", selectedModelInstance.value);
  }
}

function onReleaseModelInstance() {
  if (selectedModelInstance.value) {
    emit("release", selectedModelInstance.value);
  }
}

function onArchiveModelInstance() {
  if (selectedModelInstance.value) {
    emit("archive", selectedModelInstance.value);
  }
}

function onCreateNewRevisionOfModelInstance() {
  if (selectedModelInstance.value) {
    emit("newRevision", selectedModelInstance.value);
  }
}

function onCopyModelInstance() {
  if (selectedModelInstance.value) {
    emit("copy", selectedModelInstance.value);
  }
}

async function onDeleteAction() {
  const rows = gridWrapperRef.value?.gridApi.getSelectedRows() ?? [];
  for (const row of rows) {
    try {
      await miStore.deleteInstance(row);
      await snackbarsStore.createSnackbar({
        message: $t("schemaInstance-list-deleted-message", { $: "Deleted" }),
        closeable: true
      });
    } catch (e: any) {
      await snackbarsStore.createSnackbar({
        message: e.message,
        type: "error"
      });
    }
  }
  gridWrapperRef.value?.gridApi?.deselectAll();
}

function onPrepareColumns(_columnDefs: any) {
  _columnDefs.value = basicColumnDefs.value;
  columnDefs = _columnDefs;
}

function onSelectionChanged({ api }: { api: GridApi }) {
  const rows = api.getSelectedRows();
  selectedModelInstance.value = rows.length === 1 ? rows[0] : null;
  if (selectedModelInstance.value) {
    emit("instanceSelected", selectedModelInstance.value);
  }
}

useNotification(DocumentUpdatedNotification, (e: DocumentUpdatedNotificationEvent) => {
  onDocumentChanged(e);
});

const onDocumentChanged: (e: DocumentUpdatedNotificationEvent) => void = useDebounceFn(async (e: DocumentUpdatedNotificationEvent) => {
  //console.log("onDocumentChanged");

  const selectedNodes = gridWrapperRef?.value?.gridApi.getSelectedNodes();
  const selectedNode = selectedNodes?.length === 1 ? selectedNodes[0].id : null;

  await onFetchData();
  if (selectedNode != null) {
    gridWrapperRef.value?.gridApi.getRowNode(selectedNode)?.setSelected(true);
  }
}, 500);
</script>

<template>
  <grid-wrapper
    ref="gridWrapperRef"
    refresh-btn
    create-btn
    duplicate-btn
    delete-btn
    identifier="schema-instances"
    :create-btn-disabled="!schema"
    row-selection="single"
    :default-col-def="defaultColumnDef"
    :grid-data="instances"
    custom-create-action
    @fetch-data="onFetchData"
    @refresh-action="onRefreshAction"
    @create-action="onCreateAction"
    @row-double-clicked="onRowDoubleClicked"
    @delete-action="onDeleteAction"
    @prepare-columns="onPrepareColumns"
    @selection-changed="onSelectionChanged"
  >
    <template #custom-buttons>
      <v-tooltip location="bottom" open-delay="300">
        <template #activator="{ props }">
          <div class="d-inline-flex pr-4">
            <v-btn size="small" variant="text" density="compact" v-bind="props" :disabled="!selectedModelInstance" @click="onReleaseModelInstance">
              <v-icon icon="mdi-rocket-launch" class="pr-4" />
              {{ $t("modelInstance-list-release-action", { $: "Release" }) }}
            </v-btn>
          </div>
        </template>
        <span>{{ $t("modelInstance-list-release-action", { $: "Release" }) }}</span>
      </v-tooltip>
      <v-tooltip location="bottom" open-delay="300">
        <template #activator="{ props }">
          <div class="d-inline-flex pr-4">
            <v-btn size="small" variant="tonal" density="compact" v-bind="props" :disabled="!selectedModelInstance" @click="onArchiveModelInstance">
              <v-icon icon="mdi-archive-arrow-down-outline" class="pr-4" />
              {{ $t("modelInstance-list-archive-action", { $: "Archive" }) }}
            </v-btn>
          </div>
        </template>
        <span>{{ $t("modelInstance-list-archive-action", { $: "Archive" }) }}</span>
      </v-tooltip>
      <v-tooltip location="bottom" open-delay="300">
        <template #activator="{ props }">
          <div class="d-inline-flex pr-4">
            <v-btn size="small" variant="tonal" density="compact" v-bind="props" :disabled="!selectedModelInstance" @click="onCreateNewRevisionOfModelInstance">
              <v-icon icon="mdi-ab-testing" class="pr-4" />
              {{ $t("modelInstance-list-createNewVersion-action", { $: "Create New Version" }) }}
            </v-btn>
          </div>
        </template>
        <span>{{ $t("modelInstance-list-createNewVersion-action", { $: "Create New Version" }) }}</span>
      </v-tooltip>
      <v-tooltip v-if="copySupported" bottom open-delay="300">
        <template #activator="{ props }">
          <div class="d-inline-flex pr-4">
            <v-btn size="small" variant="tonal" density="compact" v-bind="props" :disabled="!selectedModelInstance" @click="onCopyModelInstance">
              <v-icon icon="mdi-content-copy" class="pr-4"></v-icon>
              {{ $t("schemaInstance-list-copy-action", { $: "Copy" }) }}
            </v-btn>
          </div>
        </template>
        <span>{{ $t("modelInstance-list-copy-action", { $: "Copy" }) }}</span>
      </v-tooltip>
    </template>
  </grid-wrapper>
</template>

<style lang="scss" scoped></style>
