<template>
  <master-detail-layout scenario-selector :detail="detail" :detail-dialog-width="detailDialogWidth" @change="detail = false">
    <template #master>
      <v-card elevation="0" class="flexcard fill-height">
        <v-card-title>
          <breadcrumbs>{{ $t("modelInstance-view-modelInstances-title", { $: "Model instances" }) }}</breadcrumbs>
        </v-card-title>
        <v-card-text class="fill-height">
          <v-row class="fill-height">
            <v-col cols="6">
              <v-card class="flexcard fill-height">
                <v-card-title> {{ $t("modelInstance-view-schemas-label", { $: "Schemas" }) }}</v-card-title>
                <v-card-text class="fill-height">
                  <schemas-grid @schema-selected="schemaSelected" @edit-schema="editSchema" />
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-card class="flexcard fill-height">
                <v-card-title>{{ $t("modelInstance-view-schemaInstances-title", { $: "Schema instances" }) }}</v-card-title>
                <v-card-text class="fill-height">
                  <schema-instances-grid
                    ref="schemaInstanceGrid"
                    :schema="selectedSchema"
                    @create="onCreateAction"
                    @detail="onDetailAction"
                    @release="onRelease"
                    @archive="onArchive"
                    @new-revision="onNewRevision"
                    @copy="onCopy"
                    @instance-selected="instanceSelected"
                  />
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
    <template #detail-title>
      <div v-if="detail && createDialog === 0" class="text-h5">
        {{ $t("modelInstance-view-createModelInstance-label", { $: "Create Model Instance" }) }}
      </div>
      <div v-if="detail && createDialog === 1" class="text-h5">
        {{ $t("modelInstance-view-updateModelInstance-label", { $: "Update Model Instance" }) }}
      </div>
    </template>
    <template #detail>
      <edit-model-instance
        v-if="detail && createDialog === 0"
        :schema="selectedSchema"
        :model-instance="null"
        @save="saveCreatedInstance"
        @save-document="saveDocument"
        @cancel="detail = false"
        @resize-window="resizeDetailWindow"
      />
      <edit-model-instance
        v-if="detail && createDialog === 1"
        :schema="selectedSchema"
        :model-instance="selectedInstance"
        id-read-only
        @save="saveUpdatedInstance"
        @save-document="saveDocument"
        @cancel="detail = false"
        @resize-window="resizeDetailWindow"
      />
      <schema-edit v-if="detail && createDialog === 2" :schema="selectedSchema" @save="saveUpdatedSchema" @cancel="detail = false" />
    </template>
  </master-detail-layout>
</template>

<script>
import MasterDetailLayout from "@/components/Layout/MasterDetailLayout.vue";
import SchemasGrid from "@/components/ModelInstances/SchemasGrid.vue";
import SchemaEdit from "@/components/ModelInstances/SchemaEdit.vue";
import SchemaInstancesGrid from "@/components/ModelInstances/SchemaInstancesGrid.vue";
import EditModelInstance from "@/components/ModelInstances/EditModelInstance.vue";
import { mapActions, mapState } from "pinia";
import { useModelInstancesStore } from "@/store/ModelInstancesStore";
import { useSnackbarsStore } from "@/store/SnackbarsStore";
import { useScenariosStore } from "@/store/ScenariosStore";
import { $t } from "@/i18n";
import Breadcrumbs from "@/components/Layout/Breadcrumbs.vue";
import { ModelInstanceStatus } from "@masta/generated-model";
import { useDocumentsStore } from "@/store/DocumentsStore";

export default {
  name: "ModelInstances",
  components: {
    Breadcrumbs,
    EditModelInstance,
    SchemaInstancesGrid,
    SchemasGrid,
    MasterDetailLayout,
    SchemaEdit
  },
  title: "Model instances",
  data: () => ({
    detail: false,
    selectedSchema: null,
    selectedInstance: null,
    createDialog: 0,
    detailDialogWidth: "50%",
    documentStore: useDocumentsStore()
  }),
  computed: {
    ...mapState(useScenariosStore, ["selectedScenario"])
  },
  methods: {
    ...mapActions(useModelInstancesStore, ["createInstance", "updateInstance", "release", "archive", "createNewRevision", "makeCopy"]),
    ...mapActions(useSnackbarsStore, ["createSnackbar"]),
    schemaSelected(schema) {
      this.selectedSchema = schema;
      this.selectedInstance = null;
    },
    instanceSelected(instance) {
      this.selectedInstance = instance;
    },
    onCreateAction() {
      this.createDialog = 0;
      if (this.selectedSchema) {
        this.detail = true;
      }
    },
    onDetailAction() {
      this.createDialog = 1;
      if (this.selectedInstance) {
        this.detail = true;
      }
    },
    async onRelease(instance) {
      if (instance.status === ModelInstanceStatus.WorkInProgress) {
        await this.release(instance);
      }
    },
    async onArchive(instance) {
      if (instance.status !== ModelInstanceStatus.Archived) {
        await this.archive(instance);
      }
    },
    async onNewRevision(instance) {
      await this.createNewRevision(instance);
    },
    async onCopy(instance) {
      await this.makeCopy(instance);
    },
    editSchema() {
      //it is switched off on a grid right now
      this.createDialog = 2;
      if (this.selectedSchema) {
        this.detail = true;
      }
    },
    async saveCreatedInstance(change, callback) {
      const id = await this.createInstance({
        ...change,
        scenarioId: this.selectedScenario.id,
        schemaKey: this.selectedSchema.schemaKey,
        schemaName: this.selectedSchema.schemaName,
        schemaVersion: this.selectedSchema.version
      });
      if (id) {
        this.detail = false;
        await this.$refs.schemaInstanceGrid.onFetchData();
      } else {
        callback(false);
      }
    },
    async saveUpdatedInstance(change, callback) {
      const success = await this.updateInstance({
        ...this.selectedInstance,
        ...change
      });
      if (success) {
        this.detail = false;
        await this.$refs.schemaInstanceGrid.onFetchData();
      } else {
        callback(false);
      }
    },
    async saveUpdatedSchema(schema) {
      console.log("Implement schema update", schema);
    },
    resizeDetailWindow(width) {
      this.detailDialogWidth = `${width}%`;
    },
    async saveDocument(change) {
      try {
        if (this.createDialog === 1) {
          await this.documentStore.updateDocumentWithAttachment({
            businessId: this.selectedInstance.businessId,
            revisionNumber: this.selectedInstance.revisionNumber,
            id: this.selectedInstance.id,
            ...change.value.attachment
          });
        } else {
          const id = await this.documentStore.createDocumentWithAttachment({
            //contextName: props.contextName,
            businessId: change.businessId,
            tags: change.tags,
            ...change.value.attachment
          });
        }
        await this.createSnackbar({
          message: $t("modelInstance-updateModelInstance-onUpdated-message", { $: "Done!" }),
          closeable: true
        });
      } catch (e) {
        await this.createSnackbar({
          message: e.response.status === 400 ? $t("modelInstance-updateModelInstance-onSchemaValidationError-message", { $: "Schema validation failed" }) : e.message,
          type: "error"
        });
        console.warn(e.response.data);
      }
      this.detail = false;
      await this.$refs.schemaInstanceGrid.onFetchData();
    }
  }
};
</script>

<style scoped></style>
