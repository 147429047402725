<template>
  <master-detail-layout scenario-selector :detail="showDetails" detail-dialog-width="90%" @change="showDetails = false">
    <template #master>
      <v-card elevation="0" class="flexcard fill-height">
        <v-card-title>
          <breadcrumbs>{{ $t("material-view-materials-title", { $: "Materials" }) }}</breadcrumbs>
        </v-card-title>
        <v-card-text class="fill-height">
          <v-row class="fill-height" no-gutters>
            <v-col cols="12">
              <material-grid
                ref="grid"
                @manage-model-instances="manageModelInstances"
                @manage-availability-rules="manageAvailabilityRules"
                @resource2resource="resource2resourceAction"
                @resource2resource-reverse="resource2resourceReverseAction"
                @show-details="onShowDetails"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-col v-if="editModelInstanceAssignmentDialog">
        <resource-model-instance-assignment
          :model-instances="material.modelInstances"
          :opened="editModelInstanceAssignmentDialog"
          @cancel="editModelInstanceAssignmentDialog = false"
          @save="saveModelInstanceAssignment"
        >
          <resource-label type="Material" :business-id="material.businessId" :name="material.name" />
        </resource-model-instance-assignment>
      </v-col>
      <v-col v-if="editAvailabilityRulesAssignmentDialog">
        <availability-rules-assignment-editor
          :resource="material"
          :opened="editAvailabilityRulesAssignmentDialog"
          @cancel="editAvailabilityRulesAssignmentDialog = false"
          @save="editAvailabilityRulesAssignmentDialog = false"
        />
      </v-col>
      <resource2-resource-dialog
        v-if="resourceType >= 0 && material"
        :opened="resource2resourceDialog"
        :assignment-type="resourceAssignmentType"
        :resource-type="resourceType"
        :resource="material"
        :reverse="isReverseResource2ResourceAssignment"
        @close="resource2resourceDialogClose"
      />
    </template>
    <template #detail-title>
      <span class="text-h6">{{ material?.name || material?.businessId }}</span>
    </template>
    <template #detail>
      <material-details :material="material" :schema-tabs="schemaTabs" @updated="materialUpdated" />
    </template>
  </master-detail-layout>
</template>

<script>
import MaterialGrid from "@/components/Material/MaterialGrid.vue";
import MasterDetailLayout from "@/components/Layout/MasterDetailLayout.vue";
import ResourceLabel from "@/components/ModelInstances/ModelInstanceAssignment/ResourceLabel";
import AvailabilityRulesAssignmentEditor from "@/components/AvailabilityRules/AvailabilityRulesAssignment/AvailabilityRulesAssignmentEditor";
import { mapActions, mapState } from "pinia";
import { useMaterialsStore } from "@/store/MaterialsStore";
import Resource2ResourceDialog from "@/components/Resources/ResourceAssignment/Resource2ResourceDialog.vue";
import { ResourceAssignmentType } from "@masta/generated-model";
import Breadcrumbs from "@/components/Layout/Breadcrumbs.vue";
import ResourceModelInstanceAssignment from "@/components/ModelInstances/ModelInstanceAssignment/ResourceModelInstanceAssignment.vue";
import MaterialDetails from "@/components/Material/MaterialDetails.vue";
import { useModelInstancesStore } from "@/store/ModelInstancesStore";
import { useSystemStore } from "@/store/SystemStore";

export default {
  name: "Material",
  components: {
    MaterialDetails,
    Breadcrumbs,
    Resource2ResourceDialog,
    ResourceLabel,
    ResourceModelInstanceAssignment,
    AvailabilityRulesAssignmentEditor,
    MasterDetailLayout,
    MaterialGrid
  },
  title: "Material",
  data() {
    return {
      editModelInstanceAssignmentDialog: false,
      editAvailabilityRulesAssignmentDialog: false,
      showDetails: false,
      material: null,
      refreshGridAction: null,
      resource2resourceDialog: false,
      resourceType: null,
      resourceAssignmentType: ResourceAssignmentType.MaterialToScrap,
      isReverseResource2ResourceAssignment: false,
      schemaTabs: []
    };
  },
  computed: {
    ...mapState(useModelInstancesStore, ["schemas"]),
    ...mapState(useSystemStore, ["modelInstanceTabs"])
  },
  mounted() {
    this.loadTabSchemas();
  },
  methods: {
    ...mapActions(useMaterialsStore, ["updateModelInstanceAssignment"]),
    ...mapActions(useModelInstancesStore, ["fetchSchemas"]),
    manageModelInstances(material, refreshGridAction) {
      this.refreshGridAction = refreshGridAction;
      this.material = material;
      this.editModelInstanceAssignmentDialog = true;
    },
    async saveModelInstanceAssignment(instances) {
      await this.updateModelInstanceAssignment({
        id: this.material.id,
        modelInstanceIds: instances.map((x) => x.id)
      });
      this.refreshGridAction();
      this.editModelInstanceAssignmentDialog = false;
    },
    manageAvailabilityRules(material) {
      this.material = material;
      this.editAvailabilityRulesAssignmentDialog = true;
    },
    resource2resourceAction(resourceType, material) {
      this.isReverseResource2ResourceAssignment = false;
      this.resourceType = resourceType;
      this.material = material;
      this.resource2resourceDialog = true;
    },
    resource2resourceReverseAction(resourceType, material) {
      this.isReverseResource2ResourceAssignment = true;
      this.resourceType = resourceType;
      this.material = material;
      this.resource2resourceDialog = true;
    },
    resource2resourceDialogClose() {
      this.isReverseResource2ResourceAssignment = false;
      this.resource2resourceDialog = false;
      this.resourceType = null;
      this.material = null;
    },
    onShowDetails(material) {
      this.material = material;
      this.showDetails = true;
    },
    async loadTabSchemas() {
      await this.fetchSchemas();
      for (const tab of this.modelInstanceTabs?.materials ?? []) {
        const schema = this.schemas.find((x) => x.schemaName === tab.schemaName && x.schemaKey === tab.schemaKey && x.version === tab.schemaVersion);
        if (schema) {
          this.schemaTabs.push({ title: tab.title ?? "", schema, contextName: tab.contextName ?? "", resourceSubTypes: tab.resourceSubTypes });
        }
      }
    },
    async materialUpdated(id) {
      this.$refs.grid.refreshSingle(id);
    }
  }
};
</script>

<style lang="scss" scoped></style>
