<script lang="ts" setup>
import { ref } from "vue";


export interface DeleteKpiItem {
  id: string;
  type: "calculation" | "scenario";
}

const props = defineProps<{
  modelValue: boolean;
  values: DeleteKpiItem[]
}>();
const emit = defineEmits<{
  (e: "update:modelValue", value: boolean): void;
  (e: "result", value: DeleteKpiItem | null): void;
}>();

const seletectItem = ref<DeleteKpiItem | null>(null);

function cancel() {
  seletectItem.value = null;

  emit("update:modelValue", false);
}

function deleteKpi() {
  const selectedItemValue = seletectItem.value;
  seletectItem.value = null;

  emit("result", selectedItemValue);
  emit("update:modelValue", false);
}
</script>

<template>
  <v-dialog :model-value="modelValue" width="50vw" @update:model-value="value => emit('update:modelValue', value)">
    <v-card class="pa-8 resource-capacity-create-card">
      <v-card-title class="d-flex align-center justify-space-between">
        <div class="text-h4">
          {{ $t("kpiResults-deleteDialog-title", { $: "Delete KPI" }) }}
        </div>
        <v-icon @click="cancel">mdi-close</v-icon>
      </v-card-title>
      <v-card-subtitle>
        <div class="text-h5">
          {{ $t("kpiResults-deleteDialog-subtitle", { $: "Select scenario or calculation from the list to delete" }) }}
        </div>
      </v-card-subtitle>
      <v-card-text class="pa-0">        
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                ref="input"
                v-model="seletectItem"
                :items="values"
                color="primary"
                item-title="id"
                item-value="id"
                clearable
                variant="outlined"
                auto-select-first
                hide-details
                return-object
                :multiple="false"
                :placeholder="$t('kpiResults-deleteDialog-field-placeholder', {$: 'Select scenario or calculation' })"
              >
              <template #item="{ props, item }">
                <v-list-item
                  v-bind="props"
                  :prepend-icon="item.raw.type === 'calculation' ? 'mdi-calculator-variant' : 'mdi-file-document'"
                  :subtitle="item.raw.type === 'calculation' 
                    ? $t('kpiResults-deleteDialog-field-type', {$: 'Calculation' }) 
                    : $t('kpiResults-deleteDialog-field-type', {$: 'Scenario' })"
                  :title="item.raw.id"
                ></v-list-item>
              </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col cols="6">
            <v-btn class="ma-4" variant="elevated" color="secondary" block @click="cancel">
              {{ $t("kpiResults-deleteDialog-action-cancel", { $: "Cancel" }) }}              
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn class="ma-4" variant="elevated" :color="seletectItem ? 'primary' : 'grey'" block :disabled="!seletectItem" @click="deleteKpi">
              {{ $t("kpiResults-deleteDialog-action-delete", { $: "Delete" }) }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss">
</style>
