import { ApiClient } from "@/services/api/common.api";
import {
  CreateScenarioIfDoesNotExistCommand,
  GetScenariosPaginatedQuery,
  GetScenariosResponse,
  IAgGridPaginationResponse,
  MergeScenariosCommand,
  ScenarioDto,
  UpdateScenarioCommand
} from "@masta/generated-model";

export default {
  async getScenarios() {
    return ApiClient.get<GetScenariosResponse>("/scenarios");
  },
  async getScenario(id: string) {
    return ApiClient.get<ScenarioDto>("/scenarios/${id}");
  },
  async getAllPaginated(request: GetScenariosPaginatedQuery) {
    return ApiClient.post<IAgGridPaginationResponse<ScenarioDto>>(`/scenarios`, request);
  },
  async createScenario(request: CreateScenarioIfDoesNotExistCommand) {
    return ApiClient.put("/scenarios", request);
  },
  async updateScenario(request: UpdateScenarioCommand) {
    return ApiClient.put(`/scenarios/${request.id}`, request);
  },
  async deleteScenario(id: string) {
    return ApiClient.delete(`/scenarios/${id}`);
  },
  async releaseScenario(id: string) {
    return ApiClient.put(`/scenarios/${id}/release`, {});
  },
  async mergeScenarios(request: MergeScenariosCommand) {
    return ApiClient.put(`/scenarios/${request.destinationScenarioId}/merge`, request);
  }
};
