<script lang="ts" setup>
import NumberRequirementDefinitionEditor from "@/components/ModelInstances/RequirementsDefinitions/NumberRequirementDefinitionEditor.vue";
import NumberRangeRequirementDefinitionEditor from "@/components/ModelInstances/RequirementsDefinitions/NumberRangeRequirementDefinitionEditor.vue";
import StringRequirementDefinitionEditor from "@/components/ModelInstances/RequirementsDefinitions/StringRequirementDefinitionEditor.vue";
import BooleanRequirementDefinitionEditor from "@/components/ModelInstances/RequirementsDefinitions/BooleanRequirementDefinitionEditor.vue";
import { RequirementsDefinition, IRequirementDefinition } from "@masta/generated-model";

const props = defineProps<{
  modelValue: RequirementsDefinition;
  disabled: boolean;
}>();

const emit = defineEmits(["update:modelValue"]);

function getComponent(requirement: IRequirementDefinition) {
  switch (requirement.type) {
    case "NumberRequirementDefinition":
      return NumberRequirementDefinitionEditor;
    case "NumberRangeRequirementDefinition":
      return NumberRangeRequirementDefinitionEditor;
    case "StringRequirementDefinition":
      return StringRequirementDefinitionEditor;
    case "BooleanRequirementDefinition":
      return BooleanRequirementDefinitionEditor;
    default:
      throw new Error(`Requirement type '${requirement.type}' not supported`);
  }
}

function updateRequirement(newRequirement: IRequirementDefinition, requirement: IRequirementDefinition) {
  const newRequirementsDef = {
    requirements: props.modelValue.requirements.map((x) => (x !== requirement ? x : newRequirement))
  };
  updateModelValue(newRequirementsDef);
}

function remove(requirement: IRequirementDefinition) {
  const newRequirementsDef = {
    requirements: props.modelValue.requirements.filter((x) => x !== requirement)
  };
  updateModelValue(newRequirementsDef);
}

function addNumberRequirement() {
  const newRequirement = { type: "NumberRequirementDefinition", jsonPath: "", compareMethod: "=", value: 0 };
  addRequirement(newRequirement);
}

function addNumberRangeRequirement() {
  const newRequirement = { type: "NumberRangeRequirementDefinition", jsonPath: "", min: 0, max: 100 };
  addRequirement(newRequirement);
}

function addStringRequirement() {
  const newRequirement = { type: "StringRequirementDefinition", jsonPath: "", regularExpression: "" };
  addRequirement(newRequirement);
}

function addBooleanRequirement() {
  const newRequirement = { type: "BooleanRequirementDefinition", jsonPath: "", value: true };
  addRequirement(newRequirement);
}

function addRequirement(requirement: IRequirementDefinition) {
  const newRequirementsDef = {
    requirements: [...props.modelValue.requirements, requirement]
  };
  updateModelValue(newRequirementsDef);
}

function updateModelValue(newRequirementsDef: RequirementsDefinition) {
  emit("update:modelValue", newRequirementsDef);
}
</script>

<template>
  <v-container fluid>
    <div class="d-flex justify-start align-center mb-8">
      <div class="text-subtitle-2">
        {{ $t("requirementDefinition-editor-availableRequirementTypes-label", { $: "Requirements:" }) }}
      </div>
      <v-btn type="button" variant="tonal" size="small" class="mx-4" :disabled="disabled" @click="addBooleanRequirement()">
        <v-icon>mdi-plus</v-icon>
        {{ $t("requirementDefinition-editor-addBooleanRequirement-action", { $: "Boolean" }) }}
      </v-btn>
      <v-btn type="button" variant="tonal" size="small" class="mx-4" :disabled="disabled" @click="addNumberRequirement()">
        <v-icon>mdi-plus</v-icon>
        {{ $t("requirementDefinition-editor-addNumberRequirement-action", { $: "Number" }) }}
      </v-btn>
      <v-btn type="button" variant="tonal" size="small" class="mx-4" :disabled="disabled" @click="addNumberRangeRequirement()">
        <v-icon>mdi-plus</v-icon>
        {{ $t("requirementDefinition-editor-addNumberRangeRequirement-action", { $: "Number Range" }) }}
      </v-btn>
      <v-btn type="button" variant="tonal" size="small" class="mx-4" :disabled="disabled" @click="addStringRequirement()">
        <v-icon>mdi-plus</v-icon>
        {{ $t("requirementDefinition-editor-addStringRequirement-action", { $: "String" }) }}
      </v-btn>
    </div>
    <v-slide-y-transition group>
      <v-row v-for="(requirement, index) in modelValue.requirements" :key="index" dense class="pt-6">
        <component
          :is="getComponent(requirement)"
          :model-value="requirement"
          :disabled="disabled"
          @update:modelValue="updateRequirement($event, requirement)"
          @remove="remove(requirement)"
        />
      </v-row>
    </v-slide-y-transition>
  </v-container>
</template>
