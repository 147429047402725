﻿<script lang="ts" setup>
import MasterDetailLayout from "@/components/Layout/MasterDetailLayout.vue";
import ResourceModelInstanceAssignment from "@/components/ModelInstances/ModelInstanceAssignment/ResourceModelInstanceAssignment.vue";
import AvailabilityRulesAssignmentEditor from "@/components/AvailabilityRules/AvailabilityRulesAssignment/AvailabilityRulesAssignmentEditor";
import ResourceLabel from "@/components/ModelInstances/ModelInstanceAssignment/ResourceLabel";
import CalendarsGrid from "@/components/Calendars/CalendarsGrid.vue";
import { CalendarDto, ResourceModelInstanceDto } from "@masta/generated-model";
import { ref } from "vue";
import { useCalendarsStore } from "@/store/CalendarsStore";
import Breadcrumbs from "@/components/Layout/Breadcrumbs.vue";

const calendarsStore = useCalendarsStore();
const selectedCalendar = ref<CalendarDto>();
const editModelInstanceAssignmentDialog = ref<boolean>();
const editAvailabilityRulesAssignmentDialog = ref<boolean>();
const refreshAction = ref<() => void>();

function manageModelInstances(calendar: CalendarDto, refreshGridAction: () => void) {
  refreshAction.value = refreshGridAction;
  selectedCalendar.value = calendar;
  editModelInstanceAssignmentDialog.value = true;
}

function saveModelInstanceAssignment(instances: ResourceModelInstanceDto[]) {
  if (!selectedCalendar.value) return;

  calendarsStore.updateModelInstanceAssignment({
    id: selectedCalendar.value.id,
    modelInstanceIds: instances.map((x) => x.id),
    modelInstanceBusinessIds: null,
    businessId: null,
    scenarioId: null!
  });
  editModelInstanceAssignmentDialog.value = false;
}

function manageAvailabilityRules(calendar: CalendarDto) {
  selectedCalendar.value = calendar;
  editAvailabilityRulesAssignmentDialog.value = true;
}
</script>

<template>
  <master-detail-layout scenario-selector>
    <template #master>
      <v-card elevation="0" class="flexcard fill-height">
        <v-card-title>
          <breadcrumbs>{{ $t("calendar-view-calendars-title", { $: "Calendars" }) }}</breadcrumbs>
        </v-card-title>
        <v-card-text class="fill-height">
          <v-row class="fill-height" no-gutters>
            <v-col cols="12">
              <calendars-grid @manage-model-instances="manageModelInstances" @manage-availability-rules="manageAvailabilityRules" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-col v-if="editModelInstanceAssignmentDialog">
        <resource-model-instance-assignment
          :model-instances="selectedCalendar.modelInstances"
          :opened="editModelInstanceAssignmentDialog"
          @cancel="editModelInstanceAssignmentDialog = false"
          @save="saveModelInstanceAssignment"
        >
          <resource-label type="Equipment" :business-id="selectedCalendar.businessId" :name="selectedCalendar.name" />
        </resource-model-instance-assignment>
      </v-col>
      <v-col v-if="editAvailabilityRulesAssignmentDialog">
        <availability-rules-assignment-editor
          :resource="selectedCalendar"
          :opened="editAvailabilityRulesAssignmentDialog"
          @cancel="editAvailabilityRulesAssignmentDialog = false"
          @save="editAvailabilityRulesAssignmentDialog = false"
        />
      </v-col>
    </template>
  </master-detail-layout>
</template>
