﻿import type { IRowContainer } from "@masta/gantt2/core";
import { ActivityRenderer, GanttSettings, IActivityRenderingRequest, IocSymbols, TimelineManager } from "@masta/gantt2/core";
import { inject, injectable } from "inversify";
import { DemandSchedulingResourceCapacityActivity, EnhancedGanttResourceCapacityDto } from "@/components/Gantt/ResourcesGantt";
import { StatusColorHelper } from "@/components/Gantt/ResourcesGantt/Renderers/StatusColorHelper";
import { CustomSettingKeys } from "@/components/Gantt/ResourcesGantt/CustomSettingKeys";
import type { GanttActivityColorsSetting, GanttActivityDisplayPropertySetting, TagDto } from "@masta/generated-model";
import { isDefined } from "@vueuse/core";

const FIRST_LINE_GROUP_INDEX = 0;
const SECOND_LINE_GROUP_INDEX = 1;
const THIRD_LINE_GROUP_INDEX = 2;


@injectable()
export class SchedulingActivityRenderer extends ActivityRenderer<DemandSchedulingResourceCapacityActivity> {
  private _tags: TagDto[] = [];
  private _startDateColor: string = "rgba(0,0,0,1)";
  private _endDateColor: string = "rgba(0,0,0,1)";
  private _groupedProperties: { [group: number]: GanttActivityDisplayPropertySetting[] } = {};

  private _minWidthForLineIndicator = 6;

  constructor(
    @inject(TimelineManager) timelineManager: TimelineManager,
    @inject(IocSymbols.RowContainer) rowContainer: IRowContainer,
    @inject(StatusColorHelper) private _statusColorHelper: StatusColorHelper,
    @inject(GanttSettings) protected readonly _settings: GanttSettings
  ) {
    super(timelineManager, rowContainer, SchedulingActivityRenderer.name);
    this.paddingInsets.left = 4;
    this.paddingInsets.right = 4;
    this._minWidth = 0;
  }

  async afterInitialize(): Promise<void> {
    await super.afterInitialize();
    const fillSetting = this._settings.getSetting<GanttActivityColorsSetting>(CustomSettingKeys.ACTIVITY_FILL_COLOR_SCHEDULING);
    const textColorSetting = this._settings.getSetting<GanttActivityColorsSetting>(CustomSettingKeys.ACTIVITY_TEXT_COLOR_SCHEDULING);
    const displayedPropertiesSetting = this._settings.getSetting<GanttActivityDisplayPropertySetting[]>(CustomSettingKeys.ACTIVITY_DISPLAYED_PROPERTIES);
    this._tags = this._settings.getSetting<TagDto[]>(CustomSettingKeys.TAGS) ?? [];

    if (fillSetting) {
      this.fill = fillSetting.default;
      this.fillHighlight = fillSetting.highlight;
      this.fillSelected = fillSetting.selected;
      this.fillHover = fillSetting.hover;
      this.fillPressed = fillSetting.pressed;
    }
    if (textColorSetting) {
      this.textColor = textColorSetting.default;
      this.textColorHighlight = textColorSetting.highlight;
      this.textColorSelected = textColorSetting.selected;
      this.textColorHover = textColorSetting.hover;
      this.textColorPressed = textColorSetting.pressed;
      this._startDateColor = textColorSetting.startDate ?? this._startDateColor;
      this._endDateColor = textColorSetting.endDate ?? this._endDateColor;
    }
    if (displayedPropertiesSetting) {
      displayedPropertiesSetting
        .filter((property) => property.displayed)
        .forEach((property) => {
          if (isDefined(property.displayGroup)) {
            if (!this._groupedProperties[property.displayGroup]) {
              this._groupedProperties[property.displayGroup] = [];
            }
            this._groupedProperties[property.displayGroup].push(property);
            this._groupedProperties[property.displayGroup].sort((propertyA, propertyB) => propertyA.displayOrder - propertyB.displayOrder);
          }
        });
    }
  }

  drawActivity(request: IActivityRenderingRequest<DemandSchedulingResourceCapacityActivity>): void {
    const { activityBounds, activityRef, position, canvas, context, x, y, h, offsetTop, selected, hover, highlighted, pressed } = request;
    let { w } = request;

    if (w < this._minWidthForLineIndicator) {
      // draw vertical dashed line for smaller activities
      context.save();
      context.lineWidth = 6;
      context.strokeStyle = this.fill$.value!;
      context.beginPath();
      context.setLineDash([9, 9]);
      context.moveTo(x, y);
      context.lineTo(x, y + h);
      context.stroke();
      context.closePath();
      context.restore();
      return;
    }

    context.clearRect(0, 0, context.canvas.width, context.canvas.height);

    let font = "normal 10px Roboto";
    context.font = font;
    (context as any).letterSpacing = "0.3px";
    context.globalAlpha = this.opacity;

    this.drawBackground(activityRef, position, context, x, y, w, h, offsetTop, selected, hover, highlighted, pressed);
    // border
    context.save();
    context.strokeStyle = "rgba(0,0,0,0.5)";
    context.beginPath();
    context.moveTo(x, y + h);
    context.lineTo(x, y);
    context.lineTo(x + w, y);
    context.lineTo(x + w, y + h);
    context.moveTo(x + w, y + h);
    context.closePath();
    context.stroke();
    context.restore();

    const { task, step } = activityRef.activity.userObject;

    const orgTags = task.organizations.map(org => org.tags).flat()
      .map(tag => this._tags.find(t => t.name === tag)).sort((a, b) => (a?.rank ?? 0) - (b?.rank ?? 0)).filter(tag => tag);

    const organizationColor = orgTags?.[0]?.color;

    const statusBarHeight = Math.round(h / 8);
    // if (task && task.statistics) {
    //   context.fillStyle = this._statusColorHelper.getStatusBackgroundColor(task.statistics.totalStatus);
    //   const sX = x;
    //   const sY = y + h - statusBarHeight * 2;
    //   const sW = w;
    //   const sH = statusBarHeight;
    //   context.fillRect(sX, sY, sW, sH);
    //   this.drawLine(context, sX, sY, sX + sW, sY, "rgba(0,0,0,0.25)");
    // }
    if (organizationColor) {
      context.fillStyle = organizationColor;
      const sX = x;
      const sY = y + h - statusBarHeight * 2;
      const sW = w;
      const sH = statusBarHeight;
      context.fillRect(sX, sY, sW, sH);
    }
    if (step && step.statistics) {
      const sX = x;
      const sY = y + h - statusBarHeight;
      const sW = w;
      const sH = statusBarHeight;
      context.fillStyle = this._statusColorHelper.getStepStatusBackgroundColor(step.statistics.totalStatus);
      context.fillRect(sX, sY, sW, sH);
    }

    if (w < this._minWidth) w = this._minWidth;
    context.fillStyle = this.getTextColor(selected, hover, highlighted, pressed) ?? "rgba(0,0,0,1)";

    const startDate = activityRef.activity.userObject.periodStartDateFormatted ?? activityRef.activity.userObject.periodStart;
    const endDate = activityRef.activity.userObject.periodEndDateFormatted ?? activityRef.activity.userObject.periodEnd;

    let fsize = this.getFontSize(context.font);
    if (h / 2 < fsize) {
      fsize = h / 2;
    }

    const startDateTextWidth = context.measureText(endDate).width;
    const endDateTextWidth = context.measureText(endDate).width;

    const showStartDateEndDateLine = startDateTextWidth + endDateTextWidth + this.paddingInsets.left + this.paddingInsets.right < w;
    const startEndDateLineTextY = y + h / 1.3 - fsize;

    if (showStartDateEndDateLine) {
      context.save();
      font = font.replace(/\d+px/, `${fsize}px`);
      context.font = font;
      const textY = startEndDateLineTextY;
      let textX = x + this.paddingInsets.left;
      context.fillStyle = this._startDateColor ?? context.fillStyle;
      context.fillText(startDate, textX, textY);
      textX = x + w - endDateTextWidth - this.paddingInsets.right;
      context.fillStyle = this._endDateColor ?? context.fillStyle;
      context.fillText(endDate, textX, textY);
      context.restore();
    }

    if (task) {
      const firstLineText = this.getLineText(this._groupedProperties[FIRST_LINE_GROUP_INDEX], activityRef.activity.userObject);
      const firstLineTextLines = this.getTextLines(context, firstLineText, w);

      if (w > 50) {
        // draw first line
        const firstLineX = x + w / 2;

        context.font = font.replace("normal", "bold");
        context.textAlign = "center";

        for (let i = 0; i < firstLineTextLines.length; i++) {
          const line = firstLineTextLines[i];
          const lineY = y + fsize + i * fsize;
          context.fillText(line, firstLineX, lineY);
        }

        context.font = font;

        // draw second line
        const secondLineText = this.getLineText(this._groupedProperties[SECOND_LINE_GROUP_INDEX], activityRef.activity.userObject);
        const secondLineTextLines = this.getTextLines(context, secondLineText, w);

        const secondLineTextX = x + w / 2;

        for (let i = 0; i < secondLineTextLines.length; i++) {
          const line = secondLineTextLines[i];
          const lineY = y + fsize * (2 + firstLineTextLines.length) + i * fsize;

          if (!this.canDrawLine(lineY, w, h, fsize, statusBarHeight, showStartDateEndDateLine, startEndDateLineTextY)) {
            break;
          }

          context.fillText(line, secondLineTextX, lineY);
        }

        // draw third line
        const thirdLineText = this.getLineText(this._groupedProperties[THIRD_LINE_GROUP_INDEX], activityRef.activity.userObject);
        const thirdLineTextLines = this.getTextLines(context, thirdLineText, w);

        const thirdLineTextX = x + w / 2;

        for (let i = 0; i < thirdLineTextLines.length; i++) {
          const line = thirdLineTextLines[i];
          const lineY = y + fsize * (2.5 + firstLineTextLines.length + secondLineTextLines.length) + i * fsize;

          if (!this.canDrawLine(lineY, w, h, fsize, statusBarHeight, showStartDateEndDateLine, startEndDateLineTextY)) {
            break;
          }

          context.fillText(line, thirdLineTextX, lineY);
        }
      }
    }
  }

  private canDrawLine(lineY: number, w: number, h: number, fsize: number, statusBarHeight: number, showStartDateEndDateLine: boolean, startEndDateLineTextY: number): boolean {
    // the drawing point is out of bounds
    if (lineY > h - statusBarHeight * 2) return false;

    // the drawing point coincides with the drawn start/end dates line
    if (showStartDateEndDateLine) {
      if (lineY > startEndDateLineTextY - fsize) {
        return false;
      }
    }

    return true;
  }

  private getLineText(groupProperties: GanttActivityDisplayPropertySetting[], dto: EnhancedGanttResourceCapacityDto) {
    if (!groupProperties) {
      return "";
    } else {
      return groupProperties.map((property) => this.mapPropertyToValue(property.propertyName, dto)).join(" ‧ ");
    }
  }

  private mapPropertyToValue(propertyName: string, dto: EnhancedGanttResourceCapacityDto): string {
    const { task, step }: EnhancedGanttResourceCapacityDto = dto;

    if (propertyName === "taskName") return task?.name ?? "";
    if (propertyName === "taskBusinessId") return task?.businessId ?? "";
    if (propertyName === "stepName") return step?.name ?? "";
    if (propertyName === "scheduledQtyGood") return step?.statistics?.schedulingQuantityGood?.toString() ?? "";
    if (propertyName === "executedQtyGood") return step?.statistics?.executionQuantityGood?.toString() ?? "";
    if (propertyName === "producedResourceBusinessId") return task?.producedResource?.businessId ?? "";
    if (propertyName === "customerBusinessId") return task?.organizations.map((org) => org.businessId).join(", ") ?? "";

    console.warn("Unknown property - no activity value provider", propertyName);

    return "";
  }


}
