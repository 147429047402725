import { ApiClient } from "@/services/api/common.api";
import { GetLocationDetailsCommand, GetLocationsPaginatedQuery, IAgGridPaginationResponse, LocationDetailsDto, LocationDto } from "@masta/generated-model";

export default {
  async getPaginated(request: GetLocationsPaginatedQuery) {
    return ApiClient.post<IAgGridPaginationResponse<LocationDto>>(`/locations`, request);
  },
  async getSingle(id: string, scenarioId: string) {
    return ApiClient.get<LocationDto>(`/locations/${id}?scenarioId=${scenarioId}`);
  },
  async getLocations({ scenarioId }: { scenarioId: string }) {
    return ApiClient.get<any>(`/locations?scenarioId=${scenarioId}`);
  },
  async create(request: Partial<LocationDto>) {
    return ApiClient.put<string>(`/locations`, request);
  },
  async update(request: Partial<LocationDto>) {
    return ApiClient.put<string>(`/locations/${request.id}`, request);
  },
  async remove(id: string, scenarioId: string) {
    return ApiClient.delete(`/locations/${id}?scenarioId=${scenarioId}`);
  },
  async getLocationDetails({ scenarioId, locationId }: GetLocationDetailsCommand) {
    return ApiClient.get<LocationDetailsDto>(`/locations/${scenarioId}/${locationId}`);
  }
};
