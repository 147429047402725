<script lang="ts" setup>
import { NumberRequirementDefinition } from "@masta/generated-model";

const props = defineProps<{
  modelValue: NumberRequirementDefinition;
  disabled: boolean;
}>();

const emit = defineEmits(["update:modelValue", "remove"]);

function update(propertyName: string, value: any) {
  const newRequirement = { ...props.modelValue, [propertyName]: value };
  emit("update:modelValue", newRequirement);
}

const comparisons = ["=", "<>", "<", "<=", ">", ">="];
</script>

<template>
  <v-col cols="5" xl="6">
    <v-text-field
      label="JSON Path"
      variant="outlined"
      density="compact"
      hide-details
      :model-value="modelValue.jsonPath"
      :disabled="disabled"
      @update:model-value="update('jsonPath', $event)"
    />
  </v-col>
  <v-col cols="2" xl="1" class="text-center d-flex align-center justify-center">
    <v-chip size="x-large" label :disabled="disabled" style="width: 100%">{{ $t("requirementDefinition-numberRequirementEditor-type-label", { $: "Number" }) }}</v-chip>
  </v-col>
  <v-col cols="2">
    <v-combobox
      label="Comparison"
      variant="outlined"
      density="compact"
      :model-value="modelValue.compareMethod"
      :disabled="disabled"
      :items="comparisons"
      hide-details
      @update:model-value="update('compareMethod', $event)"
    />
  </v-col>
  <v-col cols="2">
    <v-text-field
      label="Value"
      type="number"
      variant="outlined"
      density="compact"
      hide-details
      :model-value="modelValue.value"
      :disabled="disabled"
      @update:model-value="update('value', Number($event))"
    />
  </v-col>
  <v-col cols="1" class="text-right d-flex align-center justify-center">
    <v-btn variant="text" icon="mdi-close" size="large" hide-details :disabled="disabled" @click="emit('remove')"></v-btn>
  </v-col>
</template>
