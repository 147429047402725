import { GanttEvents, IocSymbols, isCtrlKey, Lifecycle } from "../../Core";
import { inject, injectable } from "inversify";
import { ViewportPointerEventNoneScrollGlass } from "./ViewportPointerEventNoneScrollGlass";
import { combineLatest, distinct, fromEvent } from "rxjs";

export type DragAndDropData = {
  activity: any;
  rowId: string;
};

@injectable()
export class DragAndDropManager extends Lifecycle {
  private _element: HTMLElement;

  constructor(
    @inject(IocSymbols.HtmlContainerSymbol) private readonly _rootElement: HTMLElement,
    @inject(GanttEvents) private _ganttEvents: GanttEvents,
    @inject(ViewportPointerEventNoneScrollGlass) private _glassLayer: ViewportPointerEventNoneScrollGlass
  ) {
    super();
  }

  async afterInitialize(): Promise<void> {
    await super.afterInitialize();
    this._element = this._glassLayer.element;
    this._element.draggable = true;

    this.subscribe(fromEvent<DragEvent>(this._element, "dragstart").subscribe(this.onDragStart.bind(this)));
    this.subscribe(fromEvent<DragEvent>(this._element, "dragover").subscribe(this.onDragOver.bind(this)));
    this.subscribe(fromEvent<DragEvent>(this._element, "dragend").subscribe(this.onDragEnd.bind(this)));
    this.subscribe(fromEvent<DragEvent>(this._element, "dragenter").subscribe(this.onDragEnter.bind(this)));
    this.subscribe(fromEvent<DragEvent>(this._element, "dragleave").subscribe(this.onDragLeave.bind(this)));
    this.subscribe(fromEvent<DragEvent>(this._element, "drag").subscribe(this.onDrag.bind(this)));
    this.subscribe(fromEvent<DragEvent>(this._element, "drop").subscribe(this.onDrop.bind(this)));

    this.subscribe(
      combineLatest([
        fromEvent<MouseEvent>(document, "mousemove"),
        this._ganttEvents.selectedActivities$$,
        this._ganttEvents.hoveredActivities$$,
        this._ganttEvents.pressedActivities$$,
        this._ganttEvents.ganttActivityDrag$$,
        this._ganttEvents.externalActivityDrag$$
      ]).subscribe(([_, selectedActs, hoveredActs, pressedActs, isDragging, isExternalDrag]) => {
        const canDrag = (selectedActs.length > 0 || (pressedActs.length > 0 && hoveredActs.length > 0)) && !isDragging && !isExternalDrag;

        if (canDrag) {
          const selIds = [...new Set([...selectedActs.map((x) => x?.activity?.id), ...pressedActs.map((x) => x?.activity?.id)])]; // remove duplicates
          const hovIds = hoveredActs.map((x) => x.activity.id);

          const isHoveringSelected = hovIds.filter((x) => selIds.includes(x)).length > 0;

          if (isHoveringSelected) {
            this._ganttEvents.dragAndDropEnabled$$.next(true);
          } else {
            this._ganttEvents.dragAndDropEnabled$$.next(false);
          }
        } else {
          this._ganttEvents.dragAndDropEnabled$$.next(false);
        }
      })
    );

    this.subscribe(this._ganttEvents.dragAndDropEnabled$$.subscribe((enabled) => {
      if (enabled) {
        if (!this._glassLayer.element.draggable) {
          this._glassLayer.element.draggable = true;
          this._glassLayer.element.style.setProperty("-webkit-user-drag", "element !important");
          if (!this._rootElement.classList.contains("drag")) {
            this._rootElement.classList.add("drag");
          }
        }
      } else {
        if (this._glassLayer.element.draggable) {
          this._glassLayer.element.draggable = false;
          this._glassLayer.element.style.removeProperty("-webkit-user-drag");
          if (this._rootElement.classList.contains("drag")) {
            this._rootElement.classList.remove("drag");
          }
        }
      }
    }));
  }

  private onDragStart(event: DragEvent): void {

    const selected = this._ganttEvents.selectedActivities$$.value.map((se) => {
      return {
        activity: se.activity.userObject,
        rowId: se.row.id
      };
    });

    const pressed = this._ganttEvents.pressedActivities$$.value.map((se) => {
      return {
        activity: se.activity.userObject,
        rowId: se.row.id
      };
    });

    // cancel if pressed activity is not selected
    if (pressed.length > 0 && selected.length > 0 && !isCtrlKey(event)) {
      const pressedIds = pressed.map((x) => x.activity.id);
      const selectedIds = selected.map((x) => x.activity.id);
      const isPressedSelected = pressedIds.filter((x) => selectedIds.includes(x)).length > 0;
      if (!isPressedSelected) {
        event.preventDefault();
        return;
      }
    }


    let data: DragAndDropData[] = [...selected, ...pressed];
    // remove duplicates
    data = data.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj.activity.id).indexOf(obj.activity.id) === pos;
    });

    event.dataTransfer!.setData("application/json", JSON.stringify(data));
    event.dataTransfer!.effectAllowed = "copyMove";

    // console.log("%cDragStart", "color: white; background-color: brown; padding: 2px;");
    this._ganttEvents.ganttActivityDrag$$.next(data);
    this._ganttEvents.externalActivityDrag$$.next(false);
    this._ganttEvents.onDragStartEvent(event);
    this._ganttEvents.onTooltipActivityEvent(null); // hide tooltip
    this._glassLayer.element.classList.add("dragging");
  }

  private onDragOver(event: DragEvent): void {
    const dataTransfer = event.dataTransfer;
    if (dataTransfer && dataTransfer.types.some((type) => type === "application/json")) {
      this._ganttEvents.onDragOverEvent(event);
      // console.log("%cDragOver", "color: black; background-color: yellow; padding: 2px;");
    } else {
      console.log("%cDrag not allowed!", "color: red;");
    }
  }

  private onDragEnter(event: DragEvent): void {
    if (!this._ganttEvents.ganttActivityDrag$$.value) {
      this._ganttEvents.externalActivityDrag$$.next(true);
    }
    // console.log("%cDragEnter", "color: black; background-color: blue; padding: 2px;");
    this._ganttEvents.onDragEnterEvent(event);
  }

  private onDragLeave(event: DragEvent): void {
    // console.log("%cDragLeave", "color: white; background-color: red; padding: 2px;");
    this._ganttEvents.onDragLeaveEvent(event);
    if (!this._ganttEvents.ganttActivityDrag$$.value) {
      this._ganttEvents.externalActivityDrag$$.next(false);
    }
  }

  private onDrag(event: DragEvent): void {
    // console.log("%cDrag", "color: white; background-color: green; padding: 2px;");
    this._ganttEvents.onDragEvent(event);
  }

  private onDragEnd(event: DragEvent): void {
    this._ganttEvents.onDragEndEvent(event);
    this._ganttEvents.pressedActivities$$.next([]);
    this._ganttEvents.selectedActivities$$.next([]);
    // console.log("%cDragEnd", "color: black; background-color: orange; padding: 2px;");
    this._ganttEvents.ganttActivityDrag$$.next(null);
    this._ganttEvents.externalActivityDrag$$.next(false);
    this._glassLayer.element.classList.remove("dragging");
  }

  private onDrop(event: DragEvent): void {
    // console.log("%cDrop", "color: white; background-color: purple; padding: 2px;");
    this._ganttEvents.onDropEvent(event);
    if (!this._ganttEvents.ganttActivityDrag$$.value) {
      this._ganttEvents.externalActivityDrag$$.next(false);
    }
    this._glassLayer.element.classList.remove("dragging");
  }
}
