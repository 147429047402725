<script lang="ts" setup>
import { ref } from "vue";
import { useSnackbarsStore } from "@/store/SnackbarsStore";
import { GridWrapperComponent } from "@/components/Grid/GridWrapperComponent";
import DeleteKpiDialog, { DeleteKpiItem } from "@/components/KpiResults/DeleteKpiDialog.vue";
import { useKeyPerformanceIndicatorsStore } from "@/store/KeyPerformanceIndicatorsStore";
import {  } from "@/store/SnackbarsStore";
import { useErrorsStore } from "@/store/ErrorsStore";

interface Props {
    gridWrapper?: GridWrapperComponent;
}

const properties = defineProps<Props>();

const values = ref<DeleteKpiItem[]>([]);

const keyPerformanceIndicatorsStore = useKeyPerformanceIndicatorsStore();
const errorsStore = useErrorsStore();

const dialog = ref(false); // Reactive reference to manage dialog state

function triggerDeleteAction() {
    const calculationIds = keyPerformanceIndicatorsStore.getKpiCalculationIds();
    const scenarioIds = keyPerformanceIndicatorsStore.getKpiScenarioIds();

    const items = calculationIds
        .map((id) => ({ id, type: "calculation" }))
        .concat(scenarioIds.map((id) => ({ id, type: "scenario" })));


    values.value = items as DeleteKpiItem[]; // Prepare the values
    dialog.value = true; // Show the dialog
}

async function onDialogResult(result: DeleteKpiItem | null) {
    if(result != null) {
        await deleteKpi(result);
        properties.gridWrapper?.crudActions?.onRefreshAction();
    }
}


async function deleteKpi(kpiItem: DeleteKpiItem) {
    try {
        if(kpiItem.type === "calculation") {
            await keyPerformanceIndicatorsStore.deleteByCalculationId(kpiItem.id);
        } else {
            await keyPerformanceIndicatorsStore.deleteByScenarioId(kpiItem.id);
        }
    } catch (e: any) {
        errorsStore.handleError(e);
    }
}
</script>

<template>
    <v-tooltip location="bottom" open-delay="300">
      <template #activator="{ props }">
        <div class="d-inline-flex pr-4">
          <v-btn size="small" variant="text" density="compact" v-bind="props" @click="triggerDeleteAction">
            <v-icon icon="mdi-delete-outline" class="pr-4" />
            {{ $t("kpiResults-delete-label", { $: "Delete" }) }}
          </v-btn>
        </div>
      </template>
      <span>{{ $t("kpiResults-delete-tooltip", { $: "Delete" }) }}</span>
    </v-tooltip>
    <delete-kpi-dialog v-model="dialog" :values="values" @result="onDialogResult" />
  </template>