<script lang="ts" setup>
import { QuantitativeFeatureDefinition } from "@masta/generated-model";
import { $t } from "@/i18n";
import { isNumberRule, isZeroOrGreaterNumberRule, requiredRule } from "@/components/ValueCellEditor/CommonValidationRules";

const props = defineProps<{
  modelValue: QuantitativeFeatureDefinition;
  disabled: boolean;
}>();

const emit = defineEmits(["update:modelValue", "remove"]);

function update(propertyName: string, value: any) {
  const newFeature = { ...props.modelValue, [propertyName]: value };
  emit("update:modelValue", newFeature);
}

const nameRules = [
  (v: string) => !!v || $t("featureDefinition-quantitativeEditor-onNameRequired-message", { $: "Name is required" }),
  (v: string) => (v && v.length > 0) || $t("featureDefinition-quantitativeEditor-onNameNotEmptyRequired-message", { $: "Name must not be empty" })
];
</script>

<template>
  <v-col cols="5" xl="6">
    <v-row>
      <v-col cols="3">
        <v-text-field
          variant="outlined"
          density="compact"
          hide-details
          :rules="[requiredRule]"
          :label="$t('featureDefinition-quantitativeEditor-name-label', { $: 'Name' })"
          :model-value="modelValue.name"
          :disabled="disabled"
          @update:model-value="update('name', $event)"
        />
      </v-col>
      <v-col cols="3">
        <v-text-field
          variant="outlined"
          density="compact"
          hide-details
          :label="$t('featureDefinition-quantitativeEditor-label-label', { $: 'Label' })"
          :model-value="modelValue.label"
          :disabled="disabled"
          @update:model-value="update('label', $event)"
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          variant="outlined"
          density="compact"
          hide-details
          :label="$t('featureDefinition-quantitativeEditor-description-label', { $: 'Description' })"
          :model-value="modelValue.description"
          :disabled="disabled"
          @update:model-value="update('description', $event)"
        />
      </v-col>
    </v-row>
  </v-col>
  <v-col cols="6" xl="5">
    <v-row>
      <v-col cols="3" xl="2" offset-xl="1">
        <v-switch
          variant="outlined"
          density="compact"
          hide-details
          color="primary"
          :label="$t('featureDefinition-quantitativeEditor-required-label', { $: 'Required' })"
          :model-value="modelValue.isRequired"
          :disabled="disabled"
          @update:model-value="update('isRequired', $event)"
        />
      </v-col>
      <v-col cols="2" class="text-center d-flex align-center justify-center">
        <v-chip size="x-large" label style="width: 100%">
          {{ $t("featureDefinition-quantitativeEditor-quantitative-label", { $: "Quantitative" }) }}
        </v-chip>
      </v-col>
      <v-col cols="2">
        <v-text-field
          variant="outlined"
          density="compact"
          hide-details
          :rules="[isNumberRule]"
          :label="$t('featureDefinition-quantitativeEditor-target-label', { $: 'Target' })"
          type="number"
          :model-value="modelValue.target"
          :disabled="disabled"
          @update:model-value="update('target', Number($event))"
        />
      </v-col>
      <v-col cols="2">
        <v-text-field
          variant="outlined"
          density="compact"
          hide-details
          :rules="[isZeroOrGreaterNumberRule]"
          :label="$t('featureDefinition-quantitativeEditor-toleranceLeft-label', { $: 'Tolerance Left' })"
          type="number"
          :model-value="modelValue.toleranceLeft"
          :disabled="disabled"
          @update:model-value="update('toleranceLeft', Number($event))"
        />
      </v-col>
      <v-col cols="2">
        <v-text-field
          variant="outlined"
          density="compact"
          hide-details
          :rules="[isZeroOrGreaterNumberRule]"
          :label="$t('featureDefinition-quantitativeEditor-toleranceRight-label', { $: 'Tolerance Right' })"
          type="number"
          :model-value="modelValue.toleranceRight"
          :disabled="disabled"
          @update:model-value="update('toleranceRight', Number($event))"
        />
      </v-col>
    </v-row>
  </v-col>
  <v-col cols="1" class="text-right d-flex align-center justify-center">
    <v-btn variant="text" icon="mdi-close" size="large" hide-details :disabled="disabled" @click="emit('remove')"></v-btn>
  </v-col>
</template>
