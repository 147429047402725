<template>
  <master-detail-layout scenario-selector :detail="task !== null" :detail-actions="detailActions" detail-dialog-width="100%" @change="$refs.reportsGrid.gridApi.deselectAll()">
    <template #master>
      <v-card elevation="0" class="flexcard fill-height">
        <v-card-title>
          <breadcrumbs>{{ $t("report-view-reports-title", { $: "Reports" }) }}</breadcrumbs>
        </v-card-title>
        <v-card-text class="fill-height">
          <v-row class="fill-height" no-gutters>
            <v-col cols="12">
              <reports-grid ref="reportsGrid" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
  </master-detail-layout>
</template>

<script>
import MasterDetailLayout from "@/components/Layout/MasterDetailLayout.vue";
import ReportsGrid from "@/components/Reports/ReportsGrid.vue";
import Breadcrumbs from "@/components/Layout/Breadcrumbs.vue";

export default {
  components: { Breadcrumbs, ReportsGrid, MasterDetailLayout },
  data: () => ({
    task: null
  }),
  computed: {
    detailActions() {
      return [
        {
          action: this.scheduleCurrent,
          icon: "mdi-calendar",
          title: "Schedule"
        }
      ];
    }
  },
  methods: {
    async scheduleCurrent() {}
  }
};
</script>

<style lang="scss" scoped></style>
