import ApiService from "@/services/api";
import {
  CreateDocumentForContextCommand,
  CreateNewDocumentRevisionWithNewAttachmentCommand,
  ModelInstanceDto,
  ModelSchemaDto,
  UpdateDocumentCommand
} from "@masta/generated-model";
import { defineStore } from "pinia";

interface State {
  schemas: ModelSchemaDto[];
  selectedSchema: ModelSchemaDto | null | undefined;
  instances: ModelInstanceDto[];
}

export function getSchemaId(schema: ModelSchemaDto) {
  return `${schema.schemaKey}.${schema.schemaName}`;
}

export const useDocumentsStore = defineStore("documents", {
  state: (): State => ({
    schemas: [],
    selectedSchema: null,
    instances: []
  }),
  actions: {
    async createDocumentWithAttachment(createDocument: CreateDocumentForContextCommand) {
      const { data } = await ApiService.documents.createDocument(createDocument);
      return data;
    },
    async updateDocumentWithAttachment(updateDocument: UpdateDocumentCommand) {
      await ApiService.documents.updateDocument(updateDocument);
    },
    async deleteDocumentWithAttachment(instance: ModelInstanceDto) {
      await ApiService.documents.deleteDocument(instance.id, instance.revisionNumber);
    },
    async deassignResourceDocument(documentId: string, resourceId: string) {
      await ApiService.documents.deassignResourceDocument(documentId, resourceId);
    },
    async deleteResourceDocument(documentId: string, revisionNumber: number, resourceId: string) {
      await ApiService.documents.deleteResourceDocument(documentId, revisionNumber, resourceId);
    },
    async deassignOrderDocument(documentId: string, orderId: string) {
      await ApiService.documents.deassignOrderDocument(documentId, orderId);
    },
    async deleteOrderDocument(documentId: string, revisionNumber: number, orderId: string) {
      await ApiService.documents.deleteOrderDocument(documentId, revisionNumber, orderId);
    },
    async deassignCostCatalogueItemDocument(documentId: string, costCatalogueItemId: string) {
      await ApiService.documents.deassignCostCatalogueItemDocument(documentId, costCatalogueItemId);
    },
    async deleteCostCatalogueItemDocument(documentId: string, revisionNumber: number, costCatalogueItemId: string) {
      await ApiService.documents.deleteCostCatalogueItemDocument(documentId, revisionNumber, costCatalogueItemId);
    },
    async createNewRevisionWithNewAttachment(createNewDocumentRevisionWithNewAttachmentCommand: CreateNewDocumentRevisionWithNewAttachmentCommand) {
      await ApiService.documents.createNewRevisionWithNewAttachment(createNewDocumentRevisionWithNewAttachmentCommand);
    }
  }
});
