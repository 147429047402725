<script lang="ts" setup>
import { NumberRangeRequirementDefinition } from "@masta/generated-model";

const props = defineProps<{
  modelValue: NumberRangeRequirementDefinition;
  disabled: boolean;
}>();

const emit = defineEmits(["update:modelValue", "remove"]);

function update(propertyName: string, value: any) {
  const newRequirement = { ...props.modelValue, [propertyName]: value };
  emit("update:modelValue", newRequirement);
}
</script>

<template>
  <v-col cols="5" xl="6">
    <v-text-field
      label="JSON Path"
      variant="outlined"
      density="compact"
      hide-details
      :model-value="modelValue.jsonPath"
      :disabled="disabled"
      @update:model-value="update('jsonPath', $event)"
    />
  </v-col>
  <v-col cols="2" xl="1" class="text-center d-flex align-center justify-center">
    <v-chip size="x-large" label class="text-wrap" style="width: 100%">{{ $t("requirementDefinition-numberRangeRequirementEditor-type-label", { $: "Number Range" }) }}</v-chip>
  </v-col>
  <v-col cols="2">
    <v-text-field
      variant="outlined"
      density="compact"
      :label="$t('requirementDefinition-numberRangeRequirementEditor-minimalRange-label', { $: 'Min' })"
      type="number"
      hide-details
      :model-value="modelValue.min"
      :disabled="disabled"
      @update:model-value="update('min', Number($event))"
    />
  </v-col>
  <v-col cols="2">
    <v-text-field
      :label="$t('requirementDefinition-numberRangeRequirementEditor-maximalRange-label', { $: 'Max' })"
      type="number"
      variant="outlined"
      density="compact"
      hide-details
      :model-value="modelValue.max"
      :disabled="disabled"
      @update:model-value="update('max', Number($event))"
    />
  </v-col>
  <v-col cols="1" class="text-right d-flex align-center justify-center">
    <v-btn variant="text" icon="mdi-close" size="large" hide-details :disabled="disabled" @click="emit('remove')"></v-btn>
  </v-col>
</template>
