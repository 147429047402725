import { SettingsColDef } from "@/components/Grid/Profile/SettingsColDef";
import { UserInterfaceProfileController } from "@/services/api-service";
import { GridState, GridStateActions, useGridStore } from "@/store/GridRegistryState";
import { SettingContext, UserInterfaceProfileDto } from "@masta/generated-model";
import { resolveUnref, useDebounceFn } from "@vueuse/core";
import { ChartCreated, ChartDestroyed, ChartOptionsChanged, ChartRangeSelectionChanged, ColumnEvent, ColumnPivotModeChangedEvent, ColumnState, 
  ExpandCollapseAllEvent, FilterChangedEvent, FirstDataRenderedEvent, GridApi, GridReadyEvent } from "ag-grid-community";
import _ from "lodash";
import { Store } from "pinia";
import { computed, onBeforeUnmount, Ref, ref, UnwrapRef, watch } from "vue";

export class GridProfileService {
  private _store: Store<string, GridState, any, GridStateActions>;
  private _api: UserInterfaceProfileController;

  constructor(store: Store<string, GridState, any, GridStateActions>) {
    this._store = store;
    this._api = new UserInterfaceProfileController();
  }

  get profiles(): UserInterfaceProfileDto[] {
    return this._store.profiles;
  }

  public createDefaultProfile(profile: string, context: SettingContext): UserInterfaceProfileDto {
    return {
      profile,
      name: "default",
      filterModel: null,
      columnStates: [],
      context,
      isDefault: false,
      id: null,
      autosizeColumns: false,
      expandAllRows: false,
      pivotMode: false,
      chartModel: null
    };
  }

  async getProfile(profile: string, context: SettingContext, name?: string): Promise<UserInterfaceProfileDto | undefined> {
    const existingProfiles = this._store.profiles.filter((x) => x.profile === profile);
    if (existingProfiles.length === 0) {
      const { data } = await this._api.getProfiles(profile);
      const ids = data.profiles!.map((x) => x.id);
      const temp = _.sortBy([...this._store.profiles.filter((x) => !ids.includes(x.id)), ...data.profiles!], ["profile", "name"], ["asc"]);
      this._store.profiles.splice(0, this._store.profiles.length);
      temp.forEach((x) => this._store.profiles.push(x));
    }
    const sortedProfiles = this._store.profiles.sort((a, b) => b.context - a.context);
    return sortedProfiles.find((x) => x.profile === profile && x.context <= context && ((!name && x.isDefault) || x.name === name));
  }

  async getAllProfiles(profile: string): Promise<UserInterfaceProfileDto | undefined> {
    const existingProfiles = this._store.profiles.filter((x) => x.profile === profile);
    if (existingProfiles.length === 0) {
      const { data } = await this._api.getProfiles(profile);
      const temp = _.sortBy([...this._store.profiles, ...data.profiles!], ["profile", "name"], ["asc"]);
      this._store.profiles.splice(0, this._store.profiles.length);
      temp.forEach((x) => this._store.profiles.push(x));
    }
    return this._store.profiles.find((x) => x.profile === profile);
  }

  async saveProfile(profileDefinition: UserInterfaceProfileDto): Promise<UserInterfaceProfileDto | null> {
    const { data } = await this._api.createOrUpdateProfile({
      ...profileDefinition
    });
    const temp = _.sortBy(
      [...this._store.profiles.filter((x) => x.id !== data.profile?.id), data.profile].map((x) => {
        if (x && data.profile?.isDefault && x.id !== data.profile?.id && x.isDefault) {
          x.isDefault = false;
        }
        return x;
      }),
      ["profile", "name"],
      ["asc"]
    );
    this._store.profiles.splice(0, this._store.profiles.length);
    temp.forEach((x) => {
      if (x) this._store.profiles.push(x);
    });
    return data.profile;
  }

  async deleteProfile(profileId: string) {
    await this._api.deleteProfile(profileId);
    const temp = _.sortBy([...this._store.profiles.filter((x) => x.id !== profileId)], ["profile", "name"], ["asc"]);
    this._store.profiles.splice(0, this._store.profiles.length);
    temp.forEach((x) => this._store.profiles.push(x));
  }
}

export const useGridProfileService = (identifier: Ref<string | undefined> | UnwrapRef<string | undefined> | string | undefined) => {
  const store = useGridStore(resolveUnref(identifier));
  return new GridProfileService(store);
};

export interface IGridProfileOptions {
  columnDefs: Ref<SettingsColDef[]>;
  identifier: Ref<string | undefined>;
}

export interface IGridProfile {
  service: GridProfileService;
  onGridReady: (params: GridReadyEvent) => Promise<void>;
  getProfile: (name?: string) => Promise<UserInterfaceProfileDto | undefined>;
  selectProfile: (_profile: UserInterfaceProfileDto) => Promise<void>;
  isUnsaved: Ref<boolean>;
  profile: Ref<UserInterfaceProfileDto | undefined>;
}

export function useGridProfile(options: IGridProfileOptions): IGridProfile {
  const { columnDefs, identifier } = options;
  const gridApi = ref<GridApi | null>(null);
  const profile = ref<UserInterfaceProfileDto | undefined>(undefined);
  const isUnsaved = ref<boolean>(false);
  const service = useGridProfileService(resolveUnref(options.identifier));

  const isCypress = computed(() => !!window.Cypress);

  watch(
    profile,
    (value, oldValue, onCleanup) => {
      if (oldValue && value) {
        isUnsaved.value = oldValue.id === value.id;
      }
    },
    { immediate: true, deep: true }
  );

  async function getProfile(name?: string) {
    if (!identifier.value) {
      return;
    }
    return await service.getProfile(identifier.value, SettingContext.Tenant, name);
  }

  async function onGridReady(params: GridReadyEvent) {
    gridApi.value = params.api;
    profile.value = await getProfile();

    if (!profile.value) {
      profile.value = service.createDefaultProfile(identifier.value ?? "default", SettingContext.Tenant);
    }

    applyColumnState();
    applyFilterModel();
    applyPivotMode();
    expandAllRows();
    restoreChart();
    // INFO : no autoSizeAll - this is call when data is rendered (onFirstDataRendered)

    gridApi.value.addEventListener("firstDataRendered", onFirstDataRendered);
    gridApi.value.addEventListener("columnResized", saveGridColumnState);
    gridApi.value.addEventListener("columnPinned", saveGridColumnState);
    gridApi.value.addEventListener("columnMoved", saveGridColumnState);
    gridApi.value.addEventListener("columnVisible", saveGridColumnState);
    gridApi.value.addEventListener("columnValueChanged", saveGridColumnState);
    gridApi.value.addEventListener("sortChanged", saveGridColumnState);
    gridApi.value.addEventListener("filterChanged", onFilterChanged);
    gridApi.value.addEventListener("expandOrCollapseAll", onExpandOrCollapseAll);
    gridApi.value.addEventListener("columnPivotModeChanged", onColumnPivotModeChanged);
    gridApi.value.addEventListener("chartCreated", onChartCreated);
    gridApi.value.addEventListener("chartOptionsChanged", onChartChanged);
    gridApi.value.addEventListener("chartRangeSelectionChanged", onChartChanged);
    gridApi.value.addEventListener("chartDestroyed", onChartDestroyed);
  }

  onBeforeUnmount(() => {
    gridApi.value?.removeEventListener("firstDataRendered", onFirstDataRendered);
    gridApi.value?.removeEventListener("columnResized", saveGridColumnState);
    gridApi.value?.removeEventListener("columnPinned", saveGridColumnState);
    gridApi.value?.removeEventListener("columnMoved", saveGridColumnState);
    gridApi.value?.removeEventListener("columnVisible", saveGridColumnState);
    gridApi.value?.removeEventListener("columnValueChanged", saveGridColumnState);
    gridApi.value?.removeEventListener("sortChanged", saveGridColumnState);
    gridApi.value?.removeEventListener("filterChanged", onFilterChanged);
    gridApi.value?.removeEventListener("expandOrCollapseAll", onExpandOrCollapseAll);
    gridApi.value?.removeEventListener("columnPivotModeChanged", onColumnPivotModeChanged);
    gridApi.value?.removeEventListener("chartCreated", onChartCreated);
    gridApi.value?.removeEventListener("chartOptionsChanged", onChartChanged);
    gridApi.value?.removeEventListener("chartRangeSelectionChanged", onChartChanged);
    gridApi.value?.removeEventListener("chartDestroyed", onChartDestroyed);
  });

  function onFirstDataRendered(event: FirstDataRenderedEvent) {
    autoSizeAll();
  }

  function applyColumnState() {
    if (!gridApi.value || !profile.value) return;
    gridApi.value.applyColumnState({ state: profile.value.columnStates as ColumnState[], applyOrder: true });
  }

  function applyFilterModel() {
    if (!gridApi.value || !profile.value || !profile.value.filterModel) return;
    gridApi.value.setFilterModel(profile.value.filterModel);
  }

  function applyPivotMode() {
    if (!gridApi.value || !profile.value) return;
    gridApi.value.setGridOption("pivotMode", profile.value.pivotMode ?? false);
  }

  function expandAllRows() {
    if (profile.value?.expandAllRows) {
      gridApi.value?.expandAll();
    }
  }

  function autoSizeAll() {
    if (!gridApi.value || !profile.value || !profile.value.autosizeColumns) return;
    
    if (isCypress.value) {
      gridApi.value?.sizeColumnsToFit();
      return;
    }
    const skipHeader = false;
    const allColumnIds: any[] = [];
    gridApi.value?.getColumns()?.forEach((column) => {
      if (!column.getColDef().suppressSizeToFit) allColumnIds.push(column.getId());
    });
    gridApi.value?.autoSizeColumns(allColumnIds, skipHeader);
  }

  function restoreChart() {
    if (!gridApi.value || !profile.value) return;
    if (!isStoreChartEnabled()) return;

    // destory existing charts
    const existingChartModels = gridApi.value.getChartModels() ?? [];

    existingChartModels.forEach((model) => {
      const chartRef = gridApi.value?.getChartRef(model.chartId);
      chartRef?.destroyChart();
    });

    // restore chart if exists
    if (profile.value.chartModel) {
      const chartModel = JSON.parse(profile.value.chartModel);
      gridApi.value.restoreChart(chartModel);
    }
  }

  async function onExpandOrCollapseAll(event: ExpandCollapseAllEvent) {
    if (!profile.value) return;

    if (event.type == "expandOrCollapseAll") {
      profile.value.expandAllRows = event.source === "expandAll";
    }
  }

  async function onColumnPivotModeChanged(event: ColumnPivotModeChangedEvent) {
    if (!profile.value) return;
    profile.value.pivotMode = event.api.isPivotMode();
  }

  async function onChartCreated(event: ChartCreated) {
    if (!profile.value) return;
    if (!isStoreChartEnabled()) return;

    const chartModels = event.api.getChartModels() || [];
    if (chartModels.length > 0) {
      const chartModel = chartModels[0];
      profile.value.chartModel = JSON.stringify(chartModel, null, 2);
    }
  }

  async function onChartChanged(event: ChartOptionsChanged | ChartRangeSelectionChanged) {
    if (!profile.value) return;
    if (!isStoreChartEnabled()) return;

    const chartModels = event.api.getChartModels() || [];
    if (chartModels.length > 0) {
      const chartModel = chartModels[0];
      profile.value.chartModel = JSON.stringify(chartModel, null, 2);
    }
  }

  async function onChartDestroyed(event: ChartDestroyed) {
    if (!profile.value) return;
    if (!isStoreChartEnabled()) return;

    profile.value.chartModel = null;
  }

  async function selectProfile(_profile: UserInterfaceProfileDto) {
    profile.value = _profile;

    applyColumnState();
    applyFilterModel();
    applyPivotMode();
    expandAllRows();
    autoSizeAll();
    restoreChart();
  }

  function isStoreChartEnabled() {
    return options.identifier.value === "kpi-results";
  }

  const saveGridColumnState = useDebounceFn(async (event: ColumnEvent) => {
    // skip for auto-resize
    if (event.type === "columnResized" && event.source === "autosizeColumns") return;
    if (!gridApi.value) return;
    const columnState = event.api.getColumnState();
    if (profile.value) {
      profile.value.columnStates = columnState;
    }
  }, 500);

  function onFilterChanged(event: FilterChangedEvent) {
    if (!gridApi.value) return;
    const filterModel = event.api.getFilterModel();
    if (profile.value) {
      profile.value!.filterModel = filterModel;
    }
  }

  return {
    service,
    onGridReady,
    getProfile,
    selectProfile,
    isUnsaved,
    profile
  } as IGridProfile;
}
