<script lang="ts" setup>
import GridWrapper from "@/components/Grid/GridWrapper.vue";
import { inject, reactive, ref, watch } from "vue";
import { GridWrapperComponent } from "@/components/Grid/GridWrapperComponent";
import { $dateTimeFormatterSymbol, DateFormatter } from "@masta/shared";
import {
  GridApi,
  GridOptions,
  ProcessCellForExportParams,
  RowSelectedEvent,
  StoreRefreshedEvent,
  ValueFormatterParams,
  ValueGetterParams,
  ValueSetterParams
} from "ag-grid-community";
import { getOrderLineStatusCellStyle } from "@/components/CustomerOrders/CustomerOrderUtils";
import { InboundDeliveryLinesServerSideDataSource } from "@/components/InboundDeliveries/InboundDeliveryLinesServerSideDataSource";
import {
  MaterialSubType,
  MeasurementUnit,
  OrderDto,
  OrderLineDto,
  OrderLineStatus,
  OrderStatusChangeNotificationEvent,
  OrderType,
  ResourceDto,
  ResourceInfoDto,
  ResourceType
} from "@masta/generated-model";
import { $t } from "@/i18n";
import { requiredRule } from "@/components/ValueCellEditor/CommonValidationRules";
import { translateMeasurementUnit, translateOrderLineStatus } from "@/composables/translateEnum";
import { OrderStatusChangedNotification, useNotification } from "@/notifications";
import { useDebounceFn } from "@vueuse/core/index";
import { enumToEditorEntries, translateEditorEntries } from "@/components/Grid/ColumnTypes";
import { useRouter } from "vue-router";
import { nameOrBusinessIdOrIdOrNull } from "../ValueCellEditor/CommonFormatters";
import { getNowInISOFormat } from "@/components/Datepicker/DatepickerUtil";
import { getSelectedRows } from "@/components/Grid/UseGridSelection";
import ApiService from "@/services/api";
import { useSnackbarsStore } from "@/store/SnackbarsStore";
import { useScenariosStore } from "@/store/ScenariosStore";

interface Props {
  filterByProductId?: string | null | undefined;
  readonly?: boolean;
  selectedOrder: OrderDto;
}

const router = useRouter();
const props = withDefaults(defineProps<Props>(), { filterByProductId: undefined });

const snackbarsStore = useSnackbarsStore();
const scenariosStore = useScenariosStore();

const serverSideDataSource = reactive(new InboundDeliveryLinesServerSideDataSource("purchase-order-lines"));
serverSideDataSource.useFilteringByProductId(props.filterByProductId);
serverSideDataSource.useFilteringByOrderId(props.selectedOrder.id);

const DEFAULT_CREATE_VALUE = {
  quantity: 1,
  quantityUnit: MeasurementUnit.Piece,
  deliveryDate: getNowInISOFormat(),
  position: () => resolveNextPosition(),
  status: OrderLineStatus.InPreparation
};

useNotification(OrderStatusChangedNotification, async (e: OrderStatusChangeNotificationEvent) => {
  onOrderStatusChanged(e);
});

const onOrderStatusChanged: (e: OrderStatusChangeNotificationEvent) => void = useDebounceFn(async (e: OrderStatusChangeNotificationEvent) => {
  if (e.orderId === props.selectedOrder.id) {
    gridWrapperRef.value?.gridApi?.refreshServerSide();
  }
}, 800);

const isRowSelected = ref(false);
const isOnlyInPreparationSelected = ref(false);
const isEditBtnDisabled = ref(false);
const isDeleteBtnDisabled = ref(false);
const selectedOrderLine = ref<OrderLineDto>();

function onSelectionChanged({ api }: { api: GridApi }) {
  const selectedRows = getSelectedRows(api);
  isRowSelected.value = selectedRows.length > 0;
  isOnlyInPreparationSelected.value = selectedRows.every((row) => row.status === OrderLineStatus.InPreparation);
  selectedOrderLine.value = selectedRows.length === 1 ? selectedRows[0] : undefined;
}

const gridWrapperRef = ref<GridWrapperComponent>();
const $dateTimeFormatter = inject<DateFormatter>($dateTimeFormatterSymbol)!;

function resolveNextPosition(): number {
  const nextRowOrdinal = gridWrapperRef.value ? gridWrapperRef.value.gridApi.getDisplayedRowCount() + 1 : 1;
  return nextRowOrdinal * 10;
}

function mapToResourceInfoDto(itemBusinessId: string | undefined): ResourceInfoDto | null {
  if (itemBusinessId) {
    const resourceInfo: ResourceInfoDto = {
      id: "",
      scenarioId: "",
      name: itemBusinessId,
      businessId: itemBusinessId,
      type: ResourceType.Material
    };
    return resourceInfo;
  } else {
    return null;
  }
}

const gridOptions: GridOptions = {
  processCellForClipboard: (params: ProcessCellForExportParams) => {
    const { value, column } = params;

    if (column.getColDef().type === "resourcePickerTypeColumn") {
      return params.formatValue(value);
    }

    return value;
  }
};

function onPrepareColumns(columnDefs: any) {
  columnDefs.value = [
    {
      field: "itemName",
      editable: false,
      sortable: true,
      resizable: true,
      filter: "agTextColumnFilter",
      headerValueGetter: (_: any) => $t("inboundDeliveryLine-list-itemName-label", { $: "Item Name" })
    },
    {
      field: "itemBusinessId",
      editable: true,
      sortable: true,
      filter: "agTextColumnFilter",
      resizable: true,
      type: "resourcePickerTypeColumn",
      headerValueGetter: (_: any) => $t("inboundDeliveryLine-list-itemBusinessId-label", { $: "Item Business Id" }),
      cellEditorParams: {
        resourceTypes: [ResourceType.Material],
        resourceSubTypes: props.selectedOrder.orderType === OrderType.InboundDelivery ? [MaterialSubType.RawOrSubcomponent] : [MaterialSubType.SemiFinishedOrFinalProduct],
        rules: [requiredRule],
        placeholder: $t("inboundDeliveryLine-list-itemBusinessId-label", { $: "Item Business Id" }),
        onValueChanged: (value: any) => {
          const cellEditorInstances = gridWrapperRef.value?.gridApi.getCellEditorInstances({ columns: ["quantityUnit"] });
          if (cellEditorInstances) {
            cellEditorInstances.forEach((cellEditorInstance) => {
              if (cellEditorInstance) {
                (cellEditorInstance as any).value = value?.orderableUnit ?? value?.measurementUnit;
              }
            });
          }
        }
      },
      valueFormatter: (params: ValueFormatterParams) => {
        const resourceInfo: ResourceDto = params.value;
        if (resourceInfo) {
          const result = nameOrBusinessIdOrIdOrNull(resourceInfo);
          return result ?? "";
        }
      },
      valueSetter: (params: ValueSetterParams) => {
        const resourceInfo: ResourceDto = params.newValue;
        params.data.itemBusinessId = resourceInfo?.businessId;
        params.data.quantityUnit = resourceInfo?.orderableUnit ?? resourceInfo?.measurementUnit;
        return true;
      },
      valueGetter: (params: ValueGetterParams) => {
        return mapToResourceInfoDto(params.data.itemBusinessId);
      }
    },
    {
      field: "description",
      editable: true,
      sortable: true,
      filter: "agTextColumnFilter",
      resizable: true,
      type: "textInputTypeColumn",
      cellEditorParams: {
        placeholder: $t("order-list-description-label", { $: "Description" })
      },
      headerValueGetter: (_: any) => $t("inboundDeliveryLine-list-description-label", { $: "Description" })
    },
    {
      field: "quantity",
      sortable: true,
      editable: true,
      filter: "agNumberColumnFilter",
      resizable: true,
      type: "textInputTypeColumn",
      cellEditorParams: {
        rules: [requiredRule],
        placeholder: $t("inboundDeliveryLine-list-quantity-label", { $: "Quantity" })
      },
      headerValueGetter: (_: any) => $t("inboundDeliveryLine-list-quantity-label", { $: "Quantity" })
    },
    {
      field: "quantityUnit",
      editable: false,
      sortable: true,
      filter: "agNumberColumnFilter",
      resizable: true,
      type: "enumTypeColumn",
      cellEditorParams: {
        values: translateEditorEntries(enumToEditorEntries(MeasurementUnit), translateMeasurementUnit),
        placeholder: $t("inboundDeliveryLine-list-quantityUnit-label", { $: "Measurement Unit" })
      },
      valueFormatter: (params: any) => (params.data ? translateMeasurementUnit(params.data.quantityUnit) : null),
      headerValueGetter: (_: any) => $t("inboundDeliveryLine-list-quantityUnit-label", { $: "Measurement Unit" })
    },
    {
      field: "position",
      editable: true,
      sortable: true,
      filter: "agNumberColumnFilter",
      resizable: true,
      type: "textInputTypeColumn",
      cellEditorParams: {
        rules: [(v: any) => (v !== undefined && v !== null && v !== "") || "Required"],
        placeholder: $t("inboundDeliveryLine-list-position-label", { $: "Position" })
      },
      headerValueGetter: (_: any) => $t("inboundDeliveryLine-list-position-label", { $: "Position" })
    },
    {
      field: "deliveryDate",
      editable: true,
      sortable: true,
      filter: "agDateColumnFilter",
      resizable: true,
      valueFormatter: (params: any) => {
        return $dateTimeFormatter(params.data.deliveryDate);
      },
      type: "datepickerTypeColumn",
      cellEditorParams: {
        rules: [requiredRule],
        placeholder: $t("inboundDeliveryLine-list-deliveryDate-label", { $: "Delivery Date" })
      },
      headerValueGetter: (_: any) => $t("inboundDeliveryLine-list-deliveryDate-label", { $: "Delivery Date" })
    },
    {
      field: "status",
      headerName: "Status",
      editable: false,
      sortable: true,
      filter: false,
      resizable: true,
      cellStyle: (params: any) => getOrderLineStatusCellStyle(params.data.status),
      valueGetter: (params: any) => translateOrderLineStatus(params.data.status),
      headerValueGetter: (_: any) => $t("inboundDeliveryLine-list-status-label", { $: "Status" })
    }
  ];
}

function checkActionButtonsState() {
  const rows = getSelectedRows(gridWrapperRef.value?.gridApi);
  if (rows.length !== 1) {
    isEditBtnDisabled.value = true;
    return;
  } else {
    isEditBtnDisabled.value = rows[0].status !== OrderLineStatus.InPreparation && rows[0].status !== OrderLineStatus.Unknown;
    isDeleteBtnDisabled.value = rows[0].status !== OrderLineStatus.InPreparation && rows[0].status !== OrderLineStatus.Unknown;
  }
}

function onRowSelected(_: RowSelectedEvent<OrderLineDto>) {
  checkActionButtonsState();
}

function onStoreRefreshed(_: StoreRefreshedEvent<OrderLineDto>) {
  checkActionButtonsState();
}

function openProductCard() {
  const selectedRows = getSelectedRows(gridWrapperRef.value?.gridApi);
  if (selectedRows.length > 0) {
    const selectedRow = selectedRows[0];
    if (selectedRow.itemId) {
      router.push({ name: "Product Card", query: { product: selectedRow.itemId, selected: selectedRow.itemId } });
    }
  }
}

watch(props, (newProps) => {
  serverSideDataSource.useFilteringByProductId(newProps.filterByProductId);
  serverSideDataSource.useFilteringByOrderId(newProps.selectedOrder.id);
  gridWrapperRef.value?.gridApi.refreshServerSide();
});

async function releaseToProduction() {
  const selectedRows = getSelectedRows(gridWrapperRef.value?.gridApi);
  if (selectedRows.length > 0) {
    const orderLineIds = selectedRows.map(function (x) {
      return x.id;
    });
    if (!scenariosStore.selectedScenario) return;
    try {
      const request = {
        orderLineIds: orderLineIds,
        orderId: selectedRows[0].orderId,
        scenarioId: scenariosStore.selectedScenario.id
      };
      await ApiService.inboundDeliveries.releaseInboundDeliveryLinesToProduction(request);
      await snackbarsStore.createSnackbar({
        message: $t("inboundDeliveryLine-list-onReleaseSuccess-message", { $: "Order lines released! Production tasks are being generated" }),
        type: "success",
        closeable: true
      });
    } catch (e) {
      console.error(e);
      await snackbarsStore.createSnackbar({
        message: $t("inboundDeliveryLine-list-onReleaseError-message", { $: "Could not release order lines" }),
        type: "error",
        closeable: true
      });
    }
  }
}

async function unrelease() {
  const selectedRows = getSelectedRows(gridWrapperRef.value?.gridApi);
  if (selectedRows.length > 0) {
    const orderLineIds = selectedRows.map(function (x) {
      return x.id;
    });
    if (!scenariosStore.selectedScenario) return;
    try {
      const request = {
        orderLineIds: orderLineIds,
        orderId: selectedRows[0].orderId,
        scenarioId: scenariosStore.selectedScenario.id
      };
      await ApiService.inboundDeliveries.unrelease(request);
      await snackbarsStore.createSnackbar({
        message: $t("inboundDeliveryLine-list-onUnreleaseSuccess-message", { $: "Order lines have been sent to unrelease" }),
        type: "success",
        closeable: true
      });
    } catch (e) {
      console.error(e);
      await snackbarsStore.createSnackbar({
        message: $t("inboundDeliveryLine-list-onUnreleaseError-message", { $: "Could not unrelease the order lines" }),
        type: "error",
        closeable: true
      });
    }
  }
}
</script>

<template>
  <grid-wrapper
    ref="gridWrapperRef"
    identifier="order-lines"
    row-selection="multiple"
    :grid-options="gridOptions"
    :create-default-value="DEFAULT_CREATE_VALUE"
    :server-side="true"
    :server-side-datasource="serverSideDataSource"
    :server-side-infinite-scroll="false"
    :pagination="false"
    refresh-btn
    :edit-btn="!readonly"
    :edit-btn-disabled="isEditBtnDisabled"
    :create-btn="!readonly"
    :duplicate-btn="!readonly"
    :delete-btn="!readonly"
    :delete-btn-disabled="isDeleteBtnDisabled"
    :hide-custom-actions-separator="readonly"
    @prepare-columns="onPrepareColumns"
    @selection-changed="onSelectionChanged"
    @row-selected="onRowSelected"
    @store-refreshed="onStoreRefreshed"
  >
    <template #custom-buttons>
      <v-menu>
        <template #activator="{ props, isActive }">
          <v-btn :disabled="!isRowSelected" v-bind="props" size="small" variant="text" density="compact" class="mr-4">
            <span>{{ $t("inboundDeliveryLine-list-actions-label", { $: "Actions" }) }}</span>
            <v-icon class="pl-4" :icon="isActive ? 'mdi-chevron-up' : 'mdi-chevron-down'" />
          </v-btn>
        </template>
        <v-list density="compact">
          <v-tooltip bottom open-delay="300">
            <template #activator="{ props }">
              <v-list-item :disabled="!isRowSelected" v-bind="props" @click="releaseToProduction">
                <template #prepend>
                  <v-icon icon="mdi-rocket-launch"></v-icon>
                </template>
                <v-list-item-title>{{ $t("inboundDeliveryLine-list-release-action", { $: "Release" }) }}</v-list-item-title>
              </v-list-item>
            </template>
            <span>{{ $t("inboundDeliveryLine-list-release-action-tooltip", { $: "Release selected lines to production" }) }}</span>
          </v-tooltip>
          <v-tooltip bottom open-delay="300">
            <template #activator="{ props }">
              <v-list-item :disabled="!isRowSelected" v-bind="props" @click="unrelease">
                <template #prepend>
                  <v-icon icon="mdi-arrow-u-left-top"></v-icon>
                </template>
                <v-list-item-title>{{ $t("inboundDeliveryLine-list-unrelease-action", { $: "Unrelease" }) }}</v-list-item-title>
              </v-list-item>
            </template>
            <span>{{ $t("inboundDeliveryLine-list-unrelease-action-tooltip", { $: "Unrelease selected lines from production" }) }}</span>
          </v-tooltip>
        </v-list>
      </v-menu>
      <v-tooltip bottom open-delay="300">
        <template #activator="{ props }">
          <v-btn size="small" v-bind="props" variant="text" density="compact" class="mr-4" @click="openProductCard">
            <v-icon class="pr-4" icon="mdi-account-hard-hat" />
            {{ $t("inboundDeliveryLine-list-openProductCard-action", { $: "Product card" }) }}
          </v-btn>
        </template>
        <span>{{ $t("inboundDeliveryLine-list-openProductCard-action-tooltip", { $: "Open product card" }) }}</span>
      </v-tooltip>
    </template>
  </grid-wrapper>
</template>

<style lang="scss" scoped></style>
