<script lang="ts" setup>
import { taskGroupValueCellStyle } from "@/components/Grid/CellRenderers/GroupValueCellStyle";
import { enumToEditorEntries, enumValueEntryWithLocaleComparator, translateEditorEntries } from "@/components/Grid/ColumnTypes";
import { groupEnumValueRowKeyCreator } from "@/components/Grid/Filters/ColumnFilters";
import { dateColumnFilterParams } from "@/components/Grid/Filters/DateTypeColumnFilters";
import { tagsTypeColumnFilterParams } from "@/components/Grid/Filters/TagsTypeColumnFilters";
import GridWrapper from "@/components/Grid/GridWrapper.vue";
import { GridWrapperComponent } from "@/components/Grid/GridWrapperComponent";
import RescheduleRootTaskGridAction from "@/components/Tasks/RescheduleRootTaskGridAction.vue";
import ScheduleTasksGridAction from "@/components/Tasks/ScheduleTasksGridAction.vue";
import { getStatusBasedCellStyle, openResourceGanttForStepsCapacities, openResourceGanttForStepsSpecification, openTaskGantt } from "@/components/Tasks/TaskUtils";
import { tasksGridPostProcessPopup } from "@/components/Tasks/TasksGridProcessPopup";
import { translatePlanningStatus, translateTaskSubType, translateTotalStatus } from "@/composables/translateEnum";
import { $t } from "@/i18n";
import { ProductionTasksChangedNotification, useNotification } from "@/notifications";
import { $appContextSymbol } from "@/plugins/app.plugin";
import { useModelInstancesStore } from "@/store/ModelInstancesStore";
import { GenericSchedulingRequest, useProductionTasksStore } from "@/store/ProductionTasksStore";
import { useScenariosStore } from "@/store/ScenariosStore";
import { useSnackbarsStore } from "@/store/SnackbarsStore";
import { useTagsStore } from "@/store/TagsStore";
import {
  GanttResourceCapacityDto,
  GenerateAndCreateSerialNumbersForTopLevelTaskCommand,
  PlanningStatus,
  ProductionTaskDto,
  ProductionTasksChangedNotificationEvent,
  ProductionTasksChangeType, SchedulingStatus,
  StepDto,
  TaskSubType,
  TotalStatus
} from "@masta/generated-model";
import { $dateSymbol, $dateTimeFormatterSymbol, $durationFormatterSymbol, DateFormatter } from "@masta/shared";
import { useDebounceFn } from "@vueuse/core";
import {
  GetContextMenuItemsParams,
  GridReadyEvent,
  IRowDragItem, IRowNode,
  PostProcessPopupParams, RefreshServerSideParams,
  RowClassParams,
  RowDragEndEvent,
  RowDragEnterEvent,
  RowDragLeaveEvent,
  RowDragMoveEvent,
  RowSelectedEvent,
  ValueFormatterParams
} from "ag-grid-community";
import dayjs from "dayjs";
import { v4 as uuid } from "uuid";
import { groupBy } from "lodash";
import { computed, reactive, inject, ref } from "vue";
import { useRouter } from "vue-router";
import { useTheme } from "vuetify";
import { TaskServerSideDataSource } from "./TaskServerSideDataSource";
import ActionsButton from "@/components/Layout/ActionsButton.vue";
import TasksNoRowsOverlay from "@/components/Tasks/TasksNoRowsOverlay.vue";
import { ServerSideTransactionResult } from "ag-grid-community/dist/types/core/interfaces/serverSideTransaction";

const $props = defineProps({
  autoGroupColumnDef: {
    type: Object,
    default: () => ({
      field: "businessId",
      cellStyle: taskGroupValueCellStyle,
      headerValueGetter: (_: any) => $t("task-list-businessId-label", { $: "Business ID" }),
      minWidth: 330,
      floatingFilterComponentParams: {
        placeholder: $t("task-list-businessId-label", { $: "Business ID" })
      }
    })
  },
  unscheduledProductionTasksData: {
    type: Boolean,
    default: false
  },
  unscheduledWorkOrdersData: {
    type: Boolean,
    default: false
  },
  /**
   * If true, the tasks hierarchy will be flattened. In other words, the tasks will be displayed as a flat list (work orders).
   * Thus, the tasks will not be grouped by (ignore autoGroupColumnDef, treeData and businessId added as a normal column).
   */
  flatHierarchy: {
    type: Boolean,
    default: false
  },
  droppable: {
    type: Boolean,
    default: false
  },
  filterByProductId: {
    type: String,
    default: null
  },
  noActionsRow: {
    type: Boolean,
    default: false
  },
  dropZoneId: {
    type: String,
    default: null
  }
});

const theme = useTheme();
const snackbarsStore = useSnackbarsStore();
const scenariosStore = useScenariosStore();
const tagsStore = useTagsStore();
const miStore = useModelInstancesStore();
const tasksStore = useProductionTasksStore();
const $router = useRouter();
const $emits = defineEmits(["rowSelected", "manageModelInstances", "rowDragEnter", "rowDragMove", "rowDragLeave", "rowDragEnd", "dataFetched"]);

const highlightTaskIds = ref<string[]>([]);

const $date = inject<typeof dayjs>($dateSymbol)!;
const $dateTimeFormatter = inject<DateFormatter>($dateTimeFormatterSymbol)!;
const $durationFormatter = inject<DateFormatter>($durationFormatterSymbol)!;
const $appContext = inject<any>($appContextSymbol)!;

const serverSideDataSource = reactive(new TaskServerSideDataSource("tasks", $props.unscheduledProductionTasksData, $props.unscheduledWorkOrdersData));

useNotification(ProductionTasksChangedNotification, (e: ProductionTasksChangedNotificationEvent) => {
  onProductionTasksChanged(e);
});

const gridWrapperRef = ref<GridWrapperComponent>();
const scheduleTasksGridActionRef = ref<typeof ScheduleTasksGridAction>();

const defaultColumnDef = ref({
  sortable: true,
  resizable: true,
  floatingFilter: true,
  type: ["textFloatingFilterColumnType"],
  filter: "agTextColumnFilter",
  filterParams: {
    applyMiniFilterWhileTyping: true
  }
});
const selectedRow = ref<any>(null);

const getDataPath = ref((data: any) => {
  return data.hierarchy;
});

async function onGridReady(event: GridReadyEvent) {
  if ($props.dropZoneId) {
    addRowDropZones($props.dropZoneId);
  }

  await miStore.fetchSchemas();
}

function transactionCallback(result: ServerSideTransactionResult) {
  if (result.status !== "Applied") {
    console.error("Task grid transaction failed", result);
  }
}

const onProductionTasksChanged: (e: ProductionTasksChangedNotificationEvent) => void = useDebounceFn(async (e: ProductionTasksChangedNotificationEvent) => {
  if (gridWrapperRef.value) {
    const gridApi = gridWrapperRef.value.gridApi;

    if (gridApi.isDestroyed()) {
      return;
    }

    if (e.taskIds) {
      for (const taskId of e.taskIds) {
        const task = await tasksStore.fetchTask(taskId);
        if (task) {
          if (e.changeType === ProductionTasksChangeType.Create) {
            gridApi.applyServerSideTransactionAsync({ add: [task] }, transactionCallback);
          } else if (e.changeType === ProductionTasksChangeType.Delete) {
            gridApi.applyServerSideTransactionAsync({ remove: [task] }, transactionCallback);
          } else if (e.changeType === ProductionTasksChangeType.Scheduling) {
            if ($props.unscheduledProductionTasksData || $props.unscheduledWorkOrdersData) {
              // on unscheduled tasks list, we simply remove the scheduled task.
              gridApi.applyServerSideTransactionAsync({ remove: [task] }, transactionCallback);
            } else {
              // otherwise, we update the task.
              gridApi.applyServerSideTransactionAsync({ update: [task] }, transactionCallback);
            }
          } else {
            gridApi.applyServerSideTransactionAsync({ update: [task] }, transactionCallback);
          }
        }
        if (task && task.childCount > 0) {
          gridApi.refreshServerSide({ route: [task.id] });
        }
      }
    }
    gridApi.flushServerSideAsyncTransactions();
  }
}, 800);

async function onRefreshAction() {
  gridWrapperRef.value?.gridApi.refreshServerSide();
}

function onPrepareColumns(columnDefs: any) {
  columnDefs.value = [
    ...($props.flatHierarchy
      ? [
        {
          field: "businessId",
          headerValueGetter: (__: any) => $t("gantt-resource-businessId-label", { $: "Business ID" }),
          cellStyle: taskGroupValueCellStyle,
          minWidth: 330,
          floatingFilterComponentParams: {
            placeholder: $t("gantt-resource-businessId-label", { $: "Business ID" })
          }
        }
      ]
      : []),
    {
      field: "name",
      headerValueGetter: (_: any) => $t("task-list-name-label", { $: "Name" }),
      cellStyle: taskGroupValueCellStyle,
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      floatingFilterComponentParams: {
        placeholder: $t("task-list-name-label", { $: "Name" })
      }
    },
    {
      field: "resourceBusinessId",
      headerValueGetter: (_: any) => $t("task-list-resourceBusinessId-label", { $: "Resource Business ID" }),
      cellStyle: taskGroupValueCellStyle,
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      floatingFilterComponentParams: {
        placeholder: $t("task-list-resourceBusinessId-label", { $: "Resource Business ID" })
      }
    },
    {
      field: "wbs",
      headerValueGetter: (_: any) => $t("task-list-workBreakdownStructure-label", { $: "Work Breakdown Structure" }),
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      floatingFilterComponentParams: {
        placeholder: $t("task-list-workBreakdownStructure-label", { $: "Work Breakdown Structure" })
      }
    },
    {
      field: "taskSubType",
      headerValueGetter: (_: any) => $t("task-list-taskSubType-label", { $: "Task Sub-Type" }),
      editable: false,
      sortable: true,
      filter: "agSetColumnFilter",
      type: ["enumTypeColumn", "setFloatingFilterColumnType"],
      filterParams: {
        keyCreator: groupEnumValueRowKeyCreator,
        valueFormatter: (params: ValueFormatterParams) => params.value.key,
        values: translateEditorEntries(enumToEditorEntries(TaskSubType), translateTaskSubType),
        comparator: enumValueEntryWithLocaleComparator
      },
      valueFormatter: (params: any) => translateTaskSubType(params.data?.taskSubType),
      floatingFilterComponentParams: {
        placeholder: $t("task-list-taskSubType-label", { $: "Task Sub-Type" }),
        values: translateEditorEntries(enumToEditorEntries(TaskSubType), translateTaskSubType)
      }
    },
    {
      field: "priority",
      type: ["numberInputTypeColumn", "numberFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("task-list-priority-label", { $: "Priority" }),
      editable: false,
      sortable: true,
      filter: "agNumberColumnFilter"
    },
    {
      field: "dateTimeFrom",
      filter: "agDateColumnFilter",
      type: ["datepickerTypeColumn", "dateFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("task-list-from-label", { $: "From" }),
      editable: false,
      sortable: true,
      filterParams: dateColumnFilterParams($date),
      valueFormatter: (params: any) => {
        return $dateTimeFormatter(params.data?.dateTimeFrom);
      }
    },
    {
      field: "dateTimeTo",
      filter: "agDateColumnFilter",
      type: ["datepickerTypeColumn", "dateFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("task-list-to-label", { $: "To" }),
      editable: false,
      sortable: true,
      filterParams: dateColumnFilterParams($date),
      valueFormatter: (params: any) => {
        return $dateTimeFormatter(params.data?.dateTimeTo);
      }
    },
    {
      field: "statistics.totalStatus",
      headerValueGetter: (_: any) => $t("task-list-totalStatus-label", { $: "Total Status" }),
      editable: false,
      sortable: true,
      filter: "agSetColumnFilter",
      type: ["enumTypeColumn", "setFloatingFilterColumnType"],
      filterParams: {
        keyCreator: groupEnumValueRowKeyCreator,
        valueFormatter: (params: ValueFormatterParams) => params.value.key,
        values: translateEditorEntries(enumToEditorEntries(TotalStatus), translateTotalStatus),
        comparator: enumValueEntryWithLocaleComparator
      },
      cellStyle: (params: any) => getStatusBasedCellStyle($appContext, params.value),
      valueFormatter: (params: any) => {
        return translateTotalStatus(params.data?.statistics?.totalStatus);
      },
      floatingFilterComponentParams: {
        placeholder: $t("task-list-totalStatus-label", { $: "Total Status" }),
        values: translateEditorEntries(enumToEditorEntries(TotalStatus), translateTotalStatus)
      }
    },
    {
      field: "statistics.schedulingStatus",
      headerValueGetter: (_: any) => $t("task-list-schedulingStatus-label", { $: "Scheduling Status" }),
      editable: false,
      sortable: true,
      filter: "agSetColumnFilter",
      type: ["enumTypeColumn", "setFloatingFilterColumnType"],
      filterParams: {
        keyCreator: groupEnumValueRowKeyCreator,
        valueFormatter: (params: ValueFormatterParams) => params.value.key,
        values: translateEditorEntries(enumToEditorEntries(TotalStatus), translateTotalStatus),
        comparator: enumValueEntryWithLocaleComparator
      },
      cellStyle: (params: any) => getStatusBasedCellStyle($appContext, params.value),
      valueFormatter: (params: any) => {
        return translateTotalStatus(params.data?.statistics?.schedulingStatus);
      },
      floatingFilterComponentParams: {
        placeholder: $t("task-list-schedulingStatus-label", { $: "Scheduling Status" }),
        values: translateEditorEntries(enumToEditorEntries(TotalStatus), translateTotalStatus)
      }
    },
    {
      field: "statistics.executionStatus",
      headerValueGetter: (_: any) => $t("task-list-executionStatus-label", { $: "Execution Status" }),
      editable: false,
      sortable: true,
      filter: "agSetColumnFilter",
      filterParams: {
        keyCreator: groupEnumValueRowKeyCreator,
        valueFormatter: (params: ValueFormatterParams) => params.value.key,
        values: translateEditorEntries(enumToEditorEntries(TotalStatus), translateTotalStatus),
        comparator: enumValueEntryWithLocaleComparator
      },
      cellStyle: (params: any) => getStatusBasedCellStyle($appContext, params.value),
      valueFormatter: (params: any) => {
        return translateTotalStatus(params.data?.statistics?.executionStatus);
      }
    },
    {
      field: "planningStatus",
      headerValueGetter: (_: any) => $t("task-list-planningStatus-label", { $: "Planning Status" }),
      editable: false,
      sortable: true,
      filter: "agSetColumnFilter",
      filterParams: {
        keyCreator: groupEnumValueRowKeyCreator,
        valueFormatter: (params: ValueFormatterParams) => params.value.key,
        values: translateEditorEntries(enumToEditorEntries(PlanningStatus), translatePlanningStatus),
        comparator: enumValueEntryWithLocaleComparator
      },
      cellStyle: (params: any) => getStatusBasedCellStyle($appContext, params.value),
      valueFormatter: (params: any) => {
        return translatePlanningStatus(params.data?.planningStatus);
      }
    },
    {
      field: "statistics.schedulingStart",
      filter: "agDateColumnFilter",
      type: ["datepickerTypeColumn", "dateFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("task-list-schedulingStart-label", { $: "Scheduling Start" }),
      editable: false,
      sortable: true,
      filterParams: dateColumnFilterParams($date),
      valueFormatter: (params: any) => {
        return $dateTimeFormatter(params.data?.statistics?.schedulingStart);
      }
    },
    {
      field: "statistics.schedulingEnd",
      filter: "agDateColumnFilter",
      type: ["datepickerTypeColumn", "dateFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("task-list-schedulingEnd-label", { $: "Scheduling End" }),
      editable: false,
      sortable: true,
      filterParams: dateColumnFilterParams($date),
      valueFormatter: (params: any) => {
        return $dateTimeFormatter(params.data?.statistics?.schedulingEnd);
      }
    },
    {
      field: "statistics.schedulingDuration",
      headerValueGetter: (_: any) => $t("task-list-schedulingDuration-label", { $: "Scheduling Duration" }),
      editable: false,
      sortable: true,
      valueFormatter: (params: any) => {
        return $durationFormatter(params.data?.statistics?.schedulingDuration);
      }
    },
    {
      field: "statistics.schedulingLeadTime",
      headerValueGetter: (_: any) => $t("task-list-schedulingLeadTime-label", { $: "Scheduling Lead Time" }),
      editable: false,
      sortable: true,
      valueFormatter: (params: any) => {
        return $durationFormatter(params.data?.statistics?.schedulingLeadTime);
      }
    },
    {
      field: "statistics.schedulingDelay",
      headerValueGetter: (_: any) => $t("task-list-schedulingDelay-label", { $: "Scheduling Delay" }),
      editable: false,
      sortable: true,
      valueFormatter: (params: any) => {
        return $durationFormatter(params.data?.statistics?.schedulingDelay);
      }
    },
    {
      field: "statistics.schedulingWaitingTime",
      headerValueGetter: (_: any) => $t("task-list-schedulingWaitingTime-label", { $: "Scheduling Waiting Time" }),
      editable: false,
      sortable: true,
      valueFormatter: (params: any) => {
        return $durationFormatter(params.data?.statistics?.schedulingWaitingTime);
      }
    },
    {
      field: "statistics.executionStart",
      filter: "agDateColumnFilter",
      type: ["datepickerTypeColumn", "dateFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("task-list-executionStart-label", { $: "Execution Start" }),
      editable: false,
      sortable: true,
      filterParams: dateColumnFilterParams($date),
      valueFormatter: (params: any) => {
        return $dateTimeFormatter(params.data?.statistics?.executionStart);
      }
    },
    {
      field: "statistics.executionEnd",
      filter: "agDateColumnFilter",
      type: ["datepickerTypeColumn", "dateFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("task-list-executionEnd-label", { $: "Execution End" }),
      editable: false,
      sortable: true,
      filterParams: dateColumnFilterParams($date),
      valueFormatter: (params: any) => {
        return $dateTimeFormatter(params.data?.statistics?.executionEnd);
      }
    },
    {
      field: "statistics.executionDuration",
      headerValueGetter: (_: any) => $t("task-list-executionDuration-label", { $: "Execution Duration" }),
      editable: false,
      sortable: true,
      valueFormatter: (params: any) => {
        return $durationFormatter(params.data?.statistics?.executionDuration);
      }
    },
    {
      field: "statistics.executionLeadTime",
      headerValueGetter: (_: any) => $t("task-list-executionLeadTime-label", { $: "Execution Lead Time" }),
      editable: false,
      sortable: true,
      valueFormatter: (params: any) => {
        return $durationFormatter(params.data?.statistics?.executionLeadTime);
      }
    },
    {
      field: "statistics.executionWaitingTime",
      headerValueGetter: (_: any) => $t("task-list-executionWaitingTime-label", { $: "Execution Waiting Time" }),
      editable: false,
      sortable: true,
      valueFormatter: (params: any) => {
        return $durationFormatter(params.data?.statistics?.executionWaitingTime);
      }
    },
    {
      field: "tags",
      type: ["tagsPickerTypeColumn", "setFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("task-list-tags-label", { $: "Tags" }),
      editable: false,
      resizable: true,
      filter: "agSetColumnFilter",
      filterParams: tagsTypeColumnFilterParams(tagsStore),
      valueFormatter: (params: ValueFormatterParams) => (params.data?.tags ?? []).join(", ")
    },
    {
      field: "createdBy",
      editable: false,
      resizable: true,
      headerValueGetter: (_: any) => $t("task-list-createdBy-label", { $: "Created By" })
    },
    {
      field: "createdAt",
      filter: "agDateColumnFilter",
      type: ["datepickerTypeColumn", "dateFloatingFilterColumnType", "dateTimeTypeColumn"],
      editable: false,
      resizable: true,
      filterParams: dateColumnFilterParams($date),
      valueFormatter: (params: any) => {
        return $dateTimeFormatter(params.data.createdAt);
      },
      headerValueGetter: (_: any) => $t("task-list-createdAt-label", { $: "Created At" })
    },
    {
      field: "modifiedBy",
      editable: false,
      resizable: true,
      headerValueGetter: (_: any) => $t("task-list-modifiedBy-label", { $: "Modified By" })
    },
    {
      field: "modifiedAt",
      filter: "agDateColumnFilter",
      type: ["datepickerTypeColumn", "dateFloatingFilterColumnType", "dateTimeTypeColumn"],
      editable: false,
      resizable: true,
      filterParams: dateColumnFilterParams($date),
      valueFormatter: (params: any) => {
        return $dateTimeFormatter(params.data.modifiedAt);
      },
      headerValueGetter: (_: any) => $t("task-list-modifiedAt-label", { $: "Modified At" })
    }
  ];
}

function getContextMenuItems(param: GetContextMenuItemsParams) {
  return [
    {
      name: $t("task-list-scheduleTask-action", { $: "Schedule" }),
      icon: "<i class=\"mdi mdi-calendar\"/>",
      action: () => scheduleTasksGridActionRef.value?.triggerScheduledAction(),
      disabled: !(param.api.getSelectedNodes().length > 0)
    },
    {
      name: $t("task-list-recalculateTask-label", { $: "Recalculate" }),
      icon: "<i class=\"mdi mdi-calendar-sync\"/>",
      action: () => recalculate()
    },
    "separator",
    {
      name: $t("task-list-modelInstances-manage-action", { $: "Manage Model Instance Assignment" }),
      action: () => {
        $emits("manageModelInstances", param.node?.data, () => {
          onRefreshAction();
        });
      }
    },
    {
      name: $t("task-list-open-tasks-gantt-action", { $: "Open task gantt" }),
      icon: "<i class=\"mdi mdi-chart-gantt\"/>",
      action: () => {
        const taskIds = param.node?.allLeafChildren.map((x) => x.key) ?? [];
        $router.push({
          path: `/scheduling/tasks-gantt`,
          query: {
            tasks: taskIds.join(",")
          }
        });
      }
    }
  ];
}

function onRowSelected(event: RowSelectedEvent<ProductionTaskDto>) {
  if (event.node.isSelected()) {
    $emits("rowSelected", event.data);
  }
}

function isServerSideGroup(dataItem: ProductionTaskDto) {
  if (dataItem?.childCount === 0) return false;

  return true;
}

function getServerSideGroupKey(dataItem: ProductionTaskDto) {
  return dataItem?.id;
}

function getServerSideChildCount(dataItem: ProductionTaskDto) {
  return dataItem?.childCount;
}

async function recalculate() {
  if (!scenariosStore.selectedScenario) return;

  const request = {
    scenarioId: scenariosStore.selectedScenario.id
  };
  try {
    await tasksStore.recalculateTasks(request);
    await snackbarsStore.createSnackbar({
      message: $t("task-list-recalculationStarted-message", { $: "Recalculation started" }),
      closeable: true
    });
  } catch (e: any) {
    console.error(e);
    await snackbarsStore.createSnackbar({
      message: e.message,
      type: "error",
      closeable: true
    });
  }
}

async function reschedule() {
  if (!scenariosStore.selectedScenario) return;

  const request = {
    scenarioId: scenariosStore.selectedScenario.id
  };
  try {
    await tasksStore.recalculateTasks(request);
    await snackbarsStore.createSnackbar({
      message: $t("task-list-recalculationStarted-message", { $: "Recalculation started" }),
      closeable: true
    });
  } catch (e: any) {
    console.error(e);
    await snackbarsStore.createSnackbar({
      message: e.message,
      type: "error",
      closeable: true
    });
  }
}

function createSchedulingRequest(): GenericSchedulingRequest | null {
  const allNodes: IRowNode[] = [];
  gridWrapperRef?.value?.gridApi?.forEachNode(n => allNodes.push(n));
  const selectedNodes = allNodes.filter(x => x.isSelected());
  const selectedTasks = selectedNodes ?? [];

  if (!selectedTasks || !scenariosStore.selectedScenario) return null;

  const rootIds = [...new Set(selectedTasks.map((x) => x.data.rootTaskId))];
  const rootId = rootIds.length === 1 ? rootIds[0] : null;

  return {
    rootTaskId: rootId,
    scenarioId: scenariosStore.selectedScenario.id,
    taskIds: selectedTasks.map((x) => x.data.id)
  };
}

async function schedule() {
  const request = createSchedulingRequest();
  if (!request) return;

  try {
    await tasksStore.scheduleTasks(request);
    await snackbarsStore.createSnackbar({
      message: $t("task-list-taskScheduled-message", { $: "Tasks queued for scheduling" }),
      closeable: true
    });
  } catch (e: any) {
    console.error(e);
    await snackbarsStore.createSnackbar({
      message: e.message,
      type: "error",
      closeable: true
    });
  }
}

async function deschedule() {
  const request = createSchedulingRequest();
  await executeDeschedule(request);
}

async function executeDeschedule(request: any) {
  if (!request) return;
  try {
    await tasksStore.descheduleTasks(request);
    await snackbarsStore.createSnackbar({
      message: $t("task-list-taskDescheduled-message", { $: "Tasks queued for descheduling" }),
      closeable: true
    });
  } catch (e: any) {
    console.error(e);
    await snackbarsStore.createSnackbar({
      message: e.message,
      type: "error",
      closeable: true
    });
  }
}

async function release() {
  const request = createSchedulingRequest();
  if (!request) return;

  try {
    await tasksStore.releaseTasks(request);
    await snackbarsStore.createSnackbar({
      message: $t("task-list-taskReleased-message", { $: "Tasks queued for release" }),
      closeable: true
    });
  } catch (e: any) {
    console.error(e);
    await snackbarsStore.createSnackbar({
      message: e.message,
      type: "error",
      closeable: true
    });
  }
}

async function unrelease() {
  const request = createSchedulingRequest();
  if (!request) return;
  try {
    await tasksStore.unreleaseTasks(request);
    await snackbarsStore.createSnackbar({
      message: $t("task-list-taskUnreased-message", { $: "Tasks queued for unrelease" }),
      closeable: true
    });
  } catch (e: any) {
    console.error(e);
    await snackbarsStore.createSnackbar({
      message: e.message,
      type: "error",
      closeable: true
    });
  }
}

let dragId = "";

function addRowDropZones(dropZoneId: string) {

  gridWrapperRef.value?.gridApi.addRowDropZone({
    getContainer: () => document.getElementById(dropZoneId) as HTMLElement,
    onDragEnter(event$: RowDragEnterEvent<ProductionTaskDto>) {
      dragId = uuid();
      const taskData: ProductionTaskDto | undefined = event$.node.data;
      if (taskData) {
        $emits("rowDragEnter", dragId, taskData, event$.event);
      }
    },
    onDragging(event$: RowDragMoveEvent<ProductionTaskDto>) {
      $emits("rowDragMove", dragId, event$.event);
    },
    onDragLeave(event$: RowDragLeaveEvent<ProductionTaskDto>) {
      $emits("rowDragLeave", dragId, event$.event);
    },
    onDragStop(event$: RowDragEndEvent<ProductionTaskDto>) {
      $emits("rowDragEnd", dragId, event$.event);
    }
  });
}

function getRowDragText(params: IRowDragItem, dragItemCount: number) {
  return params.rowNode?.data?.businessId;
}

function onDragOver(event$: DragEvent) {
  if ($props.droppable) {
    event$.dataTransfer!.dropEffect = "move";
    event$.preventDefault();
  }
}

async function onDropEvent(event$: DragEvent) {
  if ($props.droppable && scenariosStore.selectedScenario) {
    try {
      const dataTransferData = event$.dataTransfer!.getData("application/json");
      if (dataTransferData) {
        const data: {
          activity: GanttResourceCapacityDto;
          rowId: string;
        }[] = JSON.parse(dataTransferData);
        if (!Array.isArray(data)) {
          return;
        }
        const dropData = data.map((x) => ({ id: x.activity.task.id, rootTaskId: x.activity.task.rootTaskId }));
        const scenarioId = scenariosStore.selectedScenario.id;
        const groupData = groupBy(dropData, (x) => x.rootTaskId);
        for (const [rootTaskId, group] of Object.entries(groupData)) {
          await executeDeschedule({
            rootTaskId,
            scenarioId,
            taskIds: group.map((x) => x.id)
          });
        }
      }
    } catch (e) {
      console.warn("Could not parse dropped data", e);
    }
  }
}

function onResolveRowClass(params: RowClassParams): string[] {
  if (params.data) {
    const id: string = params.data.id;
    if (highlightTaskIds.value?.find((otherId) => otherId === id)) {
      return theme.name.value === "light" ? ["row-highlight-light"] : ["row-highlight-dark"];
    }
  }
  return [];
}

const postProcessPopup = (params: PostProcessPopupParams) => {
  const gridElement = (gridWrapperRef.value as any).$el as HTMLElement;
  if (gridElement) {
    tasksGridPostProcessPopup(params.ePopup, gridElement);
  }
};

async function generateTrackingIdentifiers() {
  const selectedTasks = gridWrapperRef?.value?.gridApi.getSelectedNodes();

  if (!selectedTasks || !scenariosStore.selectedScenario || selectedTasks.length !== 1) return null;

  const request: GenerateAndCreateSerialNumbersForTopLevelTaskCommand = {
    taskId: selectedTasks[0].data.id,
    scenarioId: scenariosStore.selectedScenario.id
  };
  await tasksStore.generateAndCreateSerialNumbersForTopLevelTask(request);

  await snackbarsStore.createSnackbar({
    message: $t("task-details-trackingIdentifiersGenerated-message", { $: "Tracking identifiers generated" }),
    closeable: true
  });
}

const productionTaskStore = useProductionTasksStore();
const router = useRouter();

async function openGanttResourceSpecification() {
  const selectedTaskId = gridWrapperRef?.value?.gridApi.getSelectedNodes()[0]?.data.id;
  if (!selectedTaskId) return;
  const task = await productionTaskStore.fetchDetail(selectedTaskId);
  if (!task || !task.steps) return;
  await openResourceGanttForStepsSpecification(router, task.steps);
}

async function openGanttResourceCapacities() {
  const selectedTaskId = gridWrapperRef?.value?.gridApi.getSelectedNodes()[0]?.data.id;
  if (!selectedTaskId) return;
  const task = await productionTaskStore.fetchDetail(selectedTaskId);
  if (!task || !task.steps) return;
  await openResourceGanttForStepsCapacities(router, task.steps);
}

async function openTasksGantt() {
  const nodes = gridWrapperRef?.value?.gridApi.getSelectedNodes() ?? [];
  await openTaskGantt(router, nodes.map(x => x.data));
}

const detailActions = ref([
  {
    title: $t("task-manage-generate-tracking-identifiers-action", { $: "Generate Tracking Identifiers" }),
    tooltip: $t("task-manage-generate-tracking-identifiers-action", { $: "Generate Tracking Identifiers" }),
    action: generateTrackingIdentifiers,
    icon: "mdi-barcode-scan",
    disabled: () => gridWrapperRef?.value?.gridApi?.getSelectedNodes().length !== 1,
    order: 1
  },
  {
    separator: true,
    order: 2
  },
  {
    title: $t("task-list-releaseTask-action", { $: "Release" }),
    tooltip: $t("task-list-releaseTask-action", { $: "Release" }),
    icon: "mdi-calendar-import",
    action: release,
    disabled: () => $props.unscheduledProductionTasksData || $props.unscheduledWorkOrdersData,
    order: 2
  },
  {
    title: $t("task-list-unreleaseTask-action", { $: "Unrelease" }),
    tooltip: $t("task-list-unreleaseTask-action", { $: "Unrelease" }),
    icon: "mdi-calendar-export",
    action: unrelease,
    disabled: () => $props.unscheduledProductionTasksData || $props.unscheduledWorkOrdersData,
    order: 3
  },
  {
    separator: true,
    order: 4
  },
  {
    title: $t("task-list-descheduleTask-action", { $: "Deschedule" }),
    tooltip: $t("task-list-descheduleTask-action", { $: "Deschedule" }),
    icon: "mdi-calendar-remove",
    action: deschedule,
    disabled: () => {
      const allNodes: IRowNode<ProductionTaskDto>[] = [];
      gridWrapperRef?.value?.gridApi?.forEachNode(n => allNodes.push(n));
      const selectedNodes = allNodes.filter(x => x.isSelected());
      const allAreScheduled = selectedNodes.every(n => n.data?.statistics?.schedulingStatus === SchedulingStatus.Scheduled);
      return selectedNodes.length === 0 || !allAreScheduled || $props.unscheduledProductionTasksData || $props.unscheduledWorkOrdersData;
    },
    order: 5
  },
  {
    title: $t("task-list-recalculateTask-label", { $: "Recalculate" }),
    tooltip: $t("task-list-recalculateTask-label", { $: "Recalculate" }),
    icon: "mdi-calendar-refresh",
    action: recalculate,
    disabled: () => {
      const allNodes: IRowNode<ProductionTaskDto>[] = [];
      gridWrapperRef?.value?.gridApi?.forEachNode(n => allNodes.push(n));
      const selectedNodes = allNodes.filter(x => x.isSelected());
      return selectedNodes.length === 0;
    },
    order: 6
  }
]);

const ganttDetailActions = ref([
  {
    title: $t("task-list-openGantt-resourceSpecification-action", { $: "Specified Resources" }),
    action: openGanttResourceSpecification,
    icon: "mdi-chart-gantt"
  },
  {
    title: $t("task-list-openGantt-resourceCapacities-action", { $: "Scheduled Resources" }),
    action: openGanttResourceCapacities,
    icon: "mdi-chart-gantt"
  },
  {
    title: $t("task-list-openGantt-tasks-action", { $: "Tasks" }),
    action: openTasksGantt,
    icon: "mdi-chart-gantt"
  }
]);
</script>

<template>
  <grid-wrapper
    ref="gridWrapperRef"
    :identifier="$props.flatHierarchy ? 'workOrders' : 'tasks'"
    refresh-btn
    :default-col-def="defaultColumnDef"
    :auto-group-column-def="$props.flatHierarchy ? null : autoGroupColumnDef"
    :tree-data="$props.flatHierarchy ? false : true"
    :is-server-side-group="$props.flatHierarchy ? null : isServerSideGroup"
    :get-server-side-group-key="$props.flatHierarchy ? null : getServerSideGroupKey"
    :get-child-count="$props.flatHierarchy ? null : getServerSideChildCount"
    :row-drag-managed="false"
    :row-drag-entire-row="true"
    :row-drag-text="getRowDragText"
    :no-actions-row="$props.noActionsRow"
    row-selection="multiple"
    server-side
    :no-rows-overlay-component="TasksNoRowsOverlay"
    :server-side-datasource="serverSideDataSource"
    :get-data-path="$props.flatHierarchy ? null : getDataPath"
    :post-process-popup="postProcessPopup"
    :context-menu-items="getContextMenuItems"
    hide-custom-actions-separator
    @ready="onGridReady"
    @resolve-row-class="onResolveRowClass"
    @refresh-action="onRefreshAction"
    @prepare-columns="onPrepareColumns"
    @drop="onDropEvent"
    @dragover="onDragOver"
    @row-selected="onRowSelected"
  >
    <template #custom-buttons="{ gridApi }">
      <div class="d-inline-flex pr-4">
        <actions-button :model-value="detailActions">
          <template #append>
            <schedule-tasks-grid-action ref="scheduleTasksGridActionRef" :grid-api="gridApi" />
            <reschedule-root-task-grid-action ref="rescheduleRootTaskGridActionRef" :grid-api="gridApi" />
          </template>
        </actions-button>
      </div>
      <div class="d-inline-flex pr-4">
        <actions-button :model-value="ganttDetailActions" :label="$t('actions-button-gantt-label', { $: 'Gantt' })" />
      </div>
    </template>

    <!--
        We need this component (ref) to invoke Schedule action from context menu.
        But with property noActionsRow set to true, the component is not rendered
        via grid wrapper so we need to render it manually here
      -->
    <div v-if="$props.noActionsRow" style="display: none">
      <schedule-tasks-grid-action ref="scheduleTasksGridActionRef" :grid-api="gridWrapperRef?.gridApi ?? null" />
    </div>
  </grid-wrapper>
</template>

<style lang="scss" scoped>
.spam {
  background-color: rebeccapurple;
}
</style>
