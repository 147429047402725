<script lang="ts" setup>
import GridWrapper from "@/components/Grid/GridWrapper.vue";
import { ResourceModelInstanceDto } from "@masta/generated-model";
import { Ref, ref, inject } from "vue";
import { ColDef, GridApi, RowDataUpdatedEvent, SelectionChangedEvent, ValueFormatterParams } from "ag-grid-community";
import { $t } from "@/i18n";
import { $dateTimeFormatterSymbol, DateFormatter } from "@masta/shared";
import { translateTemplateStatus } from "@/composables/translateEnum";
import { getProductTemplateStatusCellStyle } from "@/components/Tasks/TaskUtils";
import { tagsTypeColumnFilterParams } from "@/components/Grid/Filters/TagsTypeColumnFilters";
import { joinArrayOfStrings } from "@/components/ValueCellEditor/CommonFormatters";
import { useTagsStore } from "@/store/TagsStore";

const props = defineProps<{
  modelInstances: ResourceModelInstanceDto[];
}>();

const emit = defineEmits<{
  (e: "selectionChanged", instance: ResourceModelInstanceDto | null): void;
  (e: "dataUpdated", gridApi: GridApi): void;
  (e: "copy", instance: ResourceModelInstanceDto): void;
}>();

const $dateTimeFormatter = inject<DateFormatter>($dateTimeFormatterSymbol)!;
const selectedRow = ref<ResourceModelInstanceDto | null>(null);
const tagsStore = useTagsStore();

const basicColumnDefs = ref<ColDef[]>([
  {
    field: "businessId",
    editable: false,
    resizable: true,
    headerValueGetter: (_: any) => $t("modelInstance-list-businessId-label", { $: "BusinessId" })
  },
  {
    field: "revisionNumber",
    editable: false,
    resizable: true,
    sortable: true,
    filter: true,
    headerValueGetter: (_: any) => $t("schemaInstance-list-revisionNumber-label", { $: "Revision" })
  },
  {
    field: "status",
    editable: false,
    resizable: true,
    valueFormatter: (params: any) => translateTemplateStatus(params.value),
    cellStyle: (params: any) => getProductTemplateStatusCellStyle(params.value),
    headerValueGetter: (_: any) => $t("modelInstance-list-status-label", { $: "Status" })
  },
  {
    field: "tags",
    type: ["tagsPickerTypeColumn", "setFloatingFilterColumnType"],
    headerValueGetter: (_: any) => $t("modelInstance-list-tags-label", { $: "Tags" }),
    filter: "agSetColumnFilter",
    filterParams: tagsTypeColumnFilterParams(tagsStore),
    editable: false,
    resizable: true,
    valueFormatter: (params: ValueFormatterParams) => joinArrayOfStrings(params.data.tags),
    cellEditorParams: {
      placeholder: $t("modelInstance-edit-tags-label", { $: "Tags" })
    }
  },
  {
    field: "createdBy",
    editable: false,
    resizable: true,
    headerValueGetter: (_: any) => $t("modelInstance-list-createdBy-label", { $: "Created By" })
  },
  {
    field: "createdAt",
    type: ["dateTimeTypeColumn"],
    editable: false,
    resizable: true,
    valueFormatter: (params: any) => {
      return $dateTimeFormatter(params.data.createdAt);
    },
    headerValueGetter: (_: any) => $t("modelInstance-list-createdAt-label", { $: "Created At" })
  },
  {
    field: "modifiedBy",
    editable: false,
    resizable: true,
    headerValueGetter: (_: any) => $t("modelInstance-list-modifiedBy-label", { $: "Modified By" })
  },
  {
    field: "modifiedAt",
    type: ["dateTimeTypeColumn"],
    editable: false,
    resizable: true,
    valueFormatter: (params: any) => {
      return $dateTimeFormatter(params.data.modifiedAt);
    },
    headerValueGetter: (_: any) => $t("modelInstance-list-modifiedAt-label", { $: "Modified At" })
  }
]);

let columnDefs: Ref<any> = null!;

function onPrepareColumns(_columnDefs: any) {
  _columnDefs.value = basicColumnDefs.value;
  columnDefs = _columnDefs;
}

function onSelectionChanged(e: SelectionChangedEvent) {
  if (e.source === "rowDataChanged") return;
  const selectedRows = e.api.getSelectedRows();
  selectedRow.value = selectedRows.length > 0 ? selectedRows[0] : null;
  emit("selectionChanged", selectedRow.value);
}

function onCopy() {
  if (selectedRow.value) {
    emit("copy", selectedRow.value);
  }
}

function onRowDataUpdated(event: RowDataUpdatedEvent) {
  if (selectedRow.value?.id) {
    event.api.forEachNode((node) => {
      if (node.data.id === selectedRow.value?.id) {
        node.setSelected(true, true);
      }
    });
  }
  emit("dataUpdated", event.api);
}
</script>

<template>
  <grid-wrapper
    ref="gridWrapperRef"
    row-selection="single"
    identifier="resource-model-instances"
    :grid-data="modelInstances"
    @row-data-updated="onRowDataUpdated"
    @prepare-columns="onPrepareColumns"
    @selection-changed="onSelectionChanged"
  >
  <template #custom-buttons>
    <slot name="custom-buttons" />
  </template>    
  </grid-wrapper>
</template>
