import { GanttLayout, IocSymbols, Layer, Layout, LinesManagerBase, OverlapLevel, OverlappingTimeIntervalTreeActivityRepository, Row, TimelineManager } from "@masta/gantt2/core";
import { inject, injectable } from "inversify";
import { BehaviorSubject } from "rxjs";
import { AvailabilityRuleResourceCapacityActivity, ResourceCapacityActivity, ResourceRow } from "@/components/Gantt/Common/Model";

@injectable()
export class ResourceLineManger extends LinesManagerBase<ResourceCapacityActivity> {

  private _isOverlapExtended: boolean = false;
  private _maxOverlap: OverlapLevel = new OverlapLevel(Number.MAX_VALUE);
  private _lineLayout: GanttLayout = new GanttLayout();
  private _numOfOverlappedLines: number;

  constructor(
    @inject(TimelineManager) timelineManager: TimelineManager,
    @inject(IocSymbols.LayersSymbol) layers$$: BehaviorSubject<Layer[]>,
    @inject(Row<any, any, any>) row: ResourceRow
  ) {
    super(row);
  }

  set isOverlapExtended(value: boolean) {
    this._isOverlapExtended = value;
  }

  get isOverlapExtended(): boolean {
    return this._isOverlapExtended;
  }

  clearCache(): void {
  }

  getLineHeight(lineIndex: number, rowHeight: number): number {
    return Math.round(rowHeight / this._numOfOverlappedLines);
  }

  getLineIndex(activity: ResourceCapacityActivity): number {
    const maxOverlap = this._maxOverlap;

    if (activity.constructor.name === AvailabilityRuleResourceCapacityActivity.name)
      return 0;

    let lineIndex = 0;
    if (this._isOverlapExtended) {
      lineIndex = activity.overlap.getOverlapPosition();
      lineIndex = Math.min(lineIndex, maxOverlap.level);
    }
    return lineIndex;
  }

  getLineLayout(lineIndex: number): Layout {
    return this._lineLayout;
  }

  getLineLocation(lineIndex: number, rowHeight: number): number {
    let location = 0.0;
    for (let i = 0; i < lineIndex; i++) {
      location += this.getLineHeight(i, rowHeight);
    }
    return location;
  }

  layout(): void {
    this._maxOverlap = new OverlapLevel((this._row.repository as OverlappingTimeIntervalTreeActivityRepository<any>).getMaximalOverlapLevel());
    const newNumOfLines = Math.min(this.determineNumberOfLines(), this._maxOverlap.level);
    this._row.lineCount = newNumOfLines;
    this._numOfOverlappedLines = this._isOverlapExtended ? Math.min(this._maxOverlap.level, this._row.lineCount) : 1;
  }

  private determineNumberOfLines() {
    let numOfLines = 0;
    if (this._isOverlapExtended) {
      numOfLines = this._maxOverlap.level;
    } else {
      numOfLines = 1;
    }
    return numOfLines;
  }

}
