<template>
  <v-row justify="end">
    <v-col cols="12">
      <div v-if="scenarios && scenarios.length" class="mx-10">
        <span class="scenario-text font-weight-regular pr-2">
          {{ $t("scenarioSelector-scenario-label", { $: "Scenario:" }) }}
        </span>
        <v-select
          v-model="selScenId"
          :items="scenarios"
          item-title="name"
          item-value="id"
          dense
          :bg-color="selectedItemStatus!=0 ? 'warning' : ''"
          @update:model-value="scenarioChanged"
        />
      </div>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import { $t } from "@/i18n";
import { useScenariosStore } from "@/store/ScenariosStore";
import { storeToRefs } from "pinia";
import { computed, onMounted, ref } from "vue";

const scenarioStore = useScenariosStore();
const { selectedScenario, scenarios } = storeToRefs(scenarioStore);
const selScenId = ref<string | null>(null);

onMounted(async () => {
  await scenarioStore.fetch();
  selScenId.value = scenarioStore.selectedScenario?.id ?? null;
});

const selectedItemStatus = computed(() => {
  const selectedItem = scenarios.value.find(item => item.id === selScenId.value);
  return selectedItem ? selectedItem.status : 0;
});

const scenarioChanged = (newValue: string) => {
  //console.log("Selected Item ID changed to:", newValue);
  scenarioStore.selectScenario(scenarioStore.scenarioById(newValue) ?? null);
};
</script>

<style lang="scss" scoped>
.scenario-text {
  text-transform: uppercase;
  font-size: 12px;
}
</style>
