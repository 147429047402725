<template>
  <master-detail-layout scenario-selector :detail="task !== null" :detail-actions="detailActions" detail-dialog-width="100%" @change="$refs.reportsGrid.gridApi.deselectAll()">
    <template #master>
      <v-card elevation="0" class="flexcard fill-height">
        <v-card-title>
          <breadcrumbs>{{ $t("dashboard-view-dashboards-tile", { dashboardName, $: "Dashboard: {dashboardName}" }) }}</breadcrumbs>
        </v-card-title>
        <v-card-text class="fill-height">
          <v-row class="fill-height" no-gutters>
            <v-col cols="12">
              <iframe class="w-100 h-100 border-0" :src="dashboardUrl"></iframe>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
  </master-detail-layout>
</template>

<script lang="ts" setup>
import MasterDetailLayout from "@/components/Layout/MasterDetailLayout.vue";
import { ref, watch } from "vue";
import { useRoute } from "vue-router";
import Breadcrumbs from "@/components/Layout/Breadcrumbs.vue";

const route = useRoute();
const dashboardName = ref<string>(route.query.name as string);
const dashboardUrl = ref<string>(route.query.url as string);

watch(route, (routeValue) => {
  dashboardName.value = routeValue.query.name as string;
  dashboardUrl.value = routeValue.query.url as string;
});
</script>

<style lang="scss" scoped></style>
