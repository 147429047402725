<template>
  <v-card elevation="0" class="pa-2">
    <v-card-title>
      <breadcrumbs>{{ $t("import-view-import-title", { $: "Import" }) }}</breadcrumbs>
    </v-card-title>
    <v-card-text>
      <v-row class="pt-6">
        <v-col cols="5">
          <v-row>
            <v-col cols="10">
              <v-row>
                <v-col cols="12" class="text-h6">
                  {{ $t("import-view-dataImport-label", { $: "Data import" }) }}
                </v-col>
                <v-col cols="12" class="text-body-2 text-secondary">
                  <v-icon>mdi-help-circle-outline</v-icon>
                  {{ $t("import-view-importBaseCalendarInfo-text", { $: "To import records you need to select a compatible JSON file from your device." }) }}
                </v-col>
                <v-col cols="8">
                  <v-file-input
                    v-model="chosenFile"
                    variant="outlined"
                    density="compact"
                    hide-details
                    prepend-icon
                    prepend-inner-icon="mdi-paperclip"
                    accept="application/json"
                    :label="$t('import-importFromJsonFile-jsonImportFile-label', { $: 'Select JSON file' })"
                    clearable
                  />
                </v-col>
                <v-col cols="8">
                  <v-btn data-cy="import-data" variant="elevated" color="primary" block :loading="importing" :disabled="importing" @click="importData">
                    <span v-if="!importing">{{ $t("import-importFromJsonFile-import-action-active", { $: "Import data" }) }}</span>
                    <v-icon v-else> mdi-loading mdi-spin</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <!--            <v-row>
                              <v-col cols="12" class="text-h6">
                                {{ $t("import-view-generateBaseCalendar-label", { $: "Generate Base Calendar" }) }}
                              </v-col>
                              <v-col cols="12" class="text-body-2 text-secondary">
                                <v-icon>mdi-help-circle-outline</v-icon>
                                {{ $t("import-view-generateBaseCalendarInfo-text", { $: "You can export entire Base calendar to JSON file." }) }}
                              </v-col>
                              <v-col cols="4">
                                <v-btn data-cy="import-data" block variant="elevated" color="primary" @click="generateBaseCalendar">
                                  <span>{{ $t("import-view-generateBaseCalendar-action", { $: "Generate Base Calendar" }) }}</span>
                                </v-btn>
                              </v-col>
                            </v-row> -->
              <v-row>
                <v-col cols="12" class="text-h6">
                  {{ $t("import-view-dataOperations-label", { $: "Data operations" }) }}
                </v-col>
                <v-col cols="8">
                  <v-btn variant="elevated" color="secondary" block @click="aggregateResourceCapacities()">
                    {{ $t("import-dataOperations-aggregateRscp-action", { $: "Aggregate Resource Capacities" }) }}
                  </v-btn>
                </v-col>
                <v-col cols="8">
                  <v-btn variant="elevated" color="secondary" block @click="aggregateElectricityConsumptionResourceCapacities()">
                    {{ $t("import-dataOperations-aggregateElectricityConsumptionRscp-action", { $: "Aggregate Electricity Consumption" }) }}
                  </v-btn>
                </v-col>
                <v-col cols="8">
                  <v-btn variant="elevated" color="secondary" block :loading="recomputingScales" :disabled="recomputingScales" @click="recomputeAllResourceCapacityScales()">
                    {{ $t("import-dataOperations-recomputeAllResourceCapacityScales-action", { $: "Recompute All Resource Capacity Scales" }) }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-divider vertical />
        <v-col cols="5">
          <v-row>
            <v-col cols="10" offset="2">
              <v-row>
                <v-col cols="12" class="text-h6">
                  {{ $t("import-view-resetData-label", { $: "Reset data" }) }}
                </v-col>
                <v-col cols="12" class="text-body-2 text-secondary">
                  <v-icon>mdi-help-circle-outline</v-icon>
                  {{ $t("import-view-resetDataInfo-text", { $: "You can reset data using selected profile" }) }}
                </v-col>
              </v-row>
              <v-row class="pt-4">
                <v-col cols="8">
                  <v-select
                    v-model="tenantType"
                    variant="outlined"
                    density="compact"
                    hide-details
                    :label="$t('create-tenant-view-tenantType-label', { $: 'Select profile for sample data and settings' })"
                    :items="tenantTypes"
                    item-title="name"
                    item-value="id"
                    :rules="[(v) => !isNaN(v) || 'Required']"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="8">
                  <v-btn data-cy="reset-all-data" variant="elevated" color="primary" block :loading="resetting" :disabled="resetting" @click="resetAllData()">
                    {{ $t("import-resetDataToDefaults-reset-action", { $: "Reset data using selected profile" }) }}
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="8">
                  <v-btn data-cy="delete-all-data" variant="elevated" color="error" block :loading="deleting" :disabled="deleting" @click="deleteAllData()">
                    {{ $t("import-deleteAllData-delete-action", { $: "Wipe All Data" }) }}
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-divider class="my-5" />
                <v-col cols="8">
                  <v-row>
                    <v-col cols="6">
                      <v-btn data-cy="export-data" variant="elevated" color="secondary" block :loading="exporting" :disabled="exporting" height="100%" @click="exportData()">
                        <span v-if="!exporting">{{ $t("import-dataOperations-export-action", { $: "Export Data" }) }}</span>
                        <v-icon v-else> mdi-loading mdi-spin</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col cols="6">
                      <v-switch
                        v-model="exportIncludeTransactionalData"
                        hide-details
                        color="accent"
                        density="compact"
                        :label="$t('import-dataOperations-exportIncludeTransactionalData-label', { $: 'Include transactional data' })"
                      />
                      <v-switch
                        v-model="exportIncludeWorkJournalRecords"
                        hide-details
                        color="accent"
                        density="compact"
                        :label="$t('import-dataOperations-exportIncludeWorkJournalRecords-label', { $: 'Include work journal records' })"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row style="display: none">
            <v-col cols="10" offset="2">
              <v-row>
                <v-col cols="12" class="text-h6">
                  {{ $t("import-view-jiraIntegration-label", { $: "JIRA Integration" }) }}
                </v-col>
                <v-col cols="12" class="text-body-2 text-secondary">
                  <v-icon>mdi-help-circle-outline</v-icon>
                  {{ $t("import-view-jiraIntegrationInfo-text", { $: "To import JIRA project, connect with authentication app first." }) }}
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <v-btn variant="elevated" color="primary" block @click="connectToJira()">
                    {{ $t("import-importFromJira-connect-action", { $: "Connect with JIRA" }) }}
                  </v-btn>
                </v-col>
              </v-row>
              <v-row class="pt-4">
                <v-col cols="4">
                  <v-text-field v-model="jql" variant="outlined" density="compact" label="JQL" hide-details />
                </v-col>
                <v-col cols="4">
                  <v-btn variant="elevated" color="primary" density="compact" block height="100%" @click="importFromJira()">
                    {{ $t("import-importFromJira-import-action", { $: "Import" }) }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import ApiService from "@/services/api";
import { ref } from "vue";
import { useAuthStore } from "@/store/AuthStore";
import { mapActions, mapState } from "pinia";
import { useSnackbarsStore } from "@/store/SnackbarsStore";
import { $t } from "@/i18n";
import Breadcrumbs from "@/components/Layout/Breadcrumbs.vue";
import SystemEnumService from "@/services/system-enum.service";
import { format } from "date-fns";
import { useScenariosStore } from "@/store/ScenariosStore";

export default {
  components: { Breadcrumbs },
  title: "Import",
  setup() {
    const chosenFile = ref([]);
    return { chosenFile };
  },
  data: () => ({
    jql: "project in (MASTA, EFPF)",
    importing: false,
    exporting: false,
    resetting: false,
    deleting: false,
    recomputingScales: false,
    exportIncludeTransactionalData: true,
    exportIncludeWorkJournalRecords: true,
    tenantType: undefined,
    tenantTypes: [
      { id: 1, name: SystemEnumService.tenantType(1) },
      { id: 0, name: SystemEnumService.tenantType(0) }
    ]
  }),
  watch: {
    exportIncludeWorkJournalRecords(newValue) {
      if (newValue) {
        this.exportIncludeTransactionalData = true;
      }
    },
    exportIncludeTransactionalData(newValue) {
      if (!newValue) {
        this.exportIncludeWorkJournalRecords = false;
      }
    }
  },
  computed: {
    ...mapState(useAuthStore, ["currentTenantId"]),
    ...mapState(useScenariosStore, ["selectedScenario"])
  },
  mounted() {
    this.tenantType = 1;
  },
  methods: {
    ...mapActions(useSnackbarsStore, ["createSnackbar"]),
    async importData() {
      if (!this.chosenFile) return;
      try {
        this.importing = true;
        const data = await this.readFileAsTextAsync(this.chosenFile[0]);
        await ApiService.imports.importData(data);
        useScenariosStore().clearSelectedScenario();
        await useScenariosStore().fetch();
        await this.createSnackbar({
          message: $t("import-importFromJson-onSuccess-message", { $: "Import done!" }),
          closeable: true
        });
      } catch (ex) {
        console.error(ex);
        await this.createSnackbar({
          message: $t("import-importFromJson-onError-message", { $: "There was an error during import!" }),
          type: "error",
          closeable: true
        });
      } finally {
        this.importing = false;
      }
    },
    readFileAsTextAsync(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
          resolve(reader.result);
        };

        reader.onerror = reject;

        reader.readAsText(file);
      });
    },
    async deleteAllData() {
      let result = confirm("Are you sure you want to delete all data?");
      if (!result) return;
      result = confirm(`To be 100% sure check if you are on correct environment! (${import.meta.env.VITE_URL_BASE})`);
      if (!result) return;
      try {
        this.deleting = true;
        await ApiService.tenants.deleteAllData();
        await this.createSnackbar({
          message: $t("import-deleteAllData-onSuccess-message", { $: "All data deleted!" }),
          closeable: true
        });
      } catch (ex) {
        console.error(ex);
        await this.createSnackbar({
          message: $t("import-deleteAllData-onError-message", { $: "There was an error when deleting data!" }),
          type: "error",
          closeable: true
        });
      } finally {
        this.deleting = false;
      }
    },
    async resetAllData() {
      let result = confirm("Are you sure you want to reset all data?");
      if (!result) return;
      result = confirm(`To be 100% sure check if you are on correct environment! (${import.meta.env.VITE_URL_BASE})`);
      if (!result) return;
      try {
        this.resetting = true;
        await ApiService.tenants.resetAllData(this.tenantType);
        useScenariosStore().clearSelectedScenario();
        await useScenariosStore().fetch();
        await this.createSnackbar({
          message: $t("import-resetDataToDefaults-onSuccess-message", { $: "All data reset to defaults!" }),
          closeable: true
        });
      } catch (ex) {
        console.error(ex);
        await this.createSnackbar({
          message: $t("import-resetDataToDefaults-onError-message", { $: "There was an error when resetting data!" }),
          type: "error",
          closeable: true
        });
      } finally {
        this.resetting = false;
      }
    },
    generateBaseCalendarData(year) {
      const arr = [];
      const calendar = {
        elementType: "calendarResource",
        action: "create",
        name: $t("import-generateBaseCalendar-nameParam-input", { $: "Base Calendar" }),
        businessId: $t("import-generateBaseCalendar-businessIdParam-input", { $: "BC" }),
        isBase: true
      };
      arr.push(calendar);

      const dayStart = new Date(year, 0, 1, 8);
      const dayEnd = new Date(year, 0, 1, 16);
      while (dayStart.getFullYear() === year) {
        if (dayStart.getDay() !== 0 && dayStart.getDay() !== 6) {
          const calendarSupply = {
            elementType: "resourceCapacity",
            action: "create",
            periodStart: dayStart.toISOString(),
            periodEnd: dayEnd.toISOString(),
            changeType: 0,
            quantity: 1,
            capacityGroup: 0,
            resourceBusinessId: $t("import-generateBaseCalendar-businessIdParam-input", { $: "BC" })
          };
          arr.push(calendarSupply);
        }
        dayStart.setDate(dayStart.getDate() + 1);
        dayEnd.setDate(dayEnd.getDate() + 1);
      }

      return arr;
    },
    downloadExportFile(data) {
      const blob1 = new Blob([data], { type: "text/plain;charset=utf-8" });
      const url = window.URL || window.webkitURL;
      const link = url.createObjectURL(blob1);
      const a = document.createElement("a");
      a.download = `Export-${format(new Date(), "yyyyMMddHHmm")}.json`;
      a.href = link;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    downloadJSON(data) {
      let json = JSON.stringify(data);

      //Convert JSON string to BLOB.
      json = [json];
      const blob1 = new Blob(json, { type: "text/plain;charset=utf-8" });

      const url = window.URL || window.webkitURL;
      const link = url.createObjectURL(blob1);
      const a = document.createElement("a");
      a.download = "Import.json";
      a.href = link;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    generateBaseCalendar() {
      const year = +prompt($t("import-generateBaseCalendar-selectYear-prompt", { $: "Which year?" }), `${new Date().getFullYear()}`);
      if (isNaN(year)) {
        alert($t("import-generateBaseCalendar-onInvalidYearError-message", { $: "Bad year!" }));
        return;
      }
      const data = this.generateBaseCalendarData(year);
      this.downloadJSON(data);
    },
    connectToJira() {
      const state = document.location.href + "?tenant=" + this.currentTenantId;
      // eslint-disable-next-line max-len
      document.location.href = `https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=b5tRGIQtg0ImzyUHGz6sjWXmcZF4IwcS&scope=offline_access%20read%3Ajira-user%20read%3Ajira-work&redirect_uri=https%3A%2F%2Flocalhost%3A5001%2Fapi%2FImport%2Fjira_callback&state=${state}&response_type=code&prompt=consent`;
    },
    async importFromJira() {
      try {
        await ApiService.imports.importFromJira(this.jql);
        await this.createSnackbar({
          message: $t("import-importFromJira-onFinished-message", { $: "Import from JIRA done!" }),
          closeable: true
        });
      } catch (e) {
        console.log(e);
        await this.createSnackbar({
          message: $t("import-importFromJira-onError-message", { $: "Error during import from JIRA!" }),
          type: "error",
          closeable: true
        });
      }
    },
    async aggregateResourceCapacities() {
      try {
        await ApiService.aggregateResourceCapacities.aggregateResourceCapacities();
        await this.createSnackbar({
          message: $t("import-dataOperations-onFinishedAggregation-message", { $: "Aggregation of Resource Capacities started" }),
          closeable: true
        });
      } catch (e) {
        console.log(e);
        await this.createSnackbar({
          message: $t("import-dataOperations-onErrorAggregation-message", { $: "Aggregation of Resource Capacities failed!" }),
          type: "error",
          closeable: true
        });
      }
    },
    async aggregateElectricityConsumptionResourceCapacities() {
      try {
        await ApiService.aggregateElectricityConsumptionResourceCapacities.aggregateElectricityConsumptionResourceCapacities();
        await this.createSnackbar({
          message: $t("import-dataOperations-onFinishedeElectricityConsumptionAggregation-message", { $: "Aggregation of Electricity Consumption Resource Capacities started" }),
          closeable: true
        });
      } catch (e) {
        console.log(e);
        await this.createSnackbar({
          message: $t("import-dataOperations-onErrorElectricityConsumptionAggregation-message", { $: "Aggregation of Electricity Consumption Resource Capacities failed!" }),
          type: "error",
          closeable: true
        });
      }
    },
    async recomputeAllResourceCapacityScales() {
      try {
        this.recomputingScales = true;
        await ApiService.resources.recomputeAllCapacityScales({ scenarioId: this.selectedScenario?.id ?? "" });
        await this.createSnackbar({
          message: $t("import-dataOperations-onFinishedRecomputeAllResourceCapacityScales-message", { $: "Recompute All Resource Capacity Scales started" }),
          closeable: true
        });
      } catch (e) {
        console.log(e);
        await this.createSnackbar({
          message: $t("import-dataOperations-onErrorRecomputeAllResourceCapacityScales-message", { $: "Recompute All Resource Capacity Scales failed!" }),
          type: "error",
          closeable: true
        });
      } finally {
        this.recomputingScales = false;
      }
    },
    async exportData() {
      try {
        this.exporting = true;
        await ApiService.exports.createExportData(this.exportIncludeWorkJournalRecords, this.exportIncludeTransactionalData, "");
        await this.createSnackbar({
          message: this.$t("export-dataOperations-onSuccessOnExport-message", { $: "Export file is being created! An email with download link will be sent to you" }),
          type: "info",
          closeable: false
        });
      } catch (e) {
        console.log(e);
        await this.createSnackbar({
          message: $t("export-dataOperations-onErrorOnExport-message", { $: "Exporting failed!" }),
          type: "error",
          closeable: true
        });
      } finally {
        this.exporting = false;
      }
    }
  }
};
</script>

<style scoped></style>
