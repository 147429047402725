<script lang="ts" setup>
import GridWrapper from "@/components/Grid/GridWrapper.vue";
import { inject, reactive, ref, watch } from "vue";
import { GridWrapperComponent } from "@/components/Grid/GridWrapperComponent";
import { $dateTimeFormatterSymbol, DateFormatter } from "@masta/shared";
import ApiService from "@/services/api";
import { GetRowIdParams, GridApi } from "ag-grid-community";
import { useSnackbarsStore } from "@/store/SnackbarsStore";
import { useScenariosStore } from "@/store/ScenariosStore";
import {
  ArchiveModelInstanceCommand,
  CalculateCostsForOrderCommand,
  CostMonitoringDocumentType,
  CreateNewDocumentRevisionCommand,
  CreateNewDocumentRevisionWithNewAttachmentCommand,
  DocumentDto,
  MarkConfirmedModelInstanceCommand,
  MarkSentModelInstanceCommand,
  OrderDto,
  OrderStatusChangeNotificationEvent,
  QuotationDocumentGeneratedNotificationEvent,
  RegisterCostMonitoringExcelExportCommand,
  ReleaseModelInstanceCommand
} from "@masta/generated-model";
import { $t } from "@/i18n";
import { translateModelInstanceStatus } from "@/composables/translateEnum";
import { OrderStatusChangedNotification, QuotationDocumentGeneratedNotification, useNotification } from "@/notifications";
import { useDebounceFn } from "@vueuse/core/index";
import { useRouter } from "vue-router";
import { QuotationDocumentsServerSideDataSource } from "@/components/QuotationDocuments/QuotationDocumentsServerSideDataSource";
import { getModelInstanceStatusCellStyle } from "@/components/Tasks/TaskUtils";
import { getSelectedRows } from "@/components/Grid/UseGridSelection";
import { AssignModelInstanceToCustomerOrderCommand } from "../../../../../libs/generated-model/src/lib/assign-model-instance-to-customer-order-command";
import { useDocumentsStore } from "@/store/DocumentsStore";

interface Props {
  readonly?: boolean;
  selectedOrder: OrderDto;
}

const router = useRouter();
const props = withDefaults(defineProps<Props>(), {});

const snackbarsStore = useSnackbarsStore();
const documentsStore = useDocumentsStore();
const scenariosStore = useScenariosStore();

const fileInputRef = ref<HTMLInputElement>();

watch(props.selectedOrder, () => {
  serverSideDataSource.useFilteringByOrderId(props.selectedOrder.id);
  gridWrapperRef.value?.gridApi?.refreshServerSide();
});
const serverSideDataSource = reactive(new QuotationDocumentsServerSideDataSource("quotationDocuments", "quotationDocuments"));
serverSideDataSource.useFilteringByOrderId(props.selectedOrder.id);

useNotification(OrderStatusChangedNotification, async (e: OrderStatusChangeNotificationEvent) => {
  onOrderStatusChanged(e);
});

const onOrderStatusChanged: (e: OrderStatusChangeNotificationEvent) => void = useDebounceFn(async (e: OrderStatusChangeNotificationEvent) => {
  if (e.orderId === props.selectedOrder.id) {
    gridWrapperRef.value?.gridApi?.refreshServerSide();
  }
}, 800);

const isRowSelected = ref(false);
const ukryjGoProsze = ref(true); //jest mi smutno bo zmienna niniejsza juz nie jest uzywana ale zostawiam ja zeby nie bylo mi az tak smutno

function getSelectedRowData(): any {
  return getSelectedRows(gridWrapperRef.value?.gridApi)[0];
}

function onSelectionChanged({ api }: { api: GridApi }) {
  isRowSelected.value = getSelectedRows(api).length > 0;
}

async function onRowDoubleClicked({ api }: { api: GridApi }) {
  if (gridWrapperRef.value?.isEditing()) return;
  await handleExistingDocument();
}

async function onReleaseModelInstance() {
  await ApiService.documents.release({ id: getSelectedRowData()?.id, revisionNumber: getSelectedRowData()?.revisionNumber } as ReleaseModelInstanceCommand);
}

async function onArchiveModelInstance() {
  await ApiService.documents.archive({ id: getSelectedRowData()?.id, revisionNumber: getSelectedRowData()?.revisionNumber } as ArchiveModelInstanceCommand);
  gridWrapperRef.value?.gridApi?.refreshServerSide();
}

async function onCreateNewRevisionOfModelInstance() {
  await ApiService.documents.createNewRevision({ id: getSelectedRowData()?.id } as CreateNewDocumentRevisionCommand);
  await ApiService.customerOrders.assignModelInstance({
    orderId: props.selectedOrder.id,
    modelInstanceId: getSelectedRowData()?.id,
    scenarioId: props.selectedOrder.scenarioId
  } as AssignModelInstanceToCustomerOrderCommand);
  gridWrapperRef.value?.gridApi?.refreshServerSide();
}

const gridWrapperRef = ref<GridWrapperComponent>();
const $dateTimeFormatter = inject<DateFormatter>($dateTimeFormatterSymbol)!;

function resolveNextPosition(): number {
  const nextRowOrdinal = gridWrapperRef.value ? gridWrapperRef.value.gridApi.getDisplayedRowCount() + 1 : 1;
  return nextRowOrdinal * 10;
}

function getRowId(params: GetRowIdParams<DocumentDto>) {
  return `${params.data.id}-${params.data.revisionNumber}`;
}

function onPrepareColumns(columnDefs: any) {
  columnDefs.value = [
    {
      field: "businessId",
      editable: false,
      sortable: true,
      resizable: true,
      filter: "agTextColumnFilter",
      headerValueGetter: (_: any) => $t("quotation-list-businessId-label", { $: "Business Id" })
    },
    {
      field: "value.attachment.fileName",
      editable: false,
      sortable: true,
      resizable: true,
      filter: "agTextColumnFilter",
      headerValueGetter: (_: any) => $t("quotation-list-fileName-label", { $: "Filename" })
    },
    {
      field: "revisionNumber",
      editable: false,
      sortable: true,
      resizable: true,
      filter: "agTextColumnFilter",
      headerValueGetter: (_: any) => $t("quotation-list-revisionNumber-label", { $: "Revision" })
    },
    {
      field: "createdBy",
      editable: false,
      resizable: true,
      headerValueGetter: (_: any) => $t("quotation-list-createdBy-label", { $: "Created By" })
    },
    {
      field: "createdAt",
      type: ["dateTimeTypeColumn"],
      editable: false,
      resizable: true,
      valueFormatter: (params: any) => {
        return $dateTimeFormatter(params.data.createdAt);
      },
      headerValueGetter: (_: any) => $t("quotation-list-createdAt-label", { $: "Created At" })
    },
    {
      field: "modifiedBy",
      editable: false,
      resizable: true,
      headerValueGetter: (_: any) => $t("quotation-list-modifiedBy-label", { $: "Modified By" })
    },
    {
      field: "modifiedAt",
      type: ["dateTimeTypeColumn"],
      editable: false,
      resizable: true,
      valueFormatter: (params: any) => {
        return $dateTimeFormatter(params.data.modifiedAt);
      },
      headerValueGetter: (_: any) => $t("quotation-list-modifiedAt-label", { $: "Modified At" })
    },
    {
      field: "status",
      editable: false,
      sortable: true,
      resizable: true,
      valueFormatter: (params: any) => translateModelInstanceStatus(params.value),
      cellStyle: (params: any) => getModelInstanceStatusCellStyle(params.value),
      headerValueGetter: (_: any) => $t("modelInstance-list-status-label", { $: "Status" })
    }
  ];
}

watch(props, (newProps) => {
  serverSideDataSource.useFilteringByOrderId(newProps.selectedOrder.id);
  gridWrapperRef.value?.gridApi.refreshServerSide();
});

async function handleExistingDocument() {
  const selectedRows = getSelectedRows(gridWrapperRef.value?.gridApi);
  if (selectedRows.length === 0) {
    return;
  }
  if (selectedRows[0].businessId.endsWith(".xlsx")) {
    await downloadQuotationDocument(selectedRows[0].id, selectedRows[0].revisionNumber);
  } else {
    await navigateToDocumentEdition();
  }
}

async function navigateToDocumentEdition() {
  const selectedRows = getSelectedRows(gridWrapperRef.value?.gridApi);
  const query = { documentId: selectedRows[0].id, revisionNumber: selectedRows[0].revisionNumber };
  await router.push({ path: "../operations/quotation-document", query });
}

async function navigateToNewDocumentEdition() {
  if (props.selectedOrder) {
    const query = { orderId: props.selectedOrder.id };
    await router.push({ path: "../operations/quotation-document", query });
  }
}

async function downloadQuotationDocument(documentId: string, revisionNumber: number) {
  try {
    const singleDocument = await ApiService.quotationDocuments.getSingle(documentId, revisionNumber);
    const { data } = await ApiService.documents.getDocumentContentStream({
      documentId: documentId,
      revisionNumber: revisionNumber
    });
    if (singleDocument && data) {
      const urlOfXlsx = window.URL.createObjectURL(data);
      const a = document.createElement("a");
      a.href = urlOfXlsx!;
      a.download = singleDocument.data.value.attachment.fileName;
      a.target = "_blank";
      a.click();
    }
  } catch (e) {
    console.error(e);
  }
}

async function calculateCostsAndGenerateExcelCalculation() {
  if (props.selectedOrder) {
    const request = {
      orderId: props.selectedOrder.id,
      scenarioId: scenariosStore.selectedScenario?.id,
      documentType: CostMonitoringDocumentType.Quotation
    } as CalculateCostsForOrderCommand;

    try {
      //calculateCosts generates snapshot of costs as well as generates excel
      await ApiService.quotations.calculateCosts(request);
      snackbarsStore.createSnackbar({
        message: $t("quotation-list-onCalculateSuccess-message", { $: "Order has been sent to costs calculation. Please check back later" }),
        type: "success",
        closeable: true
      });
    } catch (e) {
      console.error(e);
      snackbarsStore.createSnackbar({
        message: $t("quotation-list-onCalculateError-message", { $: "Could not calculate quotation of order" }),
        type: "error",
        closeable: true
      });
    }
  }
}

async function generateCostMonitoringExport() {
  if (!props.selectedOrder || !scenariosStore.selectedScenario) {
    return;
  }
  const orderIds = [props.selectedOrder.id];
  try {
    const request = { orderIds } as RegisterCostMonitoringExcelExportCommand;
    await ApiService.productCostAccounting.generateCostMonitoringExport(request);
    await snackbarsStore.createSnackbar({
      message: $t("order-list-generateCostMonitoringExportSuccess-message", { $: "Cost monitoring export file is being created! An email with download link will be sent to you" }),
      type: "success",
      closeable: true
    });
  } catch (e) {
    console.error(e);
    await snackbarsStore.createSnackbar({
      message: $t("order-list-generateCostMonitoringExportError-message", { $: "Could not create cost monitoring export file" }),
      type: "error",
      closeable: true
    });
  }
}

async function markDocumentAsSent() {
  if (!props.selectedOrder) {
    return;
  }

  try {
    const selectedDocument = getSelectedRowData();
    if (selectedDocument) {
      await ApiService.documents.markSent({ id: selectedDocument.id, revisionNumber: selectedDocument.revisionNumber } as MarkSentModelInstanceCommand);
      gridWrapperRef.value?.gridApi?.refreshServerSide();
    }

    snackbarsStore.createSnackbar({
      message: $t("quotation-list-onQuotationSent-message", { $: "Document has been given a 'sent' status" }),
      type: "success",
      closeable: true
    });
  } catch (e) {
    console.error(e);
    snackbarsStore.createSnackbar({
      message: $t("quotation-list-onQuotationSentError-message", { $: "Could not mark document as 'sent'" }),
      type: "error",
      closeable: true
    });
  }
}

async function markDocumentAsConfirmed() {
  if (!props.selectedOrder) {
    return;
  }

  try {
    const selectedDocument = getSelectedRowData();
    if (selectedDocument) {
      await ApiService.documents.markConfirmed({ id: selectedDocument.id, revisionNumber: selectedDocument.revisionNumber } as MarkConfirmedModelInstanceCommand);
      gridWrapperRef.value?.gridApi?.refreshServerSide();
    }
    snackbarsStore.createSnackbar({
      message: $t("quotation-list-onQuotationConfirm-message", { $: "Document has been given a 'confirmed' status" }),
      type: "success",
      closeable: true
    });
  } catch (e) {
    console.error(e);
    snackbarsStore.createSnackbar({
      message: $t("quotation-list-onQuotationConfirmError-message", { $: "Could not mark document as 'confirmed'" }),
      type: "error",
      closeable: true
    });
  }
}

useNotification(QuotationDocumentGeneratedNotification, (e: QuotationDocumentGeneratedNotificationEvent) => {
  onQuotationDocumentChanged(e);
});

const onQuotationDocumentChanged: (e: QuotationDocumentGeneratedNotificationEvent) => void = useDebounceFn(async (e: QuotationDocumentGeneratedNotificationEvent) => {
  gridWrapperRef?.value?.gridApi.refreshServerSide();
}, 500);

async function handleFileSelection(event: Event) {
  const input = event.target as HTMLInputElement;
  const file = input.files ? input.files[0] : null;
  if (!file) return;

  try {
    await documentsStore.createNewRevisionWithNewAttachment({ file: file, id: getSelectedRowData().id } as CreateNewDocumentRevisionWithNewAttachmentCommand);
    await ApiService.customerOrders.assignModelInstance({
      orderId: props.selectedOrder.id,
      scenarioId: props.selectedOrder.scenarioId,
      modelInstanceId: getSelectedRowData()?.id,
      revisionNumber: getSelectedRowData()?.revisionNumber + 1
    } as AssignModelInstanceToCustomerOrderCommand);
    gridWrapperRef.value?.gridApi?.refreshServerSide();
    await snackbarsStore.createSnackbar({
      message: $t("quotation-list-onUploadSuccess-message", "New revision uploaded!"),
      type: "success",
      closeable: true
    });
  } catch (error) {
    console.log(error);
    await snackbarsStore.createSnackbar({
      message: $t("quotation-list-onUploadFailed-message", "Could not upload new revision."),
      type: "error",
      closeable: true
    });
  } finally {
    input.value = "";
  }
}
</script>

<template>
  <grid-wrapper
    ref="gridWrapperRef"
    identifier="quotations"
    row-selection="single"
    :server-side="true"
    :server-side-datasource="serverSideDataSource"
    :server-side-infinite-scroll="false"
    :get-row-id="getRowId"
    :pagination="false"
    refresh-btn
    :delete-btn="!readonly"
    :hide-custom-actions-separator="readonly"
    @prepare-columns="onPrepareColumns"
    @selection-changed="onSelectionChanged"
    @row-double-clicked="onRowDoubleClicked"
  >
    <template #custom-buttons>
      <v-menu>
        <template #activator="{ props, isActive }">
          <v-btn v-if="!readonly" v-bind="props" size="small" variant="text" density="compact" class="mr-4">
            <span>{{ $t("quotation-list-actions-action", { $: "Actions" }) }}</span>
            <v-icon class="pl-4" :icon="isActive ? 'mdi-chevron-up' : 'mdi-chevron-down'" />
          </v-btn>
        </template>
        <v-list density="compact">
          <v-tooltip bottom open-delay="300">
            <template #activator="{ props }">
              <v-list-item v-bind="props" @click="calculateCostsAndGenerateExcelCalculation">
                <template #prepend>
                  <v-icon icon="mdi-calculator"></v-icon>
                </template>
                <v-list-item-title>{{ $t("quotation-list-calculateAndGenerate-action", { $: "1. Generate New" }) }}</v-list-item-title>
              </v-list-item>
            </template>
            <span>{{ $t("quotation-list-calculateAndGenerate-tooltip", { $: "Calculate costs and generate new spreadsheet" }) }}</span>
          </v-tooltip>
          <v-tooltip bottom open-delay="300">
            <template #activator="{ props }">
              <v-list-item :disabled="!isRowSelected" v-bind="props" @click="onCreateNewRevisionOfModelInstance">
                <template #prepend>
                  <v-icon icon="mdi-ab-testing"></v-icon>
                </template>
                <v-list-item-title>{{ $t("quotation-list-createNewVersion-action", { $: "2. Create New Version" }) }}</v-list-item-title>
              </v-list-item>
            </template>
            <span>{{ $t("quotation-list-createNewVersion-tooltip", { $: "Create New Version of calculation spreadsheet" }) }}</span>
          </v-tooltip>
          <v-tooltip bottom open-delay="300">
            <template #activator="{ props }">
              <v-list-item :disabled="!isRowSelected" v-bind="props" @click="markDocumentAsSent">
                <template #prepend>
                  <v-icon icon="mdi-email-fast"></v-icon>
                </template>
                <v-list-item-title>{{ $t("quotation-list-markSent-action", { $: "3. Mark As Sent" }) }}</v-list-item-title>
              </v-list-item>
            </template>
            <span>{{ $t("quotation-list-markSent-tooltip", { $: "Mark spreadsheet as 'sent' to a customer - this is just an indication/status for user's information" }) }}</span>
          </v-tooltip>
          <v-tooltip bottom open-delay="300">
            <template #activator="{ props }">
              <v-list-item :disabled="!isRowSelected" v-bind="props" @click="markDocumentAsConfirmed">
                <template #prepend>
                  <v-icon icon="mdi-check-circle"></v-icon>
                </template>
                <v-list-item-title>{{ $t("quotation-list-markConfirmed-action", { $: "4. Mark As Confirmed" }) }}</v-list-item-title>
              </v-list-item>
            </template>
            <span>{{
              $t("quotation-list-markConfirmed-tooltip", { $: "Mark spreadsheet as 'confirmed' with a customer - this is just an indication/status for user's information" })
            }}</span>
          </v-tooltip>
          <v-tooltip bottom open-delay="300">
            <template #activator="{ props }">
              <v-list-item :disabled="!isRowSelected" v-bind="props" @click="onArchiveModelInstance">
                <template #prepend>
                  <v-icon icon="mdi-archive-arrow-down-outline"></v-icon>
                </template>
                <v-list-item-title>{{ $t("quotation-list-archive-action", { $: "5. Archive" }) }}</v-list-item-title>
              </v-list-item>
            </template>
            <span>{{ $t("quotation-list-archive-tooltip", { $: "Mark spreadsheet as 'archived'" }) }}</span>
          </v-tooltip>
        </v-list>
      </v-menu>

      <v-tooltip v-if="!readonly" bottom open-delay="300">
        <template #activator="{ props }">
          <v-btn :disabled="!isRowSelected" size="small" density="compact" variant="text" v-bind="props" @click="handleExistingDocument">
            <v-icon class="pr-4" icon="mdi-download-circle" />
            {{ $t("quotation-list-download-action", { $: "Download Calculation Spreadsheet" }) }}
          </v-btn>
        </template>
        <span>{{ $t("quotation-list-download-tooltip", { $: "Download Selected Calculation Spreadsheet" }) }}</span>
      </v-tooltip>
      <v-tooltip v-if="!readonly" bottom open-delay="300">
        <template #activator="{ props }">
          <v-btn :disabled="!isRowSelected" size="small" density="compact" variant="text" v-bind="props" @click="fileInputRef?.click()">
            <v-icon class="pr-4" icon="mdi-download-circle" />
            {{ $t("quotation-list-upload-action", { $: "Upload New Revision" }) }}
          </v-btn>
        </template>
        <span>{{ $t("quotation-list-download-tooltip", { $: "Upload New Revision Of Selected Spreadsheet" }) }}</span>
      </v-tooltip>
    </template>
  </grid-wrapper>
  <input ref="fileInputRef" type="file" style="display: none" @change="handleFileSelection" />
</template>

<style lang="scss" scoped></style>
