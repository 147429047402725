import { MessageType, ProductTemplateDraftType, StepDraftResourceSpec, StepDraft, ProductTemplateDraft } from "@/components/ProcessDrafts/ProcessDraftModels";
import { ResourceInfoDto, StepResourceUsageType, StepResourceUsageTypeDetails, StepType } from "@masta/generated-model";
import { $t } from "@/i18n";

export function autoFillConsumables(productTemplateDrafts: ProductTemplateDraft[]) {
  const productionTasks = getProductionTemplates(productTemplateDrafts);
  for (let i = 0; i < productionTasks.length - 1; i++) {
    const productTemplateDraft = productionTasks[i];
    const nextProductTemplateDraft = productionTasks[i + 1];
    createConsumeForTaskIfNecessary(productTemplateDraft, nextProductTemplateDraft);
  }
}

function createConsumeForTaskIfNecessary(productTemplateDraft: ProductTemplateDraft, nextProductTemplateDraft: ProductTemplateDraft) {
  const productionSpecs = getProductionSpecs(productTemplateDraft);
  const productionSteps = nextProductTemplateDraft.steps.filter((x) => x.type === StepType.Production);
  if (productionSteps.length === 1) {
    const step = productionSteps[0];
    productionSpecs.forEach((x) => createConsumeForStepIfNecessary(step, x));
  } else if (productionSteps.length > 1) {
    nextProductTemplateDraft.message = {
      type: MessageType.Warning,
      text: $t("processDraft-autoFill-moreThanOneProductionStep-message", { $: "Task contains more than one production step. Create consume manually." })
    };
  }
}

function getProductionTemplates(productTemplateDrafts: ProductTemplateDraft[]) {
  const productionTemplates: ProductTemplateDraft[] = [];
  productTemplateDrafts.forEach((x) => appendProductionTemplates(x, productionTemplates));
  return productionTemplates;
}

function appendProductionTemplates(productTemplateDraft: ProductTemplateDraft, productTemplateDrafts: ProductTemplateDraft[]) {
  if (productTemplateDraft.taskType === ProductTemplateDraftType.ProductionTask) {
    productTemplateDrafts.push(productTemplateDraft);
  }
  if (productTemplateDraft.childProductTemplates) {
    productTemplateDraft.childProductTemplates.forEach((x) => appendProductionTemplates(x, productTemplateDrafts));
  }
}

function getProductionSpecs(productTemplateDraft: ProductTemplateDraft) {
  return productTemplateDraft.steps
    .filter((x) => x.type === StepType.Production)
    .flatMap((x) => x.specs)
    .filter((x) => x.usageType === StepResourceUsageType.Produce);
}

function createConsumeForStepIfNecessary(step: StepDraft, spec: StepDraftResourceSpec) {
  if (!isAlreadyConsumed(step, spec)) {
    const newSpec = createConsumeSpec(spec);
    step.specs.push(newSpec);
  }
}

function isAlreadyConsumed(step: StepDraft, spec: StepDraftResourceSpec) {
  return step.specs.some((x) => x.usageType === StepResourceUsageType.Consume && areResourcesTheSame(x.resources, spec.resources));
}

function areResourcesTheSame(res1: ResourceInfoDto[], res2: ResourceInfoDto[]) {
  if (res1.length !== res2.length) return false;

  const ids1 = res1.map((x) => x.id).sort();
  const ids2 = res2.map((x) => x.id).sort();
  return ids1.toString() === ids2.toString();
}

function createConsumeSpec(spec: StepDraftResourceSpec): StepDraftResourceSpec {
  return {
    resources: spec.resources,
    quantity: spec.quantity,
    thresholdQuantity: 0,
    usageTypeDetail: StepResourceUsageTypeDetails.Default,
    usageType: StepResourceUsageType.Consume,
    resourceType: spec.resourceType,
    assignmentType: spec.assignmentType,
    message: { type: MessageType.Success, text: $t("processDraft-autoFill-resourceSpecAdded-message", { $: "Consumed resource added." }) }
  };
}
