﻿<script lang="ts" setup>
import GridWrapper from "@/components/Grid/GridWrapper.vue";
import { GridWrapperComponent } from "@/components/Grid/GridWrapperComponent";
import SplitPanel from "@/components/Layout/SplitPanel.vue";
import TaskStepDetails from "@/components/Tasks/TaskStepDetails.vue";
import { getStatusBasedCellStyle, openResourceGanttForStepsCapacities, openResourceGanttForStepsSpecification } from "@/components/Tasks/TaskUtils";
import { translateStepType } from "@/composables/translateEnum";
import { $t } from "@/i18n";
import { $appContextSymbol } from "@/plugins/app.plugin";
import SystemEnumService from "@/services/system-enum.service";
import { useProductionTasksStore } from "@/store/ProductionTasksStore";
import { useScenariosStore } from "@/store/ScenariosStore";
import { useSnackbarsStore } from "@/store/SnackbarsStore";
import { GenerateAndCreateSerialNumbersForTopLevelTaskCommand, ProductionTaskDetailsDto, StepDto, TaskDetailsSerialNumberDto, TotalStatus } from "@masta/generated-model";
import { $dateTimeFormatterSymbol, $durationFormatterSymbol, DateFormatter } from "@masta/shared";
import { RowNode } from "ag-grid-community";
import { inject, ref, toRef, watch } from "vue";
import { useRouter } from "vue-router";
import { useTheme } from "vuetify";
import ActionsButton from "@/components/Layout/ActionsButton.vue";

const theme = useTheme();

interface Props {
  task: ProductionTaskDetailsDto;
}

const selectedTab = ref("steps");

const props = defineProps<Props>();
const task = toRef(props, "task");

const taskDetail = ref<ProductionTaskDetailsDto | null>(null);
const stepsData = ref<StepDto[]>([]);
const trackingData = ref<TaskDetailsSerialNumberDto[]>([]);
const emit = defineEmits(["manageModelInstances"]);

const router = useRouter();

const $appContext = inject<any>($appContextSymbol)!;

const gridWrapperRef = ref<GridWrapperComponent>();
const taskStepDetailsRef = ref<typeof TaskStepDetails>();
const defaultColDef = ref({
  sortable: true,
  resizable: true,
  headerValueGetter: (_: any) => $t("task-details-name-label", { $: "Name" })
});

const isTaskScheduled = props.task?.statistics?.totalStatus > TotalStatus.NotScheduled ?? false;
const $dateTimeFormatter = inject<DateFormatter>($dateTimeFormatterSymbol)!;
const $durationFormatter = inject<DateFormatter>($durationFormatterSymbol)!;

const taskStore = useProductionTasksStore();
const snackbarsStore = useSnackbarsStore();
const scenariosStore = useScenariosStore();

watch(task, async (taskNew) => {
  if (taskNew) {
    taskDetail.value = taskNew;
    stepsData.value = taskNew.steps ?? [];
    trackingData.value = taskNew.serialNumbers ?? [];
  } else {
    taskDetail.value = null;
    stepsData.value = [];
    trackingData.value = [];
  }

  taskStepDetailsRef.value?.loadStepDetails(null, null);
});


async function refreshTaskDetail(taskId: string) {
  const fetchedTaskDetail = await taskStore.fetchDetail(taskId);
  if (fetchedTaskDetail) {
    taskDetail.value = fetchedTaskDetail;
    stepsData.value = fetchedTaskDetail.steps ?? [];
    trackingData.value = fetchedTaskDetail.serialNumbers ?? [];
  } else {
    taskDetail.value = null;
    stepsData.value = [];
    trackingData.value = [];
  }
}

function onPrepareStepColumns(columnDefs: any) {
  columnDefs.value = [
    {
      field: "position",
      headerValueGetter: (_: any) => $t("task-details-position-label", { $: "Position" }),
      editable: false,
      sortable: true,
      cellRenderer: "agGroupCellRenderer"
    },
    {
      field: "name",
      headerValueGetter: (_: any) => $t("task-details-stepName-label", { $: "Step Name" }),
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter"
    },
    {
      field: "stepType",
      type: "enumTypeColumn",
      headerValueGetter: (_: any) => $t("task-details-stepType-label", { $: "Step Type" }),
      editable: false,
      sortable: true,
      valueFormatter: (params: any) => translateStepType(params.data.stepType)
    },
    {
      field: "processingTime",
      headerValueGetter: (_: any) => $t("task-details-processingTime-label", { $: "Processing Time" }),
      editable: false,
      sortable: true,
      valueFormatter: (params: any) => $durationFormatter(params.data.processingTime)
    },
    {
      field: "quantityPerTime",
      headerValueGetter: (_: any) => $t("task-details-quantityPerTime-label", { $: "Quantity Per Time" }),
      editable: false,
      sortable: true
    },
    {
      field: "statistics.totalStatus",
      headerValueGetter: (_: any) => $t("task-details-totalStatus-label", { $: "Total Status" }),
      editable: false,
      sortable: true,
      filter: true,
      cellStyle: (params: any) => getStatusBasedCellStyle($appContext, params.value),
      valueFormatter: (params: any) => SystemEnumService.totalStatus(params.data.statistics?.totalStatus)
    },
    {
      field: "statistics.schedulingStatus",
      headerValueGetter: (_: any) => $t("task-details-schedulingStatus-label", { $: "Scheduling Status" }),
      editable: false,
      sortable: true,
      filter: true,
      cellStyle: (params: any) => getStatusBasedCellStyle($appContext, params.value),
      valueFormatter: (params: any) => SystemEnumService.totalStatus(params.data.statistics?.schedulingStatus)
    },
    {
      field: "statistics.executionStatus",
      headerValueGetter: (_: any) => $t("task-details-executionStatus-label", { $: "Execution Status" }),
      editable: false,
      sortable: true,
      filter: true,
      cellStyle: (params: any) => getStatusBasedCellStyle($appContext, params.value),
      valueFormatter: (params: any) => SystemEnumService.totalStatus(params.data.statistics?.executionStatus)
    },
    {
      field: "statistics.schedulingStart",
      headerValueGetter: (_: any) => $t("task-details-schedulingStart-label", { $: "Scheduling Start" }),
      editable: false,
      sortable: true,
      filter: true,
      valueFormatter: (params: any) => $dateTimeFormatter(params.data.statistics?.schedulingStart)
    },
    {
      field: "statistics.schedulingEnd",
      headerValueGetter: (_: any) => $t("task-details-schedulingEnd-label", { $: "Scheduling End" }),
      editable: false,
      sortable: true,
      filter: true,
      valueFormatter: (params: any) => $dateTimeFormatter(params.data.statistics?.schedulingEnd)
    },
    {
      field: "statistics.schedulingDuration",
      headerValueGetter: (_: any) => $t("task-details-schedulingDuration-label", { $: "Scheduling Duration" }),
      editable: false,
      sortable: true,
      filter: true,
      valueFormatter: (params: any) => $durationFormatter(params.data.statistics?.schedulingDuration)
    },
    {
      field: "statistics.performanceBasedSchedulingDuration",
      headerValueGetter: (_: any) => $t("task-details-performanceBasedSchedulingDuration-label", { $: "Scheduling Duration Performanced Based" }),
      editable: false,
      sortable: true,
      filter: true,
      valueFormatter: (params: any) => $durationFormatter(params.data.statistics?.performanceBasedSchedulingDuration)
    },
    {
      field: "statistics.schedulingLeadTime",
      headerValueGetter: (_: any) => $t("task-details-schedulingLeadTime-label", { $: "Scheduling Lead Time" }),
      editable: false,
      sortable: true,
      filter: true,
      valueFormatter: (params: any) => $durationFormatter(params.data.statistics?.schedulingLeadTime)
    },
    {
      field: "statistics.schedulingWaitingTime",
      headerValueGetter: (_: any) => $t("task-details-schedulingWaitingTime-label", { $: "Scheduling Waiting Time" }),
      editable: false,
      sortable: true,
      filter: true,
      valueFormatter: (params: any) => $durationFormatter(params.data.statistics?.schedulingWaitingTime)
    },
    {
      field: "statistics.schedulingDelay",
      headerValueGetter: (_: any) => $t("task-details-schedulingDelay-label", { $: "Scheduling Delay" }),
      editable: false,
      sortable: true,
      filter: true,
      valueFormatter: (params: any) => $durationFormatter(params.data.statistics?.schedulingDelay)
    },
    {
      field: "statistics.executionStart",
      headerValueGetter: (_: any) => $t("task-details-executionStart-label", { $: "Execution Start" }),
      editable: false,
      sortable: true,
      filter: true,
      valueFormatter: (params: any) => $dateTimeFormatter(params.data.statistics?.executionStart)
    },
    {
      field: "statistics.executionEnd",
      headerValueGetter: (_: any) => $t("task-details-executionEnd-label", { $: "Execution End" }),
      editable: false,
      sortable: true,
      filter: true,
      valueFormatter: (params: any) => $dateTimeFormatter(params.data.statistics?.executionEnd)
    },
    {
      field: "statistics.executionDuration",
      headerValueGetter: (_: any) => $t("task-details-executionDuration-label", { $: "Execution Duration" }),
      editable: false,
      sortable: true,
      filter: true,
      valueFormatter: (params: any) => $durationFormatter(params.data.statistics?.executionDuration)
    },
    {
      field: "statistics.executionLeadTime",
      headerValueGetter: (_: any) => $t("task-details-executionLeadTime-label", { $: "Execution Lead Time" }),
      editable: false,
      sortable: true,
      filter: true,
      valueFormatter: (params: any) => $durationFormatter(params.data.statistics?.executionLeadTime)
    },
    {
      field: "statistics.executionWaitingTime",
      headerValueGetter: (_: any) => $t("task-details-executionWaitingTime-label", { $: "Execution Waiting Time" }),
      editable: false,
      sortable: true,
      filter: true,
      valueFormatter: (params: any) => $durationFormatter(params.data.statistics?.executionWaitingTime)
    }
  ];
}

function onPrepareTrackingColumns(columnDefs: any) {
  columnDefs.value = [
    {
      field: "trackingNumber",
      headerValueGetter: (_: any) => $t("task-details-trackingNumber-label", { $: "Tracking Number" }),
      editable: false,
      sortable: true
    },
    {
      field: "description",
      headerValueGetter: (_: any) => $t("task-details-trackingNumber-description-label", { $: "Description" }),
      editable: false,
      sortable: true
    },
    {
      field: "type",
      headerValueGetter: (_: any) => $t("task-details-trackingNumber-type-label", { $: "Tracking Identifier Type" }),
      editable: false,
      sortable: true,
      valueFormatter: (params: any) => SystemEnumService.trackingUniqueIdentifierTypes(params.data.type)
    }
  ];
}

function onStepRowSelected({ node, data }: { node: RowNode; data: any }) {
  if (node.isSelected()) {
    taskStepDetailsRef.value?.loadStepDetails(data, taskDetail.value);
  }
}

function getContextMenuItems(param: any) {
  return [
    {
      name: $t("task-details-manageModelInstances-action", { $: "Manage Model Instance Assignment" }),
      action: () => {
        emit("manageModelInstances", param.node.data, () => {
          refreshTaskDetail(task.value.id);
        });
      }
    }
  ];
}

async function openGanttResourceSpecification() {
  await openResourceGanttForStepsSpecification(router, steps.value);
}

async function openGanttResourceCapacities() {
  await openResourceGanttForStepsCapacities(router, steps.value);
}

function createSchedulingRequest(tasks) {
  const rootIds = [...new Set(tasks.map((x) => x.rootTaskId))];
  if (rootIds.length !== 1) return;

  return {
    rootTaskId: rootIds[0],
    scenarioId: scenariosStore.selectedScenario?.id,
    taskIds: tasks.map((x) => x.id)
  };
}

async function schedule() {
  const request = createSchedulingRequest([task.value]);
  try {
    await taskStore.scheduleTasks(request);
    await snackbarsStore.createSnackbar({
      message: $t("task-details-taskScheduled-message", { $: "Tasks queued for scheduling" }),
      closeable: true
    });
    await refreshTaskDetail(task.value.id);
  } catch (e) {
    console.error(e);
    await snackbarsStore.createSnackbar({
      message: e.message,
      type: "error",
      closeable: true
    });
  }
}

async function deschedule() {
  const request = createSchedulingRequest([task.value]);
  try {
    await taskStore.descheduleTasks(request);
    await snackbarsStore.createSnackbar({
      message: $t("task-details-taskDescheduled-message", { $: "Tasks queued for descheduling" }),
      closeable: true
    });
    await refreshTaskDetail(task.value.id);
  } catch (e) {
    console.error(e);
    await snackbarsStore.createSnackbar({
      message: e.message,
      type: "error",
      closeable: true
    });
  }
}

async function release() {
  const request = createSchedulingRequest([task.value]);
  try {
    await taskStore.releaseTasks(request);
    await snackbarsStore.createSnackbar({
      message: $t("task-details-taskReleased-message", { $: "Tasks queued for release" }),
      closeable: true
    });
    await refreshTaskDetail(task.value.id);
  } catch (e) {
    console.error(e);
    await snackbarsStore.createSnackbar({
      message: e.message,
      type: "error",
      closeable: true
    });
  }
}

async function unrelease() {
  const request = createSchedulingRequest([task.value]);
  try {
    await taskStore.unreleaseTasks(request);
    await snackbarsStore.createSnackbar({
      message: $t("task-details-taskUnreased-message", { $: "Tasks queued for unrelease" }),
      closeable: true
    });
    await refreshTaskDetail(task.value.id);
  } catch (e) {
    console.error(e);
    await snackbarsStore.createSnackbar({
      message: e.message,
      type: "error",
      closeable: true
    });
  }
}

async function generateTrackingIdentifiers() {
  const request: GenerateAndCreateSerialNumbersForTopLevelTaskCommand = {
    taskId: task.value.id,
    scenarioId: task.value.scenarioId
  };
  await taskStore.generateAndCreateSerialNumbersForTopLevelTask(request);

  await snackbarsStore.createSnackbar({
    message: $t("task-details-trackingIdentifiersGenerated-message", { $: "Tracking identifiers generated" }),
    closeable: true
  });
  await refreshTaskDetail(task.value.id);
}

const detailActions = ref([
  {
    title: $t("task-manage-release-action", { $: "Release" }),
    action: release,
    icon: "mdi-calendar-import"
  },
  {
    title: $t("task-manage-unrelease-action", { $: "Unrelease" }),
    action: unrelease,
    icon: "mdi-calendar-export"
  },
  {
    title: $t("task-manage-schedule-action", { $: "Schedule" }),
    action: schedule,
    icon: "mdi-calendar"
  },
  {
    title: $t("task-manage-deschedule-action", { $: "Deschedule" }),
    action: deschedule,
    icon: "mdi-calendar-remove"
  },
  {
    title: $t("task-manage-generate-tracking-identifiers-action", { $: "Generate Tracking Identifiers" }),
    action: generateTrackingIdentifiers,
    icon: "mdi-barcode-scan"
  }
]);
const ganttDetailActions = ref([
  {
    title: $t("task-manage-openGantt-resourceSpecification-action", { $: "Specified Resources" }),
    action: openGanttResourceSpecification,
    icon: "mdi-chart-gantt"
  },
  {
    title: $t("task-manage-openGantt-resourceCapacities-action", { $: "Scheduled Resources" }),
    action: openGanttResourceCapacities,
    icon: "mdi-chart-gantt"
  }
]);
</script>

<template>
  <v-col cols="12" class="fill-height">
    <v-tabs v-model="selectedTab" class="task-details text-disabled" selected-class="v-slide-group-item--active text-primary bg-white" density="compact">
      <v-tab class="mr-1" value="steps">
        <v-icon class="pr-2">mdi-debug-step-over</v-icon>
        {{ $t("task-details-taskSteps-label", { $: "Task steps" }) }}
      </v-tab>
      <v-tab class="mr-1" value="tracking">
        <v-icon class="pr-2">mdi-barcode-scan</v-icon>
        {{ $t("task-details-tracking-label", { $: "Tracking" }) }}
      </v-tab>
    </v-tabs>

    <v-window v-model="selectedTab" class="fill-height">
      <v-window-item value="steps" class="fill-height">
        <split-panel identifier="split-panel-task-details" class="pt-2" :sizes="[50, 50]" :min-size="[200, 0]">
          <template #panel-1>
            <grid-wrapper
              ref="gridWrapperRef"
              profile-name="task-details"
              :grid-data="stepsData"
              :default-col-def="defaultColDef"
              :context-menu-items="getContextMenuItems"
              :no-auto-height="true"
              hide-custom-actions-separator
              @prepare-columns="onPrepareStepColumns"
              @row-selected="onStepRowSelected"
            >
              <template #custom-buttons>
                <div class="d-inline-flex pr-4">
                  <actions-button :model-value="detailActions" />
                </div>
                <div class="d-inline-flex pr-4">
                  <actions-button :model-value="ganttDetailActions" :label="$t('actions-button-gantt-label', { $: 'Gantt' })" />
                </div>
              </template>
            </grid-wrapper>
          </template>
          <template #panel-2>
            <task-step-details ref="taskStepDetailsRef" :theme="theme.name.value" />
          </template>
        </split-panel>
      </v-window-item>
      <v-window-item value="tracking" class="fill-height pa-5">
        <v-divider />
        <grid-wrapper
          ref="gridWrapperRef"
          identifier="task-details"
          :grid-data="trackingData"
          :default-col-def="defaultColDef"
          :context-menu-items="getContextMenuItems"
          :no-auto-height="true"
          @prepare-columns="onPrepareTrackingColumns"
        />
      </v-window-item>
    </v-window>
  </v-col>
</template>

<style lang="scss">
.task-details {
  > .v-tab {
    border-radius: 0.5rem 0.5rem 0 0;
  }
}
</style>
