<script lang="ts" setup>
import GridWrapper from "@/components/Grid/GridWrapper.vue";
import { inject, reactive, ref } from "vue";
import { PersonDto, ResourceInfoDto, ResourceType } from "@masta/generated-model";
import { GridWrapperComponent } from "@/components/Grid/GridWrapperComponent";
import OpenResourceGanttGridAction from "@/components/Gantt/OpenResourceGanttGridAction.vue";
import { useFiltersStore } from "@/store/FiltersStore";
import { useModelInstancesStore } from "@/store/ModelInstancesStore";
import { $t } from "@/i18n";
import { PersonnelServerSideDataSource } from "@/components/Personnel/PersonnelServerSideDataSource";
import {
  CellClassParams,
  GetMainMenuItemsParams,
  GridOptions,
  GridReadyEvent,
  ICellEditorParams,
  KeyCreatorParams,
  ProcessCellForExportParams,
  ValueFormatterParams,
  ValueGetterParams,
  ValueSetterParams
} from "ag-grid-community";
import { translateResourceType } from "@/composables/translateEnum";
import { enumToEditorEntriesOnlyIncluding, enumValueEntryWithLocaleComparator, translateEditorEntries } from "@/components/Grid/ColumnTypes";
import { IEnumValueSelectCellEditorParams } from "@/components/Grid/CellEditors/IEnumValueSelectCellEditorParams";
import { requiredRule } from "@/components/ValueCellEditor/CommonValidationRules";
import { joinArrayOfStrings, nameOrBusinessIdOrIdOrNull } from "@/components/ValueCellEditor/CommonFormatters";
import { $dateTimeFormatterSymbol, DateFormatter } from "@masta/shared";
import { generateRandomColorHex } from "@/composables/colorHelpers";
import { useTagsStore } from "@/store/TagsStore";
import { booleanTypeColumnFilterParams } from "@/components/Grid/Filters/BooleanTypeColumnFilters";
import { tagsTypeColumnFilterParams } from "@/components/Grid/Filters/TagsTypeColumnFilters";
import { groupValueCellStyle } from "@/components/Grid/CellRenderers/GroupValueCellStyle";
import { isDefined } from "@/components/Common/Types";
import { refreshCheckboxCellEditor } from "@/components/Grid/CellEditors/CheckboxCellEditorHelper";
import { groupTypeCellStyle } from "@/components/Grid/CellRenderers/GroupTypeCellStyle";
import ActionsButton from "@/components/Layout/ActionsButton.vue";

const emit = defineEmits(["manageModelInstances", "manageAvailabilityRules", "resource2resource", "showDetails"]);
defineExpose({
  refreshSingle
});

const miStore = useModelInstancesStore();
const filtersStore = useFiltersStore();
const tagsStore = useTagsStore();

const serverSideDataSource = reactive(new PersonnelServerSideDataSource("personnel"));
const DEFAULT_CREATE_VALUE = {
  type: ResourceType.Person,
  tags: [],
  color: () => generateRandomColorHex(),
  plannable: true
};

const ganttTypes = reactive([ResourceType.Person, ResourceType.PersonGroup]);
const gridWrapperRef = ref<GridWrapperComponent>();
const $dateTimeFormatter = inject<DateFormatter>($dateTimeFormatterSymbol)!;

const defaultColumnDef = ref({
  sortable: true,
  resizable: true,
  floatingFilter: true,
  filterParams: {
    applyMiniFilterWhileTyping: true
  }
});
const autoGroupColumnDef = ref({
  field: "businessId",
  type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
  editable: true,
  sortable: true,
  minWidth: 330,
  filter: "agTextColumnFilter",
  headerValueGetter: (_: any) => $t("personnel-list-businessId-label", { $: "Business ID" }),
  cellEditorParams: {
    placeholder: $t("personnel-list-businessId-label", { $: "Business ID" }),
    rules: [(v: any) => (v !== undefined && v !== null && v !== "") || "Required"]
  },
  cellStyle: (params: CellClassParams) => groupValueCellStyle(params, isServerSideGroup),
  floatingFilterComponentParams: {
    placeholder: $t("personnel-list-businessId-label", { $: "Business ID" })
  }
});

const sideBar = ref({
  position: "left",
  toolPanels: [
    {
      id: "filters",
      labelDefault: $t("personnel-list-filtersDefaultLabel-label", { $: "Filters" }),
      labelKey: "filters",
      iconKey: "filter",
      toolPanel: "agFiltersToolPanel",
      width: 500
    }
    // TODO fix CQL filter panel
    // {
    //   id: "cqlFilterExpressionToolPanel",
    //   labelDefault: "CQL Filter",
    //   labelKey: "CQL Filter",
    //   iconKey: "filter",
    //   toolPanel: "CqlFilterExpressionToolPanel",
    //   width: 800,
    // },
  ]
});

function onTypeChange(newValue: any, oldValue: any, params?: ICellEditorParams) {
  if (params) {
    const isEditMode = !!params.data.id;

    if (!isEditMode) {
      const isPlannable = newValue === ResourceType.Person;
      refreshCheckboxCellEditor({ value: isPlannable, api: params.api, node: params.node, column: "plannable" });
    }
  }
}

function mapToResourceInfoDto(id: string | null, businessId: string | null, name: string | null): ResourceInfoDto | null {
  if (id || businessId || name) {
    const resourceInfo: ResourceInfoDto = {
      id: id as string,
      scenarioId: "",
      name: name,
      businessId: businessId,
      type: ResourceType.PersonGroup,
      plannable: false
    };

    return resourceInfo;
  } else {
    return null;
  }
}

async function loadModelInstances() {
  await miStore.fetchSchemas();
  await filtersStore.fetchResourceFilters(true);
}

async function onReady(event: GridReadyEvent) {
  await loadModelInstances();
}

const gridOptions: GridOptions = {
  processCellForClipboard: (params: ProcessCellForExportParams) => {
    const { value, column } = params;

    if (column.getColDef().type === "resourcePickerTypeColumn") {
      return params.formatValue(value);
    }

    return value;
  }
};

function onPrepareColumns(columnDefs: any) {
  columnDefs.value = [
    {
      field: "id",
      headerValueGetter: (_: any) => $t("personnel-list-groupId-label", { $: "Group ID" }),
      editable: false,
      sortable: false,
      filter: false,
      hide: true,
      valueGetter: (params: ValueGetterParams) => params.data.name ?? params.data.businessId ?? params.data.id
    },
    {
      field: "parentId",
      type: "resourcePickerTypeColumn",
      headerValueGetter: (_: any) => $t("personnel-list-parent-label", { $: "Parent" }),
      editable: true,
      hide: true,
      cellEditorParams: {
        resourceTypes: [ResourceType.PersonGroup],
        placeholder: $t("personnel-list-parent-label", { $: "Parent" })
      },
      valueFormatter: (params: ValueFormatterParams) => {
        return nameOrBusinessIdOrIdOrNull(params.value);
      },
      valueGetter: (params: ValueGetterParams<PersonDto>) => {
        const { data } = params;
        if (!data || !isDefined(data.parentId)) return null;

        const parentNode = params.api.getRowNode(data.parentId);

        return mapToResourceInfoDto(data.parentId, parentNode && parentNode.data ? parentNode.data.businessId : null, parentNode && parentNode.data ? parentNode.data.name : null);
      },
      valueSetter: (params: ValueSetterParams<PersonDto, PersonDto>) => {
        params.data.parentId = params.newValue?.id ?? null;
        return true;
      }
    },
    {
      field: "type",
      type: ["enumTypeColumn", "setFloatingFilterColumnType"],
      cellStyle: (params: CellClassParams) => groupTypeCellStyle(params, isServerSideGroup),
      headerName: "Type",
      editable: true,
      filter: "agSetColumnFilter",
      filterParams: {
        valueFormatter: (params: ValueFormatterParams) => params.value.key,
        keyCreator: (params: KeyCreatorParams) => params.value.value,
        values: translateEditorEntries(enumToEditorEntriesOnlyIncluding(ResourceType, [ResourceType.Person, ResourceType.PersonGroup]), translateResourceType),
        comparator: enumValueEntryWithLocaleComparator
      },
      sortable: true,
      headerValueGetter: (_: any) => $t("personnel-list-type-label", { $: "Type" }),
      valueFormatter: (params: any) => translateResourceType(params.data.type),
      cellEditorParams: {
        values: translateEditorEntries(enumToEditorEntriesOnlyIncluding(ResourceType, [ResourceType.Person, ResourceType.PersonGroup]), translateResourceType),
        rules: [requiredRule],
        onValueChange: onTypeChange,
        placeholder: $t("personnel-edit-type-label", { $: "Type" }),
        isEditEnabled: () => !gridWrapperRef.value?.isUpdating(),
        clearOnEditDisabled: false
      } as unknown as IEnumValueSelectCellEditorParams,
      floatingFilterComponentParams: {
        placeholder: $t("personnel-edit-type-label", { $: "Type" }),
        values: translateEditorEntries(enumToEditorEntriesOnlyIncluding(ResourceType, [ResourceType.Person, ResourceType.PersonGroup]), translateResourceType)
      }
    },
    {
      field: "businessId",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("personnel-list-businessId-label", { $: "Business ID" }),
      editable: true,
      filter: "agTextColumnFilter",
      sortable: true,
      hide: true,
      cellEditorParams: {
        placeholder: $t("personnel-list-businessId-label", { $: "Business ID" }),
        rules: [(v: any) => (v !== undefined && v !== null && v !== "") || "Required"]
      },
      floatingFilterComponentParams: {
        placeholder: $t("personnel-list-businessId-label", { $: "Business ID" })
      }
    },
    {
      field: "name",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("personnel-list-name-label", { $: "Name" }),
      editable: true,
      resizable: true,
      sortable: true,
      filter: "agTextColumnFilter",
      cellEditorParams: {
        placeholder: $t("personnel-edit-name-label", { $: "Name" })
      },
      floatingFilterComponentParams: {
        placeholder: $t("personnel-edit-name-label", { $: "Name" })
      }
    },
    {
      field: "description",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("personnel-list-description-label", { $: "Description" }),
      editable: true,
      filter: "agTextColumnFilter",
      cellEditorParams: {
        placeholder: $t("personnel-edit-description-label", { $: "Description" })
      },
      floatingFilterComponentParams: {
        placeholder: $t("personnel-list-description-label", { $: "Description" })
      }
    },
    {
      field: "color",
      type: ["colorTypeColumn", "textFloatingFilterColumnType"],
      editable: true,
      sortable: true,
      filter: "agTextColumnFilter",
      headerValueGetter: (_: any) => $t("personnel-list-color-label", { $: "Color" }),
      cellEditorParams: {
        placeholder: $t("personnel-list-color-label", { $: "Color" })
      },
      floatingFilterComponentParams: {
        placeholder: $t("personnel-list-color-label", { $: "Color" })
      }
    },
    {
      field: "tags",
      headerValueGetter: (_: any) => $t("personnel-list-tags-label", { $: "Tags" }),
      type: ["tagsPickerTypeColumn", "setFloatingFilterColumnType"],
      editable: true,
      resizable: true,
      filter: "agSetColumnFilter",
      filterParams: tagsTypeColumnFilterParams(tagsStore),
      valueFormatter: (params: ValueFormatterParams) => joinArrayOfStrings(params.data.tags),
      cellEditorParams: {
        placeholder: $t("personnel-edit-tags-label", { $: "Tags" })
      }
    },
    {
      field: "plannable",
      type: ["setFloatingFilterColumnType"],
      filter: "agSetColumnFilter",
      filterParams: booleanTypeColumnFilterParams,
      headerValueGetter: (_: any) => $t("personnel-list-plannable-label", { $: "Plannable" }),
      editable: true,
      resizable: true,
      sortable: true,
      cellDataType: "boolean",
      cellRendererParams: {
        disabled: () => !gridWrapperRef.value?.isEditing()
      }
    },
    {
      field: "createdBy",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      filter: "agTextColumnFilter",
      editable: false,
      resizable: true,
      headerValueGetter: (_: any) => $t("personnel-list-createdBy-label", { $: "Created By" }),
      floatingFilterComponentParams: {
        placeholder: $t("personnel-list-createdBy-label", { $: "Created By" })
      }
    },
    {
      field: "createdAt",
      type: ["dateTimeTypeColumn"],
      filter: "agDateColumnFilter",
      editable: false,
      resizable: true,
      valueFormatter: (params: any) => {
        return $dateTimeFormatter(params.data.createdAt);
      },
      headerValueGetter: (_: any) => $t("personnel-list-createdAt-label", { $: "Created At" })
    },
    {
      field: "modifiedBy",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      filter: "agTextColumnFilter",
      editable: false,
      resizable: true,
      headerValueGetter: (_: any) => $t("personnel-list-modifiedBy-label", { $: "Modified By" }),
      floatingFilterComponentParams: {
        placeholder: $t("personnel-list-modifiedBy-label", { $: "Modified By" })
      }
    },
    {
      field: "modifiedAt",
      type: ["dateTimeTypeColumn"],
      filter: "agDateColumnFilter",
      editable: false,
      resizable: true,
      valueFormatter: (params: any) => {
        return $dateTimeFormatter(params.data.modifiedAt);
      },
      headerValueGetter: (_: any) => $t("personnel-list-modifiedAt-label", { $: "Modified At" })
    }
  ];
}

function isServerSideGroup(dataItem: PersonDto) {
  // new row in editing mode is not a group (otherwise the ag grid might tries to load data for it and :boom:)
  if (dataItem?.id == null) return false;

  return dataItem?.type === ResourceType.PersonGroup;
}

function getServerSideChildCount(dataItem: PersonDto) {
  return dataItem?.childCount;
}

function getServerSideGroupKey(dataItem: PersonDto) {
  return dataItem?.id;
}

function onExpandAll() {
  if (gridWrapperRef.value) {
    gridWrapperRef.value.gridApi.expandAll();
  }
}

function onCollapseAll() {
  if (gridWrapperRef.value) {
    gridWrapperRef.value.gridApi.collapseAll();
  }
}

function getContextMenuItems(param: any) {
  return [
    {
      name: $t("personnel-list-manageModelInstances-action", { $: "Manage Model Instance Assignment" }),
      action: () => {
        emit("manageModelInstances", param.node.data, () => {
          gridWrapperRef.value?.gridApi.refreshServerSide();
        });
      },
      disabled: !param.node
    },
    {
      name: $t("personnel-resource2resource-agreement-action", { $: "Agreement assignment" }),
      action: () => {
        emit("resource2resource", ResourceType.Agreement, param.node.data);
      },
      disabled: !param.node?.data || param.node?.data.type !== ResourceType.Person
    },
    {
      name: $t("personnel-resource2resource-asset-action", { $: "Asset assignment" }),
      action: () => {
        emit("resource2resource", ResourceType.Asset, param.node.data);
      },
      disabled: !param.node?.data || param.node?.data.type !== ResourceType.Person
    },
    "separator",
    {
      name: $t("personnel-list-expandAllGroups-action", { $: "Expand all groups" }),
      icon: "<span class=\"mdi mdi-expand-all\"></span>",
      action: onExpandAll
    },
    {
      name: $t("personnel-list-collapseAllGroups-action", { $: "Collapse all groups" }),
      icon: "<span class=\"mdi mdi-collapse-all\"></span>",
      action: onCollapseAll
    }
  ];
}

function getMainMenuItems(params: GetMainMenuItemsParams) {
  params.defaultItems.push("expandAll");
  params.defaultItems.push("contractAll");
  return params.defaultItems;
}

function showDetails(data: PersonDto) {
  emit("showDetails", data);
}

function refreshSingle(id: string) {
  serverSideDataSource.getSingle(id).then((data) => {
    if (data) {
      gridWrapperRef.value?.gridApi.forEachNode((node) => {
        if (node.data.id === id) {
          node.updateData(data);
          emit("showDetails", data);
        }
      });
    } else {
      console.warn(`Could not find row with id ${id} to refresh!`);
    }
  });
}
</script>

<template>
  <grid-wrapper
    ref="gridWrapperRef"
    :auto-group-column-def="autoGroupColumnDef"
    :create-default-value="DEFAULT_CREATE_VALUE"
    :grid-options="gridOptions"
    :default-col-def="defaultColumnDef"
    refresh-btn
    details-btn
    row-selection="multiple"
    identifier="personnel"
    :enable-group-edit="true"
    :get-server-side-group-key="getServerSideGroupKey"
    :is-server-side-group="isServerSideGroup"
    :get-child-count="getServerSideChildCount"
    :side-bar="sideBar"
    :server-side-datasource="serverSideDataSource"
    :tree-data="true"
    :context-menu-items="getContextMenuItems"
    :get-main-menu-items="getMainMenuItems"
    create-btn
    create-child-btn
    duplicate-btn
    delete-btn
    edit-btn
    server-side
    @refresh-action="loadModelInstances"
    @prepare-columns="onPrepareColumns"
    @ready="onReady"
    @details="showDetails"
  >
    <template #custom-buttons>
      <actions-button :model-value="[]" :label="$t('actions-button-gantt-label', { $: 'Gantt' })">
        <template #prepend>
          <open-resource-gantt-grid-action :types="ganttTypes" :grid-api="gridWrapperRef?.gridApi" />
        </template>
      </actions-button>
    </template>
  </grid-wrapper>
</template>

<style lang="scss" scoped></style>
