<script lang="ts" setup>
import { durationRequiredRule } from "@/components/ValueCellEditor/CommonValidationRules";
import { $t } from '@/i18n';
import { $dateTimeFormatterSymbol, $dateSymbol, asDuration, asJiraFormattedString, DateFormatter } from "@masta/shared";
import dayjs from "dayjs";
import { ByWeekday, Frequency, RRule } from 'rrule';
import { computed, inject, onMounted, reactive, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useTheme } from "vuetify";
import DatepickerField from "@/components/Datepicker/DatepickerField.vue";

const enum StopCondition {
    NEVER = 0,
    DATE = 1,
    OCCURRENCES = 2,
};

const emit = defineEmits<{
    "update:modelValue:rrule": [rrule: string]
    "update:modelValue:start": [start: Date]
    "update:modelValue:end": [end: Date]
}>();

const rruleTextModelValue = defineModel<string | null | undefined>('rrule', { required: true });
const startModelValue = defineModel<string | null | undefined>('start', { required: true });
const endModelValue = defineModel<string | null | undefined>('end', { required: true });

const $date = inject<typeof dayjs>($dateSymbol)!;
const $dateTimeFormatter = inject<DateFormatter>($dateTimeFormatterSymbol)!;

const rruleValue = ref<RRule | null>(null);

const frequencyValue = ref<Frequency>(RRule.DAILY);
const intervalValue = ref<number | undefined>();
const byweekdayValue = ref<ByWeekday | ByWeekday[] | null>([]);
const bymonthdayValue = ref<number[] | null>([]);
const byyeardayValue = ref<number[] | null>([]);

const stopCondition = ref<StopCondition>(StopCondition.NEVER);
const countValue = ref<number | null>(null);
const fromValue = ref<Date | null>(null);
const durationValue = ref<string>("0m");
const untilValue = ref<Date | null>(null);

const { locale } = useI18n();
const theme = reactive(useTheme());

// prettier-ignore
const durationValueRules = [(v: any) => !!v || $t("recurreneRuleEditor-validation-durationRequired-message", { $: "Duration time is required" }), durationRequiredRule];

function formatDate(date: Date) {
  return $dateTimeFormatter(date);
}

onMounted(() => {
    initializeRRule();
    initializeStartCondition();
});

const rruleRFC5545Preview = computed(() => {
    if (rruleValue.value) {
        return buildRRule();
    }

    return '';
});

watch(frequencyValue, (newValue, _) => {
    if (newValue !== RRule.WEEKLY) {
        byweekdayValue.value = [];
    }

    if (newValue !== RRule.MONTHLY) {
        bymonthdayValue.value = [];
    }

    if (newValue !== RRule.YEARLY) {
        byyeardayValue.value = [];
    }
});

function initializeStartCondition() {
    if (startModelValue.value && endModelValue.value) {
        const $start = $date(startModelValue.value);
        const $end = $date(endModelValue.value);

        if ($start.isValid() && $end.isValid()) {
            fromValue.value = $start.toDate();

            const diffInMs = $end.diff($start);
            if (diffInMs > 0) {
                const $duration = $date.duration(diffInMs);
                const durationString = asJiraFormattedString($duration);
                if (durationString) {
                    durationValue.value = durationString;
                } else {
                    durationValue.value = "0m";
                }
            } else {
                durationValue.value = "0m";
            }
        } else {
            initializeDefaultStartCondition();
        }
    } else {
        initializeDefaultStartCondition();
    }
}

function initializeRRule() {
    try {
        if (rruleTextModelValue.value && rruleTextModelValue.value != null) {
            rruleValue.value = RRule.fromString(rruleTextModelValue.value);

            const { freq, interval, byweekday, bymonthday, byyearday, count, until } = rruleValue.value.options;

            frequencyValue.value = freq;

            //rrule.js - default interval is 1; rrule.js always returns 1, even when not set (ignore it)
            if (interval && interval !== 1) {
                intervalValue.value = interval;
            }

            if (byweekday) {
                byweekdayValue.value = byweekday; // rruleValue.value.options.byweekday;
            }

            if (bymonthday) {
                bymonthdayValue.value = bymonthday;
            }

            if (byyearday) {
                byyeardayValue.value = byyearday;
            }

            if (count === null && until === null) {
                stopCondition.value = StopCondition.NEVER;
                countValue.value = null;
                untilValue.value = null;
            } else {
                stopCondition.value = count !== null ? StopCondition.OCCURRENCES : StopCondition.DATE;
                countValue.value = count;
                untilValue.value = until;
            }

        } else {
            initializeDefaultRRule();
        }
    } catch (error) {
        console.error('Invalid RRule string:', error);

        initializeDefaultRRule();
    }
}

function initializeDefaultStartCondition() {
    fromValue.value = new Date();
    durationValue.value = "5d 30m";
}

function initializeDefaultRRule() {
    rruleValue.value = null;

    frequencyValue.value = RRule.DAILY;
    intervalValue.value = undefined;
    byweekdayValue.value = [];
    bymonthdayValue.value = [];
    byyeardayValue.value = [];

    stopCondition.value = StopCondition.NEVER;
    countValue.value = null;
    untilValue.value = null;
}

function buildRRule():string {
    const rruleObj = new RRule({
        freq: frequencyValue.value,
        ...intervalValue.value && { interval: intervalValue.value },
        ...byweekdayValue.value && { byweekday: byweekdayValue.value },
        ...bymonthdayValue.value && { bymonthday: bymonthdayValue.value },
        ...byyeardayValue.value && { byyearday: byyeardayValue.value },
        ...stopCondition.value === StopCondition.OCCURRENCES && { count: countValue.value },
        ...stopCondition.value === StopCondition.DATE && { until: untilValue.value },
    });

    return rruleObj.toString();
}

function confirmChanges() {
    emit('update:modelValue:rrule', rruleRFC5545Preview.value);

    const start = fromValue.value != null ? fromValue.value : new Date();
    emit('update:modelValue:start', start);

    const $duration = asDuration(durationValue.value);
    if ($duration) {
        const end = new Date();
        end.setTime(start.getTime() + $duration.asMilliseconds());

        emit('update:modelValue:end', end);

    } else {
        emit('update:modelValue:end', new Date());
    }
}

defineExpose({
    confirmChanges
});

</script>

<template>
    <div class="recurrence-rule-editor d-flex flex-column h-100">
        <v-card :title="$t('recurreneRuleEditor-startCondition-label', { $: 'Start condition' })" elevation="1" class="recurrence-rule-editor-card mb-4">
            <v-card-text>
                <v-row class="recurrence-rule-editor-row">
                    <v-col cols="3" class="d-flex text-h6 align-center">
                        <span>{{ $t("recurreneRuleEditor-from-label", { $: "From" }) }}</span>
                        <v-tooltip location="bottom" open-delay="300">
                            <template #activator="{ props }">
                                <v-btn size="small" density="compact" elevation="0" icon="mdi-information-outline" v-bind="props" />
                            </template>
                            <span>{{ $t("recurreneRuleEditor-from-tooltip", { $: "From" }) }}</span>
                        </v-tooltip>
                    </v-col>
                    <v-col cols="9">
                        <datepicker-field 
                            v-model="fromValue" 
                            :locale="locale" 
                            :format="formatDate"
                            :clearable="true"
                            :dark="theme.name === 'dark'" 
                            class="rrule-datepicker">
                        </datepicker-field>
                    </v-col>
                </v-row>
                <v-row class="recurrence-rule-editor-row">
                    <v-col cols="3" class="d-flex text-h6 align-center">
                        <span>{{ $t("recurreneRuleEditor-duration-label", { $: "Duration" }) }}</span>
                        <v-tooltip location="bottom" open-delay="300">
                            <template #activator="{ props }">
                                <v-btn size="small" density="compact" elevation="0" icon="mdi-information-outline" v-bind="props" />
                            </template>
                            <span>{{ $t("recurreneRuleEditor-duration-tooltip", { $: "Duration" }) }}</span>
                        </v-tooltip>
                    </v-col>
                    <v-col cols="9">
                        <v-text-field v-model="durationValue" variant="outlined" single-line class="rrule-text-field"
                            type="text" hide-details :rules="durationValueRules"></v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-card elevation="1" class="recurrence-rule-editor-card mb-4">
            <v-card-text>
                <v-row class="recurrence-rule-editor-row">
                    <v-col cols="3" class="d-flex text-h6 align-center">
                        <span>{{ $t("recurreneRuleEditor-frequency-label", { $: "Frequency" }) }}</span>
                        <v-tooltip location="bottom" open-delay="300">
                            <template #activator="{ props }">
                                <v-btn 
                                    size="small" density="compact" elevation="0" icon="mdi-information-outline" v-bind="props" />
                            </template>
                            <span>{{ $t("recurreneRuleEditor-frequency-tooltip", { $: "Specifies recurrence frequency (e.g. YEARLY, MONTHLY, WEEKLY, DAILY" }) }}</span>
                        </v-tooltip>
                    </v-col>
                    <v-col cols="9">
                        <v-radio-group v-model="frequencyValue" inline hide-details>
                            <v-radio 
                                :value="RRule.DAILY" color="primary" 
                                :label="$t('recurreneRuleEditor-frequency-daily-label', { $: 'Daily' })">
                            </v-radio>
                            <v-radio 
                                :value="RRule.WEEKLY" color="primary"
                                :label="$t('recurreneRuleEditor-frequency-weekly-label', { $: 'Weekly' })">
                            </v-radio>
                            <v-radio 
                                :value="RRule.MONTHLY" color="primary"
                                :label="$t('recurreneRuleEditor-frequency-monthly-label', { $: 'Monthly' })">
                            </v-radio>
                            <v-radio 
                                :value="RRule.YEARLY" color="primary"
                                :label="$t('recurreneRuleEditor-frequency-yearly-label', { $: 'Yearly' })">
                            </v-radio>
                        </v-radio-group>
                    </v-col>
                </v-row>
                <v-row class="recurrence-rule-editor-row">
                    <v-col offset="3" cols="9" class="d-flex align-center">
                        <span class="pl-7">{{ $t('recurreneRuleEditor-inverval-every-label', { $: 'repeat every' }) }}</span>
                        <v-text-field 
                            v-model="intervalValue" variant="outlined" single-line 
                            class="rrule-text-field rrule-text-field-inline px-2" type="number" hide-details>
                        </v-text-field>
                        <span v-if="frequencyValue === RRule.DAILY">{{ $t('recurreneRuleEditor-inverval-days-label', { $: 'days(s)' }) }}</span>
                        <span v-if="frequencyValue === RRule.WEEKLY">{{ $t('recurreneRuleEditor-inverval-weeks-label', { $: 'week(s)' }) }}</span>
                        <span v-if="frequencyValue === RRule.MONTHLY">{{ $t('recurreneRuleEditor-inverval-months-label', { $: 'month(s)' }) }}</span>
                        <span v-if="frequencyValue === RRule.YEARLY">{{ $t('recurreneRuleEditor-inverval-years-label', { $: 'year(s)' }) }}</span>
                    </v-col>
                </v-row>
                <v-row v-if="frequencyValue === RRule.WEEKLY" class="recurrence-rule-editor-row">
                    <v-col cols="3" class="d-flex text-h6 align-center">
                        <span>{{ $t("recurreneRuleEditor-byWeekday-label", { $: "By weekday" }) }}</span>                
                        <v-tooltip location="bottom" open-delay="300">
                            <template #activator="{ props }">
                                <v-btn 
                                    size="small" density="compact" elevation="0" icon="mdi-information-outline" v-bind="props" />
                            </template>
                            <span>{{ $t("recurreneRuleEditor-byweekday-tooltip", { $: "Specify the recurrence weekdays" }) }}</span>
                        </v-tooltip>
                    </v-col>
                    <v-col cols="9">
                        <v-row>            
                            <v-col cols="auto">
                                <v-checkbox 
                                    v-model="byweekdayValue" :value="RRule.MO.weekday" color="primary" hide-details 
                                    :label="$t('recurreneRuleEditor-byweekday-mon-label', { $: 'Mon' })">
                                </v-checkbox>
                            </v-col>
                            <v-col cols="auto">
                                <v-checkbox 
                                    v-model="byweekdayValue" :value="RRule.TU.weekday" color="primary" hide-details
                                    :label="$t('recurreneRuleEditor-byweekday-tue-label', { $: 'Tue' })">
                                </v-checkbox>
                            </v-col>
                            <v-col cols="auto">
                                <v-checkbox 
                                    v-model="byweekdayValue" :value="RRule.WE.weekday" color="primary" hide-details
                                    :label="$t('recurreneRuleEditor-byweekday-wed-label', { $: 'Wed' })">
                                </v-checkbox>
                            </v-col>
                            <v-col cols="auto">
                                <v-checkbox 
                                    v-model="byweekdayValue" :value="RRule.TH.weekday" color="primary" hide-details
                                    :label="$t('recurreneRuleEditor-byweekday-thu-label', { $: 'Thu' })">
                                </v-checkbox>
                            </v-col>
                            <v-col cols="auto">
                                <v-checkbox 
                                    v-model="byweekdayValue" :value="RRule.FR.weekday" color="primary" hide-details
                                    :label="$t('recurreneRuleEditor-byweekday-fri-label', { $: 'Fri' })">
                                </v-checkbox>
                            </v-col>
                            <v-col cols="auto">
                                <v-checkbox 
                                    v-model="byweekdayValue" :value="RRule.SA.weekday" color="primary" hide-details
                                    :label="$t('recurreneRuleEditor-byweekday-sat-label', { $: 'Sat' })">
                                </v-checkbox>
                            </v-col>
                            <v-col cols="auto">
                                <v-checkbox 
                                    v-model="byweekdayValue" :value="RRule.SU.weekday" color="primary" hide-details
                                    :label="$t('recurreneRuleEditor-byweekday-sun-label', { $: 'Sun' })">
                                </v-checkbox>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row v-if="frequencyValue === RRule.MONTHLY" class="recurrence-rule-editor-row">
                    <v-col cols="3" class="d-flex text-h6 align-center">
                        <span>{{ $t("recurreneRuleEditor-byMonthday-label", { $: "By monthday" }) }}</span>                
                        <v-tooltip location="bottom" open-delay="300">
                            <template #activator="{ props }">
                                <v-btn 
                                    size="small" density="compact" elevation="0" icon="mdi-information-outline" v-bind="props" />
                            </template>
                            <span>{{ $t("recurreneRuleEditor-byMonthday-tooltip", { $: "Specifies a list of days of the month.  Valid values are 1 to 31" }) }}</span>
                        </v-tooltip>
                    </v-col>
                    <v-col cols="9">
                        <v-autocomplete
                            v-model="bymonthdayValue"
                            auto-select-first
                            multiple
                            chips
                            closable-chips
                            variant="outlined"
                            density="compact"
                            hide-details
                            clearable
                            :items="Array.from({ length: 31 }, (_, i) => i + 1)"
                            class="rrule-select">
                        </v-autocomplete>
                    </v-col>
                </v-row>
                <v-row v-if="frequencyValue === RRule.YEARLY" class="recurrence-rule-editor-row">
                    <v-col cols="3" class="d-flex text-h6 align-center">
                        <span>{{ $t("recurreneRuleEditor-byYearday-label", { $: "By yearday" }) }}</span>                
                        <v-tooltip location="bottom" open-delay="300">
                            <template #activator="{ props }">
                                <v-btn 
                                    size="small" density="compact" elevation="0" icon="mdi-information-outline" v-bind="props" />
                            </template>
                            <span>{{ $t("recurreneRuleEditor-byYearday-tooltip", { $: "Specifies a list of days of the year. Valid values are 1 to 366" }) }}</span>
                        </v-tooltip>
                    </v-col>
                    <v-col cols="9">
                        <v-autocomplete
                            v-model="byyeardayValue"
                            auto-select-first
                            multiple
                            chips
                            closable-chips
                            variant="outlined"
                            density="compact"
                            hide-details
                            clearable
                            :items="Array.from({ length: 366 }, (_, i) => i + 1)"
                            class="rrule-select">
                        </v-autocomplete>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-card :title="$t('recurreneRuleEditor-stopCondition-label', { $: 'Stop condition' })" elevation="1" class="recurrence-rule-editor-card mb-4">
            <v-card-text>
                <v-row class="recurrence-rule-editor-row">
                    <v-col cols="3" class="d-flex text-h6 align-center">
                        <v-radio-group v-model="stopCondition" hide-details>
                            <v-radio :value="StopCondition.NEVER" color="primary" hide-details>
                                <template #label>
                                    <span class="text-h6">
                                        <span>{{ $t("recurreneRuleEditor-neverStop-label", { $: "Never stop" }) }}</span>
                                    </span>
                                    <v-tooltip location="bottom" open-delay="300">
                                        <template #activator="{ props }">
                                            <v-btn 
                                                size="small" density="compact" elevation="0" 
                                                icon="mdi-information-outline" v-bind="props" />
                                        </template>
                                        <span>{{ $t("recurreneRuleEditor-neverStop-tooltip", { $: "The event will repeat forever" }) }}</span>
                                    </v-tooltip>                        
                                </template>
                            </v-radio>
                        </v-radio-group>
                    </v-col>
                    <v-col cols="9">
                    </v-col>
                </v-row>
                <v-row class="recurrence-rule-editor-row">
                    <v-col cols="3" class="d-flex text-h6 align-center">
                        <v-radio-group v-model="stopCondition" hide-details>
                            <v-radio :value="StopCondition.OCCURRENCES" color="primary" hide-details>
                                <template #label>
                                    <span class="text-h6">
                                        <span>{{ $t("recurreneRuleEditor-occurrences-label", { $: "Occurrences" }) }}</span>
                                    </span>                            
                                    <v-tooltip location="bottom" open-delay="300">
                                        <template #activator="{ props }">
                                            <v-btn size="small" density="compact" elevation="0" icon="mdi-information-outline" v-bind="props" />
                                        </template>
                                        <span>{{ $t("recurreneRuleEditor-occurrences-tooltip", { $: "The event will repeat until it reaches a certain amount of occurrences" }) }}</span>
                                    </v-tooltip>
                                </template>
                            </v-radio>
                        </v-radio-group>
                    </v-col>
                    <v-col cols="9">
                        <v-text-field
                            v-model="countValue" 
                            variant="outlined"
                            density="compact"
                            hide-details
                            type="number"
                            class="rrule-text-field"
                            :disabled="stopCondition !== StopCondition.OCCURRENCES">
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row class="recurrence-rule-editor-row">
                    <v-col cols="3" class="d-flex text-h6 align-center">
                        <v-radio-group v-model="stopCondition" hide-details>
                            <v-radio :value="StopCondition.DATE" color="primary" hide-details>
                                <template #label>
                                    <span class="text-h6">
                                        <span>{{ $t("recurreneRuleEditor-until-label", { $: "Until" }) }}</span>
                                    </span>
                                    <v-tooltip location="bottom" open-delay="300">
                                        <template #activator="{ props }">
                                            <v-btn size="small" density="compact" elevation="0" icon="mdi-information-outline" v-bind="props" />
                                        </template>
                                        <span>{{ $t("recurreneRuleEditor-until-tooltip", { $: "The event will run until it reaches a specific date" }) }}</span>
                                    </v-tooltip>
                                </template>
                            </v-radio>
                        </v-radio-group>
                    </v-col>
                    <v-col cols="9">
                        <datepicker-field
                            v-model="untilValue"
                            :disabled="stopCondition !== StopCondition.DATE"
                            :locale="locale"
                            :format="formatDate"
                            :clearable="true"
                            :dark="theme.name === 'dark'"
                            class="rrule-datepicker">
                        </datepicker-field>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-card 
            :title="$t('recurreneRuleEditor-preview-label', { $: 'Rule RFC5545 preview' })" 
            variant="tonal" color="secondary" elevation="1" class="recurrence-rule-editor-card mt-auto">
            <v-card-text>
                <v-row class="recurrence-rule-editor-row">
                    <v-col cols="12">
                        <v-label style="text-wrap: wrap;">{{ rruleRFC5545Preview }}</v-label>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

    </div>
</template>

<style lang="scss">
.recurrence-rule-editor {

    .recurrence-rule-editor-card {
        overflow: initial; 
        z-index: initial;
    }

    .recurrence-rule-editor-row {
        flex: 0 0 auto;
    }

    .rrule-select {
        width: 50%;
        min-width: 200px;        
    }

    .rrule-text-field-inline {
        width: 150px !important;
    }

    .rrule-text-field {
        width: 50%;
        min-width: 200px;
        flex: 0 0 auto;
        padding: 0;

        .v-field__input {
            min-height: auto;
            height: 34px;
            // padding-top: 2px;
            // padding-bottom: 2px;
        }
    }

    .rrule-datepicker {
        width: 50%;
        min-width: 200px;

        .v-field__input {
            min-height: auto;
            height: 34px;
            // padding-top: 2px;
            // padding-bottom: 2px;
        }
    }
}
</style>
