import { ApiClient } from "@/services/api/common.api";
import {
  ElectricityConsumptionPerEquipmentInputParams,
  ElectricityConsumptionPerEquipmentReportData,
  GetAllReportsResponse,
  OeeInputParams,
  OeeReportData,
  OleInputParams,
  OleReportData,
  ReportDto
} from "@masta/generated-model";

export default {
  async getReports() {
    return await ApiClient.get<GetAllReportsResponse>(`/reports`);
  },
  async getDashboardGuestToken(dashboardId: string) {
    return await ApiClient.get<string>(`/reports/dashboard/${dashboardId}/token`);
  },
  async generateReport(report: ReportDto) {
    return await ApiClient.post(`/reports/generate`, report, {
      responseType: "blob"
    });
  },
  async getOEEReportData(params: OeeInputParams) {
    return await ApiClient.post<OeeReportData>(`/reports/report/oee`, params);
  },
  async getOLEReportData(params: OleInputParams) {
    return await ApiClient.post<OleReportData>(`/reports/report/ole`, params);
  },
  async getElectricityConsumptionPerEquipmentReportData(params: ElectricityConsumptionPerEquipmentInputParams) {
    return await ApiClient.post<ElectricityConsumptionPerEquipmentReportData>(`/reports/report/electricityConsumptionPerEquipment`, params);
  }
};
