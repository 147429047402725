<script lang="ts" setup>
import GridWrapper from "@/components/Grid/GridWrapper.vue";
import { inject, reactive, Ref, ref } from "vue";
import { GridWrapperComponent } from "@/components/Grid/GridWrapperComponent";
import { ColDef, KeyCreatorParams, ValueFormatterParams } from "ag-grid-community";
import { $dateTimeFormatterSymbol, DateFormatter } from "@masta/shared";
import { $t } from "@/i18n";
import { translateAvailability } from "@/composables/translateEnum";
import { enumToEditorEntries, enumValueEntryWithLocaleComparator, translateEditorEntries } from "@/components/Grid/ColumnTypes";
import { Availability, AvailabilityRuleDataDto } from "@masta/generated-model";
import { IEnumValueSelectCellEditorParams } from "@/components/Grid/CellEditors/IEnumValueSelectCellEditorParams";
import { requiredRule } from "@/components/ValueCellEditor/CommonValidationRules";
import { AvailabilityServerSideDataSource } from "@/components/AvailabilityRules/AvailabilityServerSideDataSource";
import { useReferenceGrid } from "@/components/Grid/UseReferenceGrid";
import { isDefined } from "@vueuse/core";

interface Props {
  disableCrudControls?: boolean;
  referenceValue?: any;
}

const $emits = defineEmits(["rowSelected", "rowDoubleClicked"]);
const props = defineProps<Props>();

const serverSideDataSource = reactive(new AvailabilityServerSideDataSource("availabilityRules"));
const DEFAULT_CREATE_VALUE = {
  availability: Availability.Available,
  recurrence: "FREQ=WEEKLY;BYDAY=MO,TU,WE,TH,FR",
  start: () => nowDateProducer(),
  end: () => nowPlusDateProducer(10)
};

const $dateTimeFormatter = inject<DateFormatter>($dateTimeFormatterSymbol)!;

const nowDateProducer = () => {
  const nowDate = new Date();
  nowDate.setMinutes(0);
  nowDate.setSeconds(0);
  nowDate.setMilliseconds(0);
  return nowDate.toISOString();
};

const nowPlusDateProducer = (plusDays: number) => {
  const nowDate = new Date();
  nowDate.setMinutes(0);
  nowDate.setSeconds(0);
  nowDate.setMilliseconds(0);

  nowDate.setDate(nowDate.getDate() + plusDays);
  return nowDate.toISOString();
};

const gridWrapperRef = ref<GridWrapperComponent>();
const defaultColumnDef = ref({
  filter: false,
  floatingFilter: true,
  filterParams: {
    applyMiniFilterWhileTyping: true
  },
  sortable: true,
  resizable: true
});

function onPrepareColumns(columnDefs: Ref<ColDef[]>) {
  columnDefs.value = [
    {
      field: "name",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      filter: "agTextColumnFilter",
      headerValueGetter: (_: any) => $t("availabilityRules-list-name-label", { $: "Name" }),
      editable: true,
      sortable: true,
      cellEditorParams: {
        placeholder: $t("availabilityRules-edit-name-label", { $: "Name" })
      },
      floatingFilterComponentParams: {
        placeholder: $t("availabilityRules-edit-name-label", { $: "Name" })
      }
    },
    {
      field: "businessId",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      filter: "agTextColumnFilter",
      headerValueGetter: (_: any) => $t("availabilityRules-list-businessId-label", { $: "Business ID" }),
      editable: true,
      sortable: true,
      cellEditorParams: {
        isEditEnabled: () => !gridWrapperRef.value?.isUpdating(),
        rules: [requiredRule],
        placeholder: $t("availabilityRules-edit-businessId-label", { $: "Business ID" })
      },
      floatingFilterComponentParams: {
        placeholder: $t("availabilityRules-edit-businessId-label", { $: "Business ID" })
      }
    },
    {
      field: "availability",
      type: ["enumTypeColumn", "setFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("availabilityRules-list-availability-label", { $: "Availability" }),
      editable: true,
      sortable: true,
      filter: "agSetColumnFilter",
      filterParams: {
        valueFormatter: (params: ValueFormatterParams) => params.value.key,
        keyCreator: (params: KeyCreatorParams) => params.value.value,
        values: translateEditorEntries(enumToEditorEntries(Availability), translateAvailability),
        comparator: enumValueEntryWithLocaleComparator
      },
      valueFormatter: (params: any) => translateAvailability(params.data.availability),
      cellEditorParams: {
        rules: [requiredRule],
        placeholder: $t("availabilityRules-edit-availability-label", { $: "Availability" }),
        values: translateEditorEntries(enumToEditorEntries(Availability), translateAvailability)
      } as IEnumValueSelectCellEditorParams
    },
    {
      field: "recurrence",
      type: ["rruleTypeColumn", "textFloatingFilterColumnType"],
      filter: false,
      headerValueGetter: (_: any) => $t("availabilityRules-list-recurrence-label", { $: "Recurrence" }),
      editable: true,
      sortable: true,
      cellEditorParams: {
        rules: [requiredRule],
        placeholder: $t("availabilityRules-edit-recurrence-label", { $: "Recurrence" })
      }
    },
    {
      field: "start",
      type: ["datepickerTypeColumn", "dateTimeTypeColumn"],
      filter: "agDateColumnFilter",
      headerValueGetter: (_: any) => $t("availabilityRules-list-periodStart-label", { $: "Period start" }),
      editable: true,
      sortable: true,
      hide: true,
      valueFormatter: (params) => (params.data && params.data.start ? $dateTimeFormatter(params.data.start) : ""),
      cellEditorParams: {
        placeholder: $t("availabilityRules-edit-periodStart-label", { $: "Period start" })
      }
    },
    {
      field: "end",
      type: ["datepickerTypeColumn", "dateTimeTypeColumn"],
      filter: "agDateColumnFilter",
      headerValueGetter: (_: any) => $t("availabilityRules-list-periodEnd-label", { $: "Period end" }),
      editable: true,
      sortable: true,
      hide: true,
      valueFormatter: (params) => (params.data && params.data.end ? $dateTimeFormatter(params.data.end) : ""),
      cellEditorParams: {
        placeholder: $t("availabilityRules-edit-periodEnd-label", { $: "Period end" })
      }
    },
    {
      field: "color",
      editable: true,
      sortable: true,
      filter: false,
      headerValueGetter: (_: any) => $t("availabilityRules-list-color-label", { $: "Color" }),
      type: "colorTypeColumn",
      cellEditorParams: {
        placeholder: $t("availabilityRules-list-color-label", { $: "Color" })
      }
    }
  ];
}

const refGrid = useReferenceGrid({
  $emits,
  isRowSelected: (data: any) => { return isDefined(props.referenceValue) && data.id === props.referenceValue.id;}
});
</script>

<template>
  <grid-wrapper
    ref="gridWrapperRef"
    :create-btn="!disableCrudControls"
    :duplicate-btn="!disableCrudControls"
    :edit-btn="!disableCrudControls"
    :delete-btn="!disableCrudControls"
    refresh-btn
    row-selection="multiple"
    server-side
    :server-side-datasource="serverSideDataSource"
    :create-default-value="DEFAULT_CREATE_VALUE"
    :default-col-def="defaultColumnDef"
    identifier="availability-rules"
    @prepare-columns="onPrepareColumns"
    @ready="refGrid.onGridReady"
  />
</template>

<style lang="scss" scoped></style>
