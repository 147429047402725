import type { IContextMenuProvider } from "../../Core";
import { ActivityBounds, GanttEvents, GraphicsMousePositionEvent, IContextMenuItemActionContext, IocContainer, IocSymbols, Lifecycle, Row, TimelineManager } from "../../Core";
import { inject, injectable, optional } from "inversify";
import { filter, fromEvent } from "rxjs";
import { RowsContainer } from "./RowsContainer";
import { ViewportRowsContainer } from "./ViewportRowsContainer";
import { RowContainer } from "./Row";

@injectable()
export class ContextMenuManager extends Lifecycle {
  private _contextMenuContainerElement: HTMLElement;
  private _contextMenuElement: HTMLElement;

  private readonly _dividerElement: HTMLElement;

  constructor(
    @inject(IocContainer) private _iocContainer: IocContainer,
    @inject(GanttEvents) private _ganttEvents: GanttEvents,
    @inject(RowsContainer) private _rowsContainer: RowsContainer,
    @inject(ViewportRowsContainer) private _viewportRowsContainer: ViewportRowsContainer,
    @inject(TimelineManager) private _timelineManager: TimelineManager,
    @inject(IocSymbols.ContextMenuProvider) @optional() private _contextMenuProvider?: IContextMenuProvider
  ) {
    super();
    this._dividerElement = document.createElement("div");
    this._dividerElement.className = "context-menu-divider";
  }

  async afterInitialize(): Promise<void> {
    await super.afterInitialize();

    if (!this._contextMenuProvider) return;

    this._contextMenuContainerElement = document.createElement("div");
    this._contextMenuContainerElement.className = "context-menu-container";
    this._contextMenuElement = document.createElement("div");
    this._contextMenuElement.className = "context-menu";
    this._contextMenuContainerElement.appendChild(this._contextMenuElement);

    this._rowsContainer.element.appendChild(this._contextMenuContainerElement);

    this.subscribe(
      this._ganttEvents.contextMenuEvent$.pipe(filter((e) => !e.ctrlKey)).subscribe(async (e: GraphicsMousePositionEvent) => {
        const rowContainers = this._viewportRowsContainer.rowContainers;
        const rowContainer = rowContainers.find((rc) => e.y >= rc.element.offsetTop && e.y <= rc.element.offsetTop + rc.element.offsetHeight);
        if (rowContainer) {
          const bounds = rowContainer.getActivityBoundsForMousePosition({
            x: e.x,
            y: e.y - rowContainer.element.offsetTop
          });
          await this.show(e, rowContainer, bounds);
        }
      })
    );

    this.subscribe(
      fromEvent<PointerEvent>(document, "pointerdown").subscribe((e: PointerEvent) => {
        if (!this._contextMenuElement.contains(e.target as Node)) {
          this.hide();
        }
      })
    );
  }

  async beforeDestroy(): Promise<void> {
    await super.beforeDestroy();
  }

  private async show(e: GraphicsMousePositionEvent, rowContainer: RowContainer<Row<any, any, any>>, bounds: Array<ActivityBounds>): Promise<void> {
    const timePoint = this._timelineManager.calculateTimeForLocation(e.x);

    const context = {
      row: rowContainer.row,
      activityBounds: bounds ?? [],
      timePoint: timePoint,
      event: e,
      iocContainer: this._iocContainer
    } as IContextMenuItemActionContext;

    const contextMenuItems = (await this._contextMenuProvider?.getContextMenuItems(context))?.filter(mi => mi !== null && mi !== undefined) ?? [];

    e.preventDefault();

    if (contextMenuItems.length === 0) return;

    this._contextMenuElement.innerHTML = "";

    for (const contextMenuItem of contextMenuItems) {
      if (contextMenuItem === "separator") {
        this._contextMenuElement.appendChild(this._dividerElement.cloneNode(true));
      } else {
        const isDisabled = contextMenuItem.disabled ? await contextMenuItem.disabled(context) : false;

        const item = document.createElement("div");
        item.className = `context-menu-item ${isDisabled ? "disabled" : ""}`;
        item.innerHTML = `<div class="icon">${contextMenuItem.icon ?? ""}</div><div class="label">${contextMenuItem.label}</div>`;
        item.addEventListener("click", async () => {
          if (!isDisabled) {
            await contextMenuItem.action(context);
            this.hide();
          }
        });
        this._contextMenuElement.appendChild(item);
      }
    }

    const rowsContainer = this._contextMenuContainerElement.closest(".rows-container");
    const rect = this._contextMenuContainerElement.getBoundingClientRect();

    let xPos = e.x + rect.left;
    let yPos = e.y + rect.top - rowsContainer!.scrollTop;

    this._contextMenuElement.style.transform = `translate(${xPos}px, ${yPos}px)`;
    this._contextMenuElement.style.display = "block";

    // reposition menu if it is outside the viewport
    const menuRect = this._contextMenuElement.getBoundingClientRect();
    const rowContainerRect = this._rowsContainer.element.getBoundingClientRect();

    if (menuRect.right > window.innerWidth) {
      xPos -= menuRect.width;
      this._contextMenuElement.style.transform = `translate(${xPos}px, ${yPos}px)`;
    }

    if (menuRect.bottom > rowContainerRect.bottom) {
      yPos -= menuRect.height;
      this._contextMenuElement.style.transform = `translate(${xPos}px, ${yPos}px)`;
    }
  }

  private hide() {
    this._contextMenuElement.style.display = "none";
  }
}
