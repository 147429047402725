<script lang="ts" setup>
import { MenuItem } from "@/components/MegaMenu/MegaMenuModel";
import { ref, UnwrapRef } from "vue";
import { $t } from "@/i18n";

// interface Props {
// }

// const props = defineProps<Props>();
// const emits = defineEmits(["close"]);
const tab = ref<string | undefined>("product");
</script>

<template>
  <div class="search-menu rounded-b pa-2">
    <!--    <div class="search-content pa-2">-->
    <div class="tabs">
      <v-tabs
        v-model="tab"
        height="2em"
        class="task-details text-disabled"
        selected-class="v-slide-group-item--active text-primary bg-white"
        density="compact"
        color="primary"
        show-arrows
      >
        <v-tab value="product" data-cy="product">
          {{ $t("topSearch-tab-product-label", { $: "Product" }) }}
        </v-tab>
        <v-tab value="menu" data-cy="menu">
          {{ $t("topSearch-tab-menu-label", { $: "Menu" }) }}
        </v-tab>
      </v-tabs>
    </div>
    <div class="content pa-4">
      <v-window v-model="tab">
        <v-window-item value="product" class="fill-height">
          <slot name="product" />
        </v-window-item>
        <v-window-item value="menu">
          <slot name="menu" />
        </v-window-item>
      </v-window>
    </div>
    <!--    </div>-->
  </div>
</template>

<style lang="scss" scoped>
$app-bar-height: 81px;
.search-menu {
  width: 100%;
  background-color: rgb(var(--v-theme-background));
  box-shadow: 0px 4px 5px 0px var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.14));
  min-height: 25vh;
  max-height: calc(100vh - (#{$app-bar-height} + 5px));
  overflow: hidden;

  display: grid;
  grid-template-rows: 2.1em 1fr;

  .search-content {
    //display: grid;
    //grid-template-rows: 2.1em 1fr;
    height: 100%;
    max-height: 100%;
    //box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2);
    //background: rgb(var(--v-theme-surface)) linear-gradient(rgba(0, 0, 0, 0.34) 0px, rgba(0, 0, 0, 0.1) 4px, rgba(0, 0, 0, 0) 10px);

  }

  .content {
    max-height: 100%;
    overflow: hidden;
    //overflow-y: scroll;

    :deep(.v-window) {
      height: 100%;
    }

    :deep(.v-window-item) {
      max-height: 100%;
    }
  }
}
</style>
