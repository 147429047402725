<script lang="ts" setup>
import { onMounted, ref, watch } from "vue";
import ApiService from "@/services/api";
import { AttachmentSourceType } from "@masta/generated-model";
import VuePdfEmbed from "vue-pdf-embed";
import { $t } from "@/i18n";

const props = defineProps<{
  documentId: string;
  revisionNumber: number;
  fileName: string;
  attachmentSourceType?: AttachmentSourceType;
  attachmentContentStorageConfigurationId?: string;
}>();

const contentType = ref<string | null>(null);
const url = ref<string | null>(null);

const loadContent = async () => {
  try {
    const { data } = await ApiService.documents.getDocumentContentStream({
      documentId: props.documentId,
      revisionNumber: props.revisionNumber,
      attachmentSourceType: props.attachmentSourceType,
      attachmentContentStorageConfigurationId: props.attachmentContentStorageConfigurationId
    });
    contentType.value = data.type;
    if (contentType.value === "text/plain") {
      url.value = await data.text();
    } else {
      url.value = window.URL.createObjectURL(data);
    }
  } catch (e) {
    console.error(e);
  }
};

onMounted(loadContent);

watch(() => props.documentId, loadContent);

async function onShowContent() {
  try {
    const a = document.createElement("a");
    a.href = url.value!;
    a.download = props.fileName;
    a.target = "_blank";
    a.click();
  } catch (e) {
    console.error(e);
  }
}
</script>

<template>
  <v-img v-if="contentType?.startsWith('image/')" :src="url" height="240" />
  <video v-else-if="contentType?.startsWith('video/')" controls height="340">
    <source :src="url" :type="contentType" />
  </video>
  <v-textarea v-else-if="contentType === 'text/plain'" v-model="url" readonly :rules="[() => true]"></v-textarea>
  <vue-pdf-embed v-else-if="contentType === 'application/pdf'" :source="url" />
  <br />
  <v-btn prepend-icon="mdi-download" color="indigo-lighten-5" block @click="onShowContent">
    {{ $t("documentAttachmentDefinition-content-action", { $: "Download content" }) }}
  </v-btn>
</template>

<style lang="scss" scoped></style>
