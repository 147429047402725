import ApiService from "@/services/api";
import { CreateModelInstanceCommand, ModelInstanceDto, ModelInstanceIdDto, ModelSchemaDto, UpdateModelInstanceCommand } from "@masta/generated-model";
import { defineStore } from "pinia";
import { useErrorsStore } from "@/store/ErrorsStore";

interface State {
  schemas: ModelSchemaDto[];
  selectedSchema: ModelSchemaDto | null | undefined;
  instances: ModelInstanceDto[];
}

export function getSchemaId(schema: ModelSchemaDto) {
  return `${schema.schemaKey}.${schema.schemaName}`;
}

export const useModelInstancesStore = defineStore("model-instances", {
  state: (): State => ({
    schemas: [],
    selectedSchema: null,
    instances: []
  }),
  actions: {
    async fetchSchemas() {
      try {
        const response = await ApiService.modelInstances.getSchemas();
        this.schemas = response.data.schemas;
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    async fetchSchemaInstancesBySchema(schema: ModelSchemaDto) {
      return this.fetchSchemaInstances(getSchemaId(schema), schema.version);
    },
    async fetchSchemaInstancesByInstance(modelInstance: ModelInstanceDto) {
      return this.fetchSchemaInstances(modelInstance.schemaId, modelInstance.schemaVersion);
    },
    async fetchSchemaInstances(schemaId: string, schemaVersion: string) {
      const index = schemaId.lastIndexOf(".");
      const key = schemaId.slice(0, index);
      const name = schemaId.slice(index + 1);

      this.selectedSchema = this.schemas.find((x: ModelSchemaDto) => x.schemaKey === key && x.schemaName === name);
      try {
        const response = await ApiService.modelInstances.getSchemaInstances(schemaId, schemaVersion);
        this.instances = response.data.instances;
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    async fetchSchemaInstanceIds(schema: ModelSchemaDto): Promise<ModelInstanceIdDto[]> {
      try {
        const { data } = await ApiService.modelInstances.getModelInstanceIds(getSchemaId(schema), schema.version);
        return data.modelInstanceIds;
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    async createInstance(instance: CreateModelInstanceCommand) {
      try {
        const { data } = await ApiService.modelInstances.createInstance(instance);
        return data;
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        return null;
      }
    },
    async updateInstance(instance: ModelInstanceDto) {
      try {
        await ApiService.modelInstances.updateInstance({
          id: instance.id,
          value: instance.value,
          tags: instance.tags,
          revisionNumber: instance.revisionNumber,
          businessId: instance.businessId
        });
        await this.fetchSchemaInstancesByInstance(instance);
        return true;
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        return false;
      }
    },
    async updateModelInstance(request: UpdateModelInstanceCommand) {
      try {
        await ApiService.modelInstances.updateInstance(request);
        return true;
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        return false;
      }
    },
    async deleteInstance(instance: ModelInstanceDto) {
      try {
        await ApiService.modelInstances.deleteInstance(instance.id, instance.revisionNumber);
        await this.fetchSchemaInstancesByInstance(instance);
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    clearInstances() {
      this.instances = [];
    },
    async makeCopy(instance: ModelInstanceDto, updateStore = true) {
      try {
        const { data } = await ApiService.modelInstances.makeCopy({ id: instance.id, revisionNumber: instance.revisionNumber, businessId: null });
        if (updateStore) {
          await this.fetchSchemaInstancesByInstance(instance);
        }
        return data;
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    async release(instance: ModelInstanceDto, updateStore = true) {
      try {
        await ApiService.modelInstances.release({ id: instance.id, revisionNumber: instance.revisionNumber, businessId: null });
        if (updateStore) {
          await this.fetchSchemaInstancesByInstance(instance);
        }
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    async archive(instance: ModelInstanceDto, updateStore = true) {
      try {
        await ApiService.modelInstances.archive({ id: instance.id, revisionNumber: instance.revisionNumber, businessId: null });
        if (updateStore) {
          await this.fetchSchemaInstancesByInstance(instance);
        }
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    async createNewRevision(instance: ModelInstanceDto, updateStore = true) {
      try {
        await ApiService.modelInstances.createNewRevision({ id: instance.id });
        if (updateStore) {
          await this.fetchSchemaInstancesByInstance(instance);
        }
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    }
  }
});
