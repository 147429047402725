<script lang="ts" setup>
import { inject, ref, watch } from "vue";
import { $megaMenuSymbol, IMegaMenu } from "@/plugins/mega-menu.plugin";
import { MenuItem } from "@/components/MegaMenu/MegaMenuModel";
import { onKeyStroke, useDebounceFn, useStorage } from "@vueuse/core";
import SearchMenuResultsOverlayContent from "@/components/Search/SearchMenuResultsOverlayContent.vue";
import { $t } from "@/i18n";
import { VBtn } from "vuetify/components";
import ResourcePickerDialog from "@/components/Resources/ResourcePickerDialog.vue";
import { ResourceInfoDto, ResourceSubType, ResourceType } from "@masta/generated-model";
import SearchOverlayContent from "@/components/Search/SearchOverlayContent.vue";
import SearchResourceList from "@/components/Search/SearchResourceList.vue";
import { useSearchResourceStore } from "@/components/Search/UseSearchResourceStore";

const $megaMenu = inject<IMegaMenu>($megaMenuSymbol);
if (!$megaMenu) {
  throw Error("Could not build Mega Menu. Mega Menu Plugin not initialized.");
}
$megaMenu.updateMenuItems();
const mainMenuItems = $megaMenu.getMainMenuItemsRef();
const searchText = ref<string | null>(null);
const mainMenuFiltered = ref<MenuItem[]>([...mainMenuItems.value]);

const searchRef = ref<HTMLInputElement | undefined>(undefined);
const searchInput = ref<HTMLInputElement | undefined>(undefined);
const opened = ref(false);
const overlay = ref(false);
const overlayRightMargin = ref<number>(145);

const { addRecentResource, recentResources, selectedResource } = useSearchResourceStore();

onKeyStroke((e) => {
  if (e.ctrlKey && e.key.toLowerCase() === "f") {
    opened.value = true;
    // e.preventDefault();
  }
});


function openSearch() {
  opened.value = true;
}

watch(opened, (v) => {
  if (v) {
    setTimeout(() => {
      overlay.value = true;
    }, 300);
  } else {
    overlay.value = false;
  }
});

watch(
  searchText,
  useDebounceFn(() => {
    const value = searchText.value;
    if (value) {
      mainMenuFiltered.value = $megaMenu.filterMenuItems((x) => x.name.toLowerCase().includes(value.toLowerCase().trim()));
    } else {
      mainMenuFiltered.value = [];
    }
    if (mainMenuFiltered.value.length > 0 && opened.value) {
      overlay.value = true;
    }
  }, 250)
);

function close() {
  opened.value = false;
}

function closeAndClear() {
  close();
  searchText.value = "";
}

function clickedOutside($event: any) {
  const isSearchInputClick = searchRef.value?.contains($event.target);
  if (!isSearchInputClick || searchRef.value === $event.target) {
    close();
  }
}

function onMouseLeave() {
  // enable to close menu when mouse is leaving search area
  // overlay.value = false;
}

const resourcePickerDialog = ref(false);
const resourceSubTypes = ref<ResourceSubType[] | undefined>(undefined);

function onSelectResource() {
  resourcePickerDialog.value = true;
}

function removeSelectedResource() {
  selectedResource.value = null;
}

function onResourcePickedFromDialog(resource: ResourceInfoDto) {
  selectedResource.value = resource;

  addRecentResource(resource);

  if (resource) {
    searchText.value = resource.businessId ?? resource.name;
  }
  close();
}
</script>

<template>
  <div ref="searchRef" class="search d-flex align-center justify-end" @keyup.esc="closeAndClear">
    <v-text-field
      ref="searchInput"
      v-model="searchText"
      :placeholder="$t('topSearch-textField-placeholder', { $: 'Search' })"
      color="primary"
      variant="outlined"
      density="compact"
      hide-details
      single-line
      clearable
      prepend-inner-icon="mdi-magnify"
      class="pr-8 search-field"
      :class="{ opened: opened }"
      data-cy="search-input"
      @click:control="openSearch"
    >
      <template #append-inner>
        <v-btn variant="plain" icon="mdi-select-search" density="compact" :ripple="false" @click.stop="onSelectResource"></v-btn>
      </template>
    </v-text-field>
  </div>
  <v-overlay
    v-model="overlay"
    persistent
    no-click-animation
    transition="fade"
    :scrim="false"
    class="search-overlay"
    content-class="search-container"
    :style="{ marginRight: `${overlayRightMargin}px` }"
    data-cy="search-overlay"
    @mouseleave="onMouseLeave"
    @click:outside="($event) => clickedOutside($event)"
  >
    <v-fade-transition>
      <search-overlay-content>
        <template #product>
          <search-resource-list v-model="searchText" @close="close" />
        </template>
        <template #menu>
          <v-fade-transition>
            <div v-if="mainMenuFiltered.length > 0" class="search-content h-100 overflow-y-auto" data-cy="menu-search-results">
              <search-menu-results-overlay-content :main-menu-items="mainMenuFiltered" @close="close" />
            </div>
            <div v-else class="caption d-flex justify-center align-center h-100">
              {{ $t("topSearch-mainMenuSearchRequired-caption", { $: "Please type something to search menu items..." }) }}
            </div>
          </v-fade-transition>
        </template>
      </search-overlay-content>
    </v-fade-transition>
  </v-overlay>
  <resource-picker-dialog
    v-model:dialog="resourcePickerDialog"
    :resource="selectedResource"
    :resource-sub-types="resourceSubTypes"
    :resource-types="[ResourceType.Material]"
    @update:resource="onResourcePickedFromDialog"
  />
</template>

<style lang="scss">
$app-bar-height: 81px;

.search {
  .search-field {
    transition: width 0.2s linear, visibility 0.1s linear, opacity 0.1s linear;
    width: 200px;

    &.opened {
      width: 780px;
    }
  }
}

.search-overlay {
  justify-content: end;
}

.search-container {
  margin-top: $app-bar-height;
  width: 780px;
}
</style>
