<template>
  <master-detail-layout scenario-selector :detail="showDetails" detail-dialog-width="90%" @change="showDetails = false" @scenario-selectio-changed="onScenarioSelectionChanged">
    <template #master>
      <v-card elevation="0" class="flexcard fill-height">
        <v-card-title>
          <breadcrumbs>{{ $t("equipment-view-equipment-title", { $: "Equipment" }) }}</breadcrumbs>
        </v-card-title>
        <v-card-text class="fill-height">
          <v-row class="fill-height" no-gutters>
            <v-col cols="12">
              <equipment-grid ref="grid" @manage-model-instances="manageModelInstances" @manage-availability-rules="manageAvailabilityRules" @show-details="onShowDetails" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-col v-if="editModelInstanceAssignmentDialog">
        <resource-model-instance-assignment
          :model-instances="equipment.modelInstances"
          :opened="editModelInstanceAssignmentDialog"
          @cancel="editModelInstanceAssignmentDialog = false"
          @save="saveModelInstanceAssignment"
        >
          <resource-label type="Equipment" :business-id="equipment.businessId" :name="equipment.name" />
        </resource-model-instance-assignment>
      </v-col>
      <v-col v-if="editAvailabilityRulesAssignmentDialog">
        <availability-rules-assignment-editor
          :resource="equipment"
          :opened="editAvailabilityRulesAssignmentDialog"
          @cancel="editAvailabilityRulesAssignmentDialog = false"
          @save="editAvailabilityRulesAssignmentDialog = false"
        />
      </v-col>
    </template>
    <template #detail-title>
      <span class="text-h6">{{ equipment?.name || equipment?.businessId }}</span>
    </template>
    <template #detail>
      <equipment-details :equipment="equipment" :schema-tabs="schemaTabs" @updated="equipmentUpdated" />
    </template>
  </master-detail-layout>
</template>

<script>
import MasterDetailLayout from "@/components/Layout/MasterDetailLayout.vue";
import EquipmentGrid from "@/components/Equipment/EquipmentGrid";
import AvailabilityRulesAssignmentEditor from "@/components/AvailabilityRules/AvailabilityRulesAssignment/AvailabilityRulesAssignmentEditor";
import ResourceLabel from "@/components/ModelInstances/ModelInstanceAssignment/ResourceLabel";
import { useEquipmentsStore } from "@/store/EquipmentsStore";
import { mapActions, mapState } from "pinia";
import Breadcrumbs from "@/components/Layout/Breadcrumbs.vue";
import ResourceModelInstanceAssignment from "@/components/ModelInstances/ModelInstanceAssignment/ResourceModelInstanceAssignment.vue";
import EquipmentDetails from "@/components/Equipment/EquipmentDetails.vue";
import { useModelInstancesStore } from "@/store/ModelInstancesStore";
import { useSystemStore } from "@/store/SystemStore";

export default {
  name: "Equipment",
  components: {
    EquipmentDetails,
    Breadcrumbs,
    ResourceLabel,
    AvailabilityRulesAssignmentEditor,
    ResourceModelInstanceAssignment,
    EquipmentGrid,
    MasterDetailLayout
  },
  title: "Equipment",
  data() {
    return {
      editModelInstanceAssignmentDialog: false,
      editAvailabilityRulesAssignmentDialog: false,
      showDetails: false,
      equipment: null,
      refreshGridAction: null,
      schemaTabs: []
    };
  },
  computed: {
    ...mapState(useModelInstancesStore, ["schemas"]),
    ...mapState(useSystemStore, ["modelInstanceTabs"])
  },
  watch: {
    selectedScenario() {
      this.fetch();
    }
  },
  mounted() {
    this.loadTabSchemas();
  },
  methods: {
    ...mapActions(useEquipmentsStore, ["fetch", "updateModelInstanceAssignment"]),
    ...mapActions(useModelInstancesStore, ["fetchSchemas"]),
    onScenarioSelectionChanged() {
      this.fetch();
    },
    manageModelInstances(equipment, refreshGridAction) {
      this.refreshGridAction = refreshGridAction;
      this.equipment = equipment;
      this.editModelInstanceAssignmentDialog = true;
    },
    async saveModelInstanceAssignment(instances) {
      await this.updateModelInstanceAssignment({
        id: this.equipment.id,
        modelInstanceIds: instances.map((x) => x.id)
      });
      this.refreshGridAction();
      this.editModelInstanceAssignmentDialog = false;
    },
    manageAvailabilityRules(equipment) {
      this.equipment = equipment;
      this.editAvailabilityRulesAssignmentDialog = true;
    },
    onShowDetails(equipment) {
      this.equipment = equipment;
      this.showDetails = true;
    },
    async loadTabSchemas() {
      await this.fetchSchemas();
      for (const tab of this.modelInstanceTabs?.equipments ?? []) {
        const schema = this.schemas.find((x) => x.schemaName === tab.schemaName && x.schemaKey === tab.schemaKey && x.version === tab.schemaVersion);
        if (schema) {
          this.schemaTabs.push({ title: tab.title ?? "", schema, contextName: tab.contextName ?? "", resourceSubTypes: tab.resourceSubTypes });
        }
      }
    },
    async equipmentUpdated(id) {
      this.$refs.grid.refreshSingle(id);
    }
  }
};
</script>

<style scoped></style>
