import { ApiClient } from "@/services/api/common.api";
import { UpdateTaskModelInstanceAssignmentCommand } from "@masta/generated-model";
import { GenerateAndCreateSerialNumbersForTopLevelTaskCommand } from "@masta/generated-model";

export default {
  async updateTaskModelInstanceAssignment(request: UpdateTaskModelInstanceAssignmentCommand) {
    return ApiClient.post(`/tasks/updateModelAssignment`, request);
  },
  async generateSerialNumbers(request: GenerateAndCreateSerialNumbersForTopLevelTaskCommand) {
    return ApiClient.post(`/tasks/generateSerialNumbers`, request);
  }
};

