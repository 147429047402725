
<script lang="ts">
import { IControlledCellEditorParams, resolveValueRef, useValueChangeControl } from "@/components/Grid/UseValueChangeControl";
import OrganizationPicker from "@/components/Organizations/OrganizationPicker.vue";
import { BodyScrollEvent, ICellEditorParams } from "ag-grid-community";
import { computed, onMounted, onUnmounted, ref, toRef } from "vue";
import { VForm } from 'vuetify/lib/components/index.mjs';

export interface IOrganizationPickerCellEditorParams extends IControlledCellEditorParams, ICellEditorParams {
    rules?: any[],
}

// Warn: Use export default instead of <script setup /> because only this works with AgGrid!
export default {
    components: { OrganizationPicker },
    setup(props: {
        params: IOrganizationPickerCellEditorParams;
    }) {
        const organizationPickerRef = ref<typeof OrganizationPicker>();
        const formRef = ref<VForm>();
        const valid = ref<boolean>();

        const params = toRef(props, "params");

        const isEditEnabled = toRef(props.params.isEditEnabled ? props.params.isEditEnabled(props.params) : true);

        const initialValue = props.params.value;
        const organizationValue = resolveValueRef(props.params, initialValue);

        const rules = computed(() => {
            return params.value.rules ?? [];
        });

        const errors = computed(() => {
            return formRef.value?.errors.map((e) => e.errorMessages.join(",")).join(",");
        });

        const showTooltip = computed<boolean>(() => !!errors.value && !!organizationPickerRef.value?.hasFocus());

        function getValue() {
            return organizationValue.value;
        }

        function afterGuiAttached() {
            if (props.params.cellStartedEdit) {
                focusIn();
            }
        }

        function focusIn() {
            organizationPickerRef.value?.focus();
        }

        function focusOut() {   
        }

        function isValid() {
            return isEditEnabled.value ? valid?.value : undefined;
        }

        onMounted(() => {
            props.params.api.addEventListener("bodyScroll", onGridBodyScroll);
        });

        onUnmounted(() => {
            const { api } = props.params;
            if (api && !api.isDestroyed()) {
                api.removeEventListener("bodyScroll", onGridBodyScroll);
            }
        });

        function onGridBodyScroll(event: BodyScrollEvent) {
            // close menu on scroll (when is open) to avoid menu position issues
            if (organizationPickerRef.value && organizationPickerRef.value.getMenu()) {
                organizationPickerRef.value.setMenu(false);
            }
        }

        useValueChangeControl(organizationValue, props.params);

        return {
            getValue,
            afterGuiAttached,
            focusIn,
            focusOut,
            isValid,
            showTooltip,
            rules,
            valid,
            errors,
            isEditEnabled,
            organizationValue,
            organizationPickerRef: organizationPickerRef as any,
            formRef: formRef as any,
        };
    },
}
</script>


<template>
      <v-tooltip v-model="showTooltip" location="top" color="error" :offset="30" class="organization-picker-cell-editor-tooltip">
        <template #activator="act">
        <v-form ref="formRef" v-model="valid" :disabled="!isEditEnabled" class="organization-picker-cell-editor">
            <div class="organization-picker-cell-editor-container">
                <OrganizationPicker
                    ref="organizationPickerRef"
                    v-model="organizationValue" 
                    color="primary"
                    :readonly="!isEditEnabled"
                    :placeholder="params.placeholder"
                    :rules="rules" />
                <div v-bind="act.props" />
            </div>
      </v-form>
    </template>
    <span v-if="errors">{{ errors }}</span>
  </v-tooltip>
</template>


<style lang="scss">
.organization-picker-cell-editor {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .organization-picker-cell-editor-container {
        padding: 0.31em;

        .v-input {
            --v-input-chips-margin-top: 0;
            --v-input-chips-margin-bottom: 0;
            font-size: 1em;
        }

        .v-autocomplete {
            &--single {
                .v-field--variant-outlined input {
                    --v-input-chips-margin-top: 2px;
                    --v-input-chips-margin-bottom: 2px;
                }
            }

            .v-field {
                border-radius: 8px;
                font-size: 1em;

                .v-field__overlay {
                    background-color: white;
                }

                .v-field__input {
                    font-size: 1em;
                    min-height: 34px;
                }

                .v-field__outline {
                    --v-field-border-width: 2px;
                }

                color: rgba(0, 0, 0, 0.5);

                &:hover {
                    color: var(--ag-data-color);
                }
    
                &.v-field--focused {
                    color: var(--ag-data-color);
                }
            }    
        }
    }
}

.organization-picker-cell-editor-tooltip {
  .v-overlay__content {
    background: rgba(var(--v-theme-error), 0.7);
  }
}
</style>