<script setup lang="ts">
import ApiService from "@/services/api";
import { $t } from "@/i18n";
import { MaterialSubType, MeasurementUnit, ProductTemplateDetailsDto, ResourceInfoDto, ResourcePurpose, ResourceSubType, ResourceType } from "@masta/generated-model";
import { computed, ref, watch } from "vue";
import ResourcePicker from "@/components/Resources/ResourcePicker.vue";
import { replaceTemplateResource, ProductTemplateDraft } from "@/components/ProcessDrafts/ProcessDraftModels";
import {
  createMaterialInfo,
  createDraftFromTemplate,
  createEmptyProductTemplateDraft,
  createStubProductTemplateDraftForResource
} from "@/components/ProcessDrafts/model-from-product-template";
import { getEnumTitleValuePairs } from "@/composables/enumHelpers";
import { translateMeasurementUnit, translateResourcePurpose, translateResourceSubType } from "@/composables/translateEnum";
import { requiredRule } from "@/components/ValueCellEditor/CommonValidationRules";
import { VForm } from "vuetify/components";
import { useSnackbarsStore } from "@/store/SnackbarsStore";
import { hasProductionPurpose } from "@/components/ProcessDrafts/validation";
import { useScenariosStore } from "@/store/ScenariosStore";

const modelValue = defineModel<boolean>({ required: true });
const props = defineProps<{
  targetProduct: ResourceInfoDto;
}>();

const emit = defineEmits<{
  (e: "dialog-result", result: ProductTemplateDraft | null): void;
}>();

enum AddProductOptions {
  Existing,
  New,
  NewFromTask,
  Empty
}

interface AddProductOption {
  title: string;
  value: AddProductOptions;
}

const resource = ref<ResourceInfoDto | null>(null);
const options = ref<AddProductOption[]>([
  { title: $t("processDraft-addProductDialog-createFromExistingProduct-label", { $: "Create from existing product" }), value: AddProductOptions.Existing },
  { title: $t("processDraft-addProductDialog-createNewProduct-label", { $: "Create new resource with unique name" }), value: AddProductOptions.New },
  {
    title: $t("processDraft-addProductDialog-createNewProductFromTask-label", { $: "Create new resource using target product and task name" }),
    value: AddProductOptions.NewFromTask
  },
  { title: $t("processDraft-addProductDialog-empty-label", { $: "Create empty" }), value: AddProductOptions.Empty }
]);
const selectedOption = ref<AddProductOption>(options.value[0]);
const resourceName = ref<string>();
const resourceBusinessId = ref<string>();
const resourceSaveProblem = ref(false);
const processLoadProblem = ref(false);
const taskName = ref<string>();
const selectedUnit = ref<MeasurementUnit>(MeasurementUnit.Piece);
const measurementUnits = getEnumTitleValuePairs(MeasurementUnit, translateMeasurementUnit).sort((a, b) => a.title.localeCompare(b.title));
const selectedPurposes = ref<ResourcePurpose[]>([ResourcePurpose.Production]);
const resourcePurposes = getEnumTitleValuePairs(ResourcePurpose, translateResourcePurpose).sort((a, b) => a.title.localeCompare(b.title));
const form = ref<InstanceType<typeof VForm> | null>(null);
const valid = ref<boolean>(false);
const showProgress = ref(false);
const snackbarsStore = useSnackbarsStore();
const scenarioStore = useScenariosStore();

watch(
  () => modelValue.value,
  (newValue) => {
    if (newValue) {
      clearValues();
    }
  }
);
watch(
  () => selectedOption.value,
  () => form.value?.resetValidation()
);
watch(
  () => resourceBusinessId.value,
  () => (resourceSaveProblem.value = false)
);
watch(
  () => taskName.value,
  () => (resourceSaveProblem.value = false)
);
watch(
  () => resource.value,
  () => (processLoadProblem.value = false)
);
const showResourceSelector = computed(() => selectedOption.value.value !== AddProductOptions.NewFromTask && selectedOption.value.value !== AddProductOptions.Empty);
const showNew = computed(() => selectedOption.value.value !== AddProductOptions.Existing && selectedOption.value.value !== AddProductOptions.Empty);
const showNewFromResource = computed(() => selectedOption.value.value === AddProductOptions.New);
const showNewFromTask = computed(() => selectedOption.value.value === AddProductOptions.NewFromTask);

const businessIdRules = computed(() => (showNewFromResource.value ? [requiredRule, resourceSaveProblemRule] : []));
const taskNameRules = computed(() => (showNewFromTask.value ? [requiredRule, resourceSaveProblemRule] : []));
const resourceRules = computed(() => (showNew.value ? [processLoadProblemRule] : [requiredRule]));
const purposesRules = computed(() => (showNew.value ? [hasProductionPurpose] : []));

function onCancel() {
  close();
  emit("dialog-result", null);
}

function clearValues() {
  showProgress.value = false;
  resource.value = null;
  selectedOption.value = options.value[0];
  resourceName.value = undefined;
  resourceBusinessId.value = undefined;
  taskName.value = undefined;
  selectedUnit.value = MeasurementUnit.Piece;
  selectedPurposes.value = [ResourcePurpose.Production];
  processLoadProblem.value = false;
  resourceSaveProblem.value = false;
}

async function onAdd() {
  const result = await form.value?.validate();
  if (!result || !result.valid) {
    return;
  }
  showProgress.value = true;
  switch (selectedOption.value.value) {
    case AddProductOptions.Existing:
      await useExistingProduct();
      break;
    case AddProductOptions.New:
      await createNewProduct();
      break;
    case AddProductOptions.NewFromTask:
      await createNewProductFromTask();
      break;
    case AddProductOptions.Empty:
      await createEmpty();
      break;
  }
  showProgress.value = false;
}

async function useExistingProduct() {
  if (resource.value) {
    const task = await createProductTemplateDraftForResource(resource.value);
    if (task) {
      closeWithProductTemplateDraft(task);
    }
  }
}

async function createEmpty() {
  const task = createEmptyProductTemplateDraft();
  closeWithProductTemplateDraft(task);
}

function closeWithProductTemplateDraft(task: ProductTemplateDraft) {
  close();
  emit("dialog-result", task);
}

function createProductTemplateDraftFromTemplates(productTemplates: ProductTemplateDetailsDto[]) {
  const root = productTemplates.find((x) => x.id === x.rootTaskId);
  if (!root) {
    throw new Error("There is no root template");
  }
  if (root.resourceId !== resource.value?.id) {
    throw new Error("Template is not referencing selected resource");
  }
  return createDraftFromTemplate(root, productTemplates, true);
}

function close() {
  modelValue.value = false;
}

async function createNewProduct() {
  let productTemplateDraft: ProductTemplateDraft | null = null;
  if (resource.value) {
    productTemplateDraft = await createProductTemplateDraftForResource(resource.value, false);
    if (!productTemplateDraft) {
      snackbarsStore.createSnackbar({
        message: $t("processDraft-addProductDialog-cannotFindValidTemplateForMaterialError-message", { $: "Could not find valid template for source material." }),
        type: "error",
        closeable: true,
        position: "top-right"
      });
      processLoadProblem.value = true;
      form.value?.validate();
      return;
    }
  }

  const newResource = await createMaterial(resourceName.value ?? "", resourceBusinessId.value ?? "");
  if (!newResource) return;

  if (productTemplateDraft && resource.value) {
    replaceTemplateResource(productTemplateDraft, resource.value, newResource);
  } else {
    productTemplateDraft = createStubProductTemplateDraftForResource(newResource);
  }
  closeWithProductTemplateDraft(productTemplateDraft);
}

async function createProductTemplateDraftForResource(res: ResourceInfoDto, shouldCreateEmpty = true) {
  const response = await ApiService.productTemplates.getCurrentForResource(res.id, res.scenarioId);

  if (response.data && response.data.templates && response.data.templates.length > 0) {
    return createProductTemplateDraftFromTemplates(response.data.templates);
  }
  if (shouldCreateEmpty) {
    return createStubProductTemplateDraftForResource(res);
  }
  return null;
}

async function createNewProductFromTask() {
  const newResource = await createMaterial(`${props.targetProduct.name} ${taskName.value}`, `${props.targetProduct.businessId} ${taskName.value}`);
  if (newResource) {
    if (resource.value) {
      const productTemplateDraft = await createProductTemplateDraftForResource(resource.value, false);
      if (!productTemplateDraft) {
        snackbarsStore.createSnackbar({
          message: $t("processDraft-addProductDialog-cannotFindValidTemplateForMaterialError-message", { $: "Could not find valid template for source material." }),
          type: "error",
          closeable: true,
          position: "top-right"
        });
        processLoadProblem.value = true;
        form.value?.validate();
        return;
      }
      replaceTemplateResource(productTemplateDraft, resource.value, newResource);
      productTemplateDraft.name = taskName.value ?? "";
      closeWithProductTemplateDraft(productTemplateDraft);
    } else {
      closeWithProductTemplateDraft(createStubProductTemplateDraftForResource(newResource, taskName.value));
    }
  }
}

async function createMaterial(name: string, businessId: string) {
  try {
    const request = {
      name,
      businessId,
      materialType: MaterialSubType.SemiFinishedOrFinalProduct,
      measurementUnit: selectedUnit.value,
      purposes: selectedPurposes.value,
      plannable: true,
      scenarioId: scenarioStore.selectedScenario?.id
    };
    const result = await ApiService.materials.create(request);
    const result2 = await ApiService.materials.getSingle(result.data.resourceId, result.data.scenarioId);
    return createMaterialInfo(result2.data);
  } catch (e) {
    console.error(e);
    snackbarsStore.createSnackbar({
      message: $t("processDraft-addProductDialog-cannotCreateMaterialError-message", { $: "Could not create new material. Please check if Business ID is unique" }),
      type: "error",
      closeable: true,
      position: "top-right"
    });
    resourceSaveProblem.value = true;
    form.value?.validate();
  }
  return null;
}

function resourceSaveProblemRule(v: any): boolean | string {
  return !resourceSaveProblem.value || $t("processDrafts-edit-resourceSaveProblem-message", { $: "Must be unique" });
}

function processLoadProblemRule(v: any): boolean | string {
  return !processLoadProblem.value || $t("processDrafts-edit-processLoadProblem-message", { $: "Cannot load valid process" });
}
</script>

<template>
  <v-dialog v-model="modelValue" :persistent="true" content-class="add-product-template-dialog">
    <v-form ref="form" v-model="valid" @submit.prevent="">
      <v-card class="pa-8">
        <v-card-title class="d-flex align-center justify-space-between">
          <div class="text-h4">{{ $t("processDrafts-addProductDialog-title-label", { $: "Create product template draft" }) }}</div>
          <v-icon @click="onCancel">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-chip-group v-model="selectedOption" mandatory class="d-flex justify-center">
              <v-chip
                v-for="option in options"
                :key="option.value"
                :value="option"
                :class="option.value === selectedOption?.value ? 'bg-primary' : 'bg-indigo-lighten-5 text-primary'"
                size="large"
              >
                {{ option.title }}
              </v-chip>
            </v-chip-group>
          </v-row>
          <v-row>
            <v-col v-if="showNewFromResource" cols="6">
              <v-text-field
                v-model="resourceBusinessId"
                variant="outlined"
                density="compact"
                hide-details="auto"
                :rules="businessIdRules"
                :label="$t('processDraft-addProductDialog-resourceBusinessId-label', { $: 'Resource Business ID' })"
              />
            </v-col>
            <v-col v-if="showNewFromResource" cols="6">
              <v-text-field
                v-model="resourceName"
                variant="outlined"
                density="compact"
                hide-details="auto"
                :label="$t('processDraft-addProductDialog-resourceName-label', { $: 'Resource Name' })"
              />
            </v-col>
            <v-col v-if="showNewFromTask" cols="12">
              <v-text-field
                v-model="taskName"
                variant="outlined"
                density="compact"
                hide-details="auto"
                :rules="taskNameRules"
                :label="$t('processDraft-addProductDialog-taskName-label', { $: 'Task Name' })"
              />
            </v-col>
          </v-row>
          <v-row v-if="showNew">
            <v-col cols="12">
              <v-select
                v-model="selectedPurposes"
                :items="resourcePurposes"
                multiple
                chips
                variant="outlined"
                density="compact"
                hide-details="auto"
                :rules="purposesRules"
                :label="$t('processDraft-addProductDialog-resourcePurposes-label', { $: 'Resource Purposes' })"
              />
            </v-col>
            <v-col cols="6">
              <v-select
                v-model="selectedUnit"
                :items="measurementUnits"
                variant="outlined"
                density="compact"
                hide-details="auto"
                :rules="[requiredRule]"
                :label="$t('processDraft-addProductDialog-resourceQuantityUnit-label', { $: 'Measurement Unit' })"
              />
            </v-col>
            <v-col cols="6">
              <ResourcePicker
                v-model="resource"
                class="flex-1-1-100"
                :readonly="false"
                :rules="resourceRules"
                :hide-details="'auto'"
                :label="$t('processDraft-addProductDialog-product-label', { $: 'Source Product' })"
                :resource-types="[ResourceType.Material]"
                :resource-sub-types="[ResourceSubType.MaterialSemiFinishedOrFinalProduct]"
              />
            </v-col>
          </v-row>
          <v-row v-else-if="showResourceSelector">
            <v-col cols="12">
              <ResourcePicker
                v-model="resource"
                class="flex-1-1-100"
                :readonly="false"
                :rules="resourceRules"
                :hide-details="'auto'"
                :label="$t('processDraft-addProductDialog-product-label', { $: 'Source Product' })"
                :resource-types="[ResourceType.Material]"
                :resource-sub-types="[ResourceSubType.MaterialSemiFinishedOrFinalProduct]"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col cols="6">
              <v-btn class="ma-4" variant="elevated" color="secondary" block @click="onCancel">
                {{ $t("processDrafts-addProductDialog-cancel-action", { $: "Cancel" }) }}
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn class="ma-4" variant="elevated" color="primary" block :loading="showProgress" @click="onAdd">
                {{ $t("processDrafts-addProductDialog-add-action", { $: "Add" }) }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<style lang="scss">
.add-product-template-dialog {
  width: 30vw !important;
}

@media screen and (max-width: 1900px) {
  .add-product-template-dialog {
    width: 50vw !important;
  }
}

@media screen and (max-width: 1450px) {
  .add-product-template-dialog {
    width: 75vw !important;
  }
}
</style>
