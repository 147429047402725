import {defineStore} from "pinia";
import {Locale} from "@/i18n";

type TranslationParams = { [id: string] : any };

enum TranslationStatus {
  Missing,
  Found
}

class TranslationRequest {
  private _key: string;
  private _params: TranslationParams;
  private _status: TranslationStatus;
  private _url: string;
  private _time: string;

  public constructor(key: string, params: TranslationParams, status: TranslationStatus) {
    this._key = key;
    this._params = params;
    this._status = status;
    this._url = window.location.href;
    this._time = new Date().toString()
  }

  public static Missing(key: string, params: TranslationParams) : TranslationRequest {
    return new TranslationRequest(key, params, TranslationStatus.Missing)
  }

  public get key() : string {
    return this._key;
  }

  public get params() : TranslationParams {
    return this._params;
  }

  public get status() : TranslationStatus {
    return this._status;
  }

  get url(): string {
    return this._url;
  }

  get time(): string {
    return this._time;
  }
}

class LocaleTranslationRequests {
  private _locale: string;
  private _requests: TranslationRequest[];

  public constructor(locale: string) {
    this._locale = locale;
    this._requests = [];
  }

  public get requests(): TranslationRequest[] {
    return this._requests;
  }
}

interface State {
  missing: { [id: string] : LocaleTranslationRequests },
  currentLocale: string
}

export const useTranslationsStore = defineStore("translationsStore", {
  state: (): State => ({
    missing: {},
    currentLocale: ""
  } as State),
  // getters: {
  //   currentLocale: (state): string => {
  //     return state.currentLocale
  //   }
  // },
  actions: {
    notifyLocaleChange(locale: string) {
      console.log(`Locale changed to: ${locale}`);
      this.$state.currentLocale = locale;
    },
    reportMissingTranslation(locale: string, key: string, params: TranslationParams) {
      if (!this.$state.missing[locale]) {
        this.$state.missing[locale] = new LocaleTranslationRequests(locale);
      }
      const requests = this.$state.missing[locale].requests;
      requests.push(TranslationRequest.Missing(key, params));
    },
    createMissingTranslationKeysReport: function () {
      const report: { [id: string]: object } = {};
      const localeRequests = this.$state.missing;
      for (const localeKey in localeRequests) {
        const keysIndex: { [id: string]: object } = {};
        localeRequests[localeKey].requests
          .filter(request => request.status === TranslationStatus.Missing)
          .sort((a, b) => a.key > b.key ? 1 : -1)
          .forEach(request => {
            if (!keysIndex[request.key]) {
              let reportValue = !!request.params ? request.params : {};
              reportValue["_url"] = request.url;
              reportValue["_time"] = request.time;
              keysIndex[request.key] = reportValue;
            }
          });
        report[localeKey] = keysIndex;
      }
      return report;
    },
    createMissingTranslationKeysJsonTemplate: function () {
      const template: { [id: string]: object } = {};
      const localeRequests = this.$state.missing;
      for (const localeKey in localeRequests) {
        const keysIndex: { [id: string]: object } = {};
        localeRequests[localeKey].requests
          .filter(request => request.status === TranslationStatus.Missing)
          .sort((a, b) => a.key > b.key ? 1 : -1)
          .forEach(request => {
            if (!keysIndex[request.key]) {
              const templateValue = !!request.params ? (request.params["$"] ?? "") : "";
              keysIndex[request.key] = templateValue;
            }
          });
        template[localeKey] = keysIndex;
      }
      return template;
    }
  }
})
