<script lang="ts" setup>
import { enumToEditorEntries, enumToEditorEntriesExcluding, enumValueEntryWithLocaleComparator, translateEditorEntries } from "@/components/Grid/ColumnTypes";
import { tagsTypeColumnFilterParams } from "@/components/Grid/Filters/TagsTypeColumnFilters";
import GridWrapper from "@/components/Grid/GridWrapper.vue";
import { GridWrapperComponent } from "@/components/Grid/GridWrapperComponent";
import { useReferenceGrid } from "@/components/Grid/UseReferenceGrid";
import { OrganizationServerSideDataSource } from "@/components/Organizations/OrganizationServerSideDataSource";
import { joinArrayOfStrings } from "@/components/ValueCellEditor/CommonFormatters";
import { requiredRule } from "@/components/ValueCellEditor/CommonValidationRules";
import { translateOrganizationType } from "@/composables/translateEnum";
import { $t } from "@/i18n";
import { useAuthStore } from "@/store/AuthStore";
import { useTagsStore } from "@/store/TagsStore";
import { OrganizationDto, OrganizationType } from "@masta/generated-model";
import { ColDef, GetContextMenuItemsParams, KeyCreatorParams, ValueFormatterParams } from "ag-grid-community";
import { storeToRefs } from "pinia";
import { reactive, Ref, ref } from "vue";
import { useRouter } from "vue-router";


interface Props {
  referenceValue?: string;
}

const $emits = defineEmits(["rowSelected", "deleteAction", "rowDoubleClicked"]);
const props = defineProps<Props>();

const serverSideDataSource = reactive(new OrganizationServerSideDataSource("organizations"));

const tagsStore = useTagsStore();

const gridWrapperRef = ref<GridWrapperComponent>();
const defaultColumnDef = ref({
  filter: false,
  floatingFilter: true,
  filterParams: {
    applyMiniFilterWhileTyping: true
  },
  sortable: true,
  resizable: true
});

async function onDeleteAction() {
  $emits("deleteAction");
}

function onPrepareColumns(columnDefs: Ref<ColDef[]>) {
  columnDefs.value = [
    {
      field: "name",
      editable: true,
      sortable: true,
      filter: "agTextColumnFilter",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      cellEditorParams: {
        placeholder: $t("organization-edit-name-label", { $: "Name" })
      },
      headerValueGetter: (_: any) => $t("organization-list-name-label", { $: "Name" }),
      floatingFilterComponentParams: {
        placeholder: $t("organization-edit-name-label", { $: "Name" })
      }
    },
    {
      field: "types",
      editable: true,
      sortable: true,
      filter: "agSetColumnFilter",
      filterParams: {
        valueFormatter: (params: ValueFormatterParams) => params.value.key,
        keyCreator: (params: KeyCreatorParams) => params.value.value,
        values: translateEditorEntries(enumToEditorEntries(OrganizationType), translateOrganizationType),
        comparator: enumValueEntryWithLocaleComparator
      },
      type: ["enumTypeColumn", "setFloatingFilterColumnType"],
      cellEditorParams: {
        multiple: true,
        rules: [(v: any) => (v !== undefined && v !== null && Array.isArray(v) && v.length > 0) || $t("organization-edit-valueRequired-popup", { $: "Required" })],
        placeholder: $t("organization-edit-type-label", { $: "Type" }),
        values: translateEditorEntries(enumToEditorEntriesExcluding(OrganizationType, [OrganizationType.Unknown]), translateOrganizationType)
      },
      valueFormatter: (params: any) => (params.data.types ?? []).map(translateOrganizationType).join(", "),
      headerValueGetter: (_: any) => $t("organization-list-types-label", { $: "Types" })
    },
    {
      field: "businessId",
      editable: true,
      sortable: true,
      filter: "agTextColumnFilter",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      cellEditorParams: {
        rules: [requiredRule],
        placeholder: $t("organization-edit-businessId-label", { $: "Business ID" })
      },
      headerValueGetter: (_: any) => $t("organization-list-businessId-label", { $: "Business ID" }),
      floatingFilterComponentParams: {
        placeholder: $t("organization-edit-businessId-label", { $: "Business ID" })
      }
    },
    {
      field: "tags",
      type: ["tagsPickerTypeColumn", "setFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("organization-list-tags-label", { $: "Tags" }),
      filter: "agSetColumnFilter",
      filterParams: tagsTypeColumnFilterParams(tagsStore),
      editable: true,
      resizable: true,
      valueFormatter: (params: ValueFormatterParams) => joinArrayOfStrings(params.data.tags),
      cellEditorParams: {
        placeholder: $t("organization-edit-tags-label", { $: "Tags" })
      }
    }
  ];
}

const $router = useRouter();

function getContextMenuItems(params: GetContextMenuItemsParams<OrganizationDto>) {
  return [
    {
      name: "Show related orders",
      icon: "<i class=\"mdi mdi-basket-outline\"/>",
      action: () => {
        const ids = params.node?.data?.id ? [params.node?.data?.id] : [];
        $router.push({
          path: `/operations/orders`,
          query: {
            ctx_organizationId: ids
          }
        });
      }
    }
  ];
}

const refGrid = useReferenceGrid({
  $emits,
  isRowSelected: (data: any) => data.businessId === props.referenceValue
});
</script>

<template>
  <grid-wrapper
    ref="gridWrapperRef"
    row-selection="multiple"
    :default-col-def="defaultColumnDef"
    :context-menu-items="getContextMenuItems"
    identifier="organizations"
    edit-btn
    create-btn
    duplicate-btn
    delete-btn
    refresh-btn
    server-side
    :server-side-datasource="serverSideDataSource"
    @delete-action="onDeleteAction"
    @prepare-columns="onPrepareColumns"
    @ready="refGrid.onGridReady"
  />
</template>

<style lang="scss" scoped></style>
