<script lang="ts" setup>
import { useRoute, useRouter } from "vue-router";
import { ref, watch } from "vue";
import MasterDetailLayout from "@/components/Layout/MasterDetailLayout.vue";
import Breadcrumbs from "@/components/Layout/Breadcrumbs.vue";
import SupersetEmbeddedDashboard from "@/components/Reports/SupersetEmbeddedDashboard.vue";

const router = useRouter();
const dashboardId = ref<any>();
watch(
  router.currentRoute,
  ({ meta }) => {
    dashboardId.value = meta?.dashboardId;
  },
  { immediate: true }
);
</script>

<template>
  <master-detail-layout>
    <template #master>
      <v-card elevation="0" class="flexcard fill-height">
        <v-card-title>
          <breadcrumbs>{{ $t("dashboard-view-reports-title", { $: "Dashboard" }) }}: {{ router.currentRoute.value.name }}</breadcrumbs>
        </v-card-title>
        <v-card-text class="fill-height">
          <v-row class="fill-height">
            <v-col cols="12">
              <v-progress-linear v-if="!dashboardId" indeterminate />
              <superset-embedded-dashboard v-else :id="dashboardId" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
  </master-detail-layout>
</template>

<style lang="scss" scoped></style>
