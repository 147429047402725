<script setup lang="ts">
import { useSlots } from "vue";

const expanded = defineModel<boolean>({ required: false, default: false});

const props = withDefaults(
  defineProps<{
    expandable?: boolean;
    readonly?: boolean;
    deletable?: boolean;
  }>(),
  {
    expandable: false,
    readonly: false,
    deletable: true
  }
);

defineEmits(["delete", "move-up", "move-down"]);

const slots = useSlots();

</script>

<template>
  <div class="node">
    <div class="node-header" :class="expandable">
      <v-slide-x-transition>
        <v-btn
          v-if="expandable"
          density="compact"
          variant="flat"
          class="mr-2 mt-5"
          size="large"
          :icon="expanded ? 'mdi-chevron-down-circle' : 'mdi-chevron-right-circle'"
          @click="() => (expanded = !expanded)"
        ></v-btn>
      </v-slide-x-transition>
      <div class="node-header-content">
        <slot name="header"></slot>
      </div>
      <div class="mt-4">
        <v-slide-x-transition group>
          <v-btn key="up" :disabled="props.readonly" density="compact" variant="text" color="primary" icon="mdi-arrow-up" @click="$emit('move-up')"></v-btn>
          <v-btn key="down" :disabled="props.readonly" density="compact" variant="text" color="primary" icon="mdi-arrow-down" @click="$emit('move-down')"></v-btn>
        </v-slide-x-transition>
        <v-btn v-if="deletable" :disabled="props.readonly" density="compact" variant="text" color="primary" icon="mdi-delete" @click="$emit('delete')"></v-btn>
      </div>
    </div>
    <v-slide-y-transition>
      <div v-if="expandable && expanded" class="node-body">
        <slot></slot>
      </div>
      <div v-else-if="!expandable && !expanded" class="node-body-expanded">
        <slot></slot>
      </div>
    </v-slide-y-transition>
  </div>
</template>

<style scoped lang="scss">
.node {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: rgba(var(--v-theme-gantt-grey), 0.6);
  border-radius: 0.3125rem;
  border: 1px solid rgb(var(--v-theme-gantt-grey));
  flex: 1 auto;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

  .node-header {
    display: flex;
    width: 100%;
    padding: 0.5rem;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
    border-radius: 0.3125rem 0.3125rem 0rem 0rem;
    background-color: rgb(var(--v-theme-white, #fff));
    box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);

    .node-header-content {
      flex: 1;
      display: flex;
      margin-inline-end: auto;
    }
  }

  .node-body {
    display: flex;
    width: 100%;
    padding: 1.5rem 1.5rem 1.5rem 4.5rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
  }

  .node-body-expanded {
    margin-top: 0.125rem;
    display: flex;
    width: 100%;
    background-color: rgb(var(--v-theme-white, #fff));
  }
}
</style>
