<script lang="ts" setup>
import GridWrapper from "@/components/Grid/GridWrapper.vue";
import { ModelInstanceIdDto, ModelInstanceStatus, TotalStatus } from "@masta/generated-model";
import { inject, Ref, ref } from "vue";
import { GridWrapperComponent } from "@/components/Grid/GridWrapperComponent";
import { ColDef, GridApi, ValueFormatterParams } from "ag-grid-community";
import { $t } from "@/i18n";
import { $dateTimeFormatterSymbol, DateFormatter } from "@masta/shared";
import { groupEnumValueRowKeyCreator } from "@/components/Grid/Filters/ColumnFilters";
import { enumToEditorEntries, enumValueEntryWithLocaleComparator, translateEditorEntries } from "@/components/Grid/ColumnTypes";
import { translateModelInstanceStatus } from "@/composables/translateEnum";

const props = defineProps<{
  modelInstanceIds: ModelInstanceIdDto[];
}>();

const emit = defineEmits<{
  (e: "selectionChanged", ids: ModelInstanceIdDto[]): void;
}>();
const $dateTimeFormatter = inject<DateFormatter>($dateTimeFormatterSymbol)!;
const selectedRow = ref<ModelInstanceIdDto[]>([]);
const gridWrapperRef = ref<GridWrapperComponent>();
const basicColumnDefs = ref<ColDef[]>([
  {
    field: "businessId",
    editable: false,
    resizable: true,
    sortable: true,
    type: "textFloatingFilterColumnType",
    filter: "agTextColumnFilter",
    floatingFilter: true,
    filterParams: {
      applyMiniFilterWhileTyping: true
    },
    headerValueGetter: (_: any) => $t("modelInstanceId-list-businessId-label", { $: "BusinessId" })
  },
  {
    field: "createdAt",
    type: ["dateTimeTypeColumn"],
    editable: false,
    resizable: true,
    sortable: true,
    floatingFilter: true,
    filterParams: {
      applyMiniFilterWhileTyping: true
    },
    filter: "agDateColumnFilter",
    headerValueGetter: (_: any) => $t("modelInstanceId-list-createdAt-label", { $: "Created At" }),
    valueFormatter: (params) => (params.data && params.data.createdAt ? $dateTimeFormatter(params.data.createdAt) : "")
  },
  {
    field: "createdBy",
    editable: false,
    resizable: true,
    sortable: true,
    type: "textFloatingFilterColumnType",
    filter: "agTextColumnFilter",
    floatingFilter: true,
    filterParams: {
      applyMiniFilterWhileTyping: true
    },
    headerValueGetter: (_: any) => $t("modelInstanceId-list-createdBy-label", { $: "Created By" }),
    floatingFilterComponentParams: {
      placeholder: $t("modelInstanceId-list-createdBy-label", { $: "Created By" })
    }
  },
  {
    field: "modifiedAt",
    type: ["dateTimeTypeColumn"],
    editable: false,
    resizable: true,
    sortable: true,
    floatingFilter: true,
    filterParams: {
      applyMiniFilterWhileTyping: true
    },
    filter: "agDateColumnFilter",
    headerValueGetter: (_: any) => $t("modelInstanceId-list-modifiedAt-label", { $: "Modified At" }),
    valueFormatter: (params) => (params.data && params.data.modifiedAt ? $dateTimeFormatter(params.data.modifiedAt) : "")
  },
  {
    field: "modifiedBy",
    editable: false,
    resizable: true,
    sortable: true,
    type: "textFloatingFilterColumnType",
    filter: "agTextColumnFilter",
    floatingFilter: true,
    filterParams: {
      applyMiniFilterWhileTyping: true
    },
    headerValueGetter: (_: any) => $t("modelInstanceId-list-modifiedBy-label", { $: "Modified By" }),
    floatingFilterComponentParams: {
      placeholder: $t("modelInstanceId-list-modifiedBy-label", { $: "Modified By" })
    }
  },
  {
    field: "status",
    headerValueGetter: (_: any) => $t("modelInstanceId-list-status-label", { $: "Status" }),
    editable: false,
    sortable: true,
    filter: "agSetColumnFilter",
    type: "setFloatingFilterColumnType",
    floatingFilter: true,
    filterParams: {
      applyMiniFilterWhileTyping: true,
      keyCreator: groupEnumValueRowKeyCreator,
      valueFormatter: (params: ValueFormatterParams) => params.value.key,
      values: translateEditorEntries(enumToEditorEntries(ModelInstanceStatus), translateModelInstanceStatus),
      comparator: enumValueEntryWithLocaleComparator
    },
    valueFormatter: (params: any) => {
      return translateModelInstanceStatus(params.data?.status);
    },
    floatingFilterComponentParams: {
      placeholder: $t("modelInstanceId-list-status-label", { $: "Status" }),
      values: translateEditorEntries(enumToEditorEntries(ModelInstanceStatus), translateModelInstanceStatus)
    }
  }
]);
let columnDefs: Ref<any> = null!;

function onPrepareColumns(_columnDefs: any) {
  _columnDefs.value = basicColumnDefs.value;
  columnDefs = _columnDefs;
}

function onSelectionChanged({ api }: { api: GridApi }) {
  selectedRow.value = api.getSelectedRows();
  emit("selectionChanged", selectedRow.value);
}
</script>

<template>
  <grid-wrapper
    ref="gridWrapperRef"
    identifier="model-instances"
    row-selection="single"
    :enable-range-selection="false"
    :grid-data="modelInstanceIds"
    @prepare-columns="onPrepareColumns"
    @selection-changed="onSelectionChanged"
  />
</template>
