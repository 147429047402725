import { GetRowIdParams } from "ag-grid-community";

export const useServerSideGrid = (useServerSide: boolean | undefined, dynamicAttrs: any) => {
  if (!useServerSide) return;
  dynamicAttrs.pagination = true;
  dynamicAttrs.paginationAutoPageSize = false;
  dynamicAttrs.serverSideInfiniteScroll = true;
  dynamicAttrs.paginationPageSize = 100;
  dynamicAttrs.paginationPageSizeSelector = [20, 50, 100, 500, 1000];
  dynamicAttrs.cacheBlockSize = 100;
  dynamicAttrs.getRowId = (params: GetRowIdParams) => params.data.id;
};
