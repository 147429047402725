<script setup lang="ts">
import { inject, reactive, Ref, ref } from "vue";
import { GridWrapperComponent } from "@/components/Grid/GridWrapperComponent";
import GridWrapper from "@/components/Grid/GridWrapper.vue";
import { CellClassParams, CellStyle, ColDef, ValueFormatterParams, KeyCreatorParams } from "ag-grid-community";
import { $t } from "@/i18n";
import { BusinessActionsServerSideDataSource } from "@/components/BusinessActions/BusinessActionsServerSideDataSource";
import { translateBusinessActionAreaType, translateBusinessActionResultStatusType, translateBusinessActionStatusType } from "@/composables/translateEnum";
import { $dateTimeFormatterSymbol, DateFormatter } from "@masta/shared";
import { BusinessActionArea, BusinessActionDto, BusinessActionResultStatus, BusinessActionStatus } from "@masta/generated-model";
import { enumToEditorEntries, enumValueEntryWithLocaleComparator, translateEditorEntries } from "@/components/Grid/ColumnTypes";

const gridWrapperRef = ref<GridWrapperComponent>();
const serverSideDataSource = reactive(new BusinessActionsServerSideDataSource("business-actions"));
const $dateTimeFormatter = inject<DateFormatter>($dateTimeFormatterSymbol)!;

const defaultColumnDef = ref({
  filter: false,
  floatingFilter: true,
  filterParams: {
    applyMiniFilterWhileTyping: true
  },
  sortable: true,
  resizable: true,
});

function onPrepareColumns(columnDefs: Ref<ColDef[]>) {
  columnDefs.value = [
    {
      field: "id",
      type: "textInputTypeColumn",
      headerValueGetter: (_: any) => $t("businessActions-list-id-label", { $: "Id" }),
      sortable: true,
      hide: true,
      filter: "agTextColumnFilter"
    },
    {
      field: "publishedAt",
      sortable: true,
      filter: "agDateColumnFilter",
      resizable: true,
      valueFormatter: (params: any) => {
        return $dateTimeFormatter(params.data.publishedAt);
      },
      type: "datepickerTypeColumn",
      headerValueGetter: (_: any) => $t("orderLine-list-publishedAt-label", { $: "Published At" })
    },
    {
      field: "finishedAt",
      sortable: true,
      filter: "agDateColumnFilter",
      resizable: true,
      valueFormatter: (params: any) => {
        return $dateTimeFormatter(params.data.finishedAt);
      },
      type: "datepickerTypeColumn",
      headerValueGetter: (_: any) => $t("orderLine-list-finishedAt-label", { $: "Finished At" })
    },
    {
      field: "username",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("businessActions-list-username-label", { $: "User" }),
      sortable: true,
      filter: "agTextColumnFilter",
      floatingFilterComponentParams: {
        placeholder: $t("businessActions-list-username-label", { $: "User" })
      }
    },
    {
      field: "area",
      type: ["enumTypeColumn", "setFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("businessActions-list-area-label", { $: "Area" }),
      sortable: true,
      valueFormatter: (params: any) => translateBusinessActionAreaType(params.value),
      filter: "agSetColumnFilter",
      filterParams: {
        valueFormatter: (params: ValueFormatterParams) => params.value.key,
        keyCreator: (params: KeyCreatorParams) => params.value.value,
        values: translateEditorEntries(enumToEditorEntries(BusinessActionArea), translateBusinessActionAreaType),
        comparator: enumValueEntryWithLocaleComparator
      }
    },
    {
      field: "status",
      type: ["enumTypeColumn", "setFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("businessActions-list-status-label", { $: "Status" }),
      sortable: true,
      cellStyle: statusColumnCellStyle,
      valueFormatter: (params: any) => translateBusinessActionStatusType(params.value),
      filter: "agSetColumnFilter",
      filterParams: {
        valueFormatter: (params: ValueFormatterParams) => params.value.key,
        keyCreator: (params: KeyCreatorParams) => params.value.value,
        values: translateEditorEntries(enumToEditorEntries(BusinessActionStatus), translateBusinessActionStatusType),
        comparator: enumValueEntryWithLocaleComparator
      }
    },
    {
      field: "resultStatus",
      type: ["enumTypeColumn", "setFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("businessActions-list-resultStatus-label", { $: "Result Status" }),
      sortable: true,
      cellStyle: resultStatusColumnCellStyle,
      valueFormatter: (params: any) => translateBusinessActionResultStatusType(params.value),
      filter: "agSetColumnFilter",
      filterParams: {
        valueFormatter: (params: ValueFormatterParams) => params.value.key,
        keyCreator: (params: KeyCreatorParams) => params.value.value,
        values: translateEditorEntries(enumToEditorEntries(BusinessActionResultStatus), translateBusinessActionResultStatusType),
        comparator: enumValueEntryWithLocaleComparator
      }
    },
    {
      field: "commandType",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("businessActions-list-commandType-label", { $: "Command Type" }),
      sortable: true,
      filter: "agTextColumnFilter",
      floatingFilterComponentParams: {
        placeholder: $t("businessActions-list-commandType-label", { $: "Command Type" })
      }
    },
    {
      field: "command",
      type: ["longTextTypeColumn", "textFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("businessActions-list-command-label", { $: "Command" }),
      sortable: true,
      filter: "agTextColumnFilter",
      floatingFilterComponentParams: {
        placeholder: $t("businessActions-list-command-label", { $: "Command" }),
      }
    },
    {
      field: "resultType",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("businessActions-list-resultType-label", { $: "Result Type" }),
      sortable: true,
      filter: "agTextColumnFilter",
      floatingFilterComponentParams: {
        placeholder: $t("businessActions-list-resultType-label", { $: "Result Type" }),
      }
    },
    {
      field: "result",
      type: ["longTextTypeColumn", "textFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("businessActions-list-result-label", { $: "Result" }),
      sortable: true,
      filter: "agTextColumnFilter",
      floatingFilterComponentParams: {
        placeholder: $t("businessActions-list-result-label", { $: "Result" })
      }
    }
  ];
}

function statusColumnCellStyle(params: CellClassParams<BusinessActionDto, BusinessActionStatus>): CellStyle | null | undefined {
  switch(params.value) {
    case BusinessActionStatus.Waiting:
      return { backgroundColor: "rgb(255, 205, 255, 0.7)", color: "#6600cc" };
    case BusinessActionStatus.Running:
      return { backgroundColor: "rgb(204, 0, 204, 0.7)", color: "#fff" };
    case BusinessActionStatus.Finished:
      return { backgroundColor: "rgb(122, 99, 255, 0.7)", color: "#000" };
    default:
      return {};
  }
}

function resultStatusColumnCellStyle(params: CellClassParams<BusinessActionDto, BusinessActionResultStatus>): CellStyle | null | undefined {
  switch(params.value) {
    case BusinessActionResultStatus.Unknown:
      return { backgroundColor: "rgb(75, 0, 130, 0.7)", color: "#6600cc"};
    case BusinessActionResultStatus.Success:
      return { backgroundColor: "rgb(122, 99, 255, 0.7)", color: "#fff" };
    case BusinessActionResultStatus.Warning:
      return { backgroundColor: "rgb(204, 0, 204, 0.7)", color: "#fff" };
    case BusinessActionResultStatus.Error:
      return { backgroundColor: "rgb(75, 0, 130, 0.7)", color: "#fff" };
    default:
      return {};
  }
}
</script>

<template>
  <grid-wrapper
    ref="gridWrapperRef"
    identifier="business-actions"
    :server-side="true"
    :server-side-datasource="serverSideDataSource"
    refresh-btn
    :default-col-def="defaultColumnDef"
    @prepare-columns="onPrepareColumns"
  />
</template>
