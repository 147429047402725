<script lang="ts" setup>
import GridWrapper from "@/components/Grid/GridWrapper.vue";
import { ref } from "vue";
import { GridWrapperComponent } from "@/components/Grid/GridWrapperComponent";
import ApiService from "@/services/api";
import { GridApi } from "ag-grid-community";
import { useReportsStore } from "@/store/ReportsStore";
import { storeToRefs } from "pinia";
import { useAuthStore } from "@/store/AuthStore";
import { useSnackbarsStore } from "@/store/SnackbarsStore";
import { $t } from "@/i18n";

const snackbarsStore = useSnackbarsStore();
const authStore = useAuthStore();
const reportsStore = useReportsStore();
const { reports } = storeToRefs(reportsStore);

const gridWrapperRef = ref<GridWrapperComponent>();
const downloadRef = ref();

const isRowSelected = ref(false);

async function onFetchData() {
  await reportsStore.fetch();
}

async function onRefreshAction() {
  await onFetchData();
}

function onPrepareColumns(columnDefs: any) {
  columnDefs.value = [
    {
      field: "settings.name",
      editable: false,
      sortable: true,
      filter: true,
      headerValueGetter: (_: any) => $t("report-list-settingsName-label", { $: "Name" })
    },
    {
      field: "settings.identifier",
      editable: false,
      sortable: true,
      filter: true,
      headerValueGetter: (_: any) => $t("report-list-settingsIdentifier-label", { $: "Identifier" })
    },
    {
      field: "variant",
      editable: false,
      sortable: true,
      filter: true,
      headerValueGetter: (_: any) => $t("report-list-variant-label", { $: "Variant" })
    },
    {
      field: "settings.version",
      headerName: "Version",
      editable: false,
      sortable: true,
      filter: true,
      headerValueGetter: (_: any) => $t("report-list-settingsVersion-label", { $: "Version" })
    },
    {
      field: "settings.parameters",
      headerName: "Parameters",
      editable: false,
      sortable: true,
      filter: true,
      valueGetter: (params: any) => JSON.stringify(params?.data?.settings?.parameters),
      headerValueGetter: (_: any) => $t("report-list-settingsParameters-label", { $: "Parameters" })
    }
  ];
}

function onSelectionChanged({ api }: { api: GridApi }) {
  isRowSelected.value = api.getSelectedRows().length > 0;
}

async function generateReport() {
  if (isRowSelected.value) {
    const rows = gridWrapperRef.value?.gridApi.getSelectedRows() ?? [];
    const report = rows[0];
    await snackbarsStore.createSnackbar({
      message: $t("report-list-generateReportInProgress-message", { reportName: report?.settings?.name, $: "Generating report {reportName}" }),
      closeable: true
    });
    try {
      attachCustomerMetadata(report);
      const response = await ApiService.reports.generateReport(report);

      let fileName = `${report?.settings?.identifier}.pdf`;
      if (response.headers["content-disposition"]) {
        const header = response.headers["content-disposition"];
        fileName = header.substring(header.indexOf('"') + 1, header.lastIndexOf('"'));
        console.log("Exported report name :" + fileName);
      }
      downloadRef.value.href = window.URL.createObjectURL(new Blob([response.data]));
      downloadRef.value.setAttribute("download", fileName);
      downloadRef.value.click();

      await snackbarsStore.createSnackbar({
        message: $t("report-list-generateReportSuccess-message", { reportName: report?.settings?.name, $: "Report {reportName} generated!" }),
        type: "success",
        closeable: true
      });
    } catch (e) {
      console.error(e);
      await snackbarsStore.createSnackbar({
        message: $t("report-list-generateReportError-message", { reportName: report?.settings?.name, $: "Could not generate report {reportName}" }),
        type: "error",
        closeable: true
      });
    }
  }
}

function attachCustomerMetadata(report: any) {
  const user = authStore.getUser;
  const contactId = user?.tenant_customer_contact_id ?? $t("report-list-unknownContactId-input", { $: "unknown" });
  const organizationId = user?.tenant_customer_organization_id ?? $t("report-list-unknownOrganizationId-input", { $: "unknown" });
  report.settings.parameters["contactId"] = contactId;
  report.settings.parameters["organizationId"] = organizationId;
}
</script>

<template>
  <grid-wrapper
    ref="gridWrapperRef"
    refresh-btn
    row-selection="multiple"
    identifier="reports"
    :grid-data="reports"
    @fetch-data="onFetchData"
    @refresh-action="onRefreshAction"
    @prepare-columns="onPrepareColumns"
    @selection-changed="onSelectionChanged"
  >
    <template #custom-buttons>
      <v-tooltip bottom open-delay="300">
        <template #activator="{ props }">
          <v-btn size="small" variant="text" density="compact" v-bind="props" :disabled="!isRowSelected" @click="generateReport">
            <v-icon icon="mdi-chart-line-stacked" class="pr-4"></v-icon>
            {{ $t("report-list-generateReport-tooltip", { $: "Generate report" }) }}
          </v-btn>
        </template>
        <span>{{ $t("report-list-generateReport-tooltip", { $: "Generate report" }) }}</span>
      </v-tooltip>
    </template>
    <template #filter>
      <a ref="downloadRef" style="display: none" />
    </template>
  </grid-wrapper>
</template>

<style lang="scss" scoped></style>
