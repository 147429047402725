<script setup lang="ts">
import { ref } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps<{ uploading: boolean; multiple: boolean }>();

const emits = defineEmits(["upload"]);

const { t: $t } = useI18n();

const isDraggingOver = ref(false);

const handleDragOver = (event: DragEvent) => {
  isDraggingOver.value = true;
  setDragDropEffect(event);
};

const handleDragEnter = (_: DragEvent) => {};

const handleDragLeave = () => {
  isDraggingOver.value = false;
};

const handleDrop = (event: DragEvent) => {
  isDraggingOver.value = false;

  // Handle dropped files
  const files = event.dataTransfer?.files;
  handleDroppedFiles(files);
};

const setDragDropEffect = (event: DragEvent) => {
  // we can filter on the type of file here
  if (event.dataTransfer) event.dataTransfer.dropEffect = "copy";
};

const handleDroppedFiles = (files: FileList | undefined) => {
  // If multiple files are not allowed, take only the first file
  if (!props.multiple && files && files.length > 0) {
    const singleFileList = new DataTransfer();
    singleFileList.items.add(files[0]);
    emits("upload", singleFileList.files);
  } else {
    // If multiple files are allowed, emit all files
    emits("upload", files);
  }
};
</script>

<template>
  <v-card class="drag-drop-area" @dragover.prevent="handleDragOver" @dragenter.prevent="handleDragEnter" @dragleave.prevent="handleDragLeave" @drop.prevent="handleDrop">
    <v-card-text class="position-relative">
      <div class="drag-message" :class="{ dragging: isDraggingOver }">
        <v-fade-transition v-if="!uploading">
          <div class="px-4 w-100 text-center">
            <v-icon class="align-center fill-height middle" size="50px">mdi-file-upload</v-icon>
            <div class="name-label text-body-2">
              {{
                isDraggingOver
                  ? $t("mediaPickerDialog-drop-label", { $: "Drop files to upload" })
                  : $t("mediaPickerDialog-dragAndDropClick-label", { $: "Click or drop files here to upload" })
              }}
            </div>
          </div>
        </v-fade-transition>
        <div v-else class="px-4 w-100 text-center">
          <v-progress-circular indeterminate color="primary" size="50"></v-progress-circular>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<style scoped lang="scss">
.drag-message {
  text-align: center;
  opacity: 0.5;
  pointer-events: none;

  &.dragging {
    opacity: 1;
  }
}
</style>
