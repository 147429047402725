<script lang="ts" setup>
import { MenuItem } from "@/components/MegaMenu/MegaMenuModel";
import { UnwrapRef } from "vue";
import { useRouteParams } from "@vueuse/router";
import { useScenariosStore } from "@/store/ScenariosStore";

interface Props {
  mainMenuItems: UnwrapRef<MenuItem[]>;
}

const props = defineProps<Props>();
const emits = defineEmits(["close"]);

const scenarioId = useRouteParams("scenarioId", () => {
  const scenarioStore = useScenariosStore();
  return scenarioStore.selectedScenario?.id;
});
</script>

<template>
  <div
    v-for="firstLevelMenuItem in mainMenuItems.filter((x) => x.items?.length > 0 && x.items.some((i) => i.items.length > 0)) ?? []"
    :key="firstLevelMenuItem.id"
    class="menu-group"
    data-cy="menu-group"
  >
    <div class="text-body-1 font-weight-regular d-flex align-center no-user-select py-4 ml-2" style="user-select: none">
      <v-icon v-if="firstLevelMenuItem.icon" :icon="firstLevelMenuItem.icon" class="pr-2" />
      <span>{{ firstLevelMenuItem.name }}</span>
    </div>
    <div v-for="secondLevelMenuItem in firstLevelMenuItem.items.filter((x) => x.items?.length > 0) ?? []" :key="secondLevelMenuItem.id">
      <v-list class="pl-0 py-0" density="compact">
        <v-list-item
          v-for="thirdLevelMenuItem in secondLevelMenuItem.items.filter((x) => !!x.route) ?? []"
          :key="thirdLevelMenuItem.id"
          class="text-body-1 py-0 ml-8"
          :ripple="false"
          :to="{ name: thirdLevelMenuItem.route.name, query: thirdLevelMenuItem.query, params: {scenarioId} }"
          @click="emits('close')"
        >
          <div class="d-inline pr-2 text-disabled">
            <v-icon v-if="secondLevelMenuItem.icon" :icon="secondLevelMenuItem.icon" class="pr-2" />
            <span>{{ secondLevelMenuItem.name }}:</span>
          </div>
          {{ thirdLevelMenuItem.name }}
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.menu-group {
  width: 100%;

  :deep(.v-list-item--active),
  :deep(.v-list-item:hover) {
    color: rgb(var(--v-theme-primary), 1);
    font-weight: bold;
  }

  :deep(.v-list-item--active > .v-list-item__overlay),
  :deep(.v-list-item:hover > .v-list-item__overlay) {
    opacity: 0;
  }
}
</style>
