<template>
  <master-detail-layout :detail="detail" :scenario-selector="!embedded" detail-dialog-width="75%" @change="detail = false">
    <template #master>
      <v-card elevation="0" class="flexcard fill-height">
        <v-card-title>
          <breadcrumbs>{{ $t("costcatalogue-view-costcatalogueitems-title", { $: "Cost catalogue" }) }}</breadcrumbs>
        </v-card-title>
        <v-card-text class="fill-height">
          <v-row class="fill-height" no-gutters>
            <v-col cols="12">
              <cost-catalogue-grid @row-selected="onRowSelected" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
    <template #detail>
      <v-card class="overview-container flexcard" elevation="0">
        <v-card-text class="d-flex flex-column pa-0">
          <div class="flex-1-1 d-flex overflow-hidden">
            <cost-catalogue-media-gallery :context-name="DocumentContextNameConsts.costCatalogueItemAttachment" :cost-catalogue-item-id="selectedCostCatalogueItemId" />
          </div>
        </v-card-text>
      </v-card>
    </template>
  </master-detail-layout>
</template>

<script setup lang="ts">
import MasterDetailLayout from "@/components/Layout/MasterDetailLayout.vue";
import Breadcrumbs from "@/components/Layout/Breadcrumbs.vue";
import CostCatalogueGrid from "@/components/CostCatalogue/CostCatalogueGrid.vue";
import { $t } from "@/i18n";
import { ref } from "vue";
import CostCatalogueMediaGallery from "@/components/Media/CostCatalogueMediaGallery.vue";
import { DocumentContextNameConsts } from "@masta/generated-model";

const detail = ref(false);

const selectedCostCatalogueItemId = ref<string>("");

interface CostCatalogueItemsProps {
  embedded?: boolean;
}

const props = defineProps<CostCatalogueItemsProps>();

function onRowSelected(row: any) {
  detail.value = true;
  selectedCostCatalogueItemId.value = row.id as string;
}
</script>

<style lang="scss" scoped></style>
