import { inject, injectable } from "inversify";
import { ActivityTooltipRenderer } from "@masta/gantt2/gantt";
import { GanttSettings } from "@masta/gantt2/core";
import { GanttActivityDisplayPropertySetting, OrganizationDto, TotalStatus } from "@masta/generated-model";
import { StatusColorHelper } from "@/components/Gantt/ResourcesGantt/Renderers/StatusColorHelper";
import {
  DemandExecutionResourceCapacityActivity,
  DemandSchedulingResourceCapacityActivity,
  EnhancedGanttResourceCapacityDto,
  InterruptionExecutionResourceCapacityActivity,
  ResourceCapacityActivity,
  WaitingSchedulingResourceCapacityActivity
} from "@/components/Gantt/ResourcesGantt";
import { CustomSettingKeys } from "../CustomSettingKeys";
import { useTagsStore } from "@/store/TagsStore";
import { $t } from "@/i18n";
import { translateTotalStatus } from "@/composables/translateEnum";

@injectable()
export class CustomActivityTooltipRenderer extends ActivityTooltipRenderer {
  constructor(@inject(GanttSettings) protected readonly _settings: GanttSettings, @inject(StatusColorHelper) private _statusColorHelper: StatusColorHelper) {
    super(CustomActivityTooltipRenderer.name, "default-activity-tooltip custom-activity-tooltip");
  }

  async doDrawFromBatch(): Promise<void> {
    await super.doDrawFromBatch();
    if (this.visible) {
      const dto = this._activity.activity as unknown as EnhancedGanttResourceCapacityDto;
      const displayedPropertiesSetting = this._settings.getSetting<GanttActivityDisplayPropertySetting[]>(CustomSettingKeys.ACTIVITY_TOOLTIP_DISPLAYED_PROPERTIES);

      this.element.innerHTML = `
        <table>
          <tbody>
            <tr class="title">
              <td colspan="2" style="text-align: center">${this.getTitle(dto.type)}</td>
            </tr>
            ${this.renderProperties(displayedPropertiesSetting)}
          </tbody>
        </table>
      `;
    } else {
      this.element.innerHTML = "";
    }
  }

  private renderProperties(displayedProperties: GanttActivityDisplayPropertySetting[] | undefined): string {
    if (!displayedProperties) return "";

    let html = "";
    let label = "";
    displayedProperties
      .filter((property) => property.displayed)
      .sort((propertyA, propertyB) => propertyA.displayOrder - propertyB.displayOrder)
      .forEach((property) => {
        switch (property.propertyName) {
          case "taskName":
            html += this.renderTaskName();
            break;
          case "stepName":
            html += this.renderStepName();
            break;
          case "reason":
            html += this.renderReason();
            break;
          case "expectedDuration":
            html += this.renderExpectedDuration();
            break;
          case "payload":
            html += this.renderPayload();
            break;
          case "startTime":
            label = $t("gantt-resource-tooltip-startTime-label", { $: "Start" });
            html += this.renderRowFromDto(label, (dto) => dto.periodStartDateTimeFormatted ?? "");
            break;
          case "endTime":
            label = $t("gantt-resource-tooltip-endTime-label", { $: "End" });
            html += this.renderRowFromDto(label, (dto) => dto.periodEndDateTimeFormatted ?? "");
            break;
          case "duration":
            label = $t("gantt-resource-tooltip-duration-label", { $: "Duration" });
            html += this.renderRowFromDto(label, (dto) => dto.periodDurationFormatted ?? "");
            break;
          case "wbs":
            label = $t("gantt-resource-tooltip-wbs-label", { $: "WBS" });
            html += this.renderRowFromDto(label, (dto) => dto.task?.wbs ?? "");
            break;
          case "taskBusinessId":
            label = $t("gantt-resource-tooltip-taskBusinessId-label", { $: "Business ID" });
            html += this.renderRowFromDto(label, (dto) => dto.task?.businessId ?? "");
            break;
          case "scheduledQtyGood":
            label = $t("gantt-resource-tooltip-scheduledQtyGood-label", { $: "Scheduled Qty Good" });
            html += this.renderRowFromDto(label, (dto) => dto.step?.statistics?.schedulingQuantityGood?.toString() ?? "");
            break;
          case "executedQtyGood":
            label = $t("gantt-resource-tooltip-executedQtyGood-label", { $: "Executed Qty Good" });
            html += this.renderRowFromDto(label, (dto) => dto.step?.statistics?.executionQuantityGood?.toString() ?? "");
            break;
          case "producedResourceBusinessId":
            label = $t("gantt-resource-tooltip-producedResourceBusinessId-label", { $: "Item Business ID" });
            html += this.renderRowFromDto(label, (dto) => dto.task?.producedResource?.businessId ?? "");
            break;
          case "producedResourceColor":
            html += this.renderItemColor();
            break;
          case "customerBusinessId":
            label = $t("gantt-resource-tooltip-customerBusinessId-label", { $: "Customer Business ID" });
            html += this.renderRowFromDto(label, (dto) => this.getOrganizationBusinessIDs(dto.task?.organizations));
            break;
          case "tags":
            html += this.renderTags();
            break;
          default:
            console.warn("Unknown property - no activity tooltip provider", property.propertyName);
            break;
        }
      });
    return html;
  }

  private renderTaskName(): string {
    const dto = this._activity.activity as unknown as EnhancedGanttResourceCapacityDto;
    const { task, step, entrySourcePayloadType, entrySourcePayload: _entrySourcePayload }: EnhancedGanttResourceCapacityDto = dto;

    const taskName = task.name;
    const taskStatus = task?.statistics?.totalStatus ?? TotalStatus.Unknown;
    const taskStatusBackgroundColor = this._statusColorHelper.getStatusBackgroundColor(taskStatus);
    const taskStatusTextColor = this._statusColorHelper.getStatusTextColor(taskStatus);

    const label = $t("gantt-resource-tooltip-task-label", { $: "Task ({taskStatus})", taskStatus: translateTotalStatus(taskStatus) });

    return `
      <tr>
        <td style="background-color: ${taskStatusBackgroundColor}; color: ${taskStatusTextColor}">${label}:</td>
        <td>${taskName}</td>
      </tr>
    `;
  }

  private renderStepName(): string {
    const dto = this._activity.activity as unknown as EnhancedGanttResourceCapacityDto;
    const { task, step, entrySourcePayloadType, entrySourcePayload: _entrySourcePayload }: EnhancedGanttResourceCapacityDto = dto;

    const stepName = step.name;
    const stepStatus = step?.statistics?.totalStatus ?? TotalStatus.Unknown;

    const stepStatusBackgroundColor = this._statusColorHelper.getStepStatusBackgroundColor(stepStatus);
    const stepStatusTextColor = this._statusColorHelper.getStepStatusTextColor(stepStatus);

    if (stepName) {
      const label = $t("gantt-resource-tooltip-step-label", { $: "Step ({stepStatus})", stepStatus: translateTotalStatus(stepStatus) });

      return `
          <tr>
            <td style="background-color: ${stepStatusBackgroundColor}; color: ${stepStatusTextColor}">${label}:</td>
            <td>${stepName}</td>
          </tr>
      `;
    } else {
      return "";
    }
  }

  private renderReason(): string {
    return this.renderRowFromDto("Reason:", (dto) => {
      const { entrySourcePayload: _entrySourcePayload } = dto;
      if (!_entrySourcePayload) return;

      const entrySourcePayload = _entrySourcePayload as any;
      return entrySourcePayload.reason ?? "";
    });
  }

  private renderExpectedDuration(): string {
    const label = $t("gantt-resource-tooltip-expectedDuration-label", { $: "Expected duration" });
    return this.renderRowFromDto(label, (dto) => {
      const { entrySourcePayload: _entrySourcePayload } = dto;
      if (!_entrySourcePayload) return;

      const entrySourcePayload = _entrySourcePayload as any;
      return entrySourcePayload.expectedDuration ?? "";
    });
  }

  private renderPayload(): string {
    const label = $t("gantt-resource-tooltip-payload-label", { $: "Payload" });
    return this.renderRowFromDto(label, (dto) => {
      const { entrySourcePayload: _entrySourcePayload } = dto;
      if (!_entrySourcePayload) return;

      const entrySourcePayload = _entrySourcePayload as any;
      return entrySourcePayload ?? "";
    });
  }

  private renderItemColor(): string {
    const dto = this._activity.activity as unknown as EnhancedGanttResourceCapacityDto;
    const { task }: EnhancedGanttResourceCapacityDto = dto;
    const producedResource = task?.producedResource;

    const label = $t("gantt-resource-tooltip-itemColor-label", { $: "Item Color" });
    return `
    <tr>
      <td>${label}:</td>
      ${producedResource && producedResource.color ? `<td style="background-color: ${producedResource.color};"></td>` : `<td></td>`}
    </tr>
    `;
  }

  private renderRowFromDto(propertyName: string, propertyValueProvider: (dto: EnhancedGanttResourceCapacityDto) => string | undefined): string {
    const dto = this._activity.activity as unknown as EnhancedGanttResourceCapacityDto;
    return this.getRow(propertyName, propertyValueProvider(dto));
  }

  private getRow(label: string, value?: string) {
    return value
      ? `
    <tr>
        <td>${label}:</td>
        <td>${value}</td>
    </tr>
    `
      : "";
  }

  private renderTags() {
    const dto = this._activity.activity as unknown as EnhancedGanttResourceCapacityDto;
    const tags = useTagsStore().tags;
    const orgTags = dto.task.organizations.map(org => org.tags).flat()
      .map(tag => tags.find(t => t.name === tag)).sort((a, b) => (a?.rank ?? 0) - (b?.rank ?? 0)).filter(tag => tag)
      .map(tag => {
        if (!tag) return "";
        const str = `${tag.emoji ? `${tag.emoji} ` : ""}${tag.name}`;
        return tag.color ? `<span style="background-color: ${tag.color}; padding: .5em; border-radius: .5em;"></span>${str}` : str;
      })
      .join(", ");

    const label = $t("gantt-resource-tooltip-tags-label", { $: "Tags" });
    return this.getRow(label, orgTags);
  }

  private getTitle(type: string) {
    switch (type) {
      case ResourceCapacityActivity.name:
        return $t("gantt-resource-tooltip-title-resourceCapacityActivity-label", { $: "Resource Capacity" });
      case DemandSchedulingResourceCapacityActivity.name:
        return $t("gantt-resource-tooltip-title-scheduling-label", { $: "Scheduling" });
      case DemandExecutionResourceCapacityActivity.name:
        return $t("gantt-resource-tooltip-title-execution-label", { $: "Execution" });
      case WaitingSchedulingResourceCapacityActivity.name:
        return $t("gantt-resource-tooltip-title-waiting-label", { $: "Waiting" });
      case InterruptionExecutionResourceCapacityActivity.name:
        return $t("gantt-resource-tooltip-title-interruption-label", { $: "Interruption" });
      default:
        return $t("gantt-resource-tooltip-title-unknown-label", { $: "Unknown" });
    }
  }

  private getOrganizationBusinessIDs(organizations: OrganizationDto[]) {
    if (!organizations || organizations.length === 0) {
      return "";
    }

    return organizations.map((org) => org.businessId).join(", ");
  }
}
