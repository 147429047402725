import {
  BaseRowInfoColumnProvider,
  GanttSettings,
  IocSymbols,
  IRowInfoColumn,
  IRowInfoColumnHeader,
  IRowInfoColumnProvider,
  Lifecycle,
  ObservedObject,
  observeObject,
  SettingKey
} from "@masta/gantt2/core";
import { inject, injectable } from "inversify";
import { useI18n } from "vue-i18n";
import { AppIocSymbols } from "@/components/Gantt/ResourcesGantt/AppIocSymbols";

@injectable()
export class ResourceGanttRowInfoColumnProvider extends BaseRowInfoColumnProvider {
  protected _columns: ObservedObject<IRowInfoColumn>[] = [
    observeObject({ id: "text", className: "text", width: 130, visible: true }),
    observeObject({ id: "demand-supply-info", className: "demand-supply-info", width: 50, visible: true }),
    observeObject({ id: "scale", className: "scale", width: 80, visible: true })
  ];
  protected _headers: IRowInfoColumnHeader[] = [];
  private $t: any;

  constructor(
    @inject(IocSymbols.HtmlContainerSymbol) container: HTMLDivElement,
    @inject(GanttSettings) private _ganttSettings: GanttSettings,
    @inject(AppIocSymbols.$i18n) private _i18n: ReturnType<typeof useI18n>
  ) {
    super(container);
    this.$t = this._i18n.t;

    this._headers = [
      { id: "text", className: "text", text: this.$t("gantt-resource-column-name-label", { $: "Name" }) } as IRowInfoColumnHeader,
      { id: "demand-supply-info", className: "demand-supply-info", text: this.$t("gantt-resource-column-demandSupply-label", { $: "Demand<br>Supply" }) } as IRowInfoColumnHeader,
      { id: "scale", className: "scale", text: this.$t("gantt-resource-column-scale-label", { $: "Scale" }) } as IRowInfoColumnHeader
    ];
  }

  async beforeInitialize(): Promise<void> {
    await super.beforeInitialize();
    this.subscribe(
      this._ganttSettings.getSetting$<number>(SettingKey.CHART_HEADER_WIDTH).subscribe((s) => {
        this._columns[2].proxy.width = s! / 2;
      })
    );
    this.subscribe(
      this._ganttSettings.getSetting$<boolean>(SettingKey.CHART_SHOW).subscribe((s) => {
        this._columns[2].proxy.visible = s ?? true;
        this._headers[2].visible = s ?? true;
      })
    );
  }
}
