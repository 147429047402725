﻿import { inject, Ref } from "vue";
import { IGanttWorker, IocSymbols, Lifecycle } from "@masta/gantt2/core";
import { Gantt, IGanttOptions } from "@masta/gantt2/gantt";
import { rowFactoryIocExtension as resourceRowFactoryIocExtension } from "./ResourcesFrontModule";
import { rowFactoryIocExtension as dndRowFactoryIocExtension } from "./DragAndDrop/DragAndDropFrontModule";
import { $authService } from "@/services/AuthService";
import { toValue } from "@vueuse/core";
import { AppContextDto, ProductionTaskDto, ResourcesCapacitiesChangeNotificationEvent, SchedulingFinishedNotificationEvent } from "@masta/generated-model";
import { $appContextSymbol } from "@/plugins/app.plugin";
import { AppIocSymbols } from "@/components/Gantt/ResourcesGantt/AppIocSymbols";
import { RouteLocationNormalizedLoaded, useRoute } from "vue-router";
import { $dateTimeFormatterSymbol, $durationFormatterSymbol, DateFormatter, DurationFormatter } from "@masta/shared";
import { NoteHandler } from "@/components/Gantt/ResourcesGantt/NoteHandler";
import { ResourceCapacityChangeNotificationHandler } from "@/components/Gantt/ResourcesGantt/ResourceCapacityChangeNotificationHandler";
import ResourcesGanttWorker from "./ResourcesGanttWorker?worker&url";
import type { ResourcesGanttWorkerType } from "./ResourcesGanttWorker";
import { useI18n } from "vue-i18n";
import { IGanttDataLoaderOptions } from "@/components/Gantt/Common/CommonGanttDataLoader";
import { ContextMenuProvider } from "@/components/Gantt/ResourcesGantt/ContextMenuProvider";
import { interfaces } from "inversify";
import { DragAndDropService } from "@/components/Gantt/ResourcesGantt/DragAndDrop";

export interface IResourcesGanttOptions extends IGanttOptions<any> {
  selectedScenario: string | undefined;
  constsToRegisterInIoc: { key: symbol; value: any }[];
}

export class ResourcesGanttLoader extends Lifecycle {
  public gantt: Gantt<any>;
  private _$appContext: AppContextDto;
  private _$route: RouteLocationNormalizedLoaded;
  private _$selectedScenario: Ref<{ id: string } | undefined>;
  private _$dateTimeFormatter: DateFormatter;
  private _$durationFormatter: DurationFormatter;
  private _ganttWorker: IGanttWorker;
  private _resourceCapacityChangeNotificationHandler: ResourceCapacityChangeNotificationHandler;
  private _filterExpressionProvider?: () => string | null | undefined;
  private _$i18n: ReturnType<typeof useI18n>;
  private _dndService: DragAndDropService;
  private _tokenRefreshInterval: any;

  constructor(
    private _container: Ref<HTMLDivElement | undefined>,
    selectedScenario: Ref<any>,
    i18n: ReturnType<typeof useI18n>,
    filterExpressionProvider?: () => string | null | undefined
  ) {
    super();
    this._$appContext = inject<Ref<AppContextDto>>($appContextSymbol)!.value;
    this._$route = useRoute();
    this._$i18n = i18n;
    this._$dateTimeFormatter = inject<DateFormatter>($dateTimeFormatterSymbol)!;
    this._$durationFormatter = inject<DateFormatter>($durationFormatterSymbol)!;
    this._$selectedScenario = selectedScenario;
    this._filterExpressionProvider = filterExpressionProvider;
  }

  async beforeInitialize(): Promise<void> {
    await super.beforeInitialize();
    await this.initializeGantt(this.provideModules());
  }

  async afterInitialize(): Promise<void> {
    await super.afterInitialize();
    // initialize data loader
    await this.gantt.initializeDataLoader();
    this._ganttWorker = await this.gantt.IocContainer.getAsync<IGanttWorker>(IocSymbols.GanttWorkerSymbol);
    this._resourceCapacityChangeNotificationHandler = await this.gantt.IocContainer.getAsync<ResourceCapacityChangeNotificationHandler>(
      ResourceCapacityChangeNotificationHandler.name
    );
    // initialize drag and drop
    this._dndService = await this.gantt.IocContainer.getAsync<DragAndDropService>(DragAndDropService);


    this._tokenRefreshInterval = setInterval(async () => {
      await (this._ganttWorker as ResourcesGanttWorkerType).refreshApiToken($authService.token!);
    }, 1000 * 30);
  }

  async beforeDestroy(): Promise<void> {
    clearInterval(this._tokenRefreshInterval);
    await super.beforeDestroy();
    await this.gantt.destroy();
  }

  public async getNoteHandler(): Promise<NoteHandler> {
    return this.gantt.IocContainer.getAsync<NoteHandler>(NoteHandler);
  }

  public async getContextMenuProvider(): Promise<ContextMenuProvider> {
    return this.gantt.IocContainer.getAsync<ContextMenuProvider>(IocSymbols.ContextMenuProvider);
  }

  public async resourcesCapacitiesChangeNotificationEventHandler(event: ResourcesCapacitiesChangeNotificationEvent): Promise<void> {
    await this._resourceCapacityChangeNotificationHandler?.handleNotification(event);
    await this._dndService?.handleResourceCapacityChangeNotification(event);
  }

  public async schedulingFinishedNotificationEventHandler(event: SchedulingFinishedNotificationEvent): Promise<void> {
    await this._dndService?.handleSchedulingOperationResultEvent(event);
  }

  public async onTaskRowDragEnter(dragId: string, productionTaskDto: ProductionTaskDto, event: MouseEvent) {
    await this._dndService?.externalActivityDragStarted(dragId, productionTaskDto, event);
  }

  public async onTaskRowDragMove(dragId: string, event: MouseEvent) {
    await this._dndService?.externalActivityDragging(dragId, event);
  }

  public async onTaskRowDragLeave(dragId: string, event: MouseEvent) {
    await this._dndService.externalActivityDragFinished(dragId, event);
  }

  public async onTaskRowDragEnd(dragId: string, event: MouseEvent) {
    await this._dndService.externalActivityDropped(dragId, event);
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  protected provideModules(): (string | Function)[] {
    const commonModule = import.meta.glob("./../Common/CommonFrontModule.ts")["../Common/CommonFrontModule.ts"];
    const resourcesFrontModule = import.meta.glob("./ResourcesFrontModule.ts")["./ResourcesFrontModule.ts"];
    const dndFrontModule = import.meta.glob("./DragAndDrop/DragAndDropFrontModule.ts")["./DragAndDrop/DragAndDropFrontModule.ts"];
    return [commonModule, resourcesFrontModule, dndFrontModule];
  }

  protected provideCustomGanttWorker(): Worker {
    return new Worker(new URL(ResourcesGanttWorker, import.meta.url), { type: "module" });
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  protected async initializeGantt(modules: (string | Function)[] | undefined) {
    this.gantt = new Gantt<any>({
      customGanttWorker: this.provideCustomGanttWorker(),
      container: toValue(this._container)!,
      modules,
      dataLoaderOptions: {
        token: $authService.token!,
        tenantId: $authService.tokenParsed!.tenant_id
      } as IGanttDataLoaderOptions,
      selectedScenario: toValue(this._$selectedScenario),
      constsToRegisterInIoc: [
        { key: AppIocSymbols.$appContext, value: this._$appContext },
        { key: AppIocSymbols.$route, value: this._$route },
        { key: AppIocSymbols.$selectedScenario, value: toValue(this._$selectedScenario)?.id },
        { key: AppIocSymbols.$durationFormatter, value: this._$durationFormatter },
        { key: AppIocSymbols.$dateTimeFormatter, value: this._$dateTimeFormatter },
        { key: AppIocSymbols.filterExpressionProvider, value: this._filterExpressionProvider },
        { key: AppIocSymbols.$i18n, value: this._$i18n }
      ],
      rowFactoryIocExtension: (ioc: interfaces.Container) => {
        resourceRowFactoryIocExtension(ioc);
        dndRowFactoryIocExtension(ioc);
      },
      dragAndDropDataStore: {
        task: null
      }
    } as IResourcesGanttOptions);
    await this.gantt.initialize();
  }
}
