<script lang="ts" setup>
import EventPayloadCellRenderer from "@/components/WorkJournalRecords/EventPayloadCellRenderer.vue";
import GridWrapper from "@/components/Grid/GridWrapper.vue";
import { inject, reactive, ref } from "vue";
import { GridWrapperComponent } from "@/components/Grid/GridWrapperComponent";
import { $t } from "@/i18n";
import { GridApi, ValueFormatterParams, ValueGetterParams } from "ag-grid-community";
import { joinArrayOfStrings } from "@/components/ValueCellEditor/CommonFormatters";
import { $dateTimeFormatterSymbol, DateFormatter } from "@masta/shared";
import { WorkJournalRecordsServerSideDataSource } from "@/components/WorkJournalRecords/WorkJournalRecordsServerSideDataSource";
import { booleanTypeColumnFilterParams } from "@/components/Grid/Filters/BooleanTypeColumnFilters";
import { translateWorkJournalRecordEventAction, translateWorkJournalRecordEventType } from "@/composables/translateEnum";
import Api from "@/services/api";
import { PublishExecutionEventCommand, WorkJournalRecordEventAction, WorkJournalRecordEventType } from "@masta/generated-model";
import CreateWorkJournalRecordGridAction from "@/components/WorkJournalRecords/CreateWorkJournalRecordGridAction.vue";
import { v4 as uuidv4 } from "uuid";
import { getSelectedRows } from "@/components/Grid/UseGridSelection";

const emit = defineEmits(["showDetails"]);

const serverSideDataSource = reactive(new WorkJournalRecordsServerSideDataSource("workJournalRecords"));

const isRowSelected = ref(false);

function onSelectionChanged({ api }: { api: GridApi }) {
  isRowSelected.value = getSelectedRows(api).length > 0;
}

const gridWrapperRef = ref<GridWrapperComponent>();
const $dateTimeFormatter = inject<DateFormatter>($dateTimeFormatterSymbol)!;

function gridApi() {
  return gridWrapperRef.value!.gridApi;
}

const defaultColumnDef = ref({
  filter: false,
  floatingFilter: true,
  filterParams: {
    applyMiniFilterWhileTyping: true
  },
  sortable: true,
  resizable: true,
  editable: true
});

const isHidden = ref<boolean>(true);

function showColumns() {
  isHidden.value = false;
}

function hideColumns() {
  isHidden.value = true;
}

function onPrepareColumns(columnDefs: any) {
  columnDefs.value = [
    {
      field: "id",
      headerValueGetter: (_: any) => $t("workJournalRecord-list-Id-label", { $: "Id" }),
      editable: false,
      sortable: false,
      filter: false,
      hide: true,
      valueGetter: (params: ValueGetterParams) => params.data?.id
    },
    {
      field: "parentId",
      headerValueGetter: (_: any) => $t("workJournalRecord-list-parent-label", { $: "Parent Id" }),
      editable: false,
      resizable: true,
      hide: true,
      valueFormatter: (params: ValueFormatterParams) => {
        return params.data?.businessId;
      },
      valueGetter: (params: ValueGetterParams) => params.data?.parentId
    },
    {
      field: "resourceId",
      headerValueGetter: (_: any) => $t("workJournalRecord-list-resourceId-label", { $: "Resource Id" }),
      editable: false,
      resizable: true,
      valueFormatter: (params: ValueFormatterParams) => {
        return params.data?.resourceId;
      },
      valueGetter: (params: ValueGetterParams) => params.data?.resourceId
    },
    {
      field: "taskId",
      headerValueGetter: (_: any) => $t("workJournalRecord-list-taskId-label", { $: "Task Id" }),
      editable: false,
      resizable: true,
      valueFormatter: (params: ValueFormatterParams) => {
        return params.data?.taskId;
      },
      valueGetter: (params: ValueGetterParams) => params.data?.taskId
    },
    {
      field: "taskBusinessId",
      type: ["textFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("workJournalRecord-list-taskBusinessId-label", { $: "Task Business Id" }),
      editable: false,
      resizable: true,
      valueFormatter: (params: ValueFormatterParams) => {
        return params.data?.taskBusinessId;
      },
      valueGetter: (params: ValueGetterParams) => params.data?.taskBusinessId
    },
    {
      field: "taskName",
      type: ["textFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("workJournalRecord-list-taskName-label", { $: "Task Name" }),
      editable: false,
      resizable: true,
      valueFormatter: (params: ValueFormatterParams) => {
        return params.data?.taskName;
      },
      valueGetter: (params: ValueGetterParams) => params.data?.taskName
    },
    {
      field: "stepId",
      headerValueGetter: (_: any) => $t("workJournalRecord-list-stepId-label", { $: "Step Id" }),
      editable: false,
      resizable: true,
      valueFormatter: (params: ValueFormatterParams) => {
        return params.data?.stepId;
      },
      valueGetter: (params: ValueGetterParams) => params.data?.stepId
    },
    {
      field: "stepPosition",
      headerValueGetter: (_: any) => $t("workJournalRecord-list-stepPosition-label", { $: "Step Position" }),
      editable: false,
      resizable: true,
      valueFormatter: (params: ValueFormatterParams) => {
        return params.data?.stepPosition;
      },
      valueGetter: (params: ValueGetterParams) => params.data?.stepPosition
    },
    {
      field: "stepName",
      type: ["textFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("workJournalRecord-list-stepName-label", { $: "Step Name" }),
      editable: false,
      resizable: true,
      valueFormatter: (params: ValueFormatterParams) => {
        return params.data?.stepName;
      },
      valueGetter: (params: ValueGetterParams) => params.data?.stepName
    },
    {
      field: "personId",
      headerValueGetter: (_: any) => $t("workJournalRecord-list-personId-label", { $: "Person Id" }),
      editable: false,
      resizable: true,
      valueFormatter: (params: ValueFormatterParams) => {
        return params.data?.personId;
      },
      valueGetter: (params: ValueGetterParams) => params.data?.personId
    },
    {
      field: "personBusinessId",
      type: ["textFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("workJournalRecord-list-personId-label", { $: "Person Business Id" }),
      editable: false,
      resizable: true,
      valueFormatter: (params: ValueFormatterParams) => {
        return params.data?.personBusinessId;
      },
      valueGetter: (params: ValueGetterParams) => params.data?.personBusinessId
    },
    {
      field: "personName",
      type: ["textFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("workJournalRecord-list-personName-label", { $: "Person Name" }),
      editable: false,
      resizable: true,
      valueFormatter: (params: ValueFormatterParams) => {
        return params.data?.personName;
      },
      valueGetter: (params: ValueGetterParams) => params.data?.personName
    },
    {
      field: "agreementId",
      headerValueGetter: (_: any) => $t("workJournalRecord-list-agreementId-label", { $: "Agreement Id" }),
      editable: false,
      resizable: true,
      valueFormatter: (params: ValueFormatterParams) => {
        return params.data?.agreementId;
      },
      valueGetter: (params: ValueGetterParams) => params.data?.agreementId
    },
    {
      field: "agreementBusinessId",
      type: ["textFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("workJournalRecord-list-agreementBusinessId-label", { $: "Agreement Business Id" }),
      editable: false,
      resizable: true,
      valueFormatter: (params: ValueFormatterParams) => {
        return params.data?.agreementBusinessId;
      },
      valueGetter: (params: ValueGetterParams) => params.data?.agreementBusinessId
    },
    {
      field: "agreementName",
      type: ["textFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("workJournalRecord-list-agreementName-label", { $: "Agreement Name" }),
      editable: false,
      resizable: true,
      valueFormatter: (params: ValueFormatterParams) => {
        return params.data?.agreementName;
      },
      valueGetter: (params: ValueGetterParams) => params.data?.agreementName
    },
    {
      field: "equipmentId",
      headerValueGetter: (_: any) => $t("workJournalRecord-list-equipmentId-label", { $: "Equipment Id" }),
      editable: false,
      resizable: true,
      valueFormatter: (params: ValueFormatterParams) => {
        return params.data?.equipmentId;
      },
      valueGetter: (params: ValueGetterParams) => params.data?.equipmentId
    },
    {
      field: "equipmentBusinessId",
      type: ["textFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("workJournalRecord-list-agreementBusinessId-label", { $: "Equipment Business Id" }),
      editable: false,
      resizable: true,
      valueFormatter: (params: ValueFormatterParams) => {
        return params.data?.equipmentBusinessId;
      },
      valueGetter: (params: ValueGetterParams) => params.data?.equipmentBusinessId
    },
    {
      field: "equipmentName",
      type: ["textFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("workJournalRecord-list-equipmentName-label", { $: "Equipment Name" }),
      editable: false,
      resizable: true,
      valueFormatter: (params: ValueFormatterParams) => {
        return params.data?.equipmentName;
      },
      valueGetter: (params: ValueGetterParams) => params.data?.equipmentName
    },
    {
      field: "assetIds",
      headerValueGetter: (_: any) => $t("workJournalRecord-list-assetIds-label", { $: "Asset Ids" }),
      editable: false,
      resizable: true,
      valueFormatter: (params: ValueFormatterParams) => joinArrayOfStrings(params.data.assetIds)
    },
    {
      field: "cancelled",
      type: ["setFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("workJournalRecord-list-cancelled-label", { $: "Cancelled" }),
      filter: "agSetColumnFilter",
      filterParams: booleanTypeColumnFilterParams,
      editable: false,
      resizable: true,
      cellDataType: "boolean",
      cellRendererParams: {
        disabled: () => !gridWrapperRef.value?.isEditing()
      },
    },
    {
      field: "isAutomation",
      type: ["setFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("workJournalRecord-list-isAutomation-label", { $: "Is Automation" }),
      filter: "agSetColumnFilter",
      filterParams: booleanTypeColumnFilterParams,
      editable: false,
      resizable: true,
      cellDataType: "boolean",
      cellRendererParams: {
        disabled: () => !gridWrapperRef.value?.isEditing()
      },
    },
    {
      field: "eventType",
      type: ["enumTypeColumn", "setFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("workJournalRecord-list-eventType-label", { $: "Event Type" }),
      editable: false,
      resizable: true,
      valueFormatter: (params: ValueFormatterParams) => {
        return translateWorkJournalRecordEventType(params.data.eventType);
      }
    },
    {
      field: "eventAction",
      type: ["enumTypeColumn", "setFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("workJournalRecord-list-eventAction-label", { $: "Event Action" }),
      editable: false,
      resizable: true,
      valueFormatter: (params: ValueFormatterParams) => {
        return translateWorkJournalRecordEventAction(params.data.eventAction);
      }
    },
    {
      field: "eventStart",
      type: "datepickerTypeColumn",
      headerValueGetter: (_: any) => $t("workJournalRecord-list-eventStart-label", { $: "Start" }),
      editable: false,
      resizable: true,
      valueFormatter: (params: any) => {
        return $dateTimeFormatter(params.data.periodStart);
      }
    },
    {
      field: "eventPayload",
      type: ["textFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("workJournalRecord-list-eventPayload-label", { $: "Payload" }),
      editable: false,
      resizable: true,
      cellRenderer: EventPayloadCellRenderer,
      valueFormatter: (params: ValueFormatterParams) => {
        return params.data?.eventPayload;
      },
      valueGetter: (params: ValueGetterParams) => params.data?.eventPayload
    },
    {
      field: "tags",
      type: ["tagsTypeColumn"],
      headerValueGetter: (_: any) => $t("workJournalRecord-list-tags-label", { $: "Tags" }),
      editable: false,
      resizable: true,
      valueFormatter: (params: ValueFormatterParams) => joinArrayOfStrings(params.data.tags)
    },
    {
      field: "businessId",
      type: ["textFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("workJournalRecord-list-businessId-label", { $: "Business Id" }),
      editable: false,
      resizable: true,
      valueFormatter: (params: ValueFormatterParams) => {
        return params.data?.businessId;
      },
      valueGetter: (params: ValueGetterParams) => params.data?.businessId
    }
  ];
}

async function cancelWorkJournalRecord() {
  const rows = getSelectedRows(gridWrapperRef.value?.gridApi);
  const row = rows.length === 1 ? rows[0] : null;
  if (row) {
    const cancellationWorkJournalRecord: PublishExecutionEventCommand = {
      eventType: row.eventType,
      eventAction: WorkJournalRecordEventAction.Cancellation,
      taskId: row.taskId,
      stepId: row.stepId,
      resourceId: row.resourceId,
      affectedWorkJournalRecordId: row.id,
      eventStart: row.eventStart,
      id: uuidv4()
    };

    switch (row.eventType) {
      case WorkJournalRecordEventType.ClockIn:
      case WorkJournalRecordEventType.ClockOut:
      case WorkJournalRecordEventType.MachineInterruptStart:
      case WorkJournalRecordEventType.ResourceResourceAssignment:
        await Api.executionEvents.sendResourceExecutionEvent(cancellationWorkJournalRecord);
        break;
      case WorkJournalRecordEventType.Production:
      case WorkJournalRecordEventType.Finish:
      case WorkJournalRecordEventType.Quantity:
        await Api.executionEvents.sendTaskExecutionEvent(cancellationWorkJournalRecord);
        break;
      case WorkJournalRecordEventType.QualityCheck:
        await Api.executionEvents.sendQualityCheckExecutionEvent(cancellationWorkJournalRecord);
        break;
      default:
        throw new Error("Unsupported work journal event type");
        break;
    }
  }
}
</script>

<template>
  <grid-wrapper
    ref="gridWrapperRef"
    refresh-btn
    row-selection="multiple"
    identifier="work-journal-records"
    server-side
    :server-side-datasource="serverSideDataSource"
    :default-col-def="defaultColumnDef"
    :tree-data="false"
    :enable-group-edit="false"
    @prepare-columns="onPrepareColumns"
    @edit-action="hideColumns"
    @create-action="showColumns"
    @cancel-action="hideColumns"
    @save-action="hideColumns"
    @selection-changed="onSelectionChanged"
  >
    <template #custom-buttons="{ gridApi }">
      <v-tooltip bottom open-delay="300">
        <template #activator="{ props }">
          <v-btn :disabled="!isRowSelected" size="small" v-bind="props" variant="text" density="compact" class="mx-2" @click="cancelWorkJournalRecord">
            <v-icon class="pr-4" icon="mdi-close-outline" />
            {{ $t("workJournalRecords-list-cancel-action", { $: "Send Cancellation" }) }}
          </v-btn>
        </template>
        <span>{{ $t("workJournalRecords-list-cancel-tooltip", { $: "Storno / cancellation Work Journal Record is sent to the server" }) }}</span>
      </v-tooltip>
      <create-work-journal-record-grid-action :grid-api="gridApi" />
    </template>
  </grid-wrapper>
</template>

<style lang="scss" scoped></style>
