<template>
  <master-detail-layout scenario-selector :detail="showDetails" detail-dialog-width="90%" @change="showDetails = false">
    <template #master>
      <v-card elevation="0" class="flexcard fill-height">
        <v-card-title>
          <breadcrumbs>{{ $t("personnel-view-personnel-title", { $: "Personnel" }) }}</breadcrumbs>
        </v-card-title>
        <v-card-text class="fill-height">
          <personnel-grid
            ref="grid"
            @manage-model-instances="manageModelInstances"
            @manage-availability-rules="manageAvailabilityRules"
            @resource2resource="resource2resourceAction"
            @show-details="onShowDetails"
          />
        </v-card-text>
      </v-card>
      <v-col v-if="editModelInstanceAssignmentDialog">
        <resource-model-instance-assignment
          :model-instances="person.modelInstances"
          :opened="editModelInstanceAssignmentDialog"
          @cancel="editModelInstanceAssignmentDialog = false"
          @save="saveModelInstanceAssignment"
        >
          <resource-label type="Person" :business-id="person.businessId" :name="person.name" />
        </resource-model-instance-assignment>
      </v-col>
      <v-col v-if="editAvailabilityRulesAssignmentDialog">
        <availability-rules-assignment-editor
          :resource="person"
          :opened="editAvailabilityRulesAssignmentDialog"
          @cancel="editAvailabilityRulesAssignmentDialog = false"
          @save="editAvailabilityRulesAssignmentDialog = false"
        />
      </v-col>
      <resource2-resource-dialog
        v-if="resourceType >= 0 && person"
        :opened="resource2resourceDialog"
        :assignment-type="resourceAssignmentType"
        :resource-type="resourceType"
        :resource="person"
        :reverse="isReverseResourceAssignment"
        @close="resource2resourceDialogClose"
      />
    </template>
    <template #detail-title>
      <span class="text-h6">{{ person?.name || person?.businessId }}</span>
    </template>
    <template #detail>
      <personnel-details :person="person" :schema-tabs="schemaTabs" @updated="personUpdated" />
    </template>
  </master-detail-layout>
</template>

<script>
import PersonnelGrid from "@/components/Personnel/PersonnelGrid";
import MasterDetailLayout from "@/components/Layout/MasterDetailLayout";
import ResourceLabel from "@/components/ModelInstances/ModelInstanceAssignment/ResourceLabel";
import AvailabilityRulesAssignmentEditor from "@/components/AvailabilityRules/AvailabilityRulesAssignment/AvailabilityRulesAssignmentEditor";
import { mapActions, mapState } from "pinia";
import { usePersonnelStore } from "@/store/PersonnelStore";
import { ResourceAssignmentType, ResourceType } from "@masta/generated-model";
import Resource2ResourceDialog from "@/components/Resources/ResourceAssignment/Resource2ResourceDialog.vue";
import Breadcrumbs from "@/components/Layout/Breadcrumbs.vue";
import ResourceModelInstanceAssignment from "@/components/ModelInstances/ModelInstanceAssignment/ResourceModelInstanceAssignment.vue";
import { useModelInstancesStore } from "@/store/ModelInstancesStore";
import { useSystemStore } from "@/store/SystemStore";
import PersonnelDetails from "@/components/Personnel/PersonnelDetails.vue";

export default {
  name: "Personnel",
  components: {
    PersonnelDetails,
    Breadcrumbs,
    Resource2ResourceDialog,
    ResourceLabel,
    AvailabilityRulesAssignmentEditor,
    MasterDetailLayout,
    PersonnelGrid,
    ResourceModelInstanceAssignment
  },
  title: "Personnel",
  data() {
    return {
      editModelInstanceAssignmentDialog: false,
      editAvailabilityRulesAssignmentDialog: false,
      showDetails: false,
      person: null,
      refreshGridAction: null,
      resource2resourceDialog: false,
      resourceType: null,
      resourceAssignmentType: ResourceAssignmentType.AgreementAssignment,
      isReverseResourceAssignment: true,
      schemaTabs: []
    };
  },
  computed: {
    ...mapState(useModelInstancesStore, ["schemas"]),
    ...mapState(useSystemStore, ["modelInstanceTabs"])
  },
  mounted() {
    this.loadTabSchemas();
  },
  methods: {
    ...mapActions(usePersonnelStore, ["updateModelInstanceAssignment"]),
    ...mapActions(useModelInstancesStore, ["fetchSchemas"]),
    manageModelInstances(person, refreshGridAction) {
      this.refreshGridAction = refreshGridAction;
      this.person = person;
      this.editModelInstanceAssignmentDialog = true;
    },
    async saveModelInstanceAssignment(instances) {
      await this.updateModelInstanceAssignment({
        id: this.person.id,
        modelInstanceIds: instances.map((x) => x.id)
      });
      this.refreshGridAction();
      this.editModelInstanceAssignmentDialog = false;
    },
    manageAvailabilityRules(person) {
      this.person = person;
      this.editAvailabilityRulesAssignmentDialog = true;
    },
    resource2resourceAction(resourceType, person) {
      this.resourceType = resourceType;
      switch (this.resourceType) {
        case ResourceType.Agreement:
          this.resourceAssignmentType = ResourceAssignmentType.AgreementAssignment;
          this.isReverseResourceAssignment = true;
          break;
        case ResourceType.Asset:
          this.resourceAssignmentType = ResourceAssignmentType.Identity;
          this.isReverseResourceAssignment = false;
          break;
      }
      this.person = person;
      this.resource2resourceDialog = true;
    },
    resource2resourceDialogClose() {
      this.resource2resourceDialog = false;
      this.resourceType = null;
      this.person = null;
    },
    onShowDetails(person) {
      this.person = person;
      this.showDetails = true;
    },
    async loadTabSchemas() {
      await this.fetchSchemas();
      for (const tab of this.modelInstanceTabs?.personnel ?? []) {
        const schema = this.schemas.find((x) => x.schemaName === tab.schemaName && x.schemaKey === tab.schemaKey && x.version === tab.schemaVersion);
        if (schema) {
          this.schemaTabs.push({ title: tab.title ?? "", schema, contextName: tab.contextName ?? "", resourceSubTypes: tab.resourceSubTypes });
        }
      }
    },
    async personUpdated(id) {
      this.$refs.grid.refreshSingle(id);
    }
  }
};
</script>

<style scoped></style>
