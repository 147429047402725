<script lang="ts" setup>
import GridWrapper from "@/components/Grid/GridWrapper.vue";
import { GridWrapperComponent } from "@/components/Grid/GridWrapperComponent";
import CalculateKpiGridAction from "@/components/KpiResults/CalculateKpiGridAction.vue";
import DeleteKpiGridAction from "@/components/KpiResults/DeleteKpiGridAction.vue";
import { normalizedValueCellStyle } from "@/components/KpiResults/KpiResultsGridCellStyle";
import { $t } from "@/i18n";
import { useKeyPerformanceIndicatorsStore } from "@/store/KeyPerformanceIndicatorsStore";
import { $dateFormatterSymbol, $dateTimeFormatterSymbol, DateFormatter } from "@masta/shared";
import { CellClassParams, ValueFormatterParams, ValueGetterParams } from "ag-grid-community";
import { storeToRefs } from "pinia";
import { inject, ref } from "vue";

const keyPerformanceIndicatorsStore = useKeyPerformanceIndicatorsStore();
const { kpiResults } = storeToRefs(keyPerformanceIndicatorsStore);

const gridWrapperRef = ref<GridWrapperComponent>();

const chartToolPanelsDef = ref(null);

const $dateTimeFormatter = inject<DateFormatter>($dateTimeFormatterSymbol)!;
const $dateFormatter = inject<DateFormatter>($dateFormatterSymbol)!;

async function onFetchData() {
  await keyPerformanceIndicatorsStore.fetch();

  chartToolPanelsDef.value = {
    defaultToolPanel: "settings"
  } as any;
}

async function onRefreshAction() {
  await onFetchData();
}

function onPrepareColumns(columnDefs: any) {
  columnDefs.value = [
    {
      field: "id",
      headerName: "Id",
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      type: "textInputTypeColumn",
      headerValueGetter: (_: any) => $t("kpiResults-list-id-label", { $: "Id" })
    },
    {
      field: "scenarioId",
      headerName: "Scenario Id",
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      type: "textInputTypeColumn",
      headerValueGetter: (_: any) => $t("kpiResults-list-scenarioId-label", { $: "Scenario Id" })
    },
    {
      field: "calculationId",
      headerName: "Calculation Id",
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      type: "textInputTypeColumn",
      headerValueGetter: (_: any) => $t("kpiResults-list-calculationId-label", { $: "Calculation Id" })
    },
    {
      field: "kpiName",
      headerName: "KPI Name",
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      type: "textInputTypeColumn",
      headerValueGetter: (_: any) => $t("kpiResults-list-kpiName-label", { $: "Indicator's Name" })
    },
    {
      field: "kpiNominalValue",
      headerName: "KPI Nominal Value",
      sortable: true,
      editable: false,
      filter: "agNumberColumnFilter",
      resizable: true,
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      valueFormatter: (params: ValueFormatterParams) => {
        return params.value ? params.value.toFixed(3) : null;
      },
      headerValueGetter: (_: any) => $t("kpiResults-list-kpiNominalValue-label", { $: "Indicator's Nominal Value" })
    },
    {
      field: "kpiNormalizedValue",
      headerName: "KPI Normalized Value",
      sortable: true,
      editable: false,
      filter: "agNumberColumnFilter",
      resizable: true,
      cellStyle: (params: CellClassParams) => normalizedValueCellStyle(params, -1, 1),
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      valueFormatter: (params: ValueFormatterParams) => {
        return params.value ? params.value.toFixed(3) : null;
      },
      headerValueGetter: (_: any) => $t("kpiResults-list-kpiNormalizedValue-label", { $: "Indicator's Normalized Value" })
    },
    {
      field: "kpiDateFrom",
      filter: "agDateColumnFilter",
      editable: false,
      resizable: true,
      sortable: true,
      valueFormatter: (params: ValueFormatterParams) => {
        return params.value ? $dateFormatter(params.value) : null;
      },
      headerValueGetter: (_: any) => $t("location-list-kpiDateFrom-label", { $: "Calculation Date From" })
    },
    {
      field: "kpiDateTo",
      filter: "agDateColumnFilter",
      editable: false,
      resizable: true,
      sortable: true,
      valueFormatter: (params: ValueFormatterParams) => {
        return params.value ? $dateFormatter(params.value) : null;
      },
      headerValueGetter: (_: any) => $t("location-list-kpiDateTo-label", { $: "Calculation Date To" })
    },
    {
      field: "kpiValueContext",
      headerName: "KPI Value Context",
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      valueFormatter: (params: ValueFormatterParams) => {
        return params.data?.kpiValueContext;
      },
      cellRenderer: params => JSON.stringify(params.value, null, 2),
      headerValueGetter: (_: any) => $t("kpiResults-list-kpiValueContext-label", { $: "Calculation Value Context" }),
      valueGetter: (params: ValueGetterParams) => params.data?.kpiValueContext
    },
    {
      field: "calculationParameters",
      headerName: "Calculation Parameters",
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      valueFormatter: (params: ValueFormatterParams) => {
        return params.data?.calculationParameters;
      },
      cellRenderer: params => JSON.stringify(params.value, null, 2),
      headerValueGetter: (_: any) => $t("kpiResults-list-calculationParameters-label", { $: "Calculation Parameters" }),
      valueGetter: (params: ValueGetterParams) => params.data?.calculationParameters
    },
    {
      field: "createdBy",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      filter: "agTextColumnFilter",
      editable: false,
      resizable: true,
      sortable: true,
      headerValueGetter: (_: any) => $t("location-list-createdBy-label", { $: "Created By" }),
      floatingFilterComponentParams: {
        placeholder: $t("location-list-createdBy-label", { $: "Created By" })
      }
    },
    {
      field: "createdAt",
      type: ["dateTimeTypeColumn"],
      filter: "agDateColumnFilter",
      editable: false,
      resizable: true,
      sortable: true,
      valueFormatter: (params: ValueFormatterParams) => {
        return $dateTimeFormatter(params.data?.createdAt);
      },
      headerValueGetter: (_: any) => $t("location-list-createdAt-label", { $: "Created At" })
    },
    {
      field: "modifiedBy",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      filter: "agTextColumnFilter",
      editable: false,
      resizable: true,
      sortable: true,
      headerValueGetter: (_: any) => $t("location-list-modifiedBy-label", { $: "Modified By" }),
      floatingFilterComponentParams: {
        placeholder: $t("location-list-modifiedBy-label", { $: "Modified By" })
      }
    },
    {
      field: "modifiedAt",
      type: ["dateTimeTypeColumn"],
      filter: "agDateColumnFilter",
      editable: false,
      resizable: true,
      sortable: true,
      valueFormatter: (params: ValueFormatterParams) => {
        return $dateTimeFormatter(params.data?.modifiedAt);
      },
      headerValueGetter: (_: any) => $t("location-list-modifiedAt-label", { $: "Modified At" })
    }
  ];
}

const defaultColDef = ref({
  // allow every column to be aggregated
  enableValue: true,
  // allow every column to be grouped
  enableRowGroup: true,
  // allow every column to be pivoted
  enablePivot: true,
  floatingFilter: true,
  filterParams: {
    applyMiniFilterWhileTyping: true
  }
});
</script>

<template>
  <grid-wrapper
    ref="gridWrapperRef"
    identifier="kpi-results"
    :chart-tool-panels-def="chartToolPanelsDef"
    :enable-charts="true"
    refresh-btn
    enable-range-selection
    enable-columns-tool-panel
    enable-filters-tool-panel
    row-selection="multiple"
    :grid-data="kpiResults"
    group-display-type="multipleColumns"
    :pivot-mode="false"
    :default-col-def="defaultColDef"
    :hide-custom-actions-separator="true"
    :checkbox-selection="false"
    :enable-actions-column="false"
    :pivot-default-expanded="-1"
    :group-default-expanded="-1"
    @fetch-data="onFetchData"
    @refresh-action="onRefreshAction"
    @prepare-columns="onPrepareColumns"
  >
    <template #custom-buttons>
      <delete-kpi-grid-action :grid-wrapper="gridWrapperRef" />
      <calculate-kpi-grid-action :grid-wrapper="gridWrapperRef" />
    </template>
  </grid-wrapper>
</template>

<style lang="scss" scoped></style>
