﻿import type { IRowContainer } from "@masta/gantt2/core";
import { ActivityBounds, ActivityPosition, ActivityRef, ActivityRenderer, IActivityRenderingRequest, IocSymbols, TimelineManager } from "@masta/gantt2/core";
import { inject, injectable } from "inversify";
import { NoteActivity } from "src/components/Gantt/Common/Model";

@injectable()
export class ResourceNoteActivityRenderer extends ActivityRenderer<NoteActivity> {
  constructor(@inject(TimelineManager) timelineManager: TimelineManager, @inject(IocSymbols.RowContainer) rowContainer: IRowContainer) {
    super(timelineManager, rowContainer, ResourceNoteActivityRenderer.name);
    this.paddingInsets.left = 4;
    this.paddingInsets.top = 1;
    this.paddingInsets.right = 4;
  }

  draw(
    activityRef: ActivityRef<NoteActivity>,
    position: ActivityPosition,
    rowCanvasContext: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D,
    x: number,
    y: number,
    w: number,
    h: number,
    offsetTop: number,
    selected: boolean,
    hover: boolean,
    highlighted: boolean,
    pressed: boolean
  ): ActivityBounds | undefined {
    const note = activityRef.activity.userObject;
    y = !note.resourceId && note.taskId ? y : 0;
    w = 16;
    h = 16;
    return super.draw(activityRef, position, rowCanvasContext, x, y, w, h, offsetTop, selected, hover, highlighted, pressed);
  }

  drawActivity(request: IActivityRenderingRequest<NoteActivity>): void {
    const { activityBounds, activityRef, position, canvas, context, x, y, w, h, offsetTop, selected, hover, highlighted, pressed } = request;

    context.clearRect(0, 0, context.canvas.width, context.canvas.height);

    const { color } = activityRef.activity.userObject;

    context.globalAlpha = this.opacity;
    context.fillStyle = color !== null && color !== "" ? color : "#24c3ff";
    context.beginPath();

    const width = w;
    const height = h;
    const radius = 0.3125 * Math.min(width, height);
    const padding = radius;

    context.roundRect(x, y, width, height, [0, 0, radius, 0]);

    context.closePath();
    context.fill();

    context.strokeStyle = "#ffffff";
    context.lineWidth = 0.5;

    const distance = Math.round(h / 3) - Math.round(h / 3) / 4;

    context.beginPath();
    context.moveTo(x + padding, y + distance);
    context.lineTo(x + width - padding, y + distance);
    context.stroke();
    context.moveTo(x + padding, y + distance * 2);
    context.lineTo(x + width - padding, y + distance * 2);
    context.stroke();
    context.moveTo(x + padding, y + distance * 3);
    context.lineTo(x + width - padding, y + distance * 3);
    context.stroke();
    context.closePath();
  }
}
