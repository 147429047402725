import ApiService from "@/services/api";
import { defineStore } from "pinia";
import { UpdateResourceModelInstanceAssignmentCommand } from "@masta/generated-model";
import { useErrorsStore } from "@/store/ErrorsStore";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface State {}

export const useAssetsStore = defineStore("assets", {
  state: (): State => ({}),
  actions: {
    async updateModelInstanceAssignment(request: UpdateResourceModelInstanceAssignmentCommand) {
      try {
        await ApiService.resources.updateModelInstanceAssignment(request);
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    }
  }
});

