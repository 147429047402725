<script setup lang="ts">
import { inject, reactive, Ref, ref } from "vue";
import { GridWrapperComponent } from "@/components/Grid/GridWrapperComponent";
import GridWrapper from "@/components/Grid/GridWrapper.vue";
import { CellClassParams, CellStyle, ColDef, GridApi, KeyCreatorParams, ValueFormatterParams } from "ag-grid-community";
import { $t } from "@/i18n";
import { translateUserNoticeStatus, translateUserNoticeType } from "@/composables/translateEnum";
import { $dateTimeFormatterSymbol, DateFormatter } from "@masta/shared";
import {
  BusinessActionDto,
  BusinessActionResultStatus,
  BusinessActionStatus,
  UserNoticeCreatedOrChangedNotificationEvent,
  UserNoticeDto,
  UserNoticeStatus
} from "@masta/generated-model";
import { enumToEditorEntries, enumValueEntryWithLocaleComparator, translateEditorEntries } from "@/components/Grid/ColumnTypes";
import { useNotification, UserNoticeCreatedOrChangedNotification } from "@/notifications";
import { useDebounceFn } from "@vueuse/core/index";
import { useAuthStore } from "@/store/AuthStore";
import { UserNoticesServerSideDataSource } from "@/components/UserNotices/UserNoticesServerSideDataSource";
import { getSelectedRows } from "@/components/Grid/UseGridSelection";
import ApiService from "@/services/api";
import { useRouter } from "vue-router";

const gridWrapperRef = ref<GridWrapperComponent>();
const serverSideDataSource = reactive(new UserNoticesServerSideDataSource("user-notices"));
const $dateTimeFormatter = inject<DateFormatter>($dateTimeFormatterSymbol)!;

const authStore = useAuthStore();
const router = useRouter();
const emit = defineEmits(["close-dialog"]);

function closeDialog() {
  emit("close-dialog");
}

const isRowSelected = ref(false);
const selectedUserNotice = ref<UserNoticeDto>();

function onSelectionChanged({ api }: { api: GridApi }) {
  const selectedRows = getSelectedRows(api);
  isRowSelected.value = selectedRows.length > 0;
  selectedUserNotice.value = selectedRows.length === 1 ? selectedRows[0] : undefined;
}

async function markAcknowledged() {
  if (selectedUserNotice.value) {
    await ApiService.userNotices.markAcknowledged(selectedUserNotice.value.id);
    gridWrapperRef?.value?.gridApi.refreshServerSide();
  }
}

async function openUserNoticeHandler() {
  //TODO when other user notice types need to be handled, add a switch case or whatever
  if (selectedUserNotice.value) {
    await router.push({ name: "PickMaterialProviders", query: { userNoticeId: selectedUserNotice.value.id } });
    closeDialog();
  }
}

const defaultColumnDef = ref({
  filter: false,
  floatingFilter: true,
  filterParams: {
    applyMiniFilterWhileTyping: true
  },
  sortable: true,
  resizable: true
});

function onPrepareColumns(columnDefs: Ref<ColDef[]>) {
  columnDefs.value = [
    {
      field: "id",
      type: "textInputTypeColumn",
      headerValueGetter: (_: any) => $t("businessActions-list-id-label", { $: "Id" }),
      sortable: true,
      hide: true,
      filter: "agTextColumnFilter"
    },
    {
      field: "publishedAt",
      sortable: true,
      filter: "agDateColumnFilter",
      resizable: true,
      valueFormatter: (params: any) => {
        return $dateTimeFormatter(params.data.publishedAt);
      },
      type: "datepickerTypeColumn",
      headerValueGetter: (_: any) => $t("orderLine-list-publishedAt-label", { $: "Published At" })
    },
    {
      field: "username",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("userNotices-list-username-label", { $: "User" }),
      sortable: true,
      hide: true,
      filter: "agTextColumnFilter",
      floatingFilterComponentParams: {
        placeholder: $t("userNotices-list-username-label", { $: "User" })
      }
    },
    {
      field: "noticeType",
      type: ["enumTypeColumn", "setFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("userNotices-list-noticeType-label", { $: "Type" }),
      sortable: true,
      cellStyle: statusColumnCellStyle,
      valueFormatter: (params: any) => translateUserNoticeType(params.value),
      filter: "agSetColumnFilter",
      filterParams: {
        valueFormatter: (params: ValueFormatterParams) => params.value.key,
        keyCreator: (params: KeyCreatorParams) => params.value.value,
        values: translateEditorEntries(enumToEditorEntries(UserNoticeStatus), translateUserNoticeType),
        comparator: enumValueEntryWithLocaleComparator
      }
    },
    {
      field: "status",
      type: ["enumTypeColumn", "setFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("userNotices-list-status-label", { $: "Status" }),
      sortable: true,
      cellStyle: statusColumnCellStyle,
      valueFormatter: (params: any) => translateUserNoticeStatus(params.value),
      filter: "agSetColumnFilter",
      filterParams: {
        valueFormatter: (params: ValueFormatterParams) => params.value.key,
        keyCreator: (params: KeyCreatorParams) => params.value.value,
        values: translateEditorEntries(enumToEditorEntries(UserNoticeStatus), translateUserNoticeStatus),
        comparator: enumValueEntryWithLocaleComparator
      }
    },
    {
      field: "userNoticePayload",
      type: ["longTextTypeColumn", "textFloatingFilterColumnType"],
      headerValueGetter: (_: any) => $t("userNotices-list-payload-label", { $: "Payload" }),
      sortable: true,
      hide: true,
      filter: "agTextColumnFilter",
      floatingFilterComponentParams: {
        placeholder: $t("userNotices-list-payload-label", { $: "Payload" })
      }
    }
  ];
}

function statusColumnCellStyle(params: CellClassParams<BusinessActionDto, BusinessActionStatus>): CellStyle | null | undefined {
  switch (params.value) {
    case BusinessActionStatus.Waiting:
      return { backgroundColor: "rgb(255, 205, 255, 0.7)", color: "#6600cc" };
    case BusinessActionStatus.Running:
      return { backgroundColor: "rgb(204, 0, 204, 0.7)", color: "#fff" };
    case BusinessActionStatus.Finished:
      return { backgroundColor: "rgb(122, 99, 255, 0.7)", color: "#000" };
    default:
      return {};
  }
}

function resultStatusColumnCellStyle(params: CellClassParams<BusinessActionDto, BusinessActionResultStatus>): CellStyle | null | undefined {
  switch (params.value) {
    case BusinessActionResultStatus.Unknown:
      return { backgroundColor: "rgb(75, 0, 130, 0.7)", color: "#6600cc" };
    case BusinessActionResultStatus.Success:
      return { backgroundColor: "rgb(122, 99, 255, 0.7)", color: "#fff" };
    case BusinessActionResultStatus.Warning:
      return { backgroundColor: "rgb(204, 0, 204, 0.7)", color: "#fff" };
    case BusinessActionResultStatus.Error:
      return { backgroundColor: "rgb(75, 0, 130, 0.7)", color: "#fff" };
    default:
      return {};
  }
}

useNotification(UserNoticeCreatedOrChangedNotification, (e: UserNoticeCreatedOrChangedNotificationEvent) => {
  onUserNoticeCreatedOrChanged(e);
});

const onUserNoticeCreatedOrChanged: (e: UserNoticeCreatedOrChangedNotificationEvent) => void = useDebounceFn(async (e: UserNoticeCreatedOrChangedNotificationEvent) => {
  if (e.userId === authStore.user.sub) {
    gridWrapperRef?.value?.gridApi.refreshServerSide();
  }
}, 500);

</script>

<template>
  <grid-wrapper
    ref="gridWrapperRef"
    identifier="business-actions"
    :server-side="true"
    :server-side-datasource="serverSideDataSource"
    refresh-btn
    :default-col-def="defaultColumnDef"
    @prepare-columns="onPrepareColumns"
    @selection-changed="onSelectionChanged">
    <template #custom-buttons>
      <v-menu>
        <template #activator="{ props, isActive }">
          <v-btn :disabled="!isRowSelected" v-bind="props" size="small" variant="text" density="compact" class="mr-4">
            <span>{{ $t("userNotice-list-actions-label", { $: "Actions" }) }}</span>
            <v-icon class="pl-4" :icon="isActive ? 'mdi-chevron-up' : 'mdi-chevron-down'" />
          </v-btn>
        </template>
        <v-list density="compact">
          <v-tooltip bottom open-delay="300">
            <template #activator="{ props }">
              <v-list-item :disabled="!isRowSelected || selectedUserNotice?.status > UserNoticeStatus.InProgress" v-bind="props" @click="markAcknowledged">
                <template #prepend>
                  <v-icon icon="mdi-clipboard-check-outline"></v-icon>
                </template>
                <v-list-item-title>{{ $t("userNotice-list-markAcknowledged-action", { $: "Acknowledged" }) }}</v-list-item-title>
              </v-list-item>
            </template>
            <span>{{ $t("userNotice-list-markAcknowledged-action-tooltip", { $: "I have read and understood the notice" }) }}</span>
          </v-tooltip>
          <v-tooltip bottom open-delay="300">
            <template #activator="{ props }">
              <v-list-item :disabled="!isRowSelected || selectedUserNotice?.status > UserNoticeStatus.InProgress" v-bind="props" @click="openUserNoticeHandler">
                <template #prepend>
                  <v-icon icon="mdi-state-machine"></v-icon>
                </template>
                <v-list-item-title>{{ $t("userNotice-list-handle-action", { $: "Handle" }) }}</v-list-item-title>
              </v-list-item>
            </template>
            <span>{{ $t("userNotice-list-handle-action-tooltip", { $: "If there's an action associated with this notice you will be taken to an appropriate dialog" }) }}</span>
          </v-tooltip>
        </v-list>
      </v-menu>

    </template>
  </grid-wrapper>
</template>
