import { ContextMenuItems, GanttSettings, IContextMenuItemActionContext, IContextMenuProvider, Lifecycle, TimelineDataLoader } from "@masta/gantt2/core";
import { inject, injectable } from "inversify";
import { AppIocSymbols } from "@/components/Gantt/ResourcesGantt/AppIocSymbols";
import { useI18n } from "vue-i18n";
import { NoteHandler } from "@/components/Gantt/ResourcesGantt/NoteHandler";

@injectable()
export class ContextMenuProvider extends Lifecycle implements IContextMenuProvider {
  private $t: any;
  private _provider: (context: IContextMenuItemActionContext) => Promise<ContextMenuItems>;

  constructor(
    @inject(GanttSettings) private _ganttSettings: GanttSettings,
    @inject(AppIocSymbols.$i18n) private _i18n: ReturnType<typeof useI18n>,
    @inject(NoteHandler) private _noteHandler: NoteHandler,
    @inject(TimelineDataLoader) private _timelineDataLoader: TimelineDataLoader
  ) {
    super();
    this.$t = this._i18n.t;
  }

  setProvider(provider: (context: IContextMenuItemActionContext) => Promise<ContextMenuItems>): void {
    this._provider = provider;
  }

  async getContextMenuItems(context: IContextMenuItemActionContext): Promise<ContextMenuItems> {
    const defaultItems = await this.getDefaultContextMenuItems(context);
    if (this._provider) {
      return [...defaultItems, "separator", ...(await this._provider(context))];
    }
    return [...defaultItems];
  }

  private async getDefaultContextMenuItems(__: IContextMenuItemActionContext): Promise<ContextMenuItems> {
    const hasNote = this._noteHandler.hasNoteForContextMenuEvent(__.event);
    const noteActions = hasNote
      ? [
        {
          label: this.$t("gantt-resource-context-menu-editNote-label", { $: "Edit note" }),
          icon: "<span class=\"mdi mdi-note-edit\"></span>",
          action: async (context: IContextMenuItemActionContext) => {
            this._noteHandler.fireNoteClickEventForContextMenu(context.event);
          }
        }
      ]
      : [
        {
          label: this.$t("gantt-resource-context-menu-addNewResourceNote-label", { $: "Add new note" }),
          icon: "<span class=\"mdi mdi-note-plus\"></span>",
          action: async (context: IContextMenuItemActionContext) => {
            this._noteHandler.fireNoteClickEventForContextMenu(context.event);
          }
        }
      ];

    // intentionally disabled - requested by Dobek
    const refreshAction = [
      // {
      //   label: this.$t("gantt-resource-context-menu-refresh-label", { $: "Refresh all" }),
      //   icon: '<span class="mdi mdi-table-refresh"></span>',
      //   action: async (context: IContextMenuItemActionContext) => {
      //     await this._timelineDataLoader.refreshData();
      //   }
      // },
      // {
      //   label: this.$t("gantt-resource-context-menu-refreshRow-label", { $: "Refresh row" }),
      //   icon: '<span class="mdi mdi-refresh"></span>',
      //   action: async (context: IContextMenuItemActionContext) => {
      //     await this._timelineDataLoader.refreshData(undefined, undefined, [context.row.id]);
      //   }
      // }
    ];

    return [...noteActions, ...refreshAction];
  }
}
