<script lang="ts" setup>
import ResourceModelInstancesGrid from "@/components/ModelInstances/ResourceModelInstancesGrid.vue";
import {
  CreateDocumentForContextCommand,
  IResourceDto,
  ModelInstanceIdDto,
  ModelInstanceStatus,
  ModelSchemaDto,
  ResourceModelInstanceDto,
  UpdateDocumentCommand
} from "@masta/generated-model";
import EditModelInstance from "@/components/ModelInstances/EditModelInstance.vue";
import { computed, ref, watch } from "vue";
import { ModelInstanceChange } from "@/components/ModelInstances/ModelInstanceChange";
import { useModelInstancesStore } from "@/store/ModelInstancesStore";
import { $t } from "@/i18n";
import ResourceModelInstanceAssignment from "@/components/ModelInstances/ModelInstanceAssignment/ResourceModelInstanceAssignment.vue";
import ResourceLabel from "@/components/ModelInstances/ModelInstanceAssignment/ResourceLabel.vue";
import ApiService from "@/services/api";
import { useDocumentsStore } from "@/store/DocumentsStore";
import { useErrorsStore } from "@/store/ErrorsStore";
import { GridApi } from "ag-grid-community";
import SplitPanel from "@/components/Layout/SplitPanel.vue";


const props = defineProps<{
  resource: IResourceDto;
  schema: ModelSchemaDto;
  contextName: string;
}>();

const emit = defineEmits<{
  (e: "updated", id: string): void;
}>();

const miStore = useModelInstancesStore();
const documentStore = useDocumentsStore();

const selectedModelInstance = ref<ResourceModelInstanceDto | null>(null);
const createdModelInstanceId = ref<string | null>(null);
const createMode = ref(false);
const editModelInstanceAssignmentDialog = ref(false);

watch(
  () => props.resource,
  (newResource) => {
    if (!selectedModelInstance.value || !newResource || !newResource.modelInstances) {
      selectedModelInstance.value = null;
      return;
    }
    selectedModelInstance.value = newResource.modelInstances.find(
      (x) => x.id === selectedModelInstance.value.id && x.revisionNumber === selectedModelInstance.value.revisionNumber
    );
  }
);

const modelInstances = computed<ResourceModelInstanceDto[]>(() => {
  if (!props.resource || !props.resource.modelInstances || !props.schema) return [];
  return props.resource.modelInstances.filter((x) => x.schemaId === `${props.schema.schemaKey}.${props.schema.schemaName}` && x.schemaVersion === props.schema.version);
});

const showEditView = computed(() => selectedModelInstance.value || createMode.value);

function selectionChanged(modelInstance: ResourceModelInstanceDto | null) {
  selectedModelInstance.value = modelInstance;
  createMode.value = false;
}

function modelInstancesGridDataUpdated(gridApi: GridApi) {
  if (createdModelInstanceId.value) {
    gridApi.forEachNode((node) => {
      if (node.data.id === createdModelInstanceId.value) {
        createdModelInstanceId.value = null;
        node.setSelected(true);
      }
    });
  }
}

async function saveModelInstance(change: ModelInstanceChange, callback: (ok: boolean) => void) {
  let success = false;
  if (selectedModelInstance.value) {
    success = await miStore.updateModelInstance({
      id: selectedModelInstance.value.id,
      revisionNumber: selectedModelInstance.value.revisionNumber,
      ...change
    });
  } else {
    const id = await miStore.createInstance({
      ...change,
      schemaName: props.schema.schemaName,
      schemaKey: props.schema.schemaKey,
      schemaVersion: props.schema.version,
      release: false
    });

    createdModelInstanceId.value = id;
    success = id;

    if (success) {
      try {
        await ApiService.resources.assignModelInstance({
          resourceId: props.resource.id,
          resourceBusinessId: null,
          scenarioId: props.resource.scenarioId,
          modelInstanceId: id,
          modelInstanceBusinessId: null
        });
        success = true;
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        success = false;
      }
    }
  }
  if (success) {
    notifyUpdated();
    createMode.value = false;
  }
  callback(success);
}

async function saveDocument(change: ModelInstanceChange) {
  if (selectedModelInstance.value) {
    await documentStore.updateDocumentWithAttachment({
      businessId: selectedModelInstance.value.businessId,
      revisionNumber: selectedModelInstance.value.revisionNumber,
      id: selectedModelInstance.value.id,
      ...change.value.attachment
    } as UpdateDocumentCommand);
  } else {
    const id = await documentStore.createDocumentWithAttachment({
      contextName: props.contextName,
      businessId: change.businessId,
      tags: change.tags,
      ...change.value.attachment
    } as CreateDocumentForContextCommand);
    await ApiService.resources.assignModelInstance({
      resourceId: props.resource.id,
      resourceBusinessId: null,
      scenarioId: props.resource.scenarioId,
      modelInstanceId: id,
      modelInstanceBusinessId: null
    });
  }
  notifyUpdated();
  createMode.value = false;
}

async function editCancelled() {
  notifyUpdated();
  createMode.value = false;
}

async function releaseModelInstance() {
  if (selectedModelInstance.value) {
    await miStore.release(selectedModelInstance.value);
    notifyUpdated();
  }
}

async function archiveModelInstance() {
  if (selectedModelInstance.value && selectedModelInstance.value.status !== ModelInstanceStatus.Archived) {
    await miStore.archive(selectedModelInstance.value);
    notifyUpdated();
  }
}

async function createNewRevisionOfModelInstance() {
  if (selectedModelInstance.value) {
    await miStore.createNewRevision(selectedModelInstance.value);
    notifyUpdated();
  }
}

async function makeCopy() {
  if (selectedModelInstance.value) {
    const id = await miStore.makeCopy(selectedModelInstance.value);
    await ApiService.resources.assignModelInstance({
      resourceId: props.resource.id,
      resourceBusinessId: null,
      scenarioId: props.resource.scenarioId,
      modelInstanceId: id,
      modelInstanceBusinessId: null
    });
    notifyUpdated();
  }
}

async function createNewModelInstance() {
  selectedModelInstance.value = null;
  createMode.value = true;
}

async function deassignModelInstances() {
  if (selectedModelInstance.value) {
    await ApiService.resources.deassignModelInstance({
      resourceId: props.resource.id,
      resourceBusinessId: null,
      scenarioId: props.resource.scenarioId,
      modelInstanceId: selectedModelInstance.value.id,
      modelInstanceBusinessId: null
    });
    notifyUpdated();
  }
}

function assignExistingModelInstances() {
  editModelInstanceAssignmentDialog.value = true;
}

function assignmentCancelled() {
  editModelInstanceAssignmentDialog.value = false;
}

async function saveNewAssignment(ids: ModelInstanceIdDto[]) {
  editModelInstanceAssignmentDialog.value = false;
  await ApiService.resources.updateModelInstanceAssignment({
    id: props.resource.id,
    businessId: null,
    scenarioId: props.resource.scenarioId,
    modelInstanceIds: ids.map((x) => x.id),
    modelInstanceBusinessIds: null
  });
  notifyUpdated();
}

function createSplitPanelGutterElement(index: number, direction: "horizontal" | "vertical") {
  const gutter = document.createElement("div");
  gutter.classList.add("gutter-resource-model-instances");
  return gutter;
}

function notifyUpdated() {
  emit("updated", props.resource.id);
}
</script>

<template>
  <div class="flex-1-1 d-flex flex-column fill-height">
    <v-row class="fill-height" no-gutters>
      <split-panel 
          :identifier="`split-panel-${contextName}`" :sizes="[30,70]" :min-size="[50,50]" 
          direction="horizontal" class2="overflow-x-hidden" :gutter="createSplitPanelGutterElement">
        <template #panel-1>
          <v-card elevation="0" class="d-flex flex-column fill-height">
            <v-card-text class="fill-height pa-0">
              <v-row class="fill-height" no-gutters>
                <v-col cols="12">
                  <ResourceModelInstancesGrid
                    :model-instances="modelInstances"
                    hide-custom-actions-separator
                    @selection-changed="selectionChanged"
                    @data-updated="modelInstancesGridDataUpdated">
                    <template #custom-buttons>
                      <v-tooltip bottom open-delay="300">
                        <template #activator="{ props }">
                          <div class="d-inline-flex pr-4">
                            <v-btn size="small" variant="text" density="compact" v-bind="props" @click="createNewModelInstance">
                              <v-icon icon="mdi-plus" class="pr-4"></v-icon>
                              {{ $t("modelInstance-list-create-action", { $: "Add New" }) }}
                            </v-btn>
                          </div>
                        </template>
                        <span>{{ $t("modelInstance-list-create-tooltip", { $: "Add New" }) }}</span>
                      </v-tooltip>
                      <v-tooltip bottom open-delay="300">
                        <template #activator="{ props }">
                          <div class="d-inline-flex pr-4">
                            <v-btn size="small" variant="text" density="compact" v-bind="props" @click="assignExistingModelInstances">
                              <v-icon icon="mdi-format-list-checks" class="pr-4"></v-icon>
                              {{ $t("modelInstance-list-manageAssignments-action", { $: "Manage Assignments" }) }}
                            </v-btn>
                          </div>
                        </template>
                        <span>{{ $t("modelInstance-list-assign-tooltip", { $: "Assign/Deassign Exisiting" }) }}</span>
                      </v-tooltip>
                      <v-menu>
                        <template #activator="{ props, isActive }">
                          <v-btn :disabled="!selectedModelInstance" v-bind="props" size="small" variant="text" density="compact" class="mr-4">
                            <span>{{ $t("resourceModelInstances-list-actions-label", { $: "Actions" }) }}</span>
                            <v-icon class="pl-4" :icon="isActive ? 'mdi-chevron-up' : 'mdi-chevron-down'" />
                          </v-btn>
                        </template>
                        <v-list density="compact">
                          <v-tooltip bottom open-delay="300">
                            <template #activator="{ props }">
                              <v-list-item :disabled="!selectedModelInstance" v-bind="props" @click="createNewRevisionOfModelInstance">
                                <template #prepend>
                                  <v-icon icon="mdi-ab-testing"></v-icon>
                                </template>
                                <v-list-item-title>{{ $t("modelInstance-list-createNewVersion-action", { $: "1. Create New Version" }) }}</v-list-item-title>
                              </v-list-item>
                            </template>
                            <span>{{ $t("orderLine-list-release-action-tooltip", { $: "Create new version/revision of existing" }) }}</span>
                          </v-tooltip>
                          <v-tooltip bottom open-delay="300">
                            <template #activator="{ props }">
                              <v-list-item :disabled="!selectedModelInstance" v-bind="props" @click="releaseModelInstance">
                                <template #prepend>
                                  <v-icon icon="mdi-rocket-launch"></v-icon>
                                </template>
                                <v-list-item-title>{{ $t("modelInstance-list-release-action", { $: "2. Release" }) }}</v-list-item-title>
                              </v-list-item>
                            </template>
                            <span>{{ $t("modelInstance-list-release-action", { $: "Release" }) }}</span>
                          </v-tooltip>
                          <v-tooltip bottom open-delay="300">
                            <template #activator="{ props }">
                              <v-list-item :disabled="!selectedModelInstance" v-bind="props" @click="archiveModelInstance">
                                <template #prepend>
                                  <v-icon icon="mdi-archive-arrow-down-outline"></v-icon>
                                </template>
                                <v-list-item-title>{{ $t("modelInstance-list-archive-action", { $: "3. Archive" }) }}</v-list-item-title>
                              </v-list-item>
                            </template>
                            <span>{{ $t("modelInstance-list-archive-tooltip", { $: "Archive" }) }}</span>
                          </v-tooltip>
                        </v-list>
                      </v-menu>
                    </template>          
                  </ResourceModelInstancesGrid>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </template>
        <template #panel-2>
          <v-card v-if="showEditView" elevation="7" class="d-flex flex-column fill-height edit-model-instance-card">
            <v-card-text class="fill-height">
              <edit-model-instance
                v-if="showEditView"
                :context-name="contextName"
                title="test"
                :schema="schema"
                :model-instance="selectedModelInstance"
                @save="saveModelInstance"
                @save-document="saveDocument"
                @cancel="editCancelled"
              />
            </v-card-text>
          </v-card>
          <v-card v-if="!showEditView" elevation="7" class="d-flex flex-column fill-height no-edit-model-instance-card">
            <v-card-text class="fill-height">
              <v-row class="fill-height" no-gutters>
                <v-col cols="12" class="d-flex align-center justify-center">
                  <label class="text-secondary text-h6 font-weight-regular">
                    {{ $t("modelInstance-noModelInstanceSelected-label", { $: "No model instance selected" }) }}
                  </label>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </template>
      </split-panel>
    </v-row>

    <v-row>
      <v-col v-if="editModelInstanceAssignmentDialog">        
        <resource-model-instance-assignment
          :schema="schema"
          :model-instances="resource.modelInstances"
          :opened="editModelInstanceAssignmentDialog"
          @cancel="assignmentCancelled"
          @save="saveNewAssignment"
        >
          <resource-label type="Material" :business-id="resource.businessId" :name="resource.name" />
        </resource-model-instance-assignment>
      </v-col>
    </v-row>
  </div>
</template>

<style lang="scss">

.edit-model-instance-card {
  border: 2px solid rgb(var(--v-theme-primary));
  border-radius: 0;
  overflow-y: scroll;
}

.no-edit-model-instance-card {
  border-radius: 0;
}


.gutter-resource-model-instances {
  cursor: col-resize;
}
</style>
