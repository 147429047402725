﻿<script lang="ts" setup>
import AgreementsGrid from "@/components/Agreements/AgreementsGrid.vue";
import AssetsGrid from "@/components/Assets/AssetsGrid.vue";
import CloseCardButton from "@/components/CloseCardButton.vue";
import EquipmentGrid from "@/components/Equipment/EquipmentGrid.vue";
import LocationGrid from "@/components/Location/LocationGrid.vue";
import MaterialGrid from "@/components/Material/MaterialGrid.vue";
import PersonnelGrid from "@/components/Personnel/PersonnelGrid.vue";
import ServicesGrid from "@/components/Services/ServicesGrid.vue";
import { translateResourceType } from "@/composables/translateEnum";
import { $t } from "@/i18n";
import { ResourceDto, ResourceInfoDto, ResourceType } from "@masta/generated-model";
import { IRowNode, RowDoubleClickedEvent, RowSelectedEvent } from "ag-grid-community";
import { ref } from "vue";
import { VBtn } from "vuetify/lib/components/index.mjs";

const modelValue = defineModel<ResourceInfoDto | null | undefined>("resource", { required: true });
const dialog = defineModel<boolean>("dialog", { required: true, default: false });
const props = defineProps<{
  resourceTypes?: ResourceType[];
}>();
const selectedResource = ref<ResourceDto | null>(null);

const selectedResourceType = ref<ResourceType | null | undefined>(getInitSelectedResourceType());

const resourceTypeChipItems = ref<{ value: ResourceType, text: string }[]>(
  [
    ...isChipVisible([ResourceType.Material, ResourceType.MaterialGroup]) ? [{ value: ResourceType.Material, text: translateResourceType(ResourceType.Material) }] : [],
    ...isChipVisible([ResourceType.Person, ResourceType.PersonGroup]) ? [{ value: ResourceType.Person, text: translateResourceType(ResourceType.Person) }] : [],
    ...isChipVisible([ResourceType.Agreement, ResourceType.AgreementGroup]) ? [{ value: ResourceType.Agreement, text: translateResourceType(ResourceType.Agreement) }] : [],
    ...isChipVisible([ResourceType.Equipment, ResourceType.EquipmentGroup]) ? [{ value: ResourceType.Equipment, text: translateResourceType(ResourceType.Equipment) }] : [],
    ...isChipVisible([ResourceType.Location]) ? [{ value: ResourceType.Location, text: translateResourceType(ResourceType.Location) }] : [],
    ...isChipVisible([ResourceType.Asset]) ? [{ value: ResourceType.Asset, text: translateResourceType(ResourceType.Asset) }] : [],
    ...isChipVisible([ResourceType.Service]) ? [{ value: ResourceType.Service, text: translateResourceType(ResourceType.Service) }] : [],
  ]
);

function selectResource() {
  dialog.value = true;
}

function onBtnConfirm() {
  if (selectedResource.value) {
    modelValue.value = selectedResource.value;
  }
  dialog.value = false;
}

function onBtnCancel() {
  dialog.value = false;
}

function isChipVisible(resourceTypes: ResourceType[]) {
  if(props.resourceTypes == null || props.resourceTypes.length == 0) {
    return true;
  }

  return resourceTypes.some((resourceType) => props.resourceTypes!.includes(resourceType));
}

function getInitSelectedResourceType() {
  if (props.resourceTypes && props.resourceTypes.length > 0) {
    return props.resourceTypes[0];
  }

  return ResourceType.Material;
}

function rowSelected(event: RowSelectedEvent<ResourceDto>) {
  const row = event.data;
  
  if (row) {
    selectedResource.value = row;
  } else {
    selectedResource.value = null;
  }
}

function rowDoubleClicked(event: RowDoubleClickedEvent<ResourceDto>) {
  const row = event.data;

  if (row) {
    if (isRowSelectable(event.node)) {
      selectedResource.value = row;
      onBtnConfirm();
    }
  }
}

function isRowSelectable(row: IRowNode<ResourceDto>) {
  return !!row.data && modelValue.value?.id !== row.data.id;
}

function onSelectResource() {
  selectResource();
}

defineExpose({ onSelectResource });
</script>

<template>
  <v-dialog v-model="dialog" width="100%" height="80vh" @click:outside="onBtnCancel">
    <v-card min-width="300px" height="100%" class="reference-picker-card">
      <close-card-button @click="onBtnCancel"></close-card-button>
      <v-card-title class="pa-0">
        <div class="text-h4 pb-5">{{ $t("resourcePicker-list-chooseResource-label", { $: "Choose Resource" }) }}</div>
      </v-card-title>
      <v-card-subtitle>
        <v-chip-group 
          v-if="props.resourceTypes == null || (props.resourceTypes != null && props.resourceTypes.length > 1)" 
          v-model="selectedResourceType" class="d-flex justify-start">
            <v-chip
                v-for="item in resourceTypeChipItems"
                :key="item.value"
                variant="elevated"
                elevation="0"
                size="large"
                slim
                density="compact"
                class="resource-type-chip"
                :color="item.value === selectedResourceType ? 'primary' : ''"
                :value="item.value">
                {{ item.text }}
            </v-chip>
        </v-chip-group>        
      </v-card-subtitle>
      <v-card-text class="px-0">
        <material-grid 
            v-if="selectedResourceType === ResourceType.Material || selectedResourceType === ResourceType.MaterialGroup"
            :is-row-selectable="isRowSelectable"
            row-selection="single"
            @row-selected="rowSelected"
            @row-double-clicked="rowDoubleClicked" />
        <personnel-grid 
            v-if="selectedResourceType === ResourceType.Person || selectedResourceType === ResourceType.PersonGroup"
            :is-row-selectable="isRowSelectable"
            row-selection="single"
            @row-selected="rowSelected"
            @row-double-clicked="rowDoubleClicked" />
        <agreements-grid 
            v-if="selectedResourceType === ResourceType.Agreement || selectedResourceType === ResourceType.AgreementGroup" 
            :is-row-selectable="isRowSelectable"
            row-selection="single"
            @row-selected="rowSelected"
            @row-double-clicked="rowDoubleClicked" />
        <equipment-grid 
            v-if="selectedResourceType === ResourceType.Equipment || selectedResourceType === ResourceType.EquipmentGroup"
            :is-row-selectable="isRowSelectable"
            row-selection="single"
            @row-selected="rowSelected"
            @row-double-clicked="rowDoubleClicked" />            
        <location-grid 
            v-if="selectedResourceType === ResourceType.Location"
            :is-row-selectable="isRowSelectable"
            row-selection="single"
            @row-selected="rowSelected"
            @row-double-clicked="rowDoubleClicked" />  
        <assets-grid 
            v-if="selectedResourceType === ResourceType.Asset"
            :is-row-selectable="isRowSelectable"
            row-selection="single"
            @row-selected="rowSelected"
            @row-double-clicked="rowDoubleClicked" />  
        <services-grid 
            v-if="selectedResourceType === ResourceType.Service"
            :is-row-selectable="isRowSelectable"
            row-selection="single"
            @row-selected="rowSelected"
            @row-double-clicked="rowDoubleClicked" />
      </v-card-text>
      <v-card-actions class="pa-0">
        <div class="left-col pl-0">
          <v-btn variant="elevated" color="secondary" class="w-100" @click="onBtnCancel">
            {{ $t("resourcePicker-list-cancel-label", { $: "Cancel" }) }}
          </v-btn>
        </div>
        <div class="middle-col"></div>
        <div class="right-col pr-0">
          <v-btn variant="elevated" color="primary" class="w-100" @click="onBtnConfirm">
            {{ $t("resourcePicker-list-confirm-label", { $: "Confirm" }) }}
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss">
.resource-type-chip {
    background-color: rgb(var(--v-theme-primary), 0.3);
    color: rgb(var(--v-theme-primary));
    min-height: 26px;
}
</style>
