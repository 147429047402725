import { ApiClient } from "@/services/api/common.api";
import { CreateTagCommand, FindTagsResponse, GetTagsByGroupResponse, GetTagsResponse, TagDto, UpdateTagCommand } from "@masta/generated-model";

export default {
  async getTags() {
    return ApiClient.get<GetTagsResponse>(`/tags`);
  },
  async findTags(q: string) {
    return ApiClient.get<FindTagsResponse>(`/tags/query?q=${encodeURIComponent(q)}`);
  },
  async getTagByName(name: string) {
    const nameArg = encodeURIComponent(name);
    return ApiClient.get<TagDto>(`tags/${nameArg}`);
  },
  async getTagsByGroup(groupName = "") {
    const groupNameArg = encodeURIComponent(groupName ?? "");
    return ApiClient.get<GetTagsByGroupResponse>(`/tags/group/${groupNameArg}`);
  },
  async createTag(request: CreateTagCommand) {
    return ApiClient.post("/tags", request);
  },
  async updateTag(request: UpdateTagCommand) {
    const nameArg = encodeURIComponent(request.name!);
    return ApiClient.put(`/tags/${nameArg}`, request);
  },
  async deleteTag(name: string) {
    const nameArg = encodeURIComponent(name);
    return ApiClient.delete(`/tags/${nameArg}`);
  }
};
