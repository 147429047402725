import { inject, injectable } from "inversify";
import { GanttDomElement } from "../GanttDomElement";
import { filter, fromEvent, merge } from "rxjs";
import {
  createGraphicsDragEvent,
  createGraphicsMousePositionEvent,
  createGraphicsMouseWheelEvent,
  createMouseBtnPressedEvent,
  GanttEvents,
  IocSymbols,
  Lifecycle,
  TimelineManager
} from "../../Core";
import Hammer from "hammerjs";
import { DragState } from "../DragState";

@injectable()
export class ViewportPointerEventNoneScrollGlass extends GanttDomElement<HTMLDivElement> {
  private _hammer: HammerManager;
  private _dragState: DragState = { start: 0, end: 0, dragging: false, dragDeltaDifference: 0, previousDragDelta: 0 };

  constructor(
    @inject(GanttEvents) private _ganttEvents: GanttEvents,
    @inject(TimelineManager) private _timelineManager: TimelineManager,
    @inject(IocSymbols.NavigationScheme) private _navigationScheme: Lifecycle
  ) {
    super(ViewportPointerEventNoneScrollGlass.name, undefined, "viewport-pointer-event-none-scroll-glass");
  }

  async afterInitialize(): Promise<void> {
    this.subscribe(fromEvent<MouseEvent>(this.element, "click").subscribe(this.onMouseClick.bind(this)));
    this.subscribe(fromEvent<MouseEvent>(this.element, "mousemove").subscribe(this.onMouseMove.bind(this)));
    this.subscribe(fromEvent<MouseEvent>(this.element, "mouseenter").subscribe(this.onMouseEnter.bind(this)));
    this.subscribe(fromEvent<MouseEvent>(this.element, "mouseleave").subscribe(this.onMouseLeave.bind(this)));
    this.subscribe(fromEvent<PointerEvent>(this.element, "contextmenu").subscribe(this.onContextMenu.bind(this)));
    const mouseDown$ = fromEvent<PointerEvent>(this.element, "pointerdown");
    const mouseUp$ = fromEvent<PointerEvent>(this.element, "pointerup");

    this.subscribe(
      // combineLatest([mouseDown$, mouseUp$])
      //   .pipe(
      //     filter((e) => e[0].x === e[1].x && e[0].y === e[1].y), // ensure not mouse drag
      //     map((e) => e[0])
      //   )
      merge(mouseDown$, mouseUp$).subscribe(this.onMousePressed.bind(this))
    );
    this.subscribe(
      fromEvent<WheelEvent>(this.element, "wheel", { passive: false }) /* .pipe(filter((x) => x.ctrlKey || x.shiftKey)) */
        .subscribe((e: WheelEvent) => {
          this._ganttEvents.onMouseWheelEvent(createGraphicsMouseWheelEvent(e));
          e.preventDefault();
        })
    );

    this.initializeHammer();
  }

  private onMouseClick(e: MouseEvent) {
    this._ganttEvents.onMouseClickEvent(createGraphicsMousePositionEvent(e, this.element));
  }

  private onMouseMove(e: MouseEvent) {
    this._ganttEvents.onMousePositionEvent(createGraphicsMousePositionEvent(e, this.element));
  }

  private onMouseEnter(e: MouseEvent) {
    this._ganttEvents.onMouseEnterEvent(createGraphicsMousePositionEvent(e, this.element));
  }

  private onMouseLeave(e: MouseEvent) {
    this._ganttEvents.onMouseLeaveEvent(createGraphicsMousePositionEvent(e, this.element));
  }

  private onMousePressed(e: PointerEvent) {
    this._ganttEvents.onMousePressedEvent(createMouseBtnPressedEvent(e, this.element));
  }

  private onContextMenu(e: PointerEvent) {
    this._ganttEvents.onContextMenuEvent(createMouseBtnPressedEvent(e, this.element));
  }

  private initializeHammer() {
    this._hammer = new Hammer(this.element) as HammerManager;
    this._hammer.get("pan").set({
      threshold: 5,
      direction: Hammer.DIRECTION_ALL
    });
    const hammer$ = fromEvent(this._hammer, "panstart panmove panend touch pinch");
    this.subscribe(hammer$.pipe(filter((e) => e.type === "panstart")).subscribe((x) => this.onPanStart(x)));
    this.subscribe(hammer$.pipe(filter((e) => e.type === "panmove")).subscribe((x) => this.onPanMove(x)));
    this.subscribe(hammer$.pipe(filter((e) => e.type === "panend")).subscribe((x) => this.onPanEnd(x)));
    this.subscribe(hammer$.pipe(filter((e) => e.type === "touch")).subscribe((x) => this.onTouch(x)));
    this.subscribe(hammer$.pipe(filter((e) => e.type === "punch")).subscribe((x) => this.onPinch(x)));
  }

  private onPanStart(event: HammerInput) {
    this._dragState.dragDeltaDifference = 0;
    this._dragState.previousDragDelta = 0;

    this._dragState.start = this._timelineManager.startTime.toEpochMilli();
    this._dragState.end = this._timelineManager.startTime.toEpochMilli();

    this._dragState.dragging = true;
    this._ganttEvents.onPanStartEvent(createGraphicsDragEvent(event));
  }

  private onPanMove(event: HammerInput) {
    this._ganttEvents.onPanMoveEvent(createGraphicsDragEvent(event));
  }

  private onPanEnd(event: HammerInput) {
    this._ganttEvents.onPanEndEvent(createGraphicsDragEvent(event));
  }

  private onTouch(x: HammerInput) {
  }

  private onPinch(x: HammerInput) {
  }
}
