import { inject, injectable } from "inversify";
import { GanttEvents, TimelineManager } from "../../../../Core";
import { LayerCanvas } from "./LayerCanvas";

@injectable()
export class NowLineLayer extends LayerCanvas {
  constructor(@inject(TimelineManager) timelineManager: TimelineManager, @inject(GanttEvents) ganttEvents: GanttEvents) {
    super(timelineManager, ganttEvents, NowLineLayer.name, "now-line-layer");
  }

  async beforeInitialize(): Promise<void> {
    return super.beforeInitialize();
  }

  async afterInitialize(): Promise<void> {
    await super.afterInitialize();
    this.subscribe(this._timelineManager.nowTime$.subscribe(() => this.batchDraw()));
    this.subscribe(this._ganttEvents.timelineRefreshEvent$.subscribe(() => this.batchDraw()));
  }

  async doDrawFromBatch(): Promise<void> {
    this.clear();

    const zoneId = this._timelineManager.zoneId;
    const now = this._timelineManager.nowTime.atZone(zoneId).toInstant();
    const x = this._timelineManager.calculateLocationForTime(now);
    const offset = this.offset;

    this.context.lineWidth = 2.0;
    this.context.strokeStyle = "#D32F2F";

    const { clientHeight } = this.canvas;

    this.context.beginPath();
    this.context.moveTo(x + offset, 0);
    this.context.lineTo(x + offset, clientHeight);
    this.context.closePath();
    this.context.stroke();
  }
}
