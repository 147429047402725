<script lang="ts" setup>
import { GanttNoteDto } from "@masta/generated-model";
import { $t } from "@/i18n";
import { computed, ref, toRef, watch } from "vue";
import type { VForm } from "vuetify/components";
import Api from "@/services/api";

const props = defineProps<{ modelValue: boolean; note: GanttNoteDto }>();
const emit = defineEmits(["save", "delete", "update:modelValue"]);
const model = toRef(props, "note");
const show = toRef(props, "modelValue");
const valid = ref(true);
const form = ref<VForm>();

const isEditMode = computed(() => !!model.value.id);

watch(valid, async (v) => {
  if (v === null) valid.value = (await form?.value?.validate())?.valid ?? true; // TODO: https://github.com/vuetifyjs/vuetify/issues/15568
});

const rules = [(v: any) => !!v || $t("ganttNoteActionModal-rule-required", { $: "Field is required" })];

async function save() {
  const validationResult = await form.value?.validate();
  if (validationResult?.valid) {
    let note: GanttNoteDto;
    if (model.value.id && model.value.id !== "") {
      await Api.gantt.updateGanttNote(model.value as any);
      note = model.value;
    } else {
      if (model.value?.taskId) {
        const { data } = await Api.gantt.createGanttTaskNote(model.value as any);
        note = data as GanttNoteDto;
      } else {
        const { data } = await Api.gantt.createGanttResourceNote(model.value as any);
        note = data as GanttNoteDto;
      }
    }
    emit("save", note);
    emit("update:modelValue", false);
  }
}

async function onDeleteNoteClick() {
  if (!isEditMode.value) return;
  await Api.gantt.deleteGanttNote(model.value);
  emit("delete", model.value);
  emit("update:modelValue", false);
}
</script>

<template>
  <v-dialog :model-value="modelValue" width="auto" @update:model-value="emit('update:modelValue', $event)">
    <template #default="{ isActive }">
      <v-form ref="form" v-model="valid" validate-on="input" @submit.prevent="save()">
        <v-card class="pa-8" data-cy="gantt-note-modal">
          <v-card-title class="d-flex align-center justify-space-between">
            <span v-if="isEditMode" class="text-h4">{{ $t("ganttNoteActionModal-edit-title", { $: "Edit note" }) }}</span>
            <span v-else class="text-h4">{{ $t("ganttNoteActionModal-add-title", { $: "Add note" }) }}</span>
            <v-btn density="compact" variant="text" icon="mdi-close" @click="isActive.value = false" />
            <!--            <v-btn class="float-end" density="compact" icon="mdi-close" :disabled="!isEditMode" @click="onDeleteNoteClick" />-->
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-text-field v-model="model.name" :label="$t('ganttNoteActionModal-nameField-label', { $: 'Name' })" variant="outlined" density="compact" hide-details="auto" />
              </v-col>
              <v-col cols="6">
                <v-menu :close-on-content-click="false">
                  <template #activator="{ props }">
                    <v-text-field
                      :model-value="model.color"
                      v-bind="props"
                      :label="$t('ganttNoteActionModal-colorField-label', { $: 'Color' })"
                      readonly
                      variant="outlined"
                      density="compact"
                      hide-details="auto"
                    >
                      <template #prepend-inner>
                        <v-sheet :style="{ backgroundColor: model.color }" width="1em" height="1em" rounded></v-sheet>
                      </template>
                    </v-text-field>
                  </template>
                  <v-color-picker v-model="model.color" />
                </v-menu>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="model.text"
                  :label="$t('ganttNoteActionModal-textField-label', { $: 'Content' })"
                  :rules="rules"
                  variant="outlined"
                  density="compact"
                  hide-details="auto"
                  data-cy="content"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-row>
              <v-col cols="6">
                <v-btn variant="elevated" color="secondary" block data-cy="cancel-btn" @click="isActive.value = false">
                  {{ $t("ganttNoteActionModal-cancel-action", { $: "Cancel" }) }}
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn type="submit" variant="elevated" :color="valid ? 'primary' : 'grey'" block :disabled="!valid" data-cy="save-btn">
                  {{ $t("ganttNoteActionModal-save-action", { $: "Save" }) }}
                </v-btn>
              </v-col>
              <v-divider v-show="isEditMode" class="ma-4" />
              <v-col v-show="isEditMode" cols="12">
                <v-btn variant="elevated" color="secondary" block prepend-icon="mdi-delete" data-cy="delete-btn" @click="onDeleteNoteClick">
                  {{ $t("ganttNoteActionModal-delete-action", { $: "Delete note" }) }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-form>
    </template>
  </v-dialog>
</template>

<style lang="scss" scoped></style>
