import type { interfaces } from "inversify";
import { inject } from "inversify";
import { BehaviorSubject, Observable } from "rxjs";
import { Lifecycle } from "../Lifecycle";
import { ObservedObject } from "../ObserveObject";
import { IocSymbols } from "../IocSymbols";

export interface IRowInfoColumnRenderer {
  render(column: IRowInfoColumn): Promise<string>;
}

export interface IRowInfoColumn {
  id: string;
  className: string;
  width: number;
  visible: boolean;
}

export interface IRowInfoColumnHeader extends IRowInfoColumn {
  text: string;
}

export interface IRowInfoCell {
  cellId: string;

  setColumn(column: IRowInfoColumn): void;
}

export interface IRowInfoColumnCellRegistry {
  register(columnId: string, cellClass: new (...args: any[]) => IRowInfoCell): void;

  bindCells(container: interfaces.Container): void;

  createCell(column: IRowInfoColumn): Promise<any>;
}

export interface IRowInfoColumnProvider {
  computedInfoWidth: Observable<number>;
  columns: IRowInfoColumn[];
  headers: IRowInfoColumnHeader[];

  setColumnWidth(id: string, width: number): void;

  observeColumn(id: string): Observable<IRowInfoColumn> | undefined;

  setColumnEnabled(id: string, enabled: boolean): void;
}

export abstract class BaseRowInfoColumnProvider extends Lifecycle implements IRowInfoColumnProvider {
  private _computedInfoWidth$$ = new BehaviorSubject<number>(250);

  protected abstract _columns: ObservedObject<IRowInfoColumn>[];
  protected abstract _headers: IRowInfoColumnHeader[];

  protected constructor(@inject(IocSymbols.HtmlContainerSymbol) private _container: HTMLDivElement) {
    super();
  }

  async beforeInitialize(): Promise<void> {
    this._computedInfoWidth$$.subscribe((w) => {
      this._container.style.setProperty("--gantt-info-container-width", `${w}px`);
    });

    this._columns.forEach((c) => {
      this.subscribe(
        this.observeColumn(c.proxy.id)!.subscribe(() => {
          this.computeInfoWidth();
        })
      );
    });
  }

  async afterInitialize(): Promise<void> {
    this.computeInfoWidth();
  }

  get computedInfoWidth(): Observable<number> {
    return this._computedInfoWidth$$.asObservable();
  }

  get columns(): IRowInfoColumn[] {
    return this._columns.map((c) => c.proxy);
  }

  get headers(): IRowInfoColumnHeader[] {
    return this._headers;
  }

  setColumnWidth(id: string, width: number): void {
    const observedColumn = this._columns.find((c) => c.proxy.id === id);
    if (observedColumn) {
      observedColumn.proxy.width = width;
      this.computeInfoWidth();
    }
  }

  observeColumn(id: string): Observable<IRowInfoColumn> | undefined {
    return this._columns.find((c) => c.proxy.id === id)?.changes$;
  }

  private computeInfoWidth() {
    const width = this._columns.reduce((acc, c) => acc + (c.proxy.visible === false ? 0 : (c.proxy.width ?? 0)), 0);
    const indexWidth = 15;
    this._computedInfoWidth$$.next(indexWidth + width);
  }

  public setColumnEnabled(id: string, enabled: boolean): void {
    const observedColumn = this._columns.find((c) => c.proxy.id === id);
    if (observedColumn) {
      observedColumn.proxy.visible = enabled;
    }
  }
}
