import {
  MaterialDto,
  ProcessDraftStatus,
  ProductTemplateDetailsDto,
  ResourceDto,
  ResourceInfoDto,
  StepResourceAssignmentType,
  StepResourceSpecDto,
  StepResourceUsageType,
  StepResourceUsageTypeDetails,
  StepTemplateDto,
  StepType,
  TaskSubType
} from "@masta/generated-model";
import { ProductTemplateDraftType, StepDraftResourceSpec, StepDraft, ProductTemplateDraft, ProcessDraft } from "@/components/ProcessDrafts/ProcessDraftModels";

export function createEmptyProcessDraft(res: ResourceInfoDto | null): ProcessDraft {
  return {
    name: getProcessNameFromResource(res),
    status: ProcessDraftStatus.WorkInProgress,
    productTemplates: [],
    targetResource: res
  };
}

export function createProcessDraftDuplicate(draft: ProcessDraft): ProcessDraft {
  return {
    name: draft.name,
    status: ProcessDraftStatus.WorkInProgress,
    productTemplates: draft.productTemplates,
    targetResource: draft.targetResource
  };
}

export function createEmptyProductTemplateDraft(): ProductTemplateDraft {
  return {
    position: 0,
    name: "",
    businessId: "",
    wbs: "",
    taskType: ProductTemplateDraftType.ProductionTask,
    steps: [],
    childProductTemplates: [],
    isTopLevel: true,
    resource: null,
    tags: []
  };
}

export function createStubProductTemplateDraftForResource(res: ResourceInfoDto, name = ""): ProductTemplateDraft {
  return {
    position: 0,
    name: name,
    businessId: "",
    wbs: "",
    taskType: ProductTemplateDraftType.ProductionTask,
    steps: [
      {
        name: "",
        type: StepType.Production,
        position: 10,
        processingTime: "",
        quantityPerTime: 1,
        specs: [
          {
            usageType: StepResourceUsageType.Produce,
            resourceType: res.type,
            assignmentType: StepResourceAssignmentType.Alternatives,
            resources: [res],
            quantity: 1,
            thresholdQuantity: 0,
            usageTypeDetail: StepResourceUsageTypeDetails.Default
          }
        ]
      }
    ],
    childProductTemplates: [],
    isTopLevel: true,
    resource: res,
    tags: []
  };
}

export function createMaterialInfo(res: MaterialDto): ResourceInfoDto {
  return {
    id: res.id,
    businessId: res.businessId,
    type: res.type,
    name: res.name,
    scenarioId: res.scenarioId,
    plannable: res.plannable
  };
}

export function createDraftFromTemplate(template: ProductTemplateDetailsDto, productTemplates: ProductTemplateDetailsDto[], useExistingTemplate: boolean): ProductTemplateDraft {
  return {
    position: 0,
    name: template.name ?? "",
    businessId: template.businessId ?? "",
    wbs: "",
    taskType: getProductTemplateDraftType(template.taskSubType),
    steps: createSteps(template.steps),
    childProductTemplates: createChildTemplateDrafts(template, productTemplates, useExistingTemplate),
    isTopLevel: template.id === template.rootTaskId,
    resource: template.resource ? createResourceInfo(template.resource) : null,
    tags: template.tags ?? [],
    useExistingTemplate: useExistingTemplate
  };
}

export function getProcessNameFromResource(resource: ResourceInfoDto | null) {
  return resource?.businessId ?? "";
}

function createChildTemplateDrafts(parentTemplate: ProductTemplateDetailsDto, productTemplates: ProductTemplateDetailsDto[], useExistingTemplate: boolean) {
  const childTemplates = productTemplates.filter((x) => x.parentTaskId === parentTemplate.id);
  if (childTemplates.length <= 0) return [];

  return childTemplates.map((x) => createDraftFromTemplate(x, productTemplates, useExistingTemplate));
}

function getProductTemplateDraftType(taskSubType: TaskSubType) {
  switch (taskSubType) {
    case TaskSubType.WorkOrder:
      return ProductTemplateDraftType.WorkOrder;
    default:
      return ProductTemplateDraftType.ProductionTask;
  }
}

function createSteps(steps: StepTemplateDto[] | null) {
  if (!steps) return [];

  return steps.map((x) => createStep(x));
}

function createStep(step: StepTemplateDto): StepDraft {
  return {
    position: step.position,
    name: step.name ?? "",
    quantityPerTime: step.quantityPerTime,
    processingTime: step.processingTime,
    type: step.stepType,
    specs: createSpecs(step.resources)
  };
}

function createSpecs(specs: StepResourceSpecDto[] | null) {
  if (!specs) return [];

  return specs.map((x) => createSpec(x));
}

function createSpec(spec: StepResourceSpecDto): StepDraftResourceSpec {
  return {
    assignmentType: spec.assignmentType,
    usageType: spec.usageType,
    resourceType: spec.resourceType,
    quantity: spec.quantity,
    usageTypeDetail: spec.usageTypeDetails,
    thresholdQuantity: spec.thresholdQuantity,
    resources: createResourceInfos(spec.resources)
  };
}

function createResourceInfos(resources: ResourceDto[] | null) {
  if (!resources) return [];

  return resources.map((x) => createResourceInfo(x));
}

function createResourceInfo(res: ResourceDto): ResourceInfoDto {
  return {
    id: res.id,
    businessId: res.businessId,
    type: res.type,
    name: res.name,
    scenarioId: res.scenarioId,
    plannable: res.plannable
  };
}
