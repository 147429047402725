<script lang="ts" setup>
import { FeatureDefinition, TextFeatureDefinition } from "@masta/generated-model";
import { $t } from "@/i18n";
import { isValidRegExpRule, requiredRule } from "@/components/ValueCellEditor/CommonValidationRules";

const props = defineProps<{
  modelValue: TextFeatureDefinition;
  disabled: boolean;
}>();

const emit = defineEmits(["update:modelValue", "remove"]);

function update(propertyName: string, value: any) {
  const newFeature = { ...props.modelValue, [propertyName]: value };
  emit("update:modelValue", newFeature);
}

const nameRules = [
  (v: string) => !!v || $t("featureDefinition-textEditor-onNameRequired-message", { $: "Name is required" }),
  (v: string) => (v && v.length > 0) || $t("featureDefinition-textEditor-onNameNotEmptyRequired-message", { $: "Name must not be empty" })
];
</script>

<template>
  <v-col cols="5" xl="6">
    <v-row>
      <v-col cols="3">
        <v-text-field
          variant="outlined"
          density="compact"
          hide-details
          :rules="[requiredRule]"
          :label="$t('featureDefinition-textEditor-name-label', { $: 'Name' })"
          :model-value="modelValue.name"
          :disabled="disabled"
          @update:model-value="update('name', $event)"
        />
      </v-col>
      <v-col cols="3">
        <v-text-field
          variant="outlined"
          density="compact"
          hide-details
          :label="$t('featureDefinition-textEditor-label-label', { $: 'Label' })"
          :model-value="modelValue.label"
          :disabled="disabled"
          @update:model-value="update('label', $event)"
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          variant="outlined"
          density="compact"
          hide-details
          :label="$t('featureDefinition-textEditor-description-label', { $: 'Description' })"
          :model-value="modelValue.description"
          :disabled="disabled"
          @update:model-value="update('description', $event)"
        />
      </v-col>
    </v-row>
  </v-col>
  <v-col cols="6" xl="5">
    <v-row>
      <v-col cols="3" xl="2" offset-xl="1">
        <v-switch
          variant="outlined"
          density="compact"
          hide-details
          color="primary"
          :label="$t('featureDefinition-textEditor-required-label', { $: 'Required' })"
          :model-value="modelValue.isRequired"
          :disabled="disabled"
          @update:model-value="update('isRequired', $event)"
        />
      </v-col>
      <v-col cols="2" class="text-center d-flex align-center justify-center">
        <v-chip size="x-large" label style="width: 100%">
          {{ $t("featureDefinition-textEditor-text-label", { $: "Text" }) }}
        </v-chip>
      </v-col>
      <v-col cols="6">
        <v-text-field
          variant="outlined"
          density="compact"
          hide-details
          :rules="[isValidRegExpRule]"
          :label="$t('featureDefinition-textEditor-regularExpression-label', { $: 'Regular Expression' })"
          :model-value="modelValue.regex"
          :disabled="disabled"
          @update:model-value="update('regex', $event)"
        />
      </v-col>
    </v-row>
  </v-col>
  <v-col cols="1" class="text-right d-flex align-center justify-center">
    <v-btn variant="text" icon="mdi-close" size="large" hide-details :disabled="disabled" @click="emit('remove')"></v-btn>
  </v-col>
</template>
