import { ProductTemplateDraftType, StepDraftResourceSpec, StepDraft, ProductTemplateDraft, ProcessDraft } from "@/components/ProcessDrafts/ProcessDraftModels";
import {
  CreateProductTemplateDraftDto,
  CreateStepDraftDto,
  CreateStepDraftResourceSpecDto,
  ProcessDraftDto,
  ProductTemplateDraftDto,
  StepDraftDto,
  StepDraftResourceSpecDto
} from "@masta/generated-model";
import { asJiraFormattedString, asServerSideDurationFormattedString } from "@masta/shared";

export function mapCreateProductTemplateDraftsFromModel(productTemplateDrafts: ProductTemplateDraft[]): CreateProductTemplateDraftDto[] {
  return productTemplateDrafts.map((x) => mapCreateProductTemplateDraftFromModel(x));
}

function mapCreateProductTemplateDraftFromModel(draft: ProductTemplateDraft): CreateProductTemplateDraftDto {
  return {
    name: draft.name,
    resourceId: draft.resource?.id ?? null,
    resourceBusinessId: null,
    businessId: draft.businessId,
    tags: draft.tags,
    wbs: draft.wbs,
    childProductTemplates: mapCreateProductTemplateDraftsFromModel(draft.childProductTemplates),
    steps: draft.steps.map((x) => mapCreateStepFromModel(x)),
    useExistingTemplate: draft.useExistingTemplate ?? false
  };
}

function mapCreateStepFromModel(step: StepDraft): CreateStepDraftDto {
  return {
    name: step.name,
    position: step.position,
    processingTime: asServerSideDurationFormattedString(step.processingTime) ?? "0:00:00",
    quantity: step.quantityPerTime,
    type: step.type,
    specs: step.specs.map((x) => mapCreateSpecFromModel(x))
  };
}

function mapCreateSpecFromModel(spec: StepDraftResourceSpec): CreateStepDraftResourceSpecDto {
  return {
    resources: spec.resources?.map((x) => x.id) ?? [],
    externalResources: null,
    resourceType: spec.resourceType,
    quantity: spec.quantity,
    assignmentType: spec.assignmentType,
    usageType: spec.usageType,
    thresholdQuantity: spec.thresholdQuantity,
    usageTypeDetails: spec.usageTypeDetail,
    isBase: spec.isBase
  };
}

export function mapProcessDraftToModel(dto: ProcessDraftDto): ProcessDraft {
  return {
    id: dto.id,
    name: dto.name,
    status: dto.status,
    productTemplates: mapProductTemplateDraftsToModel(dto.productTemplates ?? []),
    targetResource: dto.targetResource
  };
}

export function mapProductTemplateDraftsToModel(dtos: ProductTemplateDraftDto[] | null, topLevel = true): ProductTemplateDraft[] {
  if (!dtos) return [];
  return dtos.map((x, i) => mapProductTemplateDraftToModel(x, i, topLevel)) ?? [];
}

function mapProductTemplateDraftToModel(dto: ProductTemplateDraftDto, pos: number, topLevel = true): ProductTemplateDraft {
  return {
    name: dto.name ?? "",
    wbs: dto.wbs ?? "",
    tags: dto.tags ?? [],
    businessId: dto.businessId ?? "",
    position: pos,
    resource: dto.resource,
    taskType: dto.childProductTemplates && dto.childProductTemplates.length > 0 ? ProductTemplateDraftType.WorkOrder : ProductTemplateDraftType.ProductionTask,
    isTopLevel: topLevel,
    childProductTemplates: mapProductTemplateDraftsToModel(dto.childProductTemplates, false),
    steps: mapStepsToModel(dto.steps),
    useExistingTemplate: dto.useExistingTemplate
  };
}

function mapStepsToModel(dtos: StepDraftDto[] | null): StepDraft[] {
  if (!dtos) return [];
  return dtos.map((x, i) => mapStepToModel(x, i)) ?? [];
}

function mapStepToModel(dto: StepDraftDto, pos: number): StepDraft {
  return {
    name: dto.name ?? "",
    position: (pos + 1) * 10,
    type: dto.type,
    processingTime: asJiraFormattedString(dto.processingTime) ?? "",
    quantityPerTime: dto.quantity,
    specs: mapSpecsToModel(dto.specs)
  };
}

function mapSpecsToModel(dtos: StepDraftResourceSpecDto[] | null): StepDraftResourceSpec[] {
  if (!dtos) return [];
  return dtos.map((x) => mapSpecToModel(x)) ?? [];
}

function mapSpecToModel(dto: StepDraftResourceSpecDto): StepDraftResourceSpec {
  return {
    resources: dto.resources ?? [],
    resourceType: dto.resourceType,
    usageType: dto.usageType,
    assignmentType: dto.assignmentType,
    quantity: dto.quantity,
    thresholdQuantity: dto.thresholdQuantity,
    usageTypeDetail: dto.thresholdQuantity,
    isBase: dto.isBase
  };
}
