<script lang="ts" setup>
import { InformativeQualitativeFeatureDefinition } from "@masta/generated-model";
import { $t } from "@/i18n";
import { requiredNotEmptyArrayRule, requiredRule } from "@/components/ValueCellEditor/CommonValidationRules";

const props = defineProps<{
  modelValue: InformativeQualitativeFeatureDefinition;
  disabled: boolean;
}>();

const emit = defineEmits(["update:modelValue", "remove"]);

function update(propertyName: string, value: any) {
  const newFeature = { ...props.modelValue, [propertyName]: value };
  emit("update:modelValue", newFeature);
}

function isMatchingValueRule(value: any) {
  if (!value || value.length === 0) {
    return true;
  }
  if (!props.modelValue.values || !props.modelValue.values.includes(value)) {
    return $t("featureDefinition-qualitativeEditor-onMatchingValueNotInEnum-message", { $: "Matching value must be from defined values" });
  }
  return true;
}
</script>

<template>
  <v-col cols="5" xl="6">
    <v-row>
      <v-col cols="3">
        <v-text-field
          variant="outlined"
          density="compact"
          hide-details
          :rules="[requiredRule]"
          :label="$t('featureDefinition-informativeQualitativeEditor-name-label', { $: 'Name' })"
          :model-value="modelValue.name"
          :disabled="disabled"
          @update:model-value="update('name', $event)"
        />
      </v-col>
      <v-col cols="3">
        <v-text-field
          variant="outlined"
          density="compact"
          hide-details
          :label="$t('featureDefinition-informativeQualitativeEditor-label-label', { $: 'Label' })"
          :model-value="modelValue.label"
          :disabled="disabled"
          @update:model-value="update('label', $event)"
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          variant="outlined"
          density="compact"
          hide-details
          :label="$t('featureDefinition-informativeQualitativeEditor-description-label', { $: 'Description' })"
          :model-value="modelValue.description"
          :disabled="disabled"
          @update:model-value="update('description', $event)"
        />
      </v-col>
    </v-row>
  </v-col>
  <v-col cols="6" xl="5">
    <v-row>
      <v-col cols="3" xl="2" offset-xl="1">
        <v-switch
          variant="outlined"
          density="compact"
          hide-details
          color="primary"
          :label="$t('featureDefinition-informativeQualitativeEditor-required-label', { $: 'Required' })"
          :model-value="modelValue.isRequired"
          :disabled="disabled"
          @update:model-value="update('isRequired', $event)"
        />
      </v-col>
      <v-col cols="2" class="d-flex align-center justify-center">
        <v-chip size="x-large" label style="width: 100%; text-wrap: balance">
          {{ $t("featureDefinition-informativeQualitativeEditor-qualitative-label", { $: "Informative Qualitative" }) }}
        </v-chip>
      </v-col>
      <v-col cols="5" xl="4">
        <div class="d-flex justify-start">
          <v-combobox
            variant="outlined"
            density="compact"
            hide-details
            class="mx-2"
            :rules="[requiredNotEmptyArrayRule]"
            :model-value="modelValue.values"
            :disabled="disabled"
            :items="modelValue.values"
            chips
            clearable
            :label="$t('featureDefinition-informativeQualitativeEditor-enumValues-label', { $: 'Enum Values' })"
            multiple
            solo
            @update:model-value="update('values', $event)"
          >
            <template #selection="{ attrs, item, selected }">
              <v-chip v-bind="attrs" :input-value="selected" close>
                <strong>{{ item }}</strong
                >&nbsp;
                <span>{{ $t("featureDefinition-informativeQualitativeEditor-interest-label", { $: "(interest)" }) }}</span>
              </v-chip>
            </template>
          </v-combobox>
        </div>
      </v-col>
      <v-col cols="2">
        <v-text-field
          variant="outlined"
          density="compact"
          hide-details
          :rules="[isMatchingValueRule]"
          :label="$t('featureDefinition-informativeQualitativeEditor-matchingValue-label', { $: 'Matching Value' })"
          :model-value="modelValue.matchingValue"
          :disabled="disabled"
          @update:model-value="update('matchingValue', $event)"
        />
      </v-col>
    </v-row>
  </v-col>
  <v-col cols="1" class="text-right d-flex align-center justify-center">
    <v-btn variant="text" icon="mdi-close" size="large" hide-details :disabled="disabled" @click="emit('remove')"></v-btn>
  </v-col>
</template>
