﻿import { IActivityObject, IRow, OverlappingActivity, OverlappingTimeIntervalTreeActivityRepository, Row } from "@masta/gantt2/core";
import { GanttNoteDto, GanttResourceCapacityDto, GanttResourceDto } from "@masta/generated-model";
import { Instant } from "@js-joda/core";
import { BehaviorSubject, Observable } from "rxjs";

export interface ExtendedGanttResourceDto extends Omit<GanttResourceDto, "name" | "parentId">, IRow {
}

export interface EnhancedGanttResourceCapacityDto extends GanttResourceCapacityDto, IActivityObject {
  type: string;
  periodStartDateFormatted?: string;
  periodEndDateFormatted?: string;
  periodStartDateTimeFormatted?: string;
  periodEndDateTimeFormatted?: string;
  periodDurationFormatted?: string;
}

export interface EnhancedGanttNoteDto extends Omit<GanttNoteDto, "name">, IActivityObject {
  type: string;
}

export function toEnhancedGanttResourceCapacityDto(type: string, data: GanttResourceCapacityDto): EnhancedGanttResourceCapacityDto {
  let name = "";
  if (data.task && data.step) {
    name = `[${data.task.name} (${data.step.name})] [${data.task.businessId}] (${data.periodStart} - ${data.periodEnd})`;
  } else if (data.task) {
    name = `[${data.task.name}] [${data.task.businessId}] (${data.periodStart} - ${data.periodEnd})`;
  } else {
    name = `(${data.periodStart} - ${data.periodEnd})`;
  }
  return {
    ...data,
    type,
    name,
    startTime: Instant.parse(data.periodStart).toEpochMilli(),
    endTime: Instant.parse(data.periodEnd).toEpochMilli()
  };
}

export function toEnhancedGanttNoteDto(type: string, data: GanttNoteDto): EnhancedGanttNoteDto {
  return {
    ...data,
    type,
    name: data.name ?? "",
    startTime: Instant.parse(data.issueDate).toEpochMilli(),
    endTime: Instant.parse(data.issueDate).toEpochMilli()
  };
}

export class ResourceCapacityActivity extends OverlappingActivity<EnhancedGanttResourceCapacityDto> {
  constructor(data: EnhancedGanttResourceCapacityDto) {
    super(data.id, data.name, Instant.ofEpochMilli(data.startTime), Instant.ofEpochMilli(data.endTime));
    this._id = data.id;
    this._userObject = data;
  }

  toJSON<TActivityObject extends IActivityObject>(): TActivityObject {
    return {
      ...this._userObject,
      name: this._name,
      startTime: this._startTime.toEpochMilli(),
      endTime: this._endTime.toEpochMilli()
    } as unknown as TActivityObject;
  }
}

export class NoteActivity extends OverlappingActivity<EnhancedGanttNoteDto> {
  constructor(data: EnhancedGanttNoteDto) {
    super(data.id, data.name, Instant.ofEpochMilli(data.startTime), Instant.ofEpochMilli(data.endTime));
    this._id = data.id;
    this._userObject = data;
  }

  toJSON<TActivityObject extends IActivityObject>(): TActivityObject {
    return {
      ...this._userObject,
      name: this._name,
      startTime: this._startTime.toEpochMilli(),
      endTime: this._endTime.toEpochMilli()
    } as unknown as TActivityObject;
  }

  get hashCode(): number {
    return `${this._id}-${this._userObject.color}`.split("").reduce((a, b) => {
      a = (a << 5) - a + b.charCodeAt(0);
      return a & a;
    }, 0);
  }
}

export class DemandSchedulingResourceCapacityActivity extends ResourceCapacityActivity {
  constructor(data: EnhancedGanttResourceCapacityDto) {
    super(data);
  }
}

export class WaitingSchedulingResourceCapacityActivity extends ResourceCapacityActivity {
  constructor(data: EnhancedGanttResourceCapacityDto) {
    super(data);
    this.selectable = false;
  }
}

export class DemandExecutionResourceCapacityActivity extends ResourceCapacityActivity {
  constructor(data: EnhancedGanttResourceCapacityDto) {
    super(data);
  }
}

export class InterruptionExecutionResourceCapacityActivity extends ResourceCapacityActivity {
  constructor(data: EnhancedGanttResourceCapacityDto) {
    super(data);
    this.selectable = false;
  }
}

export class CalendarResourceCapacityActivity extends ResourceCapacityActivity {
  constructor(data: EnhancedGanttResourceCapacityDto) {
    super(data);
    this.selectable = false;
  }
}

export class AvailabilityRuleResourceCapacityActivity extends ResourceCapacityActivity {
  constructor(data: EnhancedGanttResourceCapacityDto) {
    super(data);
    this.selectable = false;
  }
}

export class ResourceRow extends Row<ResourceRow, ResourceRow, ResourceCapacityActivity> {
  private _overlapExpanded$$ = new BehaviorSubject(false);

  constructor(resource: ExtendedGanttResourceDto, defaultHeight: number = 80) {
    super(resource);

    const overlappingActivityTypes = [
      DemandSchedulingResourceCapacityActivity.name,
      DemandExecutionResourceCapacityActivity.name,
      WaitingSchedulingResourceCapacityActivity.name
    ];

    this.repository = new OverlappingTimeIntervalTreeActivityRepository((activity) => {
      return overlappingActivityTypes.includes(activity.constructor.name);
    });

    this.id = resource.id;
    if (!resource.name) {
      this.name = resource.businessId ?? resource.id;
    }
    this.addProperty("color", resource.color);
    this.addProperty("type", resource.type);
    this.defaultHeight = defaultHeight;
    this.height = defaultHeight;
  }

  get overlapExpanded$(): Observable<boolean> {
    return this._overlapExpanded$$.asObservable();
  }

  get overlapExpanded(): boolean {
    return this._overlapExpanded$$.value;
  }

  set overlapExpanded(value: boolean) {
    this._overlapExpanded$$.next(value);
  }
}
