<template>
  <master-detail-layout scenario-selector>
    <template #master>
      <v-card elevation="0" class="flexcard fill-height">
        <v-card-title>
          <breadcrumbs>{{ $t("services-view-services-title", { $: "Services" }) }}</breadcrumbs>
        </v-card-title>
        <v-card-text class="fill-height">
          <services-grid @manage-model-instances="manageModelInstances" @manage-availability-rules="manageAvailabilityRules" />
        </v-card-text>
      </v-card>
      <v-col v-if="editModelInstanceAssignmentDialog">
        <resource-model-instance-assignment
          :model-instances="service.modelInstances"
          :opened="editModelInstanceAssignmentDialog"
          @cancel="editModelInstanceAssignmentDialog = false"
          @save="saveModelInstanceAssignment"
        >
          <resource-label type="Service" :business-id="service.businessId" :name="service.name" />
        </resource-model-instance-assignment>
      </v-col>
      <v-col v-if="editAvailabilityRulesAssignmentDialog">
        <availability-rules-assignment-editor
          :resource="service"
          :opened="editAvailabilityRulesAssignmentDialog"
          @cancel="editAvailabilityRulesAssignmentDialog = false"
          @save="editAvailabilityRulesAssignmentDialog = false"
        />
      </v-col>
    </template>
  </master-detail-layout>
</template>

<script>
import ServicesGrid from "@/components/Services/ServicesGrid";
import MasterDetailLayout from "@/components/Layout/MasterDetailLayout";
import ResourceLabel from "@/components/ModelInstances/ModelInstanceAssignment/ResourceLabel";
import AvailabilityRulesAssignmentEditor from "@/components/AvailabilityRules/AvailabilityRulesAssignment/AvailabilityRulesAssignmentEditor";
import { mapActions } from "pinia";
import { useServicesStore } from "@/store/ServicesStore";
import Breadcrumbs from "@/components/Layout/Breadcrumbs.vue";
import ResourceModelInstanceAssignment from "@/components/ModelInstances/ModelInstanceAssignment/ResourceModelInstanceAssignment.vue";

export default {
  name: "Services",
  components: {
    Breadcrumbs,
    ResourceLabel,
    AvailabilityRulesAssignmentEditor,
    MasterDetailLayout,
    ServicesGrid,
    ResourceModelInstanceAssignment
  },
  title: "Services",
  data() {
    return {
      editModelInstanceAssignmentDialog: false,
      editAvailabilityRulesAssignmentDialog: false,
      service: null,
      refreshGridAction: null
    };
  },
  methods: {
    ...mapActions(useServicesStore, ["updateModelInstanceAssignment"]),
    manageModelInstances(service, refreshGridAction) {
      this.refreshGridAction = refreshGridAction;
      this.service = service;
      this.editModelInstanceAssignmentDialog = true;
    },
    async saveModelInstanceAssignment(instances) {
      await this.updateModelInstanceAssignment({
        id: this.service.id,
        modelInstanceIds: instances.map((x) => x.id)
      });
      this.refreshGridAction();
      this.editModelInstanceAssignmentDialog = false;
    },
    manageAvailabilityRules(service) {
      this.service = service;
      this.editAvailabilityRulesAssignmentDialog = true;
    }
  }
};
</script>

<style scoped></style>
