<script lang="ts" setup>
import QualitativeFeatureDefinitionEditor from "@/components/ModelInstances/FeaturesDefinitions/QualitativeFeatureDefinitionEditor.vue";
import QuantitativeFeatureDefinitionEditor from "@/components/ModelInstances/FeaturesDefinitions/QuantitativeFeatureDefinitionEditor.vue";
import InformativeQualitativeFeatureDefinitionEditor from "@/components/ModelInstances/FeaturesDefinitions/InformativeQualitativeFeatureDefinitionEditor.vue";
import InformativeQuantitativeFeatureDefinitionEditor from "@/components/ModelInstances/FeaturesDefinitions/InformativeQuantitativeFeatureDefinitionEditor.vue";
import TextFeatureDefinitionEditor from "@/components/ModelInstances/FeaturesDefinitions/TextFeatureDefinitionEditor.vue";
import { FeatureDefinition, FeaturesDefinition } from "@masta/generated-model";
import InformativeDocumentAttachmentFeatureDefinitionEditor from "@/components/ModelInstances/FeaturesDefinitions/InformativeDocumentAttachmentFeatureDefinitionEditor.vue";

const props = defineProps<{
  modelValue: FeaturesDefinition;
  disabled: boolean;
}>();

const emit = defineEmits(["update:modelValue"]);

function getComponent(feature: FeatureDefinition) {
  switch (feature.type) {
    case "QualitativeFeatureDefinition":
      return QualitativeFeatureDefinitionEditor;
    case "QuantitativeFeatureDefinition":
      return QuantitativeFeatureDefinitionEditor;
    case "InformativeQualitativeFeatureDefinition":
      return InformativeQualitativeFeatureDefinitionEditor;
    case "InformativeQuantitativeFeatureDefinition":
      return InformativeQuantitativeFeatureDefinitionEditor;
    case "TextFeatureDefinition":
      return TextFeatureDefinitionEditor;
    case "InformativeDocumentAttachmentFeatureDefinition":
      return InformativeDocumentAttachmentFeatureDefinitionEditor;
    default:
      throw new Error(`Feature type '${feature.type}' not supported`);
  }
}

function updateFeature(newFeature: FeatureDefinition, feature: FeatureDefinition) {
  const newFeaturesDef = {
    features: props.modelValue.features.map((x) => (x !== feature ? x : newFeature))
  };
  updateModelValue(newFeaturesDef);
}

function remove(feature: FeatureDefinition) {
  const newFeaturesDef = {
    features: props.modelValue.features.filter((x) => x !== feature)
  };
  updateModelValue(newFeaturesDef);
}

function addQualitativeFeature() {
  const newFeature = { type: "QualitativeFeatureDefinition", name: "", label: "", description: "", values: [], matchingValue: null };
  addFeature(newFeature);
}

function addInformativeQualitativeFeature() {
  const newFeature = { type: "InformativeQualitativeFeatureDefinition", name: "", label: "", description: "", values: [], matchingValue: null };
  addFeature(newFeature);
}

function addQuantitativeFeature() {
  const newFeature = { type: "QuantitativeFeatureDefinition", name: "", label: "", description: "", target: 0, toleranceLeft: 0, toleranceRight: 0 };
  addFeature(newFeature);
}

function addInformativeQuantitativeFeature() {
  const newFeature = { type: "InformativeQuantitativeFeatureDefinition", name: "", label: "", description: "", target: 0, toleranceLeft: 0, toleranceRight: 0 };
  addFeature(newFeature);
}

function addTextFeature() {
  const newFeature = { type: "TextFeatureDefinition", name: "", label: "", description: "", regex: null };
  addFeature(newFeature);
}

function addDocumentAttachmentFeature() {
  const newFeature = { type: "InformativeDocumentAttachmentFeatureDefinition", name: "", label: "", description: "" };
  addFeature(newFeature);
}

function addFeature(feature: FeatureDefinition) {
  const newFeaturesDef = {
    features: [...props.modelValue.features, feature]
  };
  updateModelValue(newFeaturesDef);
}

function updateModelValue(newFeaturesDef: FeaturesDefinition) {
  emit("update:modelValue", newFeaturesDef);
}
</script>

<template>
  <v-container fluid>
    <div class="d-flex justify-start align-center mb-8">
      <div class="text-subtitle-2">
        {{ $t("featureDefinition-editor-features-title", { $: "Features:" }) }}
      </div>
      <v-btn type="button" variant="tonal" size="small" class="mx-4" :disabled="disabled" @click="addQualitativeFeature()">
        <v-icon>mdi-plus</v-icon>
        {{ $t("featureDefinition-editor-addQualitativeFeature-action", { $: "Qualitative" }) }}
      </v-btn>
      <v-btn type="button" variant="tonal" size="small" class="mx-4" :disabled="disabled" @click="addQuantitativeFeature()">
        <v-icon>mdi-plus</v-icon>
        {{ $t("featureDefinition-editor-addQuantitativeFeature-action", { $: "Quantitative" }) }}
      </v-btn>
      <v-btn type="button" variant="tonal" size="small" class="mx-4" :disabled="disabled" @click="addInformativeQualitativeFeature()">
        <v-icon>mdi-plus</v-icon>
        {{ $t("featureDefinition-editor-addInformativeQualitativeFeature-action", { $: "Informative Qualitative" }) }}
      </v-btn>
      <v-btn type="button" variant="tonal" size="small" class="mx-4" :disabled="disabled" @click="addInformativeQuantitativeFeature()">
        <v-icon>mdi-plus</v-icon>
        {{ $t("featureDefinition-editor-addInformativeQuantitativeFeature-action", { $: "Informative Quantitative" }) }}
      </v-btn>
      <v-btn type="button" variant="tonal" size="small" class="mx-4" :disabled="disabled" @click="addTextFeature()">
        <v-icon>mdi-plus</v-icon>
        {{ $t("featureDefinition-editor-addTextFeature-action", { $: "Text" }) }}
      </v-btn>
      <v-btn type="button" variant="tonal" size="small" class="mx-4" :disabled="disabled" @click="addDocumentAttachmentFeature()">
        <v-icon>mdi-plus</v-icon>
        {{ $t("featureDefinition-editor-addDocumentAttachmentFeature-action", { $: "Document Attachment" }) }}
      </v-btn>
    </div>
    <v-slide-y-transition group>
      <v-row v-for="(feature, index) in modelValue.features" :key="index" dense class="pt-6">
        <component :is="getComponent(feature)" :model-value="feature" :disabled="disabled" @update:modelValue="updateFeature($event, feature)" @remove="remove(feature)" />
      </v-row>
    </v-slide-y-transition>
  </v-container>
</template>
