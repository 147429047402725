import ApiService from "@/services/api";
import { ReportDto } from "@masta/generated-model";
import { defineStore } from "pinia";
import { useErrorsStore } from "@/store/ErrorsStore";

interface State {
  reports: ReportDto[];
}

export const useReportsStore = defineStore("reports", {
  state: (): State => ({
    reports: []
  }),
  actions: {
    async fetch() {
      try {
        const response = await ApiService.reports.getReports();
        this.reports = response.data.reports;
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    }
  }
});

