<script lang="ts" setup>
import OnboardingHelpDialog from "@/components/OnboardingHelpDialog.vue";
import { translateLocale } from "@/composables/translateEnum";
import { Locale, authorizedLocalStorageKey, setLocaleFromLocalStorageAuthorized } from "@/i18n";
import ThemeSwitchMixin from "@/mixins/themeSwitch.mixin";
import { $authService } from "@/services/AuthService";
import { useAuthStore } from "@/store/AuthStore";
import "flag-icons/css/flag-icons.min.css";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

const store = useAuthStore();

const i18n = useI18n();

const router = useRouter();
const isProfileViewAvailable = computed(() => router.hasRoute("profile") || router.hasRoute("Profile"));

const showOnboardingHelpDialog = ref(false);

async function logout() {
  await store.logout();
}

function onboardingHelp() {
  showOnboardingHelpDialog.value = true;
}

const flag = computed(() => {
  switch (i18n.locale.value) {
    case "en":
      return "gb";
    case "uk":
      return "ua";
    case "cs":
      return "cz";
    default:
      return i18n.locale.value;
  }
});

const locales = ref(Object.entries(Locale).map((x) => ({ key: x[0], value: x[1], label: i18n.t(`locale-languages-${x[0].toLowerCase()}-label`, { $: x[1] }) })));

const languageName = computed(() => {
  const locale = Object.entries(Locale).find((x) => x[1] === i18n.locale.value);
  if (locale) {
    const k: any = locale[0];
    return translateLocale(Locale[k as keyof typeof Locale]);
  }
  return translateLocale(Locale.English);
});

function setLocaleClick(locale: any) {
  const tenantId = $authService.tenantId;
  localStorage.setItem(`${authorizedLocalStorageKey}${tenantId}`, locale);
  setLocaleFromLocalStorageAuthorized(tenantId!);
}
</script>

<script lang="ts">
export default {
  mixins: [ThemeSwitchMixin]
};
</script>

<template>
  <v-menu location="bottom" class="user-profile-btn">
    <template #activator="{ props }">
      <v-btn color="primary" fab icon="mdi-account-circle-outline" variant="plain" v-bind="props" />
    </template>
    <v-list>
      <v-list-subheader class="text-h6 v-tenant-name">{{ store.tenant?.displayName }}</v-list-subheader>
      <v-divider></v-divider>

      <v-list-item v-if="isProfileViewAvailable" :to="{ name: 'Profile' }" prepend-icon="mdi-account" :title="$t('user-userProfile-profile-action', { $: 'Profile' })" />

      <!-- <v-list-item v-if="isLightTheme" prepend-icon="mdi-moon-waxing-crescent" :title="$t('theme-switch-turnOnDarkMode-action', { $: 'Dark mode' })" @click="switchMode" />
      <v-list-item v-if="isDarkTheme" prepend-icon="mdi-white-balance-sunny" :title="$t('theme-switch-turnOffDarkMode-action', { $: 'Turn off dark mode' })" @click="switchMode" /> -->

      <v-list-group key="locale">
        <template #activator="{ props }">
          <v-list-item :title="languageName" v-bind="props" :prepend-icon="`fi-${flag}`" @click.stop="() => {}"></v-list-item>
        </template>

        <v-list-item v-if="i18n.locale.value !== Locale.English" class="text-capitalize" @click="setLocaleClick(Locale.English)">
          <div class="d-flex align-center">
            <div class="circle fi fi-gb mr-4"></div>
            <div>{{ translateLocale(Locale.English) }}</div>
          </div>
        </v-list-item>
        <v-list-item v-if="i18n.locale.value !== Locale.Polish" class="text-capitalize" @click="setLocaleClick(Locale.Polish)">
          <div class="d-flex align-center">
            <div class="circle fi fi-pl mr-4"></div>
            <div>{{ translateLocale(Locale.Polish) }}</div>
          </div>
        </v-list-item>
        <v-list-item v-if="i18n.locale.value !== Locale.Ukrainian" class="text-capitalize" @click="setLocaleClick(Locale.Ukrainian)">
          <div class="d-flex align-center">
            <div class="circle fi fi-ua mr-4"></div>
            <div>{{ translateLocale(Locale.Ukrainian) }}</div>
          </div>
        </v-list-item>
        <v-list-item v-if="i18n.locale.value !== Locale.German" class="text-capitalize" @click="setLocaleClick(Locale.German)">
          <div class="d-flex align-center">
            <div class="circle fi fi-de mr-4"></div>
            <div>{{ translateLocale(Locale.German) }}</div>
          </div>
        </v-list-item>
        <v-list-item v-if="i18n.locale.value !== Locale.Spanish" class="text-capitalize" @click="setLocaleClick(Locale.Spanish)">
          <div class="d-flex align-center">
            <div class="circle fi fi-es mr-4"></div>
            <div>{{ translateLocale(Locale.Spanish) }}</div>
          </div>
        </v-list-item>
        <v-list-item v-if="i18n.locale.value !== Locale.French" class="text-capitalize" @click="setLocaleClick(Locale.French)">
          <div class="d-flex align-center">
            <div class="circle fi fi-fr mr-4"></div>
            <div>{{ translateLocale(Locale.French) }}</div>
          </div>
        </v-list-item>
        <v-list-item v-if="i18n.locale.value !== Locale.Portuguese" class="text-capitalize" @click="setLocaleClick(Locale.Portuguese)">
          <div class="d-flex align-center">
            <div class="circle fi fi-pt mr-4"></div>
            <div>{{ translateLocale(Locale.Portuguese) }}</div>
          </div>
        </v-list-item>
        <v-list-item v-if="i18n.locale.value !== Locale.Czech" class="text-capitalize" @click="setLocaleClick(Locale.Czech)">
          <div class="d-flex align-center">
            <div class="circle fi fi-cz mr-4"></div>
            <div>{{ translateLocale(Locale.Czech) }}</div>
          </div>
        </v-list-item>
        <v-list-item v-if="i18n.locale.value !== Locale.Slovak" class="text-capitalize" @click="setLocaleClick(Locale.Slovak)">
          <div class="d-flex align-center">
            <div class="circle fi fi-sk mr-4"></div>
            <div>{{ translateLocale(Locale.Slovak) }}</div>
          </div>
        </v-list-item>
        <v-list-item v-if="i18n.locale.value !== Locale.Turkish" class="text-capitalize" @click="setLocaleClick(Locale.Turkish)">
          <div class="d-flex align-center">
            <div class="circle fi fi-tr mr-4"></div>
            <div>{{ translateLocale(Locale.Turkish) }}</div>
          </div>
        </v-list-item>
      </v-list-group>

      <v-list-item prepend-icon="mdi-lightbulb-question" :title="$t('user-onboaringHelp-action', { $: 'Onboarding' })" @click="onboardingHelp" />

      <v-list-item prepend-icon="mdi-logout" :title="$t('user-userProfile-logout-action', { $: 'Logout' })" @click="logout" />
    </v-list>
  </v-menu>

  <onboarding-help-dialog v-model="showOnboardingHelpDialog" url="https://app.arcade.software/share/vVEQphcdRp1FNwJyUR9j?ref=share-link" />

</template>

<style lang="scss">
.user-profile-btn {
  .v-list {
    margin-left: -30px;
  }

  .v-tenant-name {
    justify-content: center;
  }
}
</style>
