<script setup lang="ts">
import { PersonDto } from "@masta/generated-model";

const props = defineProps<{
  personnel: PersonDto[];
  availablePersonnel: Set<string>;
  selectedIds: Set<string>;
}>();

function toggleSelection(id: string) {
  if (!props.selectedIds) return;
  if (props.selectedIds.has(id)) {
    props.selectedIds.delete(id);
  } else {
    props.selectedIds.add(id);
  }
}

function isSelected(id: string) {
  if (!props.selectedIds) return;
  return props.selectedIds.has(id);
}
</script>

<template>
  <div class="bg-grey-lighten-3 rounded-lg pa-2 fill-height">
    <v-card v-for="person in personnel" :key="person.id" class="ma-4 cursor-pointer" :class="{ 'bg-primary': isSelected(person.id) }" @click="toggleSelection(person.id)">
      <v-card-text>
        <v-row>
          <v-col cols="1" class="d-flex items-center justify-center align-center">
            <v-icon :icon="availablePersonnel.has(person.id) ? 'mdi-map-marker-check-outline' : 'mdi-minus-circle'" size="40"></v-icon>
          </v-col>
          <v-col cols="11">
            <div class="text-caption">{{ person.businessId }}</div>
            <div class="text-h5 text-uppercase">{{ person.name }}</div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
