<script setup lang="ts">
import ApiService from "@/services/api";
import { $t } from "@/i18n";
import {
  MaterialSubType,
  MeasurementUnit,
  ProcessDraftStatus,
  ProductTemplateDetailsDto,
  ResourceInfoDto,
  ResourcePurpose,
  ResourceSubType,
  ResourceType
} from "@masta/generated-model";
import { computed, ref, watch } from "vue";
import ResourcePicker from "@/components/Resources/ResourcePicker.vue";
import { replaceResource, ProcessDraft } from "@/components/ProcessDrafts/ProcessDraftModels";
import { createEmptyProcessDraft, createMaterialInfo, createDraftFromTemplate, getProcessNameFromResource } from "@/components/ProcessDrafts/model-from-product-template";
import { getEnumTitleValuePairs } from "@/composables/enumHelpers";
import { translateMeasurementUnit, translateResourcePurpose, translateResourceSubType } from "@/composables/translateEnum";
import { requiredRule } from "@/components/ValueCellEditor/CommonValidationRules";
import { VForm } from "vuetify/components";
import { useSnackbarsStore } from "@/store/SnackbarsStore";
import { hasProductionPurpose } from "@/components/ProcessDrafts/validation";
import { useScenariosStore } from "@/store/ScenariosStore";

const modelValue = defineModel<boolean>({ required: true });

const emit = defineEmits<{
  (e: "dialog-result", result: ProcessDraft | null): void;
}>();

enum CreateProcessDraftOptions {
  FromExistingProduct,
  FromNewProduct,
  Empty,
  FromAi
}

interface AddProductOption {
  title: string;
  value: CreateProcessDraftOptions;
}

const resource = ref<ResourceInfoDto | null>(null);
const options = ref<AddProductOption[]>([
  {
    title: $t("processDraft-addProcessDraftDialog-createFromExistingProduct-label", { $: "Create new revision for existing product" }),
    value: CreateProcessDraftOptions.FromExistingProduct
  },
  {
    title: $t("processDraft-addProcessDraftDialog-createFromNewProduct-label", { $: "Create new product" }),
    value: CreateProcessDraftOptions.FromNewProduct
  },
  // {
  //   title: $t("processDraft-addProcessDraftDialog-empty-label", { $: "Create empty" }),
  //   value: CreateProcessDraftOptions.Empty
  // },
  {
    title: $t("processDraft-addProcessDraftDialog-fromAi-label", { $: "Create from AI" }),
    value: CreateProcessDraftOptions.FromAi
  }
]);
const selectedOption = ref<AddProductOption>(options.value[0]);
const resourceName = ref<string>();
const resourceBusinessId = ref<string>();
const resourceSaveProblem = ref(false);
const processLoadProblem = ref(false);
const selectedUnit = ref<MeasurementUnit>(MeasurementUnit.Piece);
const measurementUnits = getEnumTitleValuePairs(MeasurementUnit, translateMeasurementUnit).sort((a, b) => a.title.localeCompare(b.title));
const selectedPurposes = ref<ResourcePurpose[]>([ResourcePurpose.Production, ResourcePurpose.Sale]);
const resourcePurposes = getEnumTitleValuePairs(ResourcePurpose, translateResourcePurpose).sort((a, b) => a.title.localeCompare(b.title));
const form = ref<InstanceType<typeof VForm> | null>(null);
const valid = ref<boolean>(false);
const showProgress = ref(false);
const snackbarsStore = useSnackbarsStore();
const scenarioStore = useScenariosStore();

watch(
  () => modelValue.value,
  (newValue) => {
    if (newValue) {
      clearValues();
    }
  }
);
watch(
  () => selectedOption.value,
  () => form.value?.resetValidation()
);

watch(
  () => resourceBusinessId.value,
  () => (resourceSaveProblem.value = false)
);

watch(
  () => resource.value,
  () => (processLoadProblem.value = false)
);

const showNew = computed(() => selectedOption.value.value === CreateProcessDraftOptions.FromNewProduct);
const businessIdRules = computed(() => (showNew.value ? [requiredRule, resourceSaveProblemRule] : []));
const purposesRules = computed(() => (showNew.value ? [hasProductionPurpose] : []));
const resourceRules = computed(() => (showNew.value ? [processLoadProblemRule] : [requiredRule]));

function onCancel() {
  close();
  emit("dialog-result", null);
}

function clearValues() {
  showProgress.value = false;
  valid.value = false;
  resource.value = null;
  selectedOption.value = options.value[0];
  resourceName.value = undefined;
  resourceBusinessId.value = undefined;
  selectedUnit.value = MeasurementUnit.Piece;
  selectedPurposes.value = [ResourcePurpose.Production, ResourcePurpose.Sale];
  processLoadProblem.value = false;
  resourceSaveProblem.value = false;
}

async function onAdd() {
  const result = await form.value?.validate();
  if (!result || !result.valid) {
    return;
  }
  showProgress.value = true;
  switch (selectedOption.value.value) {
    case CreateProcessDraftOptions.FromExistingProduct:
      await useExistingProduct();
      break;
    case CreateProcessDraftOptions.FromNewProduct:
      await createNewProduct();
      break;
    case CreateProcessDraftOptions.Empty:
      await createEmpty();
      break;
    case CreateProcessDraftOptions.FromAi:
      await createFromAi();
      break;
  }
  showProgress.value = false;
}

async function useExistingProduct() {
  if (resource.value) {
    const process = await createProcess(resource.value);
    if (process) {
      closeWithProcess(process);
    }
  }
}

async function createEmpty() {
  const process = createEmptyProcessDraft(null);
  closeWithProcess(process);
}

async function createFromAi() {
  if (resource.value) {
    const process = createEmptyProcessDraft(resource.value);
    process.status = ProcessDraftStatus.WaitingForAi;
    closeWithProcess(process);
  }
}

function closeWithProcess(process: ProcessDraft) {
  close();
  emit("dialog-result", process);
}

function close() {
  modelValue.value = false;
}

async function createNewProduct() {
  let process: ProcessDraft | null = null;
  if (resource.value) {
    process = await createProcess(resource.value, false);
    if (!process) {
      snackbarsStore.createSnackbar({
        message: $t("processDraft-addProcessDraftDialog-cannotFindValidProcessForMaterialError-message", { $: "Could not find valid process for source material." }),
        type: "error",
        closeable: true,
        position: "top-right"
      });
      processLoadProblem.value = true;
      form.value?.validate();
      return;
    }
  }

  const newResource = await createResource();

  if (!newResource) return;

  if (process && resource.value) {
    replaceResource(process, resource.value, newResource);
    process.name = getProcessNameFromResource(newResource);
  } else {
    process = createEmptyProcessDraft(newResource);
  }

  closeWithProcess(process);
}

async function createResource() {
  try {
    return await createMaterial(resourceName.value ?? "", resourceBusinessId.value ?? "");
  } catch (e) {
    console.error(e);
    snackbarsStore.createSnackbar({
      message: $t("processDraft-addProcessDraftDialog-cannotCreateMaterialError-message", { $: "Could not create new material. Please check if Business ID is unique" }),
      type: "error",
      closeable: true,
      position: "top-right"
    });

    resourceSaveProblem.value = true;
    form.value?.validate();
    return null;
  }
}

async function createProcess(res: ResourceInfoDto, createEmpty = true) {
  try {
    const templates = await getTemplates(res);
    if (templates.length > 0) {
      return createProcessFromTemplates(templates);
    }
  } catch (e) {
    console.error(e);
  }
  if (createEmpty) {
    return createEmptyProcessDraft(res);
  }
  return null;
}

async function getTemplates(res: ResourceInfoDto) {
  const response = await ApiService.productTemplates.getForResource(res.id, res.scenarioId);
  if (response.data && response.data.templates) {
    return response.data.templates;
  }

  return [];
}

function createProcessFromTemplates(productTemplates: ProductTemplateDetailsDto[]): ProcessDraft {
  const rootTemplates = productTemplates.filter((x) => x.id === x.rootTaskId);
  const tasks = rootTemplates.filter((x) => x.id === x.rootTaskId).map((x) => createDraftFromTemplate(x, productTemplates, true));
  const task = tasks.find((x) => x.resource?.id === resource.value?.id);
  if (!task || !task.resource) {
    throw new Error("Template is not referencing selected resource");
  }
  return { name: getProcessNameFromResource(task.resource), status: ProcessDraftStatus.WorkInProgress, productTemplates: tasks, targetResource: task.resource };
}

async function createMaterial(name: string, businessId: string) {
  const request = {
    name,
    businessId,
    materialType: MaterialSubType.SemiFinishedOrFinalProduct,
    measurementUnit: selectedUnit.value,
    purposes: selectedPurposes.value,
    plannable: true,
    scenarioId: scenarioStore.selectedScenario?.id
  };
  const result = await ApiService.materials.create(request);
  const result2 = await ApiService.materials.getSingle(result.data.resourceId, result.data.scenarioId);
  return createMaterialInfo(result2.data);
}

function resourceSaveProblemRule(v: any): boolean | string {
  return !resourceSaveProblem.value || $t("processDrafts-edit-resourceSaveProblem-message", { $: "Must be unique" });
}

function processLoadProblemRule(v: any): boolean | string {
  return !processLoadProblem.value || $t("processDrafts-edit-processLoadProblem-message", { $: "Cannot load valid process" });
}
</script>

<template>
  <v-dialog v-model="modelValue" :persistent="true" content-class="add-process-draft-dialog">
    <v-form ref="form" v-model="valid" @submit.prevent="">
      <v-card class="pa-8">
        <v-card-title class="d-flex align-center justify-space-between">
          <div class="text-h4">{{ $t("processDrafts-addProcessDraftDialog-title-label", { $: "Create process template draft" }) }}</div>
          <v-icon @click="onCancel">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-chip-group v-model="selectedOption" mandatory class="d-flex justify-center">
              <v-chip
                v-for="option in options"
                :key="option.value"
                :value="option"
                :class="option.value === selectedOption?.value ? 'bg-primary' : 'bg-indigo-lighten-5 text-primary'"
                size="large"
              >
                {{ option.title }}
              </v-chip>
            </v-chip-group>
          </v-row>
          <v-row v-if="showNew">
            <v-col cols="6">
              <v-text-field
                v-model="resourceBusinessId"
                variant="outlined"
                density="compact"
                hide-details="auto"
                :rules="businessIdRules"
                :label="$t('processDraft-addProcessDraftDialog-resourceBusinessId-label', { $: 'Resource Business ID' })"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="resourceName"
                variant="outlined"
                density="compact"
                hide-details="auto"
                :label="$t('processDraft-addProcessDraftDialog-resourceName-label', { $: 'Resource Name' })"
              />
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="selectedPurposes"
                :items="resourcePurposes"
                multiple
                chips
                variant="outlined"
                density="compact"
                hide-details="auto"
                :rules="purposesRules"
                :label="$t('processDraft-addProcessDraftDialog-resourcePurposes-label', { $: 'Resource Purposes' })"
              />
            </v-col>
            <v-col cols="6">
              <v-select
                v-model="selectedUnit"
                :items="measurementUnits"
                variant="outlined"
                density="compact"
                hide-details="auto"
                :label="$t('processDraft-addProcessDraftDialog-resourceQuantityUnit-label', { $: 'Measurement Unit' })"
              />
            </v-col>
            <v-col cols="6">
              <ResourcePicker
                v-model="resource"
                class="flex-1-1-100"
                :readonly="false"
                :label="$t('processDraft-addProcessDraftDialog-product-label', { $: 'Source Product' })"
                :resource-types="[ResourceType.Material]"
                :rules="resourceRules"
                :hide-details="'auto'"
                :resource-sub-types="[ResourceSubType.MaterialSemiFinishedOrFinalProduct]"
              />
            </v-col>
          </v-row>
          <v-row v-else-if="selectedOption.value !== CreateProcessDraftOptions.Empty">
            <v-col cols="12">
              <ResourcePicker
                v-model="resource"
                class="flex-1-1-100"
                :readonly="false"
                :label="$t('processDraft-addProcessDraftDialog-product-label', { $: 'Source Product' })"
                :resource-types="[ResourceType.Material]"
                :rules="resourceRules"
                :hide-details="'auto'"
                :resource-sub-types="[ResourceSubType.MaterialSemiFinishedOrFinalProduct]"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col cols="6">
              <v-btn class="ma-4" variant="elevated" color="secondary" block @click="onCancel">
                {{ $t("processDrafts-addProcessDraftDialog-cancel-action", { $: "Cancel" }) }}
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn class="ma-4" variant="elevated" color="primary" block :loading="showProgress" @click="onAdd">
                {{ $t("processDrafts-addProcessDraftDialog-add-action", { $: "Add" }) }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<style lang="scss">
.add-process-draft-dialog {
  width: 30vw !important;
}

@media screen and (max-width: 1900px) {
  .add-process-draft-dialog {
    width: 50vw !important;
  }
}

@media screen and (max-width: 1450px) {
  .add-process-draft-dialog {
    width: 75vw !important;
  }
}
</style>
