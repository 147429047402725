<script setup lang="ts">
import { onMounted, ref, watch } from "vue";
import {
  CostCatalogueItemDto,
  MultipleCostCatalogueItemsForResourceResponse,
  PickMaterialProviderUserNoticePayload,
  RegisterEventCommand,
  UpdateUserNoticeCommand,
  UserNoticeStatus
} from "@masta/generated-model";
import { $t } from "../../i18n";
import ApiService from "@/services/api";
import { translateCurrencyCode } from "@/composables/translateEnum";
import { storeToRefs } from "pinia";
import { useAuthStore } from "@/store/AuthStore";
import { useRouter } from "vue-router";

const router = useRouter();

interface Props {
  jsonString: string;
  userNoticeId: string;
}

const { currentTenantId } = storeToRefs(useAuthStore());

const props = defineProps<Props>();

const parsedItems = ref<{ [key: string]: MultipleCostCatalogueItemsForResourceResponse }>({});
const selectedProviders = ref<{ [key: string]: { [key: string]: string } }>({});
const orderLineNames = ref<{ [key: string]: string }>({});
const materialNames = ref<{ [key: string]: string }>({});
const providerNames = ref<{ [key: string]: string }>({});
const labels = ref<{ [key: string]: string }>({});

const fetchOrderLineDescription = async (orderId: string, scenarioId: string, key: string) => {
  try {
    const response = await ApiService.customerOrders.getCustomerOrderLine(orderId, key, scenarioId);
    return response.data.description;
  } catch (error) {
    console.error(`Failed to fetch name for order line ${key}:`, error);
    return key;
  }
};

const fetchMaterialName = async (scenarioId: string, key: string) => {
  try {
    const response = await ApiService.materials.getSingle(key, scenarioId);
    return response.data.name;
  } catch (error) {
    console.error(`Failed to fetch name for material ${key}:`, error);
    return key;
  }
};

const fetchProviderName = async (scenarioId: string, providerId: string) => {
  try {
    if (!providerNames.value[providerId]) {
      const response = await ApiService.organizations.getSingle(providerId, scenarioId);
      providerNames.value[providerId] = response.data.name;
    }
    return providerNames.value[providerId];
  } catch (error) {
    console.error(`Failed to fetch name for provider ${providerId}:`, error);
    return providerId;
  }
};

const pickMaterialProviderUserNoticePayload = ref<PickMaterialProviderUserNoticePayload>();

const parseJsonString = async (jsonString: string) => {
  try {
    if (!jsonString) return;
    const data: PickMaterialProviderUserNoticePayload = JSON.parse(jsonString);
    pickMaterialProviderUserNoticePayload.value = data;
    parsedItems.value = data.dictionaryOfCostCatalogueItemsThatRequirePicking;

    selectedProviders.value = Object.keys(data.dictionaryOfCostCatalogueItemsThatRequirePicking).reduce((acc, orderLineKey) => {
      acc[orderLineKey] = Object.keys(data.dictionaryOfCostCatalogueItemsThatRequirePicking[orderLineKey].costCatalogueItems).reduce((materialAcc, materialKey) => {
        materialAcc[materialKey] = data.selectedProviders?.[orderLineKey]?.[materialKey] || "";
        return materialAcc;
      }, {} as { [key: string]: string });
      return acc;
    }, {} as { [key: string]: { [key: string]: string } });

    const orderLineKeys = Object.keys(data.dictionaryOfCostCatalogueItemsThatRequirePicking);
    const materialKeys = new Set<string>();

    for (const orderLineKey of orderLineKeys) {
      const resourceResponse = data.dictionaryOfCostCatalogueItemsThatRequirePicking[orderLineKey];
      for (const materialKey in resourceResponse.costCatalogueItems) {
        materialKeys.add(materialKey);
      }
    }

    const orderLineNamePromises = orderLineKeys.map(async (key) => {
      orderLineNames.value[key] = await fetchOrderLineDescription(data.orderId, data.scenarioId, key);
    });

    const materialNamePromises = Array.from(materialKeys).map(async (key) => {
      materialNames.value[key] = await fetchMaterialName(data.scenarioId, key);
    });

    await Promise.all([...orderLineNamePromises, ...materialNamePromises]);

    await generateLabels();
  } catch (error) {
    console.error("Failed to parse JSON string:", error);
  }
};

const generateLabels = async () => {
  for (const orderLineKey in parsedItems.value) {
    const resourceResponse = parsedItems.value[orderLineKey];
    for (const materialKey in resourceResponse.costCatalogueItems) {
      for (const item of resourceResponse.costCatalogueItems[materialKey]) {
        const key = `${orderLineKey}-${materialKey}-${item.id}`;
        labels.value[key] = await createCostCatalogueText(item);
      }
    }
  }
};

const createCostCatalogueText = async (item: CostCatalogueItemDto) => {
  const parts = [];

  if (item.name) parts.push(`Name: ${item.name}`);
  if (item.manufacturer) parts.push(`Manufacturer: ${item.manufacturer}`);
  parts.push(`Price: ${item.pricePerQuantity} ${translateCurrencyCode(item.currencyCode)}`);
  if (item.providerId) {
    const providerName = await fetchProviderName(pickMaterialProviderUserNoticePayload.value?.scenarioId, item.providerId);
    parts.push(`Provider: ${providerName}`);
  }

  return parts.join(", ");
};

const saveSelections = async () => {
  try {
    const updatedData = {
      ...pickMaterialProviderUserNoticePayload.value,
      selectedProviders: selectedProviders.value
    };
    await ApiService.userNotices.update({ userNoticePayload: JSON.stringify(updatedData), id: props.userNoticeId, status: UserNoticeStatus.InProgress } as UpdateUserNoticeCommand);
  } catch (error) {
    console.error("Failed to save selections:", error);
  }
};

const savePermanently = async () => {
  try {
    const updatedData = {
      ...pickMaterialProviderUserNoticePayload.value,
      selectedProviders: selectedProviders.value
    };
    await ApiService.userNotices.update({
      userNoticePayload: JSON.stringify(updatedData),
      id: props.userNoticeId,
      status: UserNoticeStatus.ActionTaken
    } as UpdateUserNoticeCommand);
    await ApiService.events.sendEvent(currentTenantId.value, {
      tenantId: currentTenantId.value,
      eventName: "MaterialProvidersPicked",
      businessKey: pickMaterialProviderUserNoticePayload.value?.businessKey
    } as RegisterEventCommand);
    await router.push({ name: "Orders", query: { orderId: pickMaterialProviderUserNoticePayload.value?.orderId } });
  } catch (error) {
    console.error("Failed to save selections:", error);
  }
};

watch(() => props.jsonString, (newJsonString) => {
  parseJsonString(newJsonString);
}, { immediate: true });

onMounted(() => {
  parseJsonString(props.jsonString);
});
</script>

<template>
  <v-container>
    <v-row v-for="(resourceResponse, orderLineKey) in parsedItems" :key="orderLineKey" class="item-list">
      <v-col cols="12">
        <v-card>
          <v-card-title>
            {{ $t("materialProviderSelector-orderLine-description-label", { $: "Choose material providers for order line" }) }}
          </v-card-title>
          <v-card-subtitle>{{ orderLineNames[orderLineKey] || orderLineKey }}</v-card-subtitle>
          <v-card-text>
            <v-row v-for="(items, materialKey) in resourceResponse.costCatalogueItems" :key="materialKey" class="item-group">
              <v-col cols="12">
                <v-card>
                  <v-card-title>{{ materialNames[materialKey] || materialKey }}</v-card-title>
                  <v-card-text>
                    <v-radio-group v-model="selectedProviders[orderLineKey][materialKey]" :name="`${orderLineKey}-${materialKey}`">
                      <v-radio
                        v-for="item in items"
                        :key="item.id"
                        :label="labels[`${orderLineKey}-${materialKey}-${item.id}`]"
                        :value="item.id"
                      ></v-radio>
                    </v-radio-group>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!--    <v-row>-->
    <!--      <v-col cols="12">-->
    <!--        <v-card>-->
    <!--          <v-card-title>Selected Items:</v-card-title>-->
    <!--          <v-card-text>-->
    <!--            <pre>{{ selectedProviders }}</pre>-->
    <!--          </v-card-text>-->
    <!--        </v-card>-->
    <!--      </v-col>-->
    <!--    </v-row>-->
    <v-row>
      <v-col cols="2">
        <v-btn block density="default" color="primary" @click="saveSelections">{{ $t("materialProviderSelector-saveDraft-btn-label", { $: "Save draft" }) }}</v-btn>
      </v-col>
      <v-col cols="3">
        <v-btn block density="default" color="primary" @click="savePermanently">{{ $t("materialProviderSelector-SaveProceed-btn-label", { $: "Save and proceed" }) }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
.item-list {
  margin-bottom: 20px;
}

.item-group {
  margin-bottom: 15px;
}

.item {
  margin-bottom: 10px;
}
</style>
