import { inject, injectable, multiInject } from "inversify";
import {
  ActivityActionEvent,
  createActivityActionEvent,
  GanttEvents,
  IContextMenuItemActionContext, IGanttAction,
  IocSymbols,
  Layer,
  Lifecycle,
  TimeIntervalTreeActivityRepository
} from "@masta/gantt2/core";
import { ResourceCapacityActivity } from "@/components/Gantt/Common/Model";
import { BehaviorSubject, combineLatest, debounceTime, filter, finalize, first, lastValueFrom, map, merge, startWith, Subject, tap } from "rxjs";
import { LayerCanvas, ViewportRowsContainer } from "@masta/gantt2/gantt";
import ApiService from "@/services/api";
import { ActivityConnectorsLayer } from "@/components/Gantt/ResourcesGantt/Layers/ActivityConnectorsLayer";
import { GanttTaskConnection, SchedulingFinishedNotificationEvent } from "@masta/generated-model";

@injectable()
export class SelectRootTaskActivitiesAction extends Lifecycle implements IGanttAction {
  private _finished$$ = new BehaviorSubject(false);
  private _businessKey: string | null = null;
  private _rootTaskId: string | null = null;
  private _connectors: GanttTaskConnection[] | undefined = undefined;
  private _activityConnectorsLayer: ActivityConnectorsLayer;

  constructor(@inject(IocSymbols.LayersSymbol) private _layers$$: BehaviorSubject<Layer[]>,
              @inject(ViewportRowsContainer) private _viewportRowsContainer: ViewportRowsContainer,
              @inject(GanttEvents) private _ganttEvents: GanttEvents,
              @multiInject(IocSymbols.AboveRowContentSystemLayersSymbol) aboveRowLayers: LayerCanvas[] = []) {
    super();
    this._activityConnectorsLayer = [...aboveRowLayers.values()].find(x => x.identifier === ActivityConnectorsLayer.name)! as ActivityConnectorsLayer;
  }

  get rootTaskId(): string | null {
    return this._rootTaskId;
  }

  private initializeAction(context: IContextMenuItemActionContext) {
    if (context.activityBounds.length < 1) {
      console.warn("invalid activity bounds count , should be 1 or more but was ", context.activityBounds.length);
      return this.cancel();
    }
    const layers = this._layers$$;
    const bound = context.activityBounds[0];
    const activity: ResourceCapacityActivity | undefined = context.row.repository.getActivity(layers.value.find(l => l.id === bound.layerId)!, bound.activityId);
    if (!activity) {
      console.warn("no activity found");
      return this.cancel();
    }

    this._ganttEvents.deselectAllActivities();

    const rootTaskId = activity.userObject.task.rootTaskId;
    this._rootTaskId = rootTaskId;
  }

  private getAllActivities() {
    return this._viewportRowsContainer.rowContainers.map(x => (x.row.repository as TimeIntervalTreeActivityRepository<ResourceCapacityActivity>).getAllActivities()).flat();
  }

  private getRootTaskActivities() {
    return this.getAllActivities().filter(x => x.userObject.task?.rootTaskId === this.rootTaskId).filter(x => x.enabled && x.selectable);
  }

  private lockActivities() {
    const allActivities = this.getAllActivities();
    const rootNonLockedTaskActivities = this.getRootTaskActivities().filter(x => !x.locked);

    // lock other activities
    const activitiesToLock = allActivities.filter(a => !rootNonLockedTaskActivities.some(rta => rta.id === a.id));
    for (const a of activitiesToLock) {
      a.lock(ResourceCapacityActivity.name);
    }

    return rootNonLockedTaskActivities.length > 0;
  }

  private unlockActivities() {
    const allLockedActivities = this._viewportRowsContainer.rowContainers.map(x => (x.row.repository as TimeIntervalTreeActivityRepository<ResourceCapacityActivity>).getAllActivities()).flat().filter(x => x.isLockedBy(ResourceCapacityActivity.name));
    for (const a of allLockedActivities) {
      a.releaseLock(ResourceCapacityActivity.name);
    }
  }

  private doExecution() {
    const repositoryChangeEvents$ = merge(...this._viewportRowsContainer.rowContainers.map(x => x.row.repository.changeEvent$)).pipe(startWith(null));

    // Step 1: Map each activityBounds$$.data$ to its current value stream
    const activityBoundsStreams$ = this._viewportRowsContainer.rowContainers.map(x => x.activityBounds$$.data$);
    // Step 2: Combine all these observables
    const combinedActivityBounds$ = combineLatest(activityBoundsStreams$).pipe(
      // Step 3: Flatten the array of arrays into a single array
      map(arrays => arrays.flat())
    );

    this.getConnectorsFromAPI().then(() => {

      this.updateConnectorsLayer();

      this.subscribe(
        this._ganttEvents.rowMousePressedEvent$
          .pipe(filter(e => !!e && (e.type === "pointerup")))
          .subscribe((e) => {
            if (!e) return;
            if (e.osCtrlKey) {
              this.cancel();
            }
          })
      );

      // lock / unlock activities
      this.subscribe(repositoryChangeEvents$
        .pipe(
          map(() => {
            return this.lockActivities();
          }),
          debounceTime(100),
          tap(async (hasLockedSomeActivities) => {
            // update connectors layer if there are any root task activities
            if (hasLockedSomeActivities) {
              await this.getConnectorsFromAPI();
              this.updateConnectorsLayer();
            }
          }),
          finalize(() => {
            this.unlockActivities();
          })
        )
        .subscribe()
      );

      // select activities
      this.subscribe(combinedActivityBounds$
        .pipe(
          tap((activityBounds) => {

            const rootTaskActivities = this.getRootTaskActivities();

            const rootTaskBounds = activityBounds.filter(x => rootTaskActivities.some(a => a.id === x.activityId));
            const actions = rootTaskBounds.map(x => {
              const row = this._viewportRowsContainer.rowContainers.find(rc => rc.row.id === x.rowId)?.row;
              if (!row) return;
              return createActivityActionEvent(x, this._layers$$.value, row);
            }).filter(x => !!x) as ActivityActionEvent[];

            if (actions.length === 0) return;

            this._ganttEvents.addSelectedActivities(actions);
          }),
          finalize(() => {
            this._ganttEvents.deselectAllActivities();
            this._viewportRowsContainer.batchDraw(true);
          })
        )
        .subscribe()
      );

      // redraw viewport
      this.subscribe(combinedActivityBounds$
        .pipe(
          tap(() => this._viewportRowsContainer.batchDraw(true)),
          debounceTime(100)
        ).subscribe()
      );

    }).catch((e) => {
      console.error(e);
      this.cancel();
    });
  }

  public execute(context: IContextMenuItemActionContext) {

    this.initializeAction(context);

    this.doExecution();

    return lastValueFrom(this._finished$$.pipe(
      // Use the first operator to get the first true value emitted
      first(value => value === true),
      // Destroy the actions and subscriptions
      tap(async () => {
        if (this._subscriptions$$.closed) return;
        this._ganttEvents.deselectAllActivities();
        this._activityConnectorsLayer.visible = false;
        await this.destroy();
      }),
      // Map the value to undefined because we only care about the resolution
      map(() => undefined)
    ));
  }

  setInProgress(businessKey: string) {
    this._businessKey = businessKey;
  }

  onSchedulingFinished(event: SchedulingFinishedNotificationEvent) {
    if (event.businessKey === this._businessKey) {
      // this.cancel();
    }
  }

  cancel(): void {
    this._finished$$.next(true);
  }

  private async getConnectorsFromAPI() {
    if (!this._rootTaskId) return;
    try {
      const { data } = await ApiService.gantt.getConnectors(this._rootTaskId);
      this._connectors = data.connections;
    } catch (e: any) {
      if (e.name !== "AbortError") {
        console.error(e);
      }
    }
  }

  private updateConnectorsLayer() {
    if (!this._rootTaskId || !this._connectors) return;
    const connectors = [];

    const activities = this.getAllActivities();

    for (const connector of this._connectors ?? []) {

      const startTaskActivities = activities.filter(x => x.userObject.task?.id === connector.startTaskId);
      const endTaskActivities = activities.filter(x => x.userObject.task?.id === connector.endTaskId);

      const startActivityDto = startTaskActivities.length > 0 ? startTaskActivities.reduce((a, b) => a.endTime.toEpochMilli() > b.endTime.toEpochMilli() ? a : b)?.userObject : undefined;
      const startRowContainer = this._viewportRowsContainer.rowContainers.find(r => r.row.id === startActivityDto?.resourceId);
      const endActivityDto = endTaskActivities.length > 0 ? endTaskActivities.reduce((a, b) => a.endTime.toEpochMilli() > b.endTime.toEpochMilli() ? a : b)?.userObject : undefined;
      const endRowContainer = this._viewportRowsContainer.rowContainers.find(r => r.row.id === endActivityDto?.resourceId);

      if (!startRowContainer || !endRowContainer) continue;

      connectors.push({
        ...connector,
        startTaskStartTime: startActivityDto?.startTime,
        startTaskEndTime: startActivityDto?.endTime,
        startTaskResourceId: startActivityDto?.resourceId,
        startTaskRowContainer: startRowContainer,
        endTaskStartTime: endActivityDto?.startTime,
        endTaskEndTime: endActivityDto?.endTime,
        endTaskResourceId: endActivityDto?.resourceId,
        endTaskRowContainer: endRowContainer
      });
    }

    this._activityConnectorsLayer.connectors = connectors;

    this._activityConnectorsLayer.visible = true;
  }
}
