<script lang="ts" setup>
import * as OV from "online-3d-viewer";
import { onMounted, onUnmounted, ref } from "vue";
import type { EmbeddedViewer } from "online-3d-viewer";

const $props = defineProps<{
  file: () => Promise<File>;
}>();
const viewerRef = ref();
const embeddedViewer = ref<EmbeddedViewer>();
onMounted(async () => {
  // set the location of the external libraries
  OV.SetExternalLibLocation("/libs3d");

  const file = await $props.file();

  embeddedViewer.value = OV.Init3DViewerFromFileList(viewerRef.value, [file], {});
});

onUnmounted(() => {
  if (embeddedViewer.value) {
    embeddedViewer.value.Destroy();
  }
});
</script>

<template>
  <div ref="viewerRef" class="online_3d_viewer"></div>
</template>

<style lang="scss" scoped>
.online_3d_viewer {
  border: 1px solid;
  width: 100%;
  height: 100%;
}
</style>
