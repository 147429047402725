import { ActivityRef, IActivity } from "../Model";
import { ActivityPosition } from "./ActivityPosition";
import { ActivityBounds } from "./ActivityBounds";
import { Renderer } from "./Renderer";
import { TimelineManager } from "../TimelineManager";
import type { IRowContainer } from "./IRowContainer";

export interface IActivityRenderingRequest<TActivity extends IActivity> {
  activityBounds: ActivityBounds;
  activityRef: ActivityRef<TActivity>;
  position: ActivityPosition;
  canvas: OffscreenCanvas | HTMLCanvasElement;
  context: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D;
  x: number;
  y: number;
  w: number;
  h: number;
  offsetTop: number;
  selected: boolean;
  hover: boolean;
  highlighted: boolean;
  pressed: boolean;
}

export abstract class ActivityRenderer<TActivity extends IActivity> extends Renderer {
  protected _minWidth = 2;
  protected _ignoreCache = false;
  protected readonly MAX_CANVAS_WIDTH = 32767; // @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/canvas#maximum_canvas_size

  protected constructor(timelineManager: TimelineManager, protected _rowContainer: IRowContainer, name: string) {
    super(timelineManager, name);
  }

  protected getActivityBounds(activityRef: ActivityRef<TActivity>): ActivityBounds<TActivity> | undefined {
    return this._rowContainer.activityBounds$$.data.get(activityRef.id);
  }

  protected addActivityBounds(activityRef: ActivityRef<TActivity>, bounds: ActivityBounds<TActivity>): void {
    this._rowContainer.activityBounds$$.add(bounds);
  }

  public draw(
    activityRef: ActivityRef<TActivity>,
    position: ActivityPosition,
    rowCanvasContext: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D,
    x: number,
    y: number,
    w: number,
    h: number,
    offsetTop: number,
    selected: boolean,
    hover: boolean,
    highlighted: boolean,
    pressed: boolean
  ): ActivityBounds | undefined {
    if (w <= this._minWidth) return;

    let bounds = this.getActivityBounds(activityRef);
    if (bounds) {
      if (
        bounds.width === w &&
        bounds.height === h &&
        bounds.highlighted === highlighted &&
        bounds.selected === selected &&
        bounds.hovered === hover &&
        bounds.pressed === pressed &&
        bounds.locked === activityRef.activity.locked
      ) {
        bounds.x = x;
        bounds.y = y;
        bounds.offsetTop = offsetTop;
        if (w > this.MAX_CANVAS_WIDTH) {
          // console.log("%cActivityRenderer.draw: canvas width exceeds maximum allowed value. Using drawActivity instead.", "color: red; font-weight: bold;", activityRef.id);
          this.drawActivity({
            activityBounds: bounds,
            activityRef,
            position,
            context: rowCanvasContext,
            canvas: rowCanvasContext.canvas,
            x,
            y,
            w,
            h,
            offsetTop,
            selected,
            hover,
            highlighted,
            pressed
          });
          return bounds;
        }
        if (!this._ignoreCache && bounds.width > 0 && bounds.height > 0) {
          // console.log("%cActivityRenderer.draw: using cached canvas", "color: green; font-weight: bold;", activityRef.id);
          return this.drawFromCache(bounds, rowCanvasContext);
        }
      } else {
        // console.log("%cActivityRenderer.draw: resizing canvas", "color: yellow; font-weight: bold;", activityRef.id");
        const dpr = window.devicePixelRatio || 1;
        bounds.canvas.width = w * dpr;
        bounds.canvas.height = h * dpr;
        bounds.context?.scale(dpr, dpr);
        bounds.x = x;
        bounds.y = y;
        bounds.width = w;
        bounds.height = h;
        bounds.offsetTop = offsetTop;
        bounds.highlighted = highlighted;
        bounds.selected = selected;
        bounds.hovered = hover;
        bounds.pressed = pressed;
        bounds.locked = activityRef.activity.locked;
      }
    } else {
      bounds = new ActivityBounds<any>(activityRef, x, y, w, h, offsetTop);
      this.addActivityBounds(activityRef, bounds);
    }
    if (w > this.MAX_CANVAS_WIDTH) {
      // console.log("%cActivityRenderer.draw: canvas width exceeds maximum allowed value. Using drawActivity instead.", "color: red; font-weight: bold;", activityRef.id);
      this.drawActivity({
        activityBounds: bounds,
        activityRef,
        position,
        context: rowCanvasContext,
        canvas: rowCanvasContext.canvas,
        x,
        y,
        w,
        h,
        offsetTop,
        selected,
        hover,
        highlighted,
        pressed
      });
      return bounds;
    }
    // console.log("%cActivityRenderer.draw: drawing activity", "color: #5F65F8FF; font-weight: bold;", activityRef.id, { w, h, aw: bounds.width, ah: bounds.height });
    this.drawActivity({
      activityBounds: bounds,
      activityRef,
      position,
      context: bounds.context!,
      canvas: bounds.canvas,
      x: 0,
      y: 0,
      w: w,
      h: h,
      offsetTop,
      selected,
      hover,
      highlighted,
      pressed
    });
    if (bounds.width > 0 && bounds.height > 0) {
      return this.drawFromCache(bounds, rowCanvasContext);
    } else {
      return;
    }
  }

  public abstract drawActivity(request: IActivityRenderingRequest<TActivity>): void;

  protected drawBackground(
    activityRef: ActivityRef<TActivity>,
    position: ActivityPosition,
    context: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D,
    x: number,
    y: number,
    w: number,
    h: number,
    offsetTop: number,
    selected: boolean,
    hover: boolean,
    highlighted: boolean,
    pressed: boolean
  ): void {
    context.save();
    const fillColor = this.getFill(selected, hover, highlighted, pressed);
    if (fillColor) {
      context.fillStyle = fillColor;
    }
    context.fillRect(x, y, w, h);
    context.restore();
  }

  public drawLine(context: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D, startX: number, startY: number, endX: number, endY: number, strokeStyle?: string) {
    const prevStrokeStyle = context.strokeStyle;
    if (strokeStyle) context.strokeStyle = strokeStyle;

    context.beginPath();
    context.moveTo(startX, startY);
    context.lineTo(endX, endY);
    context.stroke();

    if (strokeStyle) context.strokeStyle = prevStrokeStyle;
  }

  protected drawFromCache(activityBounds: ActivityBounds<TActivity>, rowCanvasContext: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D) {
    const dx = activityBounds.x;
    const dy = activityBounds.y;
    rowCanvasContext.drawImage(activityBounds.canvas, dx, dy, activityBounds.width, activityBounds.height);
    // console.log("%cActivityRenderer.draw: drawing from cache", "color: #5F65F8FF; font-weight: bold;", activityBounds.activityId);
    return activityBounds;
  }

  protected getTextLines(ctx: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D, text: string, maxWidth: number) {
    const words = text.split(" ");
    const lines = [];
    let currentLine = words[0];

    for (let i = 1; i < words.length; i++) {
      const word = words[i];
      const width = ctx.measureText(currentLine + " " + word).width;
      if (width < maxWidth) {
        currentLine += " " + word;
      } else {
        lines.push(currentLine);
        currentLine = word;
      }
    }
    lines.push(currentLine);
    return lines;
  }
}
