<script setup lang="ts">
import { $t } from "@/i18n";
import { computed, useSlots } from "vue";

interface Props {
  modelValue: any[];
  label?: string;
}

defineProps<Props>();
const slots = useSlots();

const hasAnySlot = computed(() => Object.keys(slots).length > 0);

function isDisabled(item: any) {
  return typeof item.disabled === "function" ? item.disabled() : typeof item.disabled === "boolean" ? item.disabled : false;
}
</script>
<template>
  <v-menu v-if="modelValue.length > 0 || hasAnySlot" open-on-hover bottom offset-y open-delay="100" eager>
    <template #activator="{ props, isActive }">
      <slot name="activator" :props="props" :is-active="isActive">
        <v-btn variant="text" density="compact" v-bind="props">
          <v-icon class="pr-4" :icon="isActive ? 'mdi-chevron-double-up' : 'mdi-chevron-double-down'" />
          <span>{{ label ?? $t("actions-button-label", { $: "Actions" }) }}</span>
        </v-btn>
      </slot>
    </template>

    <v-list density="compact" class="pa-0">
      <slot name="prepend" />
      <template v-for="(item, index) in modelValue" :key="index">
        <v-tooltip v-if="item.tooltip" location="bottom" open-delay="300">
          <template #activator="{ props }">
            <v-list-item
              v-bind="props" dense class="pt-0 pb-0" :title="item.title" :prepend-icon="item.icon" :disabled="isDisabled(item)" :style="{order: item.order}"
              @click="item.action" />
          </template>
          {{ item.tooltip }}
        </v-tooltip>
        <v-list-item
          v-else-if="!item.separator" dense class="pt-0 pb-0" :title="item.title" :prepend-icon="item.icon" :disabled="isDisabled(item)" :style="{order: item.order}"
          @click="item.action" />
        <v-divider v-else :style="{order: item.order}" />
      </template>
      <slot name="append" />
    </v-list>
  </v-menu>
</template>

<style lang="scss" scoped>
:deep(.v-list) {
  display: flex;
  flex-direction: column;
}
</style>
