<script setup lang="ts">

import UserNoticesGrid from "@/components/UserNotices/UserNoticesGrid.vue";

const openedModelValue = defineModel<boolean>({ required: true });

const props = defineProps<{
  url?: string;
}>();

function close() {
  openedModelValue.value = false;
}

function openInNewTab() {
  window.open(props.url, "_blank");
  close();
}

</script>

<template>
  <v-dialog v-model="openedModelValue" width="80vw" height="80vh" @click:outside="close">
    <v-card width="100%" height="100%" location="center center" class="userNotice-dialog-card">
      <v-card-title class="pa-3">
        {{ $t("userNotice-title-label", { $: "User Notices" }) }}
      </v-card-title>
      <v-card-text class="pa-3">
        <user-notices-grid @close-dialog="close" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>


<style lang="scss">
.onboarding-help-dialog-card {
  padding: 1em;

  .left-col,
  .right-col {
    flex: 1;
    overflow: hidden;
    padding: 6px;
  }

  .middle-col {
    min-width: 78px;
    width: auto;
  }
}
</style>
