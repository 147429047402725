﻿<script setup lang="ts">
import DatepickerField from "@/components/Datepicker/DatepickerField.vue";
import GridWrapper from "@/components/Grid/GridWrapper.vue";
import Breadcrumbs from "@/components/Layout/Breadcrumbs.vue";
import MasterDetailLayout from "@/components/Layout/MasterDetailLayout.vue";
import ResourcesPicker from "@/components/Resources/ResourcesPicker.vue";
import { $t } from "@/i18n";
import ApiService from "@/services/api";
import { useSnackbarsStore } from "@/store/SnackbarsStore";
import { OleAggregationType, OleReportData, OleReportRecord, ResourceInfoDto, ResourceType } from "@masta/generated-model";
import { $dateSymbol, $longTermSecondsBasedDurationFormatterSymbol, SecondsDurationFormatter } from "@masta/shared";
import { CreateRangeChartParams } from "ag-grid-charts-enterprise";
import { FirstDataRenderedEvent, ValueFormatterParams } from "ag-grid-community";
import dayjs from "dayjs";
import { inject, ref, watch } from "vue";
import { useScenariosStore } from "@/store/ScenariosStore";

const $durationFormatter = inject<SecondsDurationFormatter>($longTermSecondsBasedDurationFormatterSymbol)!;
const $date = inject<typeof dayjs>($dateSymbol)!;
const start = $date.utc().startOf("week").hour(0).minute(0).second(0);
const end = start.add(7, "day");
const defaultRequest = {
  start: start.toISOString(),
  end: end.toISOString(),
  aggregationType: OleAggregationType.Day,
  timeZone: "UTC"
};

const requestParams = ref({
  start: defaultRequest.start,
  end: defaultRequest.end,
  aggregationType: defaultRequest.aggregationType,
  timeZone: defaultRequest.timeZone,
  personnelBusinessIds: [] as string[]
});

const selectedResources = ref<ResourceInfoDto[]>([]);

const timeZones = [
  { name: "Local", value: Intl.DateTimeFormat().resolvedOptions().timeZone },
  { name: "UTC (+0)", value: "UTC" }
];

const aggregationTypes = [
  { id: OleAggregationType.Day, label: "Day" },
  { id: OleAggregationType.Month, label: "Month" },
  { id: OleAggregationType.Week, label: "Week" }
];

const reportData = ref<OleReportData | null>(null);
const gridData = ref<OleReportRecord[]>([]);

const defaultColumnDef = ref<object>({
  filter: false,
  sortable: true,
  resizable: true
});

const snackbarsStore = useSnackbarsStore();
const loading = ref<boolean>(true);
const chartToolPanelsDef = ref(null);

async function onFetchData() {
  loading.value = true;
  try {
    const { data } = await ApiService.reports.getOLEReportData({
      aggregationType: requestParams.value.aggregationType,
      timeZone: requestParams.value.timeZone,
      start: $date(requestParams.value.start).toISOString().slice(0, -1),
      end: $date(requestParams.value.end).toISOString().slice(0, -1),
      personnelBusinessIds: requestParams.value.personnelBusinessIds,
      scenarioId: useScenariosStore().selectedScenario?.id
    });
    reportData.value = data;
    gridData.value = data.records ?? [];
    chartToolPanelsDef.value = {
      defaultToolPanel: "settings"
    } as any;
  } catch (err: any) {
    await snackbarsStore.createSnackbar({
      message: err.message,
      type: "error"
    });
  } finally {
    loading.value = false;
  }
}

async function onRefreshAction() {
  await onFetchData();
}

function onPrepareColumns(columnDefs: any) {
  columnDefs.value = [
    {
      field: "period",
      editable: false,
      sortable: false
    },

    {
      field: "ole",
      editable: false,
      sortable: false,
      valueFormatter: (params: ValueFormatterParams) => `${params.value}%`
    },
    {
      field: "availabilityMax",
      editable: false,
      sortable: false,
      valueFormatter: (params: ValueFormatterParams) => $durationFormatter(params.value)
    },
    {
      field: "availabilityBreaks",
      editable: false,
      sortable: false,
      valueFormatter: (params: ValueFormatterParams) => $durationFormatter(params.value)
    },
    {
      field: "availabilityWorkingTime",
      editable: false,
      sortable: false,
      valueFormatter: (params: ValueFormatterParams) => $durationFormatter(params.value)
    },
    {
      field: "availabilityPercentage",
      editable: false,
      sortable: false,
      valueFormatter: (params: ValueFormatterParams) => `${params.value}%`
    },
    {
      field: "effectivenessQuantityGood",
      editable: false,
      sortable: false
    },
    {
      field: "effectivenessProcessingTime",
      editable: false,
      sortable: false,
      valueFormatter: (params: ValueFormatterParams) => $durationFormatter(params.value)
    },
    {
      field: "effectivenessExpectedProcessingTime",
      editable: false,
      sortable: false,
      valueFormatter: (params: ValueFormatterParams) => $durationFormatter(params.value)
    },
    {
      field: "effectivenessPercentage",
      editable: false,
      sortable: false,
      valueFormatter: (params: ValueFormatterParams) => `${params.value}%`
    },
    {
      field: "qualityQuantityGood",
      editable: false,
      sortable: false
    },
    {
      field: "qualityQuantityScrap",
      editable: false,
      sortable: false
    },
    {
      field: "qualityPercentage",
      editable: false,
      sortable: false,
      valueFormatter: (params: ValueFormatterParams) => `${params.value}%`
    }
  ];
}

function onFirstDataRendered(event: FirstDataRenderedEvent) {
  const eContainer1 = document.querySelector("#chart1");
  const params1 = {
    cellRange: {
      columns: ["period", "ole"]
    },
    chartType: "line",
    chartContainer: eContainer1
  } as CreateRangeChartParams;
  event.api.createRangeChart(params1);
}

watch(selectedResources, (resources) => {
  requestParams.value.personnelBusinessIds = resources.map((x) => x.businessId);
});
</script>

<template>
  <master-detail-layout scenario-selector>
    <template #master>
      <v-card elevation="0" class="flexcard fill-height">
        <v-card-title>
          <breadcrumbs>{{ $t("ole-report-view-title", { $: "OLE Report" }) }}</breadcrumbs>
        </v-card-title>
        <v-card-text class="fill-height">
          <v-row class="align-center">
            <v-col cols="2" align-self="start">
              <datepicker-field
                v-model="requestParams.start"
                utc="preserve"
                timezone="UTC"
                auto-apply
                format="yyyy-MM-dd HH:mm"
                teleport="body"
                :clearable="false"
                :disabled="loading"
                :label="$t('ole-report-view-start-label', { $: 'Start' })"
                class="datapicker-field"
              ></datepicker-field>
            </v-col>
            <v-col cols="2" align-self="start">
              <datepicker-field
                v-model="requestParams.end"
                utc="preserve"
                timezone="UTC"
                auto-apply
                format="yyyy-MM-dd HH:mm"
                teleport="body"
                :clearable="false"
                :disabled="loading"
                :label="$t('ole-report-view-end-label', { $: 'End' })"
                class="datapicker-field"
              ></datepicker-field>
            </v-col>
            <v-col cols="2" lg="1" align-self="start">
              <v-select
                v-model="requestParams.timeZone"
                :disabled="loading"
                variant="outlined"
                density="compact"
                hide-details
                :items="timeZones"
                item-title="name"
                item-value="value"
                :label="$t('ole-report-view-timeZone-label', { $: 'Time zone' })"
              />
            </v-col>
            <v-col cols="6" lg="2" align-self="start">
              <v-field variant="outlined" :label="$t('ole-report-view-aggregationType-label', { $: 'Aggregate' })" active>
                <v-radio-group
                  v-model="requestParams.aggregationType"
                  :disabled="loading"
                  class="radio-select"
                  density="compact"
                  hide-details
                  inline
                >
                  <v-radio v-for="item in aggregationTypes" :key="item.id" :label="item.label" :value="item.id" />
                </v-radio-group>
              </v-field>
            </v-col>
            <v-col cols="5" lg="3" align-self="start">
              <resources-picker
                v-model="selectedResources"
                :readonly="loading"
                :disabled="loading"
                :label="$t('ole-report-view-equipment-label', { $: 'Personel' })"
                :resource-type="ResourceType.Person" />
            </v-col>
            <v-col cols="2" align-self="start">
              <v-btn color="primary" density="comfortable" block :disabled="loading" :loading="loading" @click="onRefreshAction">
                {{ $t("ole-report-view-refresh-action", { $: "Refresh" }) }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="fill-height mt-0">
            <v-col cols="12">
              <grid-wrapper
                ref="gridWrapperRef"
                identifier="ole-report"
                :enable-charts="true"
                :enable-range-selection="true"
                :grid-data="gridData"
                :default-col-def="defaultColumnDef"
                :chart-tool-panels-def="chartToolPanelsDef"
                class="h-50"
                @fetch-data="onFetchData"
                @refresh-action="onRefreshAction"
                @prepare-columns="onPrepareColumns"
                @first-data-rendered="onFirstDataRendered"
              >
                <v-row style="height: 45%">
                  <v-col cols="4">
                    <v-row v-if="reportData" class="fill-height text-h5 py-4">
                      <v-col cols="6" class="d-flex align-center justify-end text-right">
                        {{ $t("ole-report-view-totalAvailabilityPercentage-label", { $: "Total Availability" }) }}:
                      </v-col>
                      <!--                      <v-col cols="6"> {{ reportData.totalAvailabilityPercentage }}%</v-col>-->
                      <v-col cols="6" class="d-flex align-center">
                        <v-progress-linear v-model="reportData.totalAvailabilityPercentage" height="25" color="blue" class="text-white" rounded>
                          <template #default="{ value }">
                            <strong>{{ value }}%</strong>
                          </template>
                        </v-progress-linear>
                      </v-col>
                      <v-col cols="6" class="d-flex align-center justify-end text-right">
                        {{ $t("ole-report-view-totalEffectivenessPercentage-label", { $: "Total Effectiveness" }) }}:
                      </v-col>
                      <v-col cols="6" class="d-flex align-center">
                        <v-progress-linear v-model="reportData.totalEffectivenessPercentage" height="25" color="green" class="text-white" rounded>
                          <template #default="{ value }">
                            <strong>{{ value }}%</strong>
                          </template>
                        </v-progress-linear>
                      </v-col>
                      <v-col cols="6" class="d-flex align-center justify-end text-right"> {{ $t("ole-report-view-totalQualityPercentage-label", { $: "Total Quality" }) }}:</v-col>
                      <v-col cols="6" class="d-flex align-center">
                        <v-progress-linear v-model="reportData.totalQualityPercentage" height="25" color="golden" class="text-white" rounded>
                          <template #default="{ value }">
                            <strong>{{ value }}%</strong>
                          </template>
                        </v-progress-linear>
                      </v-col>
                      <v-col cols="6" class="d-flex align-center justify-end text-right"> {{ $t("ole-report-view-totalOLE-label", { $: "Total OLE" }) }}:</v-col>
                      <v-col cols="6" class="d-flex align-center">
                        <v-progress-linear v-model="reportData.totalOle" height="25" color="primary" class="text-white" rounded>
                          <template #default="{ value }">
                            <strong>{{ value }}%</strong>
                          </template>
                        </v-progress-linear>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="8">
                    <div id="chart1" style="flex: 1 1 auto; overflow: hidden; height: 100%; width: 100%; flex-direction: row"></div>
                  </v-col>
                </v-row>
              </grid-wrapper>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
  </master-detail-layout>
</template>

<style lang="scss">
.datapicker-field {
  .v-text-field input.v-field__input {
    min-height: 34px;
  }
}
</style>
