import { inject, injectable, multiInject } from "inversify";
import {
  GanttEvents,
  GanttException,
  GanttSettings,
  GraphicsMousePositionEvent,
  IocSymbols,
  IRowChartDataSet,
  Lifecycle,
  RowChartRepository,
  RowHoverEvent,
  SettingKey,
  TimelineManager
} from "@masta/gantt2/core";
import { startWith, withLatestFrom } from "rxjs";
import { GanttDragAndDropContextResult, SchedulingFinishedNotificationEvent, SchedulingResponseStatus } from "@masta/generated-model";
import ApiService from "@/services/api";
import { DropAreasLayer } from "@/components/Gantt/ResourcesGantt/DragAndDrop/DropAreasLayer";
import { DragAndDropData, LayerCanvas, ViewportPointerEventNoneScrollGlass, ViewportRowsContainer } from "@masta/gantt2/gantt";
import { RowDropZone } from "@/components/Gantt/ResourcesGantt/DragAndDrop/RowDropZone";
import { $t } from "@/i18n";
import { useSnackbarsStore } from "@/store/SnackbarsStore";
import { Instant } from "@js-joda/core";
import { v4 as uuid } from "uuid";
import { useProductionTasksStore } from "@/store/ProductionTasksStore";
import { EnhancedGanttResourceCapacityDto } from "@/components/Gantt/Common/Model";
import { GhostActivityLayer } from "@/components/Gantt/ResourcesGantt/DragAndDrop/GhostActivityLayer";
import { useScenariosStore } from "@/store/ScenariosStore";

@injectable()
export class GanttTaskDragHandler extends Lifecycle {

  private _snackbarsStore: ReturnType<typeof useSnackbarsStore>;
  private _dragAndDropData: DragAndDropData;
  private _context: GanttDragAndDropContextResult;
  private _rowHoverEvent: RowHoverEvent | null = null;
  private _mousePosition: GraphicsMousePositionEvent;
  private _rowDatasets: IRowChartDataSet[];
  private _rowDropZones: RowDropZone[] = [];
  private _dropAreasLayer: DropAreasLayer;
  private _ghostActivityLayer: GhostActivityLayer;
  private _destroyTimeoutMilis: number = 10000;
  private _destroyTimeoutId: any;
  private _businessKey: string | undefined;
  private _isDropped: boolean = false;
  private _dragId: string;

  constructor(@inject(GanttEvents) private _ganttEvents: GanttEvents,
              @inject(GanttSettings) private _settings: GanttSettings,
              @inject(TimelineManager) private _timelineManager: TimelineManager,
              @inject(ViewportRowsContainer) private _rowsContainer: ViewportRowsContainer,
              @inject(RowChartRepository) private _rowChartRepository: RowChartRepository,
              @inject(ViewportPointerEventNoneScrollGlass) private _glassLayer: ViewportPointerEventNoneScrollGlass,
              @multiInject(IocSymbols.AboveRowContentSystemLayersSymbol) aboveRowLayers: LayerCanvas[] = []) {
    super();
    this._dragId = uuid();
    this._snackbarsStore = useSnackbarsStore();
    this._dropAreasLayer = aboveRowLayers.find(x => x.identifier == DropAreasLayer.name) as DropAreasLayer;
    this._ghostActivityLayer = aboveRowLayers.find(x => x.identifier == GhostActivityLayer.name) as GhostActivityLayer;
  }

  set dragAndDropData(value: DragAndDropData) {
    if (this._dragAndDropData) {
      throw new GanttException("DragAndDropData is already set");
    }
    this._dragAndDropData = value;
  }

  public get businessKey(): string | undefined {
    return this._businessKey;
  }

  async afterInitialize(): Promise<void> {
    await super.afterInitialize();
    // this._dropAreasLayer.visible = true;
    this.subscribe(this._rowChartRepository.dataSets$.subscribe(ds => {
      this._rowDatasets = ds.filter(x => x.visible);
    }));

    this.subscribe(
      this._ganttEvents.dragOverEvent$$.pipe(withLatestFrom(this._ganttEvents.externalActivityDrag$$, this._ganttEvents.ganttActivityDrag$$))
        .subscribe(([event, isExternal, draggedActivityData]) => this.onDragOver(event, isExternal, draggedActivityData))
    );

    this.subscribe(
      this._ganttEvents.dropEvent$$.pipe(withLatestFrom(this._ganttEvents.externalActivityDrag$$, this._ganttEvents.ganttActivityDrag$$))
        .subscribe(([event, isExternal, draggedActivityData]) => this.onDrop(event, isExternal, draggedActivityData))
    );
    this._ganttEvents.mousePositionEvent$.pipe(withLatestFrom(this._ganttEvents.rowHoverEvent$.pipe(startWith(null)))).subscribe(async ([mousePositionEvent, rowHoverEvent]) => {
      if (this._rowHoverEvent !== rowHoverEvent) {
        this._rowHoverEvent = rowHoverEvent as RowHoverEvent;
      }

      this._mousePosition = mousePositionEvent;
    });
  }

  async beforeDestroy() {
    await super.beforeDestroy();
    this._dropAreasLayer.visible = false;
    this._dropAreasLayer.batchDraw();
    this._ghostActivityLayer.removeExternalTaskGhostActivityLayerContext(this._dragId);
    this._ghostActivityLayer.removeSchedulingOperationGhostActivityLayerContext(this._businessKey);
    this._ghostActivityLayer.batchDraw();
    this.showAllRows();
  }

  async setContext() {
    try {
      const scenariosStore = useScenariosStore();
      const { data } = await ApiService.gantt.getDragAndDropContext(this._dragAndDropData.activity.task.id, scenariosStore.selectedScenario!.id);
      // check if component is destroyed (delay between drag cancel and API response)
      if (this.isDestroyed) {
        return;
      }
      this._context = data;
      this.hideRowsOutsideOfDndContext();
      await this.setDropZones();

      this._ghostActivityLayer.addExternalTaskGhostActivityLayerContext({
        dragId: this._dragId,
        context: this._context,
        draggableTaskDto: this._dragAndDropData.activity.task
      });
    } catch (e: any) {
      if (e.name !== "AbortError") {
        console.error(e);
      }
    }
  }

  async setDropZones() {
    const resourceIds = this.getUniqueResourceIds();
    const rowContainers = this._rowsContainer.rowContainers.filter(x => resourceIds.length === 0 || resourceIds.includes(x.row.id));
    this._rowDropZones = rowContainers.map(rowContainer => new RowDropZone(rowContainer, this._rowDatasets, this._timelineManager));
    this._dropAreasLayer.rowDropZones = this._rowDropZones;
    this._dropAreasLayer.batchDraw();
    this._ghostActivityLayer.rowDropZones = this._rowDropZones;
  }

  public async handleSchedulingOperationResultEvent(event: SchedulingFinishedNotificationEvent) {
    let status: SchedulingResponseStatus;
    const statuses: SchedulingResponseStatus[] = Object.values(event.taskResponses);
    // determine status for event
    if (statuses.includes(SchedulingResponseStatus.Failure)) {
      status = SchedulingResponseStatus.Failure;
    } else if (statuses.includes(SchedulingResponseStatus.Error)) {
      status = SchedulingResponseStatus.Error;
    } else if (statuses.includes(SchedulingResponseStatus.Warning)) {
      status = SchedulingResponseStatus.Warning;
    } else {
      status = SchedulingResponseStatus.Success;
    }
    this._ghostActivityLayer.updateSchedulingOperationStatus(event.businessKey, status);
  }

  public async destroyWithTimeout() {
    // destroy immediately if no scheduling operation is in progress (not dropped)
    if (!this._isDropped) {
      await this.destroy();
      return;
    }
    this._destroyTimeoutId = setTimeout(async () => {
      await this.destroy();
    }, this._destroyTimeoutMilis);
  }

  private cancelDestroyTimeout() {
    if (this._destroyTimeoutId) {
      clearTimeout(this._destroyTimeoutId);
    }
  }

  private getUniqueResourceIds() {
    const resourceIdsUnique = new Set();

    for (const resourceId of this._context.resourceIds ?? []) {
      resourceIdsUnique.add(resourceId);
    }

    return Array.from(resourceIdsUnique);
  }

  private hideRowsOutsideOfDndContext() {
    try {
      // this._settings.setSetting<boolean>({ key: SettingKey.HELP_SHOW_LAYER, value: true });
      // this._settings.setSetting<boolean>({ key: SettingKey.HELP_SHOW_INFO, value: true });
      // this._settings.setSetting<boolean>({ key: SettingKey.HELP_SHOW_NAV_KEYBOARD, value: true });

      const scenariosStore = useScenariosStore();
      if (!scenariosStore.selectedScenario) return;

      const resourceIds = this.getUniqueResourceIds();

      const containers = this._rowsContainer.rowContainers;
      if (resourceIds.length === 0) {
        for (const rowContainer of containers) {
          rowContainer.element.classList.add("droppable");
        }
      } else {
        for (const rowContainer of containers) {
          if (!resourceIds?.includes(rowContainer.row.id)) {
            rowContainer.element.classList.add("hidden");
          } else {
            rowContainer.element.classList.add("droppable");
          }
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  private showAllRows() {
    const containers = this._rowsContainer.rowContainers;
    for (const rowContainer of containers) {
      const hasHiddenClass = rowContainer.element.classList.contains("hidden");
      const hasDroppableClass = rowContainer.element.classList.contains("droppable");
      rowContainer.element.classList.remove("hidden");
      rowContainer.element.classList.remove("droppable");
      if (hasHiddenClass || hasDroppableClass)
        rowContainer.batchDraw(true);
    }
    // this._settings.setSetting<boolean>({ key: SettingKey.HELP_SHOW_LAYER, value: false });
    // this._settings.setSetting<boolean>({ key: SettingKey.HELP_SHOW_INFO, value: false });
    // this._settings.setSetting<boolean>({ key: SettingKey.HELP_SHOW_NAV_KEYBOARD, value: false });
  }

  private isHoveringOverRow() {
    return this._rowsContainer.rowContainers.filter(rowContainer => this._mousePosition?.y >= rowContainer.element.offsetTop && this._mousePosition?.y <= rowContainer.element.offsetTop + rowContainer.element.offsetHeight).length > 0;
  }

  private async onDragOver(event: DragEvent, isExternal: boolean, _: DragAndDropData[] | null) {
    const rowId = this._rowHoverEvent?.rowId ?? this._dragAndDropData.rowId;

    if (!isExternal && event.dataTransfer && this._context && rowId && this._mousePosition && this.isHoveringOverRow()) {
      const rowDropZone = this._rowDropZones.find(x => x.row.id === rowId);

      if (!rowDropZone) {
        return;
      }

      const isDropAllowed = rowDropZone.isDropAllowedForSupplyArea(this._mousePosition.x);
      if (isDropAllowed) {
        if (this._glassLayer.element.classList.contains("not-allowed")) {
          this._glassLayer.element.classList.remove("not-allowed");
        }
        event.dataTransfer.dropEffect = "move";
        event.preventDefault();
        return;
      } else {
        if (!this._glassLayer.element.classList.contains("not-allowed")) {
          this._glassLayer.element.classList.add("not-allowed");
        }
        event.dataTransfer.dropEffect = "none";
      }
    }
  }

  private async onDrop(event: DragEvent, isExternal: boolean, _: DragAndDropData[] | null) {
    const rowId = this._rowHoverEvent?.rowId ?? this._dragAndDropData.rowId;
    if (!isExternal && !this.isDestroyed && this._dragAndDropData && !this._isDropped && this._mousePosition && rowId && this.isHoveringOverRow()) {
      this._isDropped = true;

      const rowDropZone = this._rowDropZones.find(x => x.row.id === rowId);

      if (!rowDropZone) {
        return;
      }

      const isDropAllowed = rowDropZone.isDropAllowedForSupplyArea(this._mousePosition.x);
      if (!isDropAllowed) {
        const name = this._dragAndDropData.activity.name;
        const businessId = this._dragAndDropData.activity.businessId;
        await this._snackbarsStore.createSnackbar({
          message:
            $t("gantt-dragAndDrop-dropNotAllowed-message", { name, businessId, $: "Task {name}({businessId}) cannot be dropped here!" }),
          type: "warning",
          closeable: true
        });
        return;
      }


      if (this._businessKey) {
        console.warn("Task already scheduled, businessKey: ", this._businessKey);
        return;
      }

      const snapX = rowDropZone.getSnapXCoordinate(this._mousePosition.x, RowDropZone.SnapAreaWidth, this._context.schedulingLengthMillis);
      const dropTime = this._timelineManager.calculateTimeForLocation(snapX);

      this._businessKey = await this.rescheduleTask(this._dragAndDropData.activity, dropTime, rowId);

      if (!this._businessKey) return;

      this.showAllRows();

      // this.cancelDestroyTimeout();

      this._dropAreasLayer.visible = false;
      this._dropAreasLayer.batchDraw();

      this._ghostActivityLayer.removeExternalTaskGhostActivityLayerContext(this._dragId);
      this._ghostActivityLayer.addSchedulingOperationGhostActivityLayerContext({
        businessKey: this._businessKey,
        rowDropZone,
        dropTime,
        context: this._context,
        draggableTaskDto: this._dragAndDropData.activity.task
      });

      setTimeout(() => {
        rowDropZone.container.element.scrollIntoView({ behavior: "instant", block: "center" });
      }, 100);
    }

    await this.destroyWithTimeout();
  }

  private async rescheduleTask(data: EnhancedGanttResourceCapacityDto, dropTime: Instant, resourceId: string | undefined) {
    const name = data.task.name;
    const businessId = data.task.businessId;
    try {
      const businessKey = uuid();
      const store = useProductionTasksStore();
      await store.rescheduleGanttDroppedTask({
        taskId: data.task.id,
        resourceId,
        time: dropTime.toJSON(),
        businessKey
      });
      await this._snackbarsStore.createSnackbar({
        message: $t("gantt-dragAndDrop-onRescheduleTaskSuccess-message", { name, businessId, $: "Task {name}({businessId}) rescheduled!" }),
        type: "info",
        closeable: true
      });
      return businessKey;
    } catch (e) {
      console.error(e);
      await this._snackbarsStore.createSnackbar({
        message: $t("gantt-dragAndDrop-onRescheduleTaskError-message", { name, businessId, $: "Could not schedule task {name}({businessId})" }),
        type: "error",
        closeable: true
      });
    }
  }
}
