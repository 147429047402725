export type GraphicsDragEvent = {
  ctrlKey: boolean;
  altKey: boolean;
  shiftKey: boolean;
  deltaX: number;
  deltaY: number;
};

export function createGraphicsDragEvent(event: HammerInput): GraphicsDragEvent {
  return {
    ctrlKey: event.srcEvent.ctrlKey,
    altKey: event.srcEvent.altKey,
    shiftKey: event.srcEvent.shiftKey,
    deltaX: event.deltaX,
    deltaY: event.deltaY
  };
}
