<script lang="ts">
import { ICellEditorParams } from "ag-grid-community";
import { computed, ref } from "vue";
import { IControlledCellEditorParams, resolveValueRef, useValueChangeControl } from "@/components/Grid/UseValueChangeControl";
import CloseCardButton from "@/components/CloseCardButton.vue";
import { MaybeRef, toRef } from "@vueuse/core";

type CellValueProcessor = (value: any) => any;

export interface IReferencePickerCellEditorParams extends IControlledCellEditorParams, ICellEditorParams {
  render: CellValueProcessor | null;
  transform: CellValueProcessor | null;
  valueSelectedCallback?: (value: any) => void;
  multiselect: boolean;
  props?: MaybeRef<any>;
  component: any;
}

// Warn: Use export default instead of <script setup /> because only this works with AgGrid!
export default {
  components: { CloseCardButton },
  setup(props: { params: IReferencePickerCellEditorParams }) {
    const params = toRef(props, "params");

    const multiselect = ref<boolean>(props.params.multiselect);
    const isEditEnabled = toRef(props.params.isEditEnabled ? props.params.isEditEnabled(props.params) : true);

    const initialValue = multiselect.value && !Array.isArray(props.params.value) ? [props.params.value] : props.params.value;
    const referenceValue = resolveValueRef(props.params, initialValue);

    const container = ref<any>();
    const input = ref<any>();
    const componentProps = toRef(params.value.props);

    const dialog = ref<boolean>(false);
    const reference = ref<any>(null);
    const referenceSelected = ref<boolean>(!!referenceValue.value);

    function getValue() {
      return referenceValue.value;
    }

    function focusIn() {}

    function focusOut() {}

    function afterGuiAttached() {
      if (props.params.cellStartedEdit) {
        focusIn();
      }
    }

    function onBtnConfirm() {
      dialog.value = false;
      if (!referenceSelected.value) {
        referenceValue.value = null;
      } else {
        if ((params.value as any).transform) {
          referenceValue.value = (params.value as any).transform(reference.value);
        } else {
          referenceValue.value = reference.value;
        }
      }
      if (typeof params.value.valueSelectedCallback === "function") {
        params.value.valueSelectedCallback(referenceValue.value);
      }
    }

    function onBtnCancel() {
      dialog.value = false;
    }

    function onReferenceSelected(row: any) {
      referenceSelected.value = !!row;
      reference.value = row;
    }

    const placeholder = computed(() => {
      const renderedPlaceholderValue = typeof params.value.render === "function" ? params.value.render(referenceValue.value) : referenceValue.value;
      return renderedPlaceholderValue ? renderedPlaceholderValue : props.params.placeholder;
    });

    useValueChangeControl(referenceValue, props.params);

    return {
      getValue,
      focusIn,
      focusOut,
      afterGuiAttached,
      params,
      referenceValue,
      componentProps,
      container,
      input,
      placeholder,
      dialog,
      reference,
      referenceSelected,
      onBtnConfirm,
      onBtnCancel,
      onReferenceSelected,
      isEditEnabled
    };
  }
};
</script>

<template>
  <v-dialog v-model="dialog" width="100%" height="80vh" class="reference-picker-dialog" @click:outside="onBtnCancel">
    <template #activator="{ props }">
      <div class="reference-picker-cell-editor-container">
        <v-btn
          :color="!isEditEnabled ? '' : referenceSelected ? 'primary' : 'error'"
          :disabled="!isEditEnabled"
          class="reference-btn-cell-editor"
          variant="outlined"
          density="compact"
          size="small"
          style="width: 100%; height: 100%"
          v-bind="props"
          >{{ placeholder }}
        </v-btn>
      </div>
    </template>
    <v-card min-width="300px" height="100%" class="reference-picker-card">
      <v-card-title class="d-flex align-center justify-space-between">
        <div class="text-h4">&nbsp; {{ placeholder }}</div>
        <v-icon @click="onBtnCancel">mdi-close</v-icon>
      </v-card-title>
      <v-card-text class="px-0">
        <component :is="params.component" :reference-value="referenceValue" v-bind="componentProps" @row-selected="onReferenceSelected" />
      </v-card-text>
      <v-card-actions class="pa-0">
        <div class="left-col pl-0">
          <v-btn variant="elevated" color="secondary" class="w-100" @click="onBtnCancel">Cancel</v-btn>
        </div>
        <div class="middle-col"></div>
        <div class="right-col pr-0">
          <v-btn variant="elevated" color="primary" class="w-100" @click="onBtnConfirm">Confirm</v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss">
.reference-picker-cell-editor-container {
  width: 100%;
  height: 100%;
  padding: 0.31em;

  .reference-btn-cell-editor {
    display: block;
    font-size: 12px;

    .v-btn__content {
      white-space: break-spaces;
      text-transform: none !important;
    }
  }
}
</style>
