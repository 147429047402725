<script lang="ts" setup>
import { computed, ref, watch, WritableComputedRef } from "vue";
import DynamicForm from "@/components/ModelInstances/DynamicForm/DynamicForm.vue";
import FeaturesDefinitionEditor from "@/components/ModelInstances/FeaturesDefinitions/FeaturesDefinitionEditor.vue";
import RequirementsDefinitionEditor from "@/components/ModelInstances/RequirementsDefinitions/RequirementsDefinitionEditor.vue";
import DocumentAttachmentDefinitionEditor from "@/components/ModelInstances/DocumentAttachmentDefinition/DocumentAttachmentDefinitionEditor.vue";
import ManualEditor from "@/components/ModelInstances/Manual/ManualEditor.vue";
import { AttachmentSourceType, ModelInstanceDto, ModelSchemaDto, ModelSchemaKeys, ModelSchemaType, ModelInstanceStatus } from "@masta/generated-model";
import { ModelInstanceChange } from "@/components/ModelInstances/ModelInstanceChange";
import ModelInstanceCodeEditor from "@/components/CodeEditor.vue";
import { useTagsStore } from "@/store/TagsStore";
import { storeToRefs } from "pinia";
import { $t } from "@/i18n";
import { requiredRule } from "@/components/ValueCellEditor/CommonValidationRules";
import { VForm } from "vuetify/components";
import { v4 as uuidv4 } from "uuid";
import { getNowInISOFormat } from "@/components/Datepicker/DatepickerUtil";

const props = withDefaults(
  defineProps<{
    schema: ModelSchemaDto;
    modelInstance: ModelInstanceDto | null;
    clearAfterSave?: boolean | undefined;
    clearAfterCancel?: boolean | undefined;
    modelSchemaTypeProp?: ModelSchemaType | undefined;
    contextName: string;
  }>(),
  {
    clearAfterSave: false,
    clearAfterCancel: false
  }
);

const emit = defineEmits<{
  (e: "save", value: ModelInstanceChange, callback: (result: boolean) => void): void;
  (e: "saveDocument", value: ModelInstanceChange, callback: (result: boolean) => void): void;
  (e: "cancel"): void;
  (e: "resizeWindow", value: number): void;
}>();

const tagStore = useTagsStore();
const { tags } = storeToRefs(tagStore);

const form = ref<InstanceType<typeof VForm> | null>(null);
const disabled = ref(true);
const valid = ref(true);
const tab = ref("schema-editor");
const schemaEditor = ref(false);
const codeEditor = ref(false);
const featuresDefinitionEditor = ref(false);
const requirementsDefinitionEditor = ref(false);
const documentAttachmentDefinitionEditor = ref(false);
const manualEditor = ref(false);
const jsonValue = ref<any>({});
const businessId = ref<string | null>(null);
const selectedTags = ref<string[]>([]);
const modelSchemaType = ref<ModelSchemaType>(ModelSchemaType.GenericDocumentDefinition);

const businessIdDisabled = computed(
  () => disabled.value || (props.modelInstance && (props.modelInstance.revisionNumber > 1 || props.modelInstance.status != ModelInstanceStatus.WorkInProgress))
);

const editorValue: WritableComputedRef<string> = computed({
  get(): string {
    return JSON.stringify(jsonValue.value, null, 2);
  },
  set(newValue: string): void {
    try {
      jsonValue.value = JSON.parse(newValue);
      // eslint-disable-next-line no-empty
    } catch (e) {
      console.error("err", e);
    }
  }
});

function defaultJsonValue() {
  return {
    name: "",
    author: "",
    color: "",
    issueDate: getNowInISOFormat(),
    attachment: {
      file: null,
      uri: null,
      fileName: null,
      mimeType: null,
      sourceType: AttachmentSourceType.LocalDb,
      description: "",
      attachmentContentStorageConfigurationId: "localDb1"
    },
    modelSchemaType: ModelSchemaType.GenericDocumentDefinition
  };
}

watch(
  () => props.modelInstance,
  (newModelInstance: ModelInstanceDto | null) => {
    let initialized = false;
    jsonValue.value = null;
    businessId.value = uuidv4();
    modelSchemaType.value = props.modelSchemaTypeProp ?? ModelSchemaType.GenericDocumentDefinition;
    selectedTags.value = [];
    if (newModelInstance) {
      if (newModelInstance.value) {
        jsonValue.value = newModelInstance.value;
        initialized = true;
      }
      businessId.value = newModelInstance.businessId;
      modelSchemaType.value = newModelInstance.schemaTypes[0];
      if (newModelInstance.tags) {
        selectedTags.value = [...newModelInstance.tags];
      }
      disabled.value = true;
    } else {
      disabled.value = false;
    }
    switch (props.schema.schemaKey) {
      case "model.schema.json":
        schemaEditor.value = true;
        codeEditor.value = true;
        tab.value = "schema-editor";
        emit("resizeWindow", 50);
        break;
      case ModelSchemaKeys.mODEL_SCHEMA_JSON_FEATURESDEFINITION:
        if (!initialized) {
          jsonValue.value = { features: [] };
        }
        featuresDefinitionEditor.value = true;
        tab.value = "features-definition-editor";
        emit("resizeWindow", 85);
        break;
      case ModelSchemaKeys.mODEL_SCHEMA_JSON_REQUIREMENTSDEFINITION:
        if (!initialized) {
          jsonValue.value = { requirements: [] };
        }
        requirementsDefinitionEditor.value = true;
        tab.value = "requirements-definition-editor";
        emit("resizeWindow", 85);
        break;
      case ModelSchemaKeys.mODEL_SCHEMA_JSON_DOCUMENTDEFINITION:
        if (!initialized) {
          jsonValue.value = defaultJsonValue();
        }
        documentAttachmentDefinitionEditor.value = true;
        tab.value = "document-attachment-definition-editor";
        emit("resizeWindow", 50);
        break;
      case ModelSchemaKeys.mODEL_SCHEMA_JSON_MANUAL:
        if (!initialized) {
          jsonValue.value = { manual: "" };
        }
        manualEditor.value = true;
        tab.value = "manual-editor";
        emit("resizeWindow", 85);
        break;
    }
  },
  { immediate: true }
);

async function onSaveAction() {
  const validationResult = await form.value?.validate();
  if (validationResult && validationResult.valid) {
    if (props.schema.schemaKey === ModelSchemaKeys.mODEL_SCHEMA_JSON_DOCUMENTDEFINITION) {
      const result = {
        value: jsonValue.value,
        businessId: businessId.value,
        tags: selectedTags.value?.filter((x) => x !== null && x !== undefined && x !== "") ?? []
      } as ModelInstanceChange;
      emit("saveDocument", result, (isOk: boolean) => {
        if (isOk && props.clearAfterSave) {
          clear();
        }
      });
    } else {
      const result = {
        value: jsonValue.value,
        businessId: businessId.value,
        tags: selectedTags.value?.filter((x) => x !== null && x !== undefined && x !== "") ?? []
      } as ModelInstanceChange;
      disabled.value = true;
      emit("save", result, (isOk: boolean) => {
        if (isOk && props.clearAfterSave) {
          clear();
        } else {
          disabled.value = false;
        }
      });
    }
  }
}

function onCancelAction() {
  emit("cancel");
  disabled.value = true;
  if (props.clearAfterCancel) {
    clear();
  }
}

function onEditAction() {
  disabled.value = false;
}

function clear() {
  disabled.value = false;
  jsonValue.value = defaultJsonValue();
  businessId.value = null;
  selectedTags.value = [];
}

defineExpose({ clear });
</script>

<template>
  <v-row>
    <v-col cols="8">
      <v-card-subtitle class="pl-0">
        {{ $t("modelInstance-edit-schema-label", { schemaName: schema.schemaName, schemaVersion: schema.version, $: "Schema: {schemaName} (v.{schemaVersion})" }) }}
      </v-card-subtitle>
    </v-col>
    <v-col cols="4">
      <slot name="subtitle"></slot>
    </v-col>
    <v-col cols="12">
      <v-row class="actions-row h-100">
        <v-tooltip v-if="!disabled" location="bottom" open-delay="300">
          <template #activator="{ props }">
            <div class="d-inline-flex pr-4">
              <v-btn size="small" v-bind="props" variant="tonal" density="compact" @click="onCancelAction">
                <v-icon icon="mdi-cancel" class="pr-4" />
                {{ $t("modelInstance-edit-cancel-action", { $: "Cancel" }) }}
              </v-btn>
            </div>
          </template>
          <span>{{ $t("modelInstance-edit-cancel-action", { $: "Cancel" }) }}</span>
        </v-tooltip>
        <v-tooltip v-if="!disabled" location="bottom" open-delay="300">
          <template #activator="{ props }">
            <div class="d-inline-flex pr-4">
              <v-btn size="small" v-bind="props" variant="tonal" density="compact" @click="onSaveAction">
                <v-icon class="pr-4" icon="mdi-content-save" />
                {{ $t("modelInstance-edit-save-action", { $: "Save" }) }}
              </v-btn>
            </div>
          </template>
          <span>{{ $t("modelInstance-edit-save-action", { $: "Save" }) }}</span>
        </v-tooltip>
        <v-tooltip v-if="disabled" location="bottom" open-delay="300">
          <template #activator="{ props }">
            <div class="d-inline-flex pr-4">
              <v-btn size="small" v-bind="props" variant="tonal" density="compact" @click="onEditAction">
                <v-icon icon="mdi-pencil" class="pr-4" />
                {{ $t("modelInstance-edit-edit-action", { $: "Edit" }) }}
              </v-btn>
            </div>
          </template>
          <span>{{ $t("modelInstance-edit-edit-action", { $: "Edit" }) }}</span>
        </v-tooltip>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-form ref="form" v-model="valid">
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="businessId"
              variant="outlined"
              density="compact"
              hide-details
              :disabled="businessIdDisabled"
              :rules="[requiredRule]"
              :label="$t('modelInstance-edit-businessId-label', { $: 'Business ID' })"
            />
          </v-col>
          <v-col cols="12">
            <v-autocomplete
              v-model="selectedTags"
              variant="outlined"
              density="compact"
              :label="$t('modelInstance-edit-tags-label', { $: 'Tags' })"
              :items="tags"
              :disabled="disabled"
              item-title="name"
              item-value="name"
              chips
              closable-chips
              multiple
              hide-details
            >
              <template #chip="{ item }">
                <v-chip color="primary" size="default" outlined>
                  <span>{{ item.title }}</span>
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col v-if="documentAttachmentDefinitionEditor" cols="12">
            <v-divider class="py-4" />
            <document-attachment-definition-editor
              v-model="jsonValue"
              :model-instance-id="modelInstance?.id"
              :revision-number="modelInstance?.revisionNumber"
              :is-new="modelInstance == null"
              :disabled="disabled"
              :context-name="props.contextName"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col v-if="schema" cols="12">
            <v-tabs v-if="!documentAttachmentDefinitionEditor" v-model="tab" color="primary">
              <v-tab v-if="schemaEditor" value="schema-editor">
                <v-icon>mdi-rollupjs</v-icon>
                {{ $t("modelInstance-edit-visualEditor-label", { $: "Form" }) }}
              </v-tab>
              <v-tab v-if="codeEditor" value="code-editor">
                <v-icon>mdi-code-braces</v-icon>
                {{ $t("modelInstance-edit-codeEditor-label", { $: "Code editor" }) }}
              </v-tab>
              <v-tab v-if="featuresDefinitionEditor" value="features-definition-editor">
                <v-icon>mdi-feature-search-outline</v-icon>
                {{ $t("modelInstance-edit-featuresDefinitionEditor-label", { $: "Features Definition" }) }}
              </v-tab>
              <v-tab v-if="requirementsDefinitionEditor" value="requirements-definition-editor">
                <v-icon>mdi-asterisk-circle-outline</v-icon>
                {{ $t("modelInstance-edit-requirementsDefinitionEditor-label", { $: "Requirements Definition" }) }}
              </v-tab>
              <v-tab v-if="manualEditor" value="manual-editor">
                <v-icon>mdi-file-document-multiple-outline</v-icon>
                {{ $t("modelInstance-edit-manualEditor-label", { $: "Manual editor" }) }}
              </v-tab>
            </v-tabs>
            <v-window v-model="tab" class="pt-8">
              <v-window-item v-if="schemaEditor" value="schema-editor">
                <dynamic-form v-model="jsonValue" :schema="schema.value!" :disabled="disabled" />
              </v-window-item>
              <v-window-item v-if="codeEditor" value="code-editor">
                <model-instance-code-editor v-model="editorValue" language="json" :readonly="disabled" />
              </v-window-item>
              <v-window-item v-if="featuresDefinitionEditor" value="features-definition-editor">
                <features-definition-editor v-model="jsonValue" :disabled="disabled" />
              </v-window-item>
              <v-window-item v-if="requirementsDefinitionEditor" value="requirements-definition-editor">
                <requirements-definition-editor v-model="jsonValue" :disabled="disabled" />
              </v-window-item>
              <v-window-item v-if="manualEditor" value="manual-editor">
                <manual-editor v-model="jsonValue" :disabled="disabled" :context-name="contextName" />
              </v-window-item>
            </v-window>
          </v-col>
        </v-row>
      </v-form>
    </v-col>
  </v-row>
</template>

<style lang="scss" scoped>
.h-separator {
  height: 26px;
}

.actions-row {
  display: flex;
  flex-wrap: wrap;
  padding: 6px;
  row-gap: 10px;
}
</style>
