<script lang="ts" setup>
import GridWrapper from "@/components/Grid/GridWrapper.vue";
import { inject, ref } from "vue";
import SystemEnumService from "@/services/system-enum.service";
import { GridWrapperComponent } from "@/components/Grid/GridWrapperComponent";
import { $dateTimeFormatterSymbol, DateFormatter } from "@masta/shared";
import { $t } from "@/i18n";
import { translateMeasurementUnit } from "@/composables/translateEnum";

interface Props {
  capacities: any[];
}

defineProps<Props>();

const gridWrapperRef = ref<GridWrapperComponent>();
const autoGroupColumnDef = ref({
  minWidth: 200
});
const $dateTimeFormatter = inject<DateFormatter>($dateTimeFormatterSymbol)!;

function onPrepareColumns(columnDefs: any) {
  columnDefs.value = [
    {
      field: "resourceBusinessId",
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      headerValueGetter: (_: any) => $t("location-resourceCapacityList-resourceBusinessId-label", { $: "Resource Business ID" })
    },
    {
      field: "periodStart",
      editable: false,
      sortable: true,
      filter: "agDateColumnFilter",
      valueFormatter: (params: any) => {
        return params.data ? $dateTimeFormatter(params.data.periodStart) : null;
      },
      headerValueGetter: (_: any) => $t("location-resourceCapacityList-periodStart-label", { $: "Start" })
    },
    {
      field: "periodEnd",
      editable: false,
      sortable: true,
      filter: "agDateColumnFilter",
      valueFormatter: (params: any) => {
        return params.data ? $dateTimeFormatter(params.data.periodEnd) : null;
      },
      headerValueGetter: (_: any) => $t("location-resourceCapacityList-periodEnd-label", { $: "End" })
    },
    {
      field: "quantity",
      editable: false,
      sortable: true,
      filter: true,
      headerValueGetter: (_: any) => $t("location-resourceCapacityList-quantity-label", { $: "Quantity" })
    },
    {
      field: "quantityUnit",
      editable: false,
      sortable: true,
      filter: true,
      valueFormatter: (params: any) => (params.data ? translateMeasurementUnit(params.data.quantityUnit) : null),
      headerValueGetter: (_: any) => $t("location-resourceCapacityList-quantityUnit-label", { $: "Measurement Unit" })
    },
    {
      field: "capacityGroup",
      headerName: "Capacity group",
      editable: false,
      sortable: true,
      filter: true,
      valueFormatter: (params: any) => (params.data ? SystemEnumService.resourceCapacityGroup(params.data.capacityGroup) : null),
      headerValueGetter: (_: any) => $t("location-resourceCapacityList-capacityGroup-label", { $: "Capacity group" })
    },
    {
      field: "changeType",
      headerName: "Change type",
      editable: false,
      sortable: true,
      filter: true,
      valueFormatter: (params: any) => (params.data ? SystemEnumService.resourceCapacityChangeType(params.data.changeType) : null),
      headerValueGetter: (_: any) => $t("location-resourceCapacityList-changeType-label", { $: "Change type" })
    },
    {
      field: "entrySource",
      headerName: "Entry source",
      editable: false,
      sortable: true,
      filter: true,
      valueFormatter: (params: any) => (params.data ? SystemEnumService.resourceCapacityEntrySource(params.data.entrySource) : null),
      headerValueGetter: (_: any) => $t("location-resourceCapacityList-entrySource-label", { $: "Entry source" })
    }
  ];
}
</script>

<template>
  <grid-wrapper
    ref="gridWrapperRef"
    identifier="location-resource-capacities"
    :grid-data="capacities"
    :auto-group-column-def="autoGroupColumnDef"
    @prepare-columns="onPrepareColumns"
  />
</template>

<style lang="scss" scoped></style>
