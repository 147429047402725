﻿import type { IRowContainer } from "@masta/gantt2/core";
import { ActivityRenderer, GanttSettings, IActivityRenderingRequest, IocSymbols, TimelineManager } from "@masta/gantt2/core";
import { inject, injectable } from "inversify";
import { DemandExecutionResourceCapacityActivity } from "src/components/Gantt/Common/Model";
import { StatusColorHelper } from "@/components/Gantt/ResourcesGantt/Renderers/StatusColorHelper";
import { GanttActivityColorsSetting, type TagDto } from "@masta/generated-model";
import { CustomSettingKeys } from "@/components/Gantt/ResourcesGantt/CustomSettingKeys";

@injectable()
export class ExecutionActivityRenderer extends ActivityRenderer<DemandExecutionResourceCapacityActivity> {
  private _tags: TagDto[] = [];
  private _startDateColor: string = "rgba(0,0,0,1)";
  private _endDateColor: string = "rgba(0,0,0,1)";

  private _minWidthForLineIndicator = 6;

  constructor(
    @inject(TimelineManager) timelineManager: TimelineManager,
    @inject(IocSymbols.RowContainer) rowContainer: IRowContainer,
    @inject(StatusColorHelper) private _statusColorHelper: StatusColorHelper,
    @inject(GanttSettings) protected readonly _settings: GanttSettings
  ) {
    super(timelineManager, rowContainer, ExecutionActivityRenderer.name);
    this.paddingInsets.left = 4;
    this.paddingInsets.right = 4;
    this._minWidth = 0;
  }

  async afterInitialize(): Promise<void> {
    await super.afterInitialize();
    const fillSetting = this._settings.getSetting<GanttActivityColorsSetting>(CustomSettingKeys.ACTIVITY_FILL_COLOR_EXECUTION);
    const textColorSetting = this._settings.getSetting<GanttActivityColorsSetting>(CustomSettingKeys.ACTIVITY_TEXT_COLOR_EXECUTION);
    this._tags = this._settings.getSetting<TagDto[]>(CustomSettingKeys.TAGS) ?? [];

    if (fillSetting) {
      this.fill = fillSetting.default;
      this.fillHighlight = fillSetting.highlight;
      this.fillSelected = fillSetting.selected;
      this.fillHover = fillSetting.hover;
      this.fillPressed = fillSetting.pressed;
    }
    if (textColorSetting) {
      this.textColor = textColorSetting.default;
      this.textColorHighlight = textColorSetting.highlight;
      this.textColorSelected = textColorSetting.selected;
      this.textColorHover = textColorSetting.hover;
      this.textColorPressed = textColorSetting.pressed;
      this._startDateColor = textColorSetting.startDate ?? this._startDateColor;
      this._endDateColor = textColorSetting.endDate ?? this._endDateColor;
    }
  }

  drawActivity(request: IActivityRenderingRequest<DemandExecutionResourceCapacityActivity>): void {
    const { activityBounds, activityRef, position, canvas, context, x, y, h, offsetTop, selected, hover, highlighted, pressed } = request;
    let { w } = request;

    if (w < this._minWidthForLineIndicator) {
      // draw vertical dashed line for smaller activities
      context.save();
      context.lineWidth = 6;
      context.strokeStyle = this.fill$.value!;
      context.beginPath();
      context.setLineDash([9, 9]);
      context.moveTo(x, y);
      context.lineTo(x, y + h);
      context.stroke();
      context.closePath();
      context.restore();
      return;
    }

    context.clearRect(0, 0, context.canvas.width, context.canvas.height);

    const font = "normal 10px Roboto";
    context.font = font;
    (context as any).letterSpacing = "0.3px";

    context.globalAlpha = this.opacity;

    this.drawBackground(activityRef, position, context, x, y, w, h, offsetTop, selected, hover, highlighted, pressed);
    // border
    context.save();
    context.strokeStyle = "rgba(0,0,0,0.8)";
    context.beginPath();
    context.moveTo(x, y + h);
    context.lineTo(x, y);
    context.lineTo(x + w, y);
    context.lineTo(x + w, y + h);
    context.moveTo(x + w, y + h);
    context.closePath();
    context.stroke();
    context.restore();

    const { task, step } = activityRef.activity.userObject;

    const orgTags = task.organizations.map(org => org.tags).flat()
      .map(tag => this._tags.find(t => t.name === tag)).sort((a, b) => (a?.rank ?? 0) - (b?.rank ?? 0)).filter(tag => tag);

    const organizationColor = orgTags?.[0]?.color;

    const statusBarHeight = Math.round(h / 8);
    // if (task && task.statistics) {
    //   context.fillStyle = this._statusColorHelper.getStatusBackgroundColor(task.statistics.totalStatus);
    //   const sX = x + 1;
    //   const sY = y + h - statusBarHeight * 2;
    //   context.fillRect(sX, sY, w - 2, statusBarHeight);
    //   this.drawLine(context, sX, sY, sX + w - 2, sY, "rgba(0,0,0,0.25)");
    // }
    if (organizationColor) {
      context.fillStyle = organizationColor;
      const sX = x;
      const sY = y + h - statusBarHeight * 2;
      const sW = w;
      const sH = statusBarHeight;
      context.fillRect(sX, sY, sW, sH);
    }
    if (step && step.statistics) {
      const sX = x + 1;
      const sY = y + h - statusBarHeight;
      context.fillStyle = this._statusColorHelper.getStepStatusBackgroundColor(step.statistics.totalStatus);
      context.fillRect(x + 1, y + (h - 2) - statusBarHeight, w - 2, statusBarHeight);
    }

    if (w < this._minWidth) w = this._minWidth;
    context.fillStyle = this.getTextColor(selected, hover, highlighted, pressed) ?? "rgba(0,0,0,1)";
    context.font = context.font.replace(/\d+px/, `${8}px`);

    const startDate = activityRef.activity.userObject.periodStartDateFormatted ?? activityRef.activity.userObject.periodStart;
    const endDate = activityRef.activity.userObject.periodEndDateFormatted ?? activityRef.activity.userObject.periodEnd;

    let fsize = this.getFontSize(context.font);
    if (h / 2 < fsize) {
      fsize = h / 2;
    }

    const startDateTextWidth = context.measureText(endDate).width;
    const endDateTextWidth = context.measureText(endDate).width;

    if (startDateTextWidth + endDateTextWidth + this.paddingInsets.left + this.paddingInsets.right < w) {
      context.save();
      context.font = context.font.replace(/\d+px/, `${fsize}px`);
      const textY = y + h / 1.3 - fsize;
      let textX = x + this.paddingInsets.left;
      context.fillStyle = this._startDateColor ?? context.fillStyle;
      context.fillText(startDate, textX, textY);
      textX = x + w - endDateTextWidth - this.paddingInsets.right;
      context.fillStyle = this._endDateColor ?? context.fillStyle;
      context.fillText(endDate, textX, textY);
      context.restore();
    }

    if (task) {
      const taskName = task.name ?? "";
      const taskBusinessId = task.businessId ?? "";

      const taskNameLines = this.getTextLines(context, taskName, w);

      if (w > 50) {
        const tasNameX = x + w / 2;

        context.font = font.replace("normal", "bold");
        context.textAlign = "center";

        for (let i = 0; i < taskNameLines.length; i++) {
          const line = taskNameLines[i];
          const lineY = y + fsize + i * fsize;
          context.fillText(line, tasNameX, lineY);
        }

        context.font = font;

        let secondLineText = taskBusinessId;

        if (step && step.name) {
          secondLineText = `${taskBusinessId} ‧ ${step.name}`;
        }

        const secondLineLines = this.getTextLines(context, secondLineText, w);

        const secondLineTextX = x + w / 2;

        for (let i = 0; i < secondLineLines.length; i++) {
          const line = secondLineLines[i];
          const lineY = y + fsize * (2 + taskNameLines.length) + i * fsize;
          context.fillText(line, secondLineTextX, lineY);
        }
      }
    }
  }
}
