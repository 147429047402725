<script lang="ts" setup>
import { MenuItem } from "@/components/MegaMenu/MegaMenuModel";
import { ref, UnwrapRef } from "vue";
import { RouteLocation, useRoute } from "vue-router";
import _ from "lodash";
import { useRouteParams } from "@vueuse/router";
import { useScenariosStore } from "@/store/ScenariosStore";

interface Props {
  mainMenuItems: UnwrapRef<MenuItem[]>;
  currentRoute: RouteLocation;
  currentTab?: string;
}

const props = defineProps<Props>();
const emits = defineEmits(["close"]);

function isActive(menuItem: MenuItem) {
  const menuItemQuery = { ...menuItem?.query };
  const routeQuery = { ...props.currentRoute?.query };
  return menuItem.route?.name === props.currentRoute.name && _.isEqual(menuItemQuery, routeQuery);
}

function isMainMenuActive(menuItem: MenuItem) {
  return !!menuItem.items.find((subMenuItem) => {
    if (subMenuItem.route?.name === props.currentRoute.name) {
      return true;
    }
  });
}

const scenarioId = useRouteParams("scenarioId", () => {
  const scenarioStore = useScenariosStore();
  return scenarioStore.selectedScenario?.id;
});

</script>

<template>
  <div v-for="mainMenuItem in mainMenuItems.find((x) => x.id === currentTab)?.items.filter((x) => !x.route && x.items.length > 0) ?? []" :key="mainMenuItem.id" class="menu-group">
    <div class="text-secondary text-body-1 font-weight-regular d-flex align-center pb-4 px-8 font-weight-bold">
      <v-icon v-if="mainMenuItem.icon" :icon="mainMenuItem.icon" class="pr-2" />
      <span>{{ mainMenuItem.name }}</span>
    </div>
    <v-divider />
    <v-list class="pl-0 py-0">
      <v-list-item
        v-for="subMainMenuItem in mainMenuItem.items"
        :key="subMainMenuItem.id"
        class="text-body-1 py-0"
        :ripple="false"
        :to="{ name: subMainMenuItem.route?.name, query: subMainMenuItem.query, params: {scenarioId} }"
        @click="emits('close', subMainMenuItem)"
      >
        {{ subMainMenuItem?.name }}
      </v-list-item>
    </v-list>
  </div>
</template>

<style lang="scss" scoped>
.menu-group {
  min-width: 270px;

  :deep(.v-list-item--active),
  :deep(.v-list-item:hover) {
    color: rgb(var(--v-theme-primary), 1);
    font-weight: bold;
  }

  :deep(.v-list-item--active > .v-list-item__overlay),
  :deep(.v-list-item:hover > .v-list-item__overlay) {
    opacity: 0;
  }
}
</style>
