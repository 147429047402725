import ApiService from "@/services/api";
import { useErrorsStore } from "@/store/ErrorsStore";
import { useScenariosStore } from "@/store/ScenariosStore";
import { useSystemStore } from "@/store/SystemStore";
import {
  GenerateAndCreateSerialNumbersForTopLevelTaskCommand,
  RegisterRecalculateTasksCommand,
  RegisterRootRescheduleCommand,
  RegisterScheduleTasksCommand,
  UpdateTaskModelInstanceAssignmentCommand
} from "@masta/generated-model";
import { defineStore } from "pinia";

interface State {
}

export const useProductionTasksStore = defineStore("productionTasks", {
  state: (): State => ({}),
  getters: {},
  actions: {
    async fetchTask(taskId: string) {
      try {
        const scenariosStore = useScenariosStore();
        if (!scenariosStore.selectedScenario) return;
        const scenarioId = scenariosStore.selectedScenario.id;

        const response = await ApiService.productionTasks.getTask(scenarioId, taskId);
        return response.data;
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    async fetchDetail(taskId: string) {
      try {
        const scenariosStore = useScenariosStore();
        if (!scenariosStore.selectedScenario) return;
        const id = scenariosStore.selectedScenario.id;
        const response = await ApiService.productionTasks.getTaskDetails(id, taskId);
        return response.data;
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    async updateTaskModelInstanceAssignment(request: UpdateTaskModelInstanceAssignmentCommand) {
      try {
        await ApiService.tasks.updateTaskModelInstanceAssignment(request);
        // await this.fetch();
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    async scheduleTasks(request: GenericSchedulingRequest) {
      const systemStore = useSystemStore();
      const settingName = systemStore.defaultSchedulingSettingName;
      try {
        await ApiService.scheduling.scheduleTasks({
          ...request,
          settingName: settingName
        } as RegisterScheduleTasksCommand);
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    async complexScheduleTasks(request: RegisterScheduleTasksCommand) {
      try {
        await ApiService.scheduling.scheduleTasks(request);
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    async descheduleTasks(request: GenericSchedulingRequest) {
      try {
        await ApiService.scheduling.descheduleTasks(request);
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    async releaseTasks(request: GenericSchedulingRequest) {
      try {
        await ApiService.scheduling.releaseTasks(request);
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    async unreleaseTasks(request: GenericSchedulingRequest) {
      try {
        await ApiService.scheduling.unreleaseTasks(request);
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    async generateAndCreateSerialNumbersForTopLevelTask(request: GenerateAndCreateSerialNumbersForTopLevelTaskCommand) {
      try {
        await ApiService.tasks.generateSerialNumbers(request);
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    async scheduleGanttDroppedTask({ taskId, resourceId, time, businessKey }: any) {
      const systemStore = useSystemStore();

      const task = await this.fetchTask(taskId);

      if (!task) return;
      const isParent = task.id === task.rootTaskId;
      const settingName = isParent ? systemStore.defaultSchedulingSettingName : systemStore.defaultSchedulingDragAndDropSettingName;

      const scenariosStore = useScenariosStore();
      if (!scenariosStore.selectedScenario) return;
      const id = scenariosStore.selectedScenario.id;

      await ApiService.scheduling.scheduleTasks({
        rootTaskId: task.rootTaskId,
        taskIds: [taskId],
        resourceId,
        start: time,
        scenarioId: id,
        settingName: settingName,
        canReschedule: false,
        businessKey
      });
    },
    async rescheduleGanttDroppedTask({ taskId, resourceId, time, businessKey }: any) {
      const systemStore = useSystemStore();

      const scenariosStore = useScenariosStore();
      if (!scenariosStore.selectedScenario) return;
      const settingName = systemStore.defaultSchedulingDragAndDropSettingName;
      const id = scenariosStore.selectedScenario.id;

      await ApiService.scheduling.scheduleTasks({
        canReschedule: true,
        settingName: settingName,
        rootTaskId: null,
        taskIds: [taskId],
        resourceId,
        start: time,
        scenarioId: id,
        businessKey
      });
    },
    async recalculateTasks(request: RegisterRecalculateTasksCommand) {
      try {
        await ApiService.scheduling.recalculateTasks(request);
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    async rescheduleRootTask(request: RegisterRootRescheduleCommand) {
      try {
        await ApiService.scheduling.rootReschedule(request);
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    }
  }
});

export interface GenericSchedulingRequest {
  rootTaskId: string;
  scenarioId: string;
  taskIds: string[];
}



