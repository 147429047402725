<script lang="ts" setup>
import GridWrapper from "@/components/Grid/GridWrapper.vue";
import { defineExpose, inject, reactive, ref, watch } from "vue";
import { GridWrapperComponent } from "@/components/Grid/GridWrapperComponent";
import { $dateTimeFormatterSymbol, DateFormatter } from "@masta/shared";
import { FilterChangedEvent, GridApi, KeyCreatorParams, RowSelectedEvent, ValueFormatterParams, ValueGetterParams, ValueSetterParams } from "ag-grid-community";
import ApiService from "@/services/api";
import { getOrderReleaseStatusCellStyle } from "@/components/CustomerOrders/CustomerOrderUtils";
import { OrderDto, OrderReleaseStatus, OrderStatusChangeNotificationEvent, OrderType, OrganizationDto, RegisterCostMonitoringExcelExportCommand } from "@masta/generated-model";
import { useDebounceFn } from "@vueuse/core";
import { OrderStatusChangedNotification, useNotification } from "@/notifications";
import { useSnackbarsStore } from "@/store/SnackbarsStore";
import { useScenariosStore } from "@/store/ScenariosStore";
import FilterService from "@/services/filter.service";
import { InboundDeliveriesServerSideDataSource } from "@/components/InboundDeliveries/InboundDeliveriesServerSideDataSource";
import dayjs from "dayjs";
import { $t } from "@/i18n";
import { requiredRule, validDateRequiredRule } from "@/components/ValueCellEditor/CommonValidationRules";
import { enumToEditorEntriesExcluding, enumValueEntryWithLocaleComparator, translateEditorEntries } from "@/components/Grid/ColumnTypes";
import { translateOrderReleaseStatus, translateOrderType } from "@/composables/translateEnum";
import { useReferenceGrid } from "@/components/Grid/UseReferenceGrid";
import { getSelectedRows } from "@/components/Grid/UseGridSelection";

const $emits = defineEmits(["rowSelected", "deleteAction", "rowDoubleClicked"]);
const props = defineProps<{
  filterByProductId?: string | null | undefined;
  readonly?: boolean;
  referenceValue?: string;
}>();

const snackbarsStore = useSnackbarsStore();
const scenariosStore = useScenariosStore();

const serverSideDataSource = reactive(new InboundDeliveriesServerSideDataSource("purchase-orders"));
serverSideDataSource.useFilteringByProductId(props.filterByProductId);

const DEFAULT_CREATE_VALUE = {
  issueDate: () => resolveIssueDate(),
  orderType: OrderType.InboundDelivery,
  businessId: null,
  releaseStatus: OrderReleaseStatus.New
};

useNotification(OrderStatusChangedNotification, async (e: OrderStatusChangeNotificationEvent) => {
  onOrderStatusChanged(e);
});

const onOrderStatusChanged: (e: OrderStatusChangeNotificationEvent) => void = useDebounceFn(async (e: OrderStatusChangeNotificationEvent) => {
  const row = gridWrapperRef.value?.gridApi?.getRowNode(e.orderId);
  if (row) {
    gridWrapperRef.value?.gridApi?.refreshServerSide();
  }
}, 800);

const defaultColumnDef = ref({
  floatingFilter: true,
  filterParams: {
    applyMiniFilterWhileTyping: true
  }
});

const gridWrapperRef = ref<GridWrapperComponent>();
const $dateTimeFormatter = inject<DateFormatter>($dateTimeFormatterSymbol)!;
const isRowSelected = ref(false);
const isEditBtnDisabled = ref(false);
const isDeleteBtnDisabled = ref(false);

function resolveIssueDate(): Date {
  return new Date();
}

function onManageOrderLines() {
  if (gridWrapperRef.value) {
    if (gridWrapperRef.value.isEditing()) return;

    const { gridApi } = gridWrapperRef.value;
    const rows = getSelectedRows(gridApi);
    if (rows.length === 1) {
      $emits("rowSelected", rows[0]);
    } else {
      $emits("rowSelected", null);
    }
  }
}

function onPrepareColumns(columnDefs: any) {
  columnDefs.value = [
    {
      field: "description",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      editable: true,
      sortable: true,
      resizable: true,
      filter: "agTextColumnFilter",
      cellEditorParams: {
        placeholder: $t("inboundDelivery-list-description-label", { $: "Description" })
      },
      headerValueGetter: (_: any) => $t("inboundDelivery-list-description-label", { $: "Description" }),
      floatingFilterComponentParams: {
        laceholder: $t("inboundDelivery-list-description-label", { $: "Description" })
      }
    },
    {
      field: "businessId",
      type: ["textInputTypeColumn", "textFloatingFilterColumnType"],
      editable: true,
      sortable: true,
      resizable: true,
      filter: "agTextColumnFilter",
      cellEditorParams: {
        rules: [requiredRule],
        placeholder: $t("inboundDelivery-list-businessId-label", { $: "Business ID" })
      },
      headerValueGetter: (_: any) => $t("inboundDelivery-list-businessId-label", { $: "Business ID" }),
      floatingFilterComponentParams: {
        placeholder: $t("inboundDelivery-list-businessId-label", { $: "Business ID" })
      }
    },
    {
      field: "issueDate",
      editable: true,
      sortable: true,
      resizable: true,
      filter: "agDateColumnFilter",
      type: "datepickerTypeColumn",
      valueFormatter: (params: any) => {
        return $dateTimeFormatter(params.data.issueDate);
      },
      cellEditorParams: {
        rules: [validDateRequiredRule],
        placeholder: $t("inboundDelivery-list-issueDate-label", { $: "Issue Date" })
      },
      headerValueGetter: (_: any) => $t("inboundDelivery-list-issueDate-label", { $: "Issue Date" })
    },
    {
      field: "customerName",
      editable: (params: any) => !params.data.id,
      sortable: true,
      resizable: true,
      filter: "agTextColumnFilter",
      type: ["organizationPickerTypeColumn", "textFloatingFilterColumnType"],
      cellEditorParams: {
        rules: [requiredRule],
        placeholder: $t("inboundDelivery-list-customerName-label", { $: "Customer" })
      },
      valueGetter: (params: ValueGetterParams<OrderDto, OrganizationDto>) => {
        const { data } = params;
        if (!data || !data.customerName) return null;

        return {
          businessId: data.customerName,
          name: data.customerName
        } as OrganizationDto;
      },
      valueSetter: (params: ValueSetterParams<OrderDto, OrganizationDto>) => {
        params.data.customerName = params.newValue?.businessId ?? "";
        return true;
      },
      valueFormatter: (params: ValueFormatterParams) => params.data?.customerName,
      headerValueGetter: (_: any) => $t("inboundDelivery-list-customerName-label", { $: "Customer" })
    },
    {
      field: "releaseStatus",
      resizable: true,
      cellStyle: (params: any) => getOrderReleaseStatusCellStyle(params.data.releaseStatus),
      valueGetter: (params: any) => translateOrderReleaseStatus(params.data.releaseStatus),
      headerValueGetter: (_: any) => $t("inboundDelivery-list-releaseStatus-label", { $: "Release Status" })
    },
    {
      field: "organizationId",
      hide: true,
      filter: "agTextColumnFilter",
      suppressColumnsToolPanel: true,
      headerValueGetter: (_: any) => $t("inboundDelivery-list-organizationId-label", { $: "Organization ID" })
    }
  ];
}

function onSelectionChanged({ api }: { api: GridApi }) {
  const selectedRows = getSelectedRows(api);
  isRowSelected.value = selectedRows.length > 0;
  if (selectedRows.length === 1) {
    $emits("rowSelected", selectedRows[0]);
  }
}

async function onFilterChanged(event: FilterChangedEvent) {
  console.log(event.api.getFilterModel());
  const cql = FilterService.translate2Cql(event.api.getFilterModel());
  console.log(cql);
  const result = await FilterService.validateResourceFilter(cql);
  console.log(result);
}

function onRowSelected(event: RowSelectedEvent<OrderDto>) {
  const rows = getSelectedRows(event.api);
  if (rows.length !== 1) {
    isEditBtnDisabled.value = true;
    return;
  } else {
    isEditBtnDisabled.value = rows[0].releaseStatus !== OrderReleaseStatus.InProgress;
    isDeleteBtnDisabled.value = rows[0].releaseStatus !== OrderReleaseStatus.InProgress;
  }
}

watch(props, (newProps) => {
  serverSideDataSource.useFilteringByProductId(newProps.filterByProductId);
  gridWrapperRef.value.gridApi.refreshServerSide({ purge: true });
});

const refGrid = useReferenceGrid({
  $emits,
  isRowSelected: (data: any) => data.businessId === props.referenceValue
});

async function releaseToProduction() {
  const selectedRows = getSelectedRows(gridWrapperRef.value?.gridApi);
  if (selectedRows.length <= 0 || !scenariosStore.selectedScenario) {
    return;
  }
  const orderIds = selectedRows.map((x) => x.id);
  try {
    const request = { orderIds, scenarioId: scenariosStore.selectedScenario.id };
    await ApiService.inboundDeliveries.releaseToProduction(request);
    await snackbarsStore.createSnackbar({
      message: $t("customerOrder-list-releaseSuccess-message", { $: "Inbound deliveries released! Production tasks are being generated" }),
      type: "success",
      closeable: true
    });
  } catch (e) {
    console.error(e);
    await snackbarsStore.createSnackbar({
      message: $t("customerOrder-list-releaseError-message", { $: "Could not release inbound deliveries" }),
      type: "error",
      closeable: true
    });
  }
}

defineExpose({ gridWrapperRef });
</script>

<template>
  <grid-wrapper
    ref="gridWrapperRef"
    identifier="orders"
    :default-col-def="defaultColumnDef"
    row-selection="multiple"
    :create-default-value="DEFAULT_CREATE_VALUE"
    server-side
    :server-side-datasource="serverSideDataSource"
    refresh-btn
    :edit-btn="!readonly"
    :edit-btn-disabled="isEditBtnDisabled"
    :delete-btn-disabled="isDeleteBtnDisabled"
    :create-btn="!readonly"
    :duplicate-btn="!readonly"
    :delete-btn="!readonly"
    :hide-custom-actions-separator="readonly"
    @prepare-columns="onPrepareColumns"
    @selection-changed="onSelectionChanged"
  >
    <template #custom-buttons>
      <v-tooltip v-if="!readonly" location="bottom" open-delay="300">
        <template #activator="{ props }">
          <div class="d-inline-flex pr-4">
            <v-btn :disabled="!isRowSelected" size="small" variant="text" density="compact" v-bind="props" class="mx-2" @click="releaseToProduction">
              <v-icon icon="mdi-account-hard-hat" class="pr-4" />
              {{ $t("inboundDelivery-list-release-tooltip", { $: "Release Order" }) }}
            </v-btn>
          </div>
        </template>
        <span>{{ $t("inboundDelivery-list-release-tooltip", { $: "Release Order" }) }}</span>
      </v-tooltip>
    </template>
  </grid-wrapper>
</template>

<style lang="scss" scoped></style>
