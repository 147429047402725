import { RouteLocationNormalized, RouteLocationRaw } from "vue-router";
import { checkRoles } from "@/router/guards/util";
import { useScenariosStore } from "@/store/ScenariosStore";

export const scenarioGuard: (to: RouteLocationNormalized) => Promise<Error | RouteLocationRaw | boolean> = async (to: RouteLocationNormalized) => {
  const proceed = () => {
    if (!checkRoles(to)) {
      return false; // abort
    }

    // If the user has selected scenario, continue with the route
    const store = useScenariosStore();
    if (store.isScenarioSelected) {
      return true;
    }
    // Otherwise abort
    return false;
  };

  const store = useScenariosStore();
  // If scenario selection has already finished, check our state using `proceed()`
  if (store.selectedScenario !== null) {
    return proceed();
  }

  return await new Promise((resolve) => {
    const stopHandle = store.$subscribe(
      (mutation, state) => {
        if (state.selectedScenario) {
          stopHandle();
          return resolve(proceed());
        }
      },
      { detached: true }
    );
  });
};
