﻿<script lang="ts">
import { ICellEditorParams } from "ag-grid-community";
import { computed, ref, toRef } from "vue";
import { VForm } from "vuetify/components";
import { resolveValueRef, useValueChangeControl } from "@/components/Grid/UseValueChangeControl";
import { INumberInputCellEditorParams } from "@/components/Grid/CellEditors/INumberInputCellEditorParams";

interface IProps extends INumberInputCellEditorParams, ICellEditorParams {}

export default {
  setup(props: { params: IProps }) {
    const params = toRef(props, "params");
    const value = resolveValueRef(props.params, props.params.value);
    const valid = ref<boolean>();
    const hasFocus = ref<boolean>(false);
    const form = ref<VForm>();
    const input = ref<any>();
    const errors = computed(() => {
      return form.value?.errors.map((e) => e.errorMessages.join(",")).join(",");
    });
    const showTooltip = computed<boolean>(() => !!errors.value && hasFocus.value);

    const isEditEnabled = toRef(props.params.isEditEnabled ? props.params.isEditEnabled(props.params) : true);

    const rules = computed(() => {
      return params.value.rules ?? [];
    });

    function getValue() {
      return value.value;
    }

    function focusIn() {
      if (input.value && input.value.$el) {
        input.value.$el.focus();
        const inputs = input.value.$el.getElementsByTagName("input");
        if (inputs.length >= 1) {
          inputs[0].focus();
        }
      }
      hasFocus.value = true;
    }

    function focusOut() {
      if (form.value && form.value.validate) {
        form.value.validate();
      }
      hasFocus.value = false;
    }

    function afterGuiAttached() {
      if (props.params.cellStartedEdit) {
        focusIn();
      }
      if (form.value && form.value.validate) {
        form.value.validate();
      }
    }

    function isCancelAfterEnd() {
      return !valid.value;
    }

    function isValid() {
      return isEditEnabled.value ? valid?.value : undefined;
    }

    function onBlur() {
      if (form.value && form.value.validate) {
        form.value.validate();
      }
      hasFocus.value = false;
    }

    function onFocus() {
      hasFocus.value = true;
    }

    useValueChangeControl(value, props.params);

    return {
      getValue,
      focusIn,
      focusOut,
      isCancelAfterEnd,
      afterGuiAttached,
      isValid,
      showTooltip,
      rules,
      valid,
      errors,
      value,
      input,
      hasFocus,
      params,
      onBlur,
      onFocus,
      form: form as any,
      isEditEnabled
    };
  }
};
</script>

<template>
  <v-tooltip v-model="showTooltip" location="bottom" :offset="30" class="ag-text-field-cell-editor-tooltip">
    <template #activator="act">
      <v-form ref="form" v-model="valid" class="text-input-cell-editor">
        <div class="text-input-cell-editor-container">
          <v-text-field
            ref="input"
            v-model="value"
            type="number"
            color="primary"
            :rules="rules"
            :readonly="params.readonly"
            variant="outlined"
            density="compact"
            hide-details
            :disabled="!isEditEnabled"
            :placeholder="params.placeholder"
            @focus="onFocus"
            @blur="onBlur"
          >
            <template #prepend-inner>
              <span v-bind="act.props" />
            </template>
          </v-text-field>
        </div>
      </v-form>
    </template>
    <span v-if="errors">{{ errors }}</span>
  </v-tooltip>
</template>

<style lang="scss"></style>
