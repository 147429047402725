export default {
  resourceCapacityChangeType(enumValue) {
    switch (enumValue) {
      case 0:
        return "Absolute";
      case 1:
        return "Relative";
      default:
        return "Unknown";
    }
  },
  resourceCapacityEntrySource(enumValue) {
    switch (enumValue) {
      case 100:
        return "Calendar";
      case 150:
        return "AvailabilityRule";
      case 200:
        return "PlanningBoard";
      case 300:
        return "Shift";
      case 400:
        return "Scheduling";
      case 500:
        return "Execution";
      case 900:
        return "Import";
      default:
        return "Unknown";
    }
  },
  resourceCapacityEntrySourceSpecialMapping(enumValue) {
    switch (enumValue) {
      case 100:
        return "Calendar";
      case 200:
        return "PlanningBoard";
      case 300:
        return "Shift";
      case 400:
        return "Scheduling";
      case 500:
        return "Execution";
      case 900:
        return "Import";
      default:
        return "Unknown";
    }
  },
  resourceCapacityGroup(enumValue) {
    switch (enumValue) {
      case 0:
        return "Supply";
      case 1:
        return "Demand";
      default:
        return "Unknown";
    }
  },
  agreementType(enumValue) {
    switch (enumValue) {
      case 1:
        return "Budget manual";
      case 2:
        return "Budget automated";
      case 0:
      default:
        return "FTE";
    }
  },
  scenarioStatus(enumValue) {
    switch (enumValue) {
      case 0:
        return "Production";
      case 1:
        return "Simulation";
      case 2:
        return "Archived";
      default:
        return "Unknown";
    }
  },
  resourceType(enumValue) {
    switch (enumValue) {
      case 0:
        return "Person";
      case 1:
        return "PersonGroup";
      case 2:
        return "Agreement";
      case 3:
        return "AgreementGroup";
      case 4:
        return "Calendar";
      case 5:
        return "Equipment";
      case 6:
        return "EquipmentGroup";
      case 7:
        return "Material";
      case 8:
        return "Location";
      case 9:
        return "Asset";
      default:
        return "Unknown";
    }
  },
  resourceAssignmentType(enumValue) {
    switch (enumValue) {
      case 1:
        return "Agreement";
      case 2:
        return "Identity";
      case 3:
        return "Material to Scrap";
      case 4:
        return "Asset to Personnel";
      default:
        return "Unknown";
    }
  },
  materialTypes(enumValues) {
    const stringValues = enumValues.map(function (enumValue) {
      switch (enumValue) {
        case 7000:
          return "Produce";
        case 7001:
          return "Receive";
        case 7002:
          return "Scrap";
        default:
          return "Unknown";
      }
    });
    return stringValues.join(", ");
  },
  resourceSubTypes(enumValues) {
    const stringValues = enumValues.map(function (enumValue) {
      switch (enumValue) {
        case 2000:
          return "FTE";
        case 2001:
          return "Budget manual progress";
        case 2002:
          return "Budget automated progress";
        case 7000:
          return "Produce";
        case 7001:
          return "Receive";
        case 7002:
          return "Scrap";
        case 9001:
          return "Asset identity";
        default:
          return "Unknown";
      }
    });
    return stringValues.join(", ");
  },
  orderReleaseStatus(enumValue) {
    switch (enumValue) {
      case 0:
        return "Unreleased";
      case 10:
        return "Partially Released";
      case 20:
        return "Released";
      default:
        return enumValue;
    }
  },
  orderLineStatus(enumValue) {
    switch (enumValue) {
      case 0:
        return "Added";
      case 10:
        return "Accepted";
      case 20:
        return "Cancelled";
      case 30:
        return "Disputed";
      case 40:
        return "Rejected";
      case 50:
        return "Revised";
      case 60:
        return "PreReleased";
      case 70:
        return "Released";
      default:
        return enumValue;
    }
  },
  stepResourceUsageType(enumValue) {
    switch (enumValue) {
      case 0:
        return "Book";
      case 1:
        return "Consume";
      case 2:
        return "Produce";
      case 3:
        return "Scrap";
      default:
        return "Unknown";
    }
  },
  stepResourceAssignmentType(enumValue) {
    switch (enumValue) {
      case 0:
        return "Alternatives";
      case 3:
        return "Capabilities";
      case 4:
        return "Generic";
      default:
        return "Unknown";
    }
  },
  // MAT
  matSupplierStatus(enumValue) {
    switch (enumValue) {
      case -1:
        return "Error";
      case 0:
        return "Initialized";
      case 1:
        return "Confirmed";
      case 2:
        return "No capabilities";
      default:
        return "Unknown";
    }
  },
  tenantType(enumValue) {
    switch (enumValue) {
      case 0:
        return "Software Development";
      case 1:
        return "Manufacturing";
      default:
        return "Unknown";
    }
  },
  totalStatus(enumValue) {
    switch (enumValue) {
      case 0:
        return "Unknown";
      case 100:
        return "Not Scheduled";
      case 200:
        return "Partially Scheduled";
      case 300:
        return "Scheduled";
      case 400:
        return "Released";
      case 500:
        return "Not Started";
      case 600:
        return "Interrupted";
      case 700:
        return "Running";
      case 900:
        return "Finished";
      default:
        return "Unknown";
    }
  },
  organizationTypes(enumValues) {
    if (!enumValues) {
      return "";
    }

    const results = [];
    for (const enumValue of enumValues) {
      switch (enumValue) {
        case 1:
          results.push("Supplier");
          break;
        case 2:
          results.push("Customer");
          break;
        case 3:
          results.push("Subcontractor");
          break;
        case 0:
        default:
          results.push("Unknown");
          break;
      }
    }
    return results.join(", ");
  },
  orderTypes(enumValue) {
    switch (enumValue) {
      case 1:
        return "Customer Order";
      default:
        return "Unknown";
    }
  },
  templateStatuses(enumValue) {
    switch (enumValue) {
      case 10:
        return "Work In Progress";
      case 20:
        return "Released";
      case 30:
        return "Archived";
      default:
        return "Unknown";
    }
  },
  availability(enumValue) {
    switch (enumValue) {
      case 0:
        return "Not available";
      case 1:
        return "Available";
      default:
        return "Unknown";
    }
  },
  tagTypes(enumValue) {
    switch (enumValue) {
      case 1:
        return "Constraint";
      default:
        return "Classification";
    }
  },
  trackingUniqueIdentifierTypes(enumValue) {
    switch (enumValue) {
      case 1:
        return "Lot Number";
      default:
        return "Serial Number";
    }
  },
  solutionType(enumValue) {
    switch (enumValue) {
      case 0:
        return "Finish Task";
      default:
        return "Unknown";
    }
  }
};
