<script lang="ts" setup>
import GridWrapper from "@/components/Grid/GridWrapper.vue";
import { inject, reactive, ref } from "vue";
import { GridWrapperComponent } from "@/components/Grid/GridWrapperComponent";
import { $t } from "@/i18n";
import { GetRowIdParams, GridApi, ValueFormatterParams, ValueGetterParams } from "ag-grid-community";
import { $dateTimeFormatterSymbol, DateFormatter } from "@masta/shared";
import { QuotationLinesServerSideDataSource } from "@/components/QuotationLines/QuotationLinesServerSideDataSource";
import { translateMeasurementUnit, translateQuotationType, translateResourceType, translateStepType } from "@/composables/translateEnum";
import { CalculateQuotationForOrderCommand, OrderDto, QuotationQuotationLineDto } from "@masta/generated-model";
import ApiService from "@/services/api";
import { useSnackbarsStore } from "@/store/SnackbarsStore";
import { useScenariosStore } from "@/store/ScenariosStore";
import { getSelectedRows } from "@/components/Grid/UseGridSelection";

const emit = defineEmits(["showDetails"]);

const serverSideDataSource = reactive(new QuotationLinesServerSideDataSource("quotationLines"));

const snackbarsStore = useSnackbarsStore();
const scenariosStore = useScenariosStore();

const gridWrapperRef = ref<GridWrapperComponent>();
const $dateTimeFormatter = inject<DateFormatter>($dateTimeFormatterSymbol)!;
const chartToolPanelsDef = ref({
  defaultToolPanel: "settings"
});

function gridApi() {
  return gridWrapperRef.value!.gridApi;
}

interface Props {
  selectedOrder: OrderDto;
}

const props = withDefaults(defineProps<Props>(), { selectedOrder: undefined });

const defaultColumnDef = ref({
  filter: false,
  floatingFilter: true,
  filterParams: {
    applyMiniFilterWhileTyping: true
  },
  sortable: true,
  resizable: true,
  editable: true
});

const isHidden = ref<boolean>(true);

function showColumns() {
  isHidden.value = false;
}

function hideColumns() {
  isHidden.value = true;
}

function onPrepareColumns(columnDefs: any) {
  columnDefs.value = [
    {
      field: "quotationId",
      headerValueGetter: (_: any) => $t("quotationLines-list-quotationId-label", { $: "Quotation Id" }),
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      valueGetter: (params: ValueGetterParams) => params.data?.quotationId
    },
    {
      field: "orderLineId",
      headerValueGetter: (_: any) => $t("quotationLines-list-orderLineId-label", { $: "Order Line Id" }),
      editable: false,
      resizable: true,
      sortable: true,
      filter: "agTextColumnFilter",
      valueFormatter: (params: ValueFormatterParams) => {
        return params.data?.businessId;
      },
      valueGetter: (params: ValueGetterParams) => params.data?.orderLineId
    },
    {
      field: "orderBusinessId",
      headerValueGetter: (_: any) => $t("quotationLines-list-orderBusinessId-label", { $: "Order Business Id" }),
      editable: false,
      resizable: true,
      sortable: true,
      filter: "agTextColumnFilter",
      valueFormatter: (params: ValueFormatterParams) => {
        return params.data?.businessId;
      },
      valueGetter: (params: ValueGetterParams) => params.data?.orderBusinessId
    },
    {
      field: "position",
      headerValueGetter: (_: any) => $t("quotationLines-list-position-label", { $: "Position" }),
      editable: false,
      resizable: true,
      sortable: true,
      filter: "agNumberColumnFilter",
      valueFormatter: (params: ValueFormatterParams) => {
        return params.data?.position;
      },
      valueGetter: (params: ValueGetterParams) => params.data?.position
    },
    {
      field: "orderLineQuantity",
      headerValueGetter: (_: any) => $t("quotationLines-list-orderLineQuantity-label", { $: "Order Line Quantity" }),
      editable: false,
      resizable: true,
      sortable: true,
      filter: "agNumberColumnFilter",
      valueFormatter: (params: ValueFormatterParams) => {
        return params.data?.orderLineQuantity;
      },
      valueGetter: (params: ValueGetterParams) => params.data?.orderLineQuantity
    },
    {
      field: "orderLineQuantityUnit",
      headerValueGetter: (_: any) => $t("quotationLines-list-orderLineQuantityUnit-label", { $: "Order Line Measurement Unit" }),
      editable: false,
      resizable: true,
      sortable: true,
      filter: "agNumberColumnFilter",
      type: "enumTypeColumn",
      valueFormatter: (params: any) => (params.data ? translateMeasurementUnit(params.data.orderLineQuantityUnit) : null)
    },
    {
      field: "orderLineResourceName",
      headerValueGetter: (_: any) => $t("quotationLines-list-orderLineResourceName-label", { $: "Order Line Resource Name" }),
      editable: false,
      resizable: true,
      sortable: true,
      filter: "agTextColumnFilter",
      valueFormatter: (params: ValueFormatterParams) => {
        return params.data?.orderLineResourceName;
      },
      valueGetter: (params: ValueGetterParams) => params.data?.orderLineResourceName
    },
    {
      field: "description",
      headerValueGetter: (_: any) => $t("quotationLines-list-description-label", { $: "Description" }),
      editable: false,
      resizable: true,
      sortable: true,
      filter: "agTextColumnFilter",
      valueFormatter: (params: ValueFormatterParams) => {
        return params.data?.description;
      },
      valueGetter: (params: ValueGetterParams) => params.data?.description
    },
    {
      field: "quotationBusinessId",
      headerValueGetter: (_: any) => $t("quotationLines-list-quotationBusinessId-label", { $: "Quotation Business Id" }),
      editable: false,
      resizable: true,
      sortable: true,
      filter: "agTextColumnFilter",
      valueFormatter: (params: ValueFormatterParams) => {
        return params.data?.quotationBusinessId;
      },
      valueGetter: (params: ValueGetterParams) => params.data?.quotationBusinessId
    },
    {
      field: "revisionNumber",
      headerValueGetter: (_: any) => $t("quotationLines-list-revisionNumber-label", { $: "Revision" }),
      editable: false,
      resizable: true,
      sortable: true,
      filter: "agNumberColumnFilter",
      valueFormatter: (params: ValueFormatterParams) => {
        return params.data?.revisionNumber;
      },
      valueGetter: (params: ValueGetterParams) => params.data?.revisionNumber
    },
    {
      field: "quotationType",
      headerValueGetter: (_: any) => $t("quotationLines-list-quotationType-label", { $: "Quotation Type" }),
      editable: false,
      resizable: true,
      sortable: true,
      type: "enumTypeColumn",
      filter: "agNumberColumnFilter",
      valueFormatter: (params: ValueFormatterParams) => {
        return translateQuotationType(params.data?.quotationType);
      },
      valueGetter: (params: ValueGetterParams) => params.data?.quotationType
    },
    {
      field: "scenarioId",
      headerValueGetter: (_: any) => $t("quotationLines-list-scenarioId-label", { $: "Scenario Id" }),
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      valueGetter: (params: ValueGetterParams) => params.data?.scenarioId
    },
    {
      field: "quotationLineId",
      headerValueGetter: (_: any) => $t("quotationLines-list-quotationLineId-label", { $: "Quotation Line Id" }),
      editable: false,
      sortable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      valueGetter: (params: ValueGetterParams) => params.data?.quotationLineId
    },
    {
      field: "quotationLineBusinessId",
      headerValueGetter: (_: any) => $t("quotationLines-list-quotationLineBusinessId-label", { $: "Quotation Line Business Id" }),
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      valueGetter: (params: ValueGetterParams) => params.data?.quotationLineBusinessId
    },
    {
      field: "resourceId",
      headerValueGetter: (_: any) => $t("quotationLines-list-resourceId-label", { $: "Resource Id" }),
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      valueGetter: (params: ValueGetterParams) => params.data?.resourceId
    },
    {
      field: "resourceBusinessId",
      headerValueGetter: (_: any) => $t("quotationLines-list-resourceBusinessId-label", { $: "Resource Business Id" }),
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      valueGetter: (params: ValueGetterParams) => params.data?.resourceBusinessId
    },
    {
      field: "resourceType",
      headerValueGetter: (_: any) => $t("quotationLines-list-resourceType-label", { $: "Resource Type" }),
      editable: false,
      sortable: true,
      type: "enumTypeColumn",
      filter: "agNumberColumnFilter",
      valueFormatter: (params: ValueFormatterParams) => {
        return translateResourceType(params.data?.resourceType);
      },
      valueGetter: (params: ValueGetterParams) => params.data?.resourceType
    },
    {
      field: "resourceName",
      headerValueGetter: (_: any) => $t("quotationLines-list-resourceName-label", { $: "Resource Name" }),
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      valueGetter: (params: ValueGetterParams) => params.data?.resourceName
    },
    {
      field: "price",
      headerValueGetter: (_: any) => $t("quotationLines-list-price-label", { $: "Price" }),
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      valueGetter: (params: ValueGetterParams) => params.data?.price
    },
    {
      field: "quantity",
      headerValueGetter: (_: any) => $t("quotationLines-list-quantity-label", { $: "Quantity" }),
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      valueGetter: (params: ValueGetterParams) => params.data?.quantity
    },
    {
      field: "measurementUnit",
      headerValueGetter: (_: any) => $t("quotationLines-list-measurementUnit-label", { $: "Measurement Unit" }),
      editable: false,
      sortable: true,
      type: "enumTypeColumn",
      filter: "agNumberColumnFilter",
      valueFormatter: (params: ValueFormatterParams) => {
        return translateMeasurementUnit(params.data?.measurementUnit);
      },
      valueGetter: (params: ValueGetterParams) => params.data?.measurementUnit
    },
    {
      field: "currencyCode",
      headerValueGetter: (_: any) => $t("quotationLines-list-currencyCode-label", { $: "Currency Code" }),
      editable: false,
      sortable: true,
      type: "enumTypeColumn",
      filter: "agNumberColumnFilter",
      valueGetter: (params: ValueGetterParams) => params.data?.currencyCode
    },
    {
      field: "taskName",
      headerValueGetter: (_: any) => $t("quotationLines-list-taskName-label", { $: "Task Name" }),
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      valueGetter: (params: ValueGetterParams) => params.data?.taskName
    },
    {
      field: "stepName",
      headerValueGetter: (_: any) => $t("quotationLines-list-stepName-label", { $: "Step Name" }),
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      valueGetter: (params: ValueGetterParams) => params.data?.stepName
    },
    {
      field: "stepType",
      headerValueGetter: (_: any) => $t("quotationLines-list-stepType-label", { $: "Step Type" }),
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      valueFormatter: (params: ValueFormatterParams) => {
        return translateStepType(params.data?.stepType);
      },
      valueGetter: (params: ValueGetterParams) => params.data?.stepType
    },
    {
      field: "wbs",
      headerValueGetter: (_: any) => $t("quotationLines-list-wbs-label", { $: "Wbs" }),
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      valueGetter: (params: ValueGetterParams) => params.data?.wbs
    },
    {
      field: "lineQuotationType",
      headerValueGetter: (_: any) => $t("quotationLines-list-lineQuotationType-label", { $: "Line Quotation Type" }),
      editable: false,
      sortable: true,
      type: "enumTypeColumn",
      filter: "agNumberColumnFilter",
      valueFormatter: (params: ValueFormatterParams) => {
        return translateQuotationType(params.data?.lineQuotationType);
      },
      valueGetter: (params: ValueGetterParams) => params.data?.lineQuotationType
    },
    {
      field: "providerId",
      headerValueGetter: (_: any) => $t("quotationLines-list-providerId-label", { $: "Provider Id" }),
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      valueGetter: (params: ValueGetterParams) => params.data?.providerId
    },
    {
      field: "createdBy",
      headerValueGetter: (_: any) => $t("quotationLines-list-createdBy-label", { $: "Created By" }),
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      valueGetter: (params: ValueGetterParams) => params.data?.createdBy
    },
    {
      field: "createdAt",
      type: ["dateTimeTypeColumn"],
      headerValueGetter: (_: any) => $t("quotationLines-list-createdAt-label", { $: "Created At" }),
      editable: false,
      sortable: true,
      filter: "agDateColumnFilter",
      valueFormatter: (params: ValueFormatterParams) => {
        return $dateTimeFormatter(params.data?.createdAt);
      },
      valueGetter: (params: ValueGetterParams) => params.data?.createdAt
    },
    {
      field: "modifiedBy",
      headerValueGetter: (_: any) => $t("quotationLines-list-modifiedBy-label", { $: "Modified By" }),
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      valueGetter: (params: ValueGetterParams) => params.data?.modifiedBy
    },
    {
      field: "modifiedAt",
      type: ["dateTimeTypeColumn"],
      headerValueGetter: (_: any) => $t("quotationLines-list-modifiedAt-label", { $: "Modified At" }),
      editable: false,
      sortable: true,
      filter: "agDateColumnFilter",
      valueFormatter: (params: ValueFormatterParams) => {
        return $dateTimeFormatter(params.data?.modifiedAt);
      },
      valueGetter: (params: ValueGetterParams) => params.data?.modifiedAt
    }
  ];
}

function onRowDoubleClicked({ api }: { api: GridApi }) {
  if (gridWrapperRef.value?.isEditing()) return;
  const rows = getSelectedRows(api);
  const selectedRow = rows.length === 1 ? rows[0] : null;
  if (selectedRow) {
    emit("showDetails", selectedRow);
  }
}

function getRowId(params: GetRowIdParams<QuotationQuotationLineDto>) {
  return `${params.data.quotationLineId}`;
}

async function generateEstimatedCostCalculation() {
  if (props.selectedOrder) {
    const request = {
      orderId: props.selectedOrder.id,
      scenarioId: scenariosStore.selectedScenario?.id
    } as CalculateQuotationForOrderCommand;

    try {
      await ApiService.quotations.calculate(request);
      snackbarsStore.createSnackbar({
        message: $t("quotation-list-onCalculateSuccess-message", { $: "Order has been sent to quotation calculation" }),
        type: "success",
        closeable: true
      });
    } catch (e) {
      console.error(e);
      snackbarsStore.createSnackbar({
        message: $t("quotation-list-onCalculateError-message", { $: "Could not calculate quotation of order" }),
        type: "error",
        closeable: true
      });
    }
  }
}
</script>

<template>
  <grid-wrapper
    ref="gridWrapperRef"
    refresh-btn
    row-selection="multiple"
    identifier="quotation-lines"
    :enable-charts="true"
    :chart-tool-panels-def="chartToolPanelsDef"
    server-side
    :server-side-datasource="serverSideDataSource"
    :default-col-def="defaultColumnDef"
    :tree-data="false"
    :enable-group-edit="false"
    :get-row-id="getRowId"
    @prepare-columns="onPrepareColumns"
    @row-double-clicked="onRowDoubleClicked"
    @edit-action="hideColumns"
    @create-action="showColumns"
    @cancel-action="hideColumns"
    @save-action="hideColumns"
  >
    <template #custom-buttons>
      <v-tooltip bottom open-delay="300">
        <template #activator="{ props }">
          <v-btn size="small" v-bind="props" variant="text" density="compact" class="mr-4" @click="generateEstimatedCostCalculation">
            <v-icon class="pr-4" icon="mdi-calculator" />
            {{ $t("quotationLines-list-generateCostCalculation-action", { $: "New calculation" }) }}
          </v-btn>
        </template>
        <span>{{ $t("quotationLines-list-generateCostCalculation-tooltip", { $: "Saves snapshot of costs of the order as they are now" }) }}</span>
      </v-tooltip>
    </template>
  </grid-wrapper>
</template>

<style lang="scss" scoped></style>
