<template>
  <span>{{ displayValue }}</span>
</template>

<script>
export default {
  name: "EventPayloadCellRenderer",
  data() {
    return {
      displayValue: ""
    };
  },
  beforeMount() {
    this.displayValue = this.params.value;
  }
};
</script>

<style lang="scss" scoped></style>
