<script lang="ts" setup>
import ApiService from "@/services/api";
import { ref, watch } from "vue";
import { ProductTemplateHistoricalPerformanceResult, ProductTemplateDto } from "@masta/generated-model";

const $props = defineProps<{
  modelValue: boolean;
  template: ProductTemplateDto;
}>();
const emit = defineEmits(["update:modelValue"]);
const result = ref<ProductTemplateHistoricalPerformanceResult | null>(null);

watch(
  () => $props.modelValue,
  async (value) => {
    if (!value || !$props.template) return;
    const r = await ApiService.productTemplates.getEfficiencyById($props.template.id, $props.template.scenarioId);
    result.value = r.data;
    console.log("result", r);
  }
);
</script>

<template>
  <v-dialog :model-value="modelValue" close-on-back width="100%" height="80vh" @update:model-value="emit('update:modelValue', $event)">
    <v-card min-width="300px" height="100%">
      <v-card-title>
        {{ $t("productTemplateHistoricalPerformance-dialog-productTemplateHistoricalPerformance-title", { $: "Product Template Performance Advisor" }) }}
      </v-card-title>
      <v-card-text>
        <div v-if="result">
          <div>
            {{ result.name }} <span v-if="result.businessId">({{ result.businessId }})</span>
          </div>
          <v-table>
            <thead>
              <tr>
                <th>{{ $t("productTemplateHistoricalPerformance-dialog-position-label", { $: "Position" }) }}</th>
                <th>{{ $t("productTemplateHistoricalPerformance-dialog-position-label", { $: "Name" }) }}name</th>
                <th>{{ $t("productTemplateHistoricalPerformance-dialog-resourceName-label", { $: "Product Name" }) }}</th>
                <th>{{ $t("productTemplateHistoricalPerformance-dialog-resourceBusinessId-label", { $: "Product ID" }) }}</th>
                <th>{{ $t("productTemplateHistoricalPerformance-dialog-totalQuantity-label", { $: "Total Qty. Produced" }) }}</th>
                <th>
                  {{ $t("productTemplateHistoricalPerformance-dialog-totalSteps-label", { $: "Total Qty. Executed Steps" }) }}
                </th>
                <th>
                  {{ $t("productTemplateHistoricalPerformance-dialog-averageDurationInSecondsPerPiece-label", { $: "Average Duration Per 1 Qty. [s]" }) }}
                </th>
                <th>
                  {{ $t("productTemplateHistoricalPerformance-dialog-averageDurationInSecondsPer1000Pieces-label", { $: "Average Duration per 1000 Qty. [s]" }) }}
                </th>
                <th>
                  {{ $t("productTemplateHistoricalPerformance-dialog-medianDurationInSecondsPerPiece-label", { $: "Mdn. Duration Per 1 Qty. [s]" }) }}
                </th>
                <th>
                  {{ $t("productTemplateHistoricalPerformance-dialog-medianDurationInSecondsPer1000Pieces-label", { $: "Mdn. Duration per 1000 Qty. [s]" }) }}
                </th>
              </tr>
            </thead>
            <tr v-for="step in result.stepResults" :key="step.id">
              <td>{{ step.position }}</td>
              <td>{{ step.name }}</td>
              <td>{{ step.resourceName }}</td>
              <td>{{ step.resourceBusinessId }}</td>
              <td v-if="!step.isValid" colspan="6">{{ step.errorMessage }}</td>
              <td v-if="step.isValid">{{ step.totalQuantity }}</td>
              <td v-if="step.isValid">{{ step.totalSteps }}</td>
              <td v-if="step.isValid">{{ step.averageDurationInSecondsPerPiece }}</td>
              <td v-if="step.isValid">{{ step.averageDurationInSecondsPer1000Pieces }}</td>
              <td v-if="step.isValid">{{ step.medianDurationInSecondsPerPiece }}</td>
              <td v-if="step.isValid">{{ step.medianDurationInSecondsPer1000Pieces }}</td>
            </tr>
          </v-table>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn class="ma-4" variant="outlined" color="secondary" text @click="emit('update:modelValue', false)"
          >{{ $t("productTemplateHistoricalPerformance-dialog-close-action", { $: "Close" }) }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
