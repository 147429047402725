import ApiService from "@/services/api";
import { ServerSideDataSource } from "@/components/Grid/ServerSideDataSource";
import { CostCatalogueItemDto, CreateOrUpdateCostCatalogueItemCommand, GetCostCatalogueItemsPaginatedQuery, IAgGridPaginationResponse } from "@masta/generated-model";
import { IServerSideGetRowsParams } from "ag-grid-community";
import { State as ScenarioState, useScenariosStore } from "@/store/ScenariosStore";
import { Store } from "pinia";
import { Promise } from "cypress/types/cy-bluebird";

export class CostCatalogueServerSideDataSource extends ServerSideDataSource<
  CostCatalogueItemDto,
  CostCatalogueItemDto,
  CreateOrUpdateCostCatalogueItemCommand,
  CreateOrUpdateCostCatalogueItemCommand,
  string
> {
  private _scenarioStore: Store<string, ScenarioState>;
  private _filterByResourceId: string | null;

  constructor(id: string) {
    super(id);
    this._scenarioStore = useScenariosStore();
    this._filterByResourceId = null;
  }

  public useFilteringByResourceId(resourceId: string | null) {
    this._filterByResourceId = resourceId;
  }

  async getAll<T>(params: IServerSideGetRowsParams): Promise<IAgGridPaginationResponse<CostCatalogueItemDto>> {
    if (!this._scenarioStore.selectedScenario) return { count: 0, results: [] } as IAgGridPaginationResponse<CostCatalogueItemDto>;

    const request = {
      ...this.constructServerRequest(params),
      scenarioId: this._scenarioStore.selectedScenario.id,
      resourceId: this._filterByResourceId
    } as GetCostCatalogueItemsPaginatedQuery;
    const response = await ApiService.costCatalogue.getPaginated(request);
    return response.data as IAgGridPaginationResponse<CostCatalogueItemDto>;
  }

  async create(entity: CostCatalogueItemDto): Promise<string> {
    const request = this.transformForCreate(entity);
    const scenario = this._scenarioStore.selectedScenario;
    request.scenarioId = scenario?.id ?? "";
    const response = await ApiService.costCatalogue.create(request);
    return response.data.costCatalogueItemId;
  }

  async getSingle(id: string): Promise<CostCatalogueItemDto | undefined> {
    const response = await ApiService.costCatalogue.getSingle(id, this._scenarioStore.selectedScenario?.id);
    return response.data;
  }

  async remove(entity: CostCatalogueItemDto): Promise<void> {
    if (!entity.id) return;
    await ApiService.costCatalogue.remove(entity.id, entity.scenarioId);
  }

  async update(entity: CostCatalogueItemDto): Promise<string> {
    const response = await ApiService.costCatalogue.update(this.transformForUpdate(entity));
    return response.data.costCatalogueItemId;
  }

  public transformCostCatalogueDtoToCreateOrUpdateCostCatalogueCommand(listEntity: CostCatalogueItemDto): CreateOrUpdateCostCatalogueItemCommand {
    return {
      id: listEntity.id,
      tenantId: listEntity.tenantId,
      scenarioId: this._scenarioStore.selectedScenario?.id,
      resourceBusinessId: listEntity.resourceBusinessId,
      quantityPerPrice: listEntity.quantityPerPrice,
      quantityPerPriceUnit: listEntity.quantityPerPriceUnit,
      pricePerQuantity: listEntity.pricePerQuantity,
      currencyCode: listEntity.currencyCode,
      price: listEntity.pricePerQuantity,
      validFrom: listEntity.validFrom,
      validTo: listEntity.validTo,
      leadTime: listEntity.leadTime,
      providerBusinessId: listEntity.providerBusinessId,
      packSize: listEntity.packSize,
      orderableUnit: listEntity.orderableUnit,
      packaging: listEntity.packaging,
      minimumNumberOfPackagesToOrder: listEntity.minimumNumberOfPackagesToOrder,
      minimumOrderableQuantity: listEntity.minimumOrderableQuantity,
      businessId: listEntity.businessId,
      tags: listEntity.tags,
      name: listEntity.name,
      manufacturer: listEntity.manufacturer
    };
  }

  protected transformForCreate(listEntity: CostCatalogueItemDto): CreateOrUpdateCostCatalogueItemCommand {
    return this.transformCostCatalogueDtoToCreateOrUpdateCostCatalogueCommand(listEntity);
  }

  protected transformForUpdate(listEntity: CostCatalogueItemDto): CreateOrUpdateCostCatalogueItemCommand {
    return this.transformCostCatalogueDtoToCreateOrUpdateCostCatalogueCommand(listEntity);
  }
}
