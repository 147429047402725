import { useRoute, useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import { useAuthStore } from "@/store/AuthStore";
import { onBeforeUnmount, watch } from "vue";

export interface MasterDetailLayoutRouteOptions {
  componentRoutePath: string;
  openDetail: (id: string) => void;
  closeDetail: () => void;
}

export type MasterDetailLayoutRoute = ReturnType<typeof useMasterDetailLayoutRoute>;

export const useMasterDetailLayoutRoute = (options: MasterDetailLayoutRouteOptions) => {
  const router = useRouter();
  const route = useRoute();

  const unwatch = watch(
    route,
    (newRoute, oldRoute) => {
      const { id } = newRoute.query;
      if (newRoute.path.includes("detail") && id) {
        options.openDetail(`${id}`);
      } else {
        options.closeDetail();
      }
    },
    { flush: "post" }
  );

  onBeforeUnmount(() => {
    unwatch();
  });

  function onComponentMounted() {
    const { id } = route.query;
    if (isCurrentRouteDetail() && id) {
      options.openDetail(`${id}`);
    }
  }

  function isCurrentRouteDetail() {
    return route.path.includes("detail");
  }

  async function navigateToOpenDetail(rowId: string) {
    const detailRoutePath = getComponnentRoutePath(true);

    await router.push({
      path: detailRoutePath,
      query: {
        id: rowId
      }
    });
  }

  async function navigateToCloseDetail() {
    if (isCurrentRouteDetail()) {
      const normalRoutePath = getComponnentRoutePath();

      await router.push({
        path: normalRoutePath
      });
    }
  }

  function getComponnentRoutePath(detail = false) {
    if (detail) {
      return `/${options.componentRoutePath}/detail`;
    } else {
      return `/${options.componentRoutePath}`;
    }
  }

  return {
    onComponentMounted,
    isCurrentRouteDetail,
    navigateToOpenDetail,
    navigateToCloseDetail
  };
};

export const resolveComponentRoutePath = (routeName: string) => {
  const router = useRouter();
  const resolved = router.resolve({
    name: routeName
  });
  return resolved?.path;
};
