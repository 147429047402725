<template>
  <div class="split-layout">
    <div v-if="scenarioSelector" class="scenario-selector">
      <v-row no-gutters>
        <v-col cols="12">
          <!-- <scenario-selector v-if="isAuthenticated && !isAuthLoading" @scenario-selection-changed="onScenarioSelectionChanged" /> -->
        </v-col>
      </v-row>
    </div>
    <div v-if="scenarioSelector && !isScenarioSelected" class="d-flex justify-center pa-4">
      <v-progress-circular indeterminate />
    </div>
    <div v-if="!scenarioSelector || isScenarioSelected" class="fill-height">
      <split-panel :identifier="identifier" direction="horizontal" class="split-layout-panel" :min-size="[50, 50]" :gutter-size="gutterSize" :gutter="gutter">
        <template #panel-1>
          <slot name="master" />
        </template>
        <template #panel-2>
          <slot name="detail" />
        </template>
      </split-panel>
    </div>
  </div>
</template>

<script>
import ScenarioSelector from "@/components/ScenarioSelector.vue";
import { useAuthStore } from "@/store/AuthStore";
import { mapState } from "pinia";
import { useScenariosStore } from "@/store/ScenariosStore";
import SplitPanel from "@/components/Layout/SplitPanel.vue";

export default {
  name: "SplitLayout",
  components: { ScenarioSelector, SplitPanel },
  props: {
    identifier: {
      type: String,
      default: undefined
    },
    scenarioSelector: {
      type: Boolean,
      default: false
    },
    gutter: {
      type: Function,
      default: undefined
    },
    gutterSize: {
      type: Number,
      default: undefined
    }
  },
  emits: ["scenarioSelectionChanged"],
  computed: {
    ...mapState(useAuthStore, {
      isAuthLoading: "loading",
      isAuthenticated: "isAuthenticated"
    }),
    ...mapState(useScenariosStore, ["selectedScenario"]),
    isScenarioSelected() {
      return this.selectedScenario !== null;
    }
  },
  unmounted() {
  },
  methods: {
    onScenarioSelectionChanged(selectedScenario) {
      this.$emit("scenarioSelectionChanged", selectedScenario);
    },
  }
};
</script>

<style lang="scss">
.split-layout {
  height: 100%;

  .scenario-selector {
    position: absolute;
    z-index: 1;
    top: 99px;
    right: 12px;
    min-width: 20vw;
    text-align: right;
  }
}
</style>
