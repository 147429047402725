<script lang="ts" setup>
import { StringRequirementDefinition } from "@masta/generated-model";

const props = defineProps<{
  modelValue: StringRequirementDefinition;
  disabled: boolean;
}>();

const emit = defineEmits(["update:modelValue", "remove"]);

function update(propertyName: string, value: any) {
  const newRequirement = { ...props.modelValue, [propertyName]: value };
  emit("update:modelValue", newRequirement);
}
</script>

<template>
  <v-col cols="5" xl="6">
    <v-text-field
      variant="outlined"
      density="compact"
      hide-details
      :label="$t('requirementDefinition-stringRequirementEditor-jsonPath-label', { $: 'JSON Path' })"
      :model-value="modelValue.jsonPath"
      :disabled="disabled"
      @update:model-value="update('jsonPath', $event)"
    />
  </v-col>
  <v-col cols="2" xl="1" class="text-center d-flex align-center justify-center">
    <v-chip size="x-large" label style="width: 100%">{{ $t("requirementDefinition-stringRequirementEditor-type-label", { $: "String" }) }}</v-chip>
  </v-col>
  <v-col cols="4">
    <v-text-field
      variant="outlined"
      density="compact"
      hide-details
      :label="$t('requirementDefinition-stringRequirementEditor-regularExpression-label', { $: 'Regular Expression' })"
      :model-value="modelValue.regularExpression"
      :disabled="disabled"
      @update:model-value="update('regularExpression', $event)"
    />
  </v-col>
  <v-col cols="1" class="text-right d-flex align-center justify-center">
    <v-btn variant="text" icon="mdi-close" size="large" hide-details :disabled="disabled" @click="emit('remove')"></v-btn>
  </v-col>
</template>
