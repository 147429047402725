import { ApiClient } from "@/services/api/common.api";
import {
  CreateProcessDraftCommand,
  UpdateProcessDraftCommand,
  ProcessDraftDto,
  IAgGridPaginationResponse,
  GetProcessDraftsPaginatedQuery,
  GetProcessDraftRowNumberQuery
} from "@masta/generated-model";

export default {
  async getPaginated(request: GetProcessDraftsPaginatedQuery) {
    return await ApiClient.post<IAgGridPaginationResponse<ProcessDraftDto>>(`/ProcessDrafts`, request);
  },
  async getRowNumber(request: GetProcessDraftRowNumberQuery) {
    return await ApiClient.post<number>(`/ProcessDrafts/rowNumber`, request);
  },
  async getById(id: string) {
    return await ApiClient.get<ProcessDraftDto>(`/ProcessDrafts/${id}`);
  },
  async create(request: CreateProcessDraftCommand) {
    return await ApiClient.put<string>(`/ProcessDrafts/`, request);
  },
  async update(request: UpdateProcessDraftCommand) {
    const id = request.id;
    return await ApiClient.put(`/ProcessDrafts/${id}`, request);
  },
  async remove(id: string) {
    return await ApiClient.delete(`/ProcessDrafts/${id}`);
  },
  async makeCopy(id: string) {
    return ApiClient.post(`/ProcessDrafts/${id}/makeCopy`);
  }
};
