<script lang="ts">
import { FilterChangedEvent, IAfterGuiAttachedParams, IFloatingFilterParams } from "ag-grid-community";
import { toRef } from "@vueuse/core";
import { ref, watch } from "vue";

export interface ITextFloatingFilterParams extends IFloatingFilterParams {
  inputType?: string | undefined;
  placeholder?: string;
}

export default {
  setup(props: { params: ITextFloatingFilterParams }) {
    const params = toRef(props, "params");

    const value = ref();

    watch(value, (v) => {
      if (v === "") {
        // Remove the filter
        params.value.parentFilterInstance((instance) => {
          instance.onFloatingFilterChanged("text", null);
        });
        return;
      }

      params.value.parentFilterInstance((instance) => {
        instance.onFloatingFilterChanged("text", v);
      });
    });

    return {
      value,
      params,
      label: "test",
      onParentModelChanged(parentModel: any, filterChangedEvent?: FilterChangedEvent | null) {
        if (!parentModel) {
          value.value = null;
        } else {
          value.value = parentModel.filter;
        }
      },
      // eslint-disable-next-line @typescript-eslint/no-shadow
      afterGuiAttached(params?: IAfterGuiAttachedParams): void {
        // intentionally empty
      }
    };
  }
};
</script>

<template>
  <div class="text-floating-filter">
    <div class="text-floating-filter-container">
      <v-text-field
        ref="input"
        v-model="value"
        color="primary"
        variant="outlined"
        density="compact"
        hide-details
        :type="params.inputType ?? 'text'"
        :placeholder="params.placeholder"
      ></v-text-field>
    </div>
  </div>
</template>

<style lang="scss">
.text-floating-filter {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .text-floating-filter-container {
    padding: 0.31em;

    .v-field--disabled {
      opacity: 0.75;
    }

    .v-input {
      font-size: 1em;

      .v-field {
        border-radius: 8px;
        font-size: 1em;

        .v-field__overlay {
          background-color: white;
        }

        .v-field__input {
          font-size: 1em;
          min-height: 34px;
          // padding: 0;
          padding-top: 0;
          padding-bottom: 0;
        }

        .v-field__outline {
          --v-field-border-width: 2px;
        }

        color: rgba(0, 0, 0, 0.5);

        &:hover {
          color: var(--ag-data-color);
        }

        &.v-field--focused {
          color: var(--ag-data-color);
        }
      }
    }
  }
}
</style>
