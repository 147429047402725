import ApiService from "@/services/api";
import { defineStore } from "pinia";
import { useScenariosStore } from "@/store/ScenariosStore";
import { useErrorsStore } from "@/store/ErrorsStore";
import { CalculateKpiValuesCommand, CalculateKpiValuesCommand, DeleteKpiResultByCalculationIdCommand, KpiResultDto } from "@masta/generated-model";

interface State {
  kpiResults: KpiResultDto[];
  kpiProfiles: string[];
}

export const useKeyPerformanceIndicatorsStore = defineStore("keyPerformanceIndicators", {
  state: (): State => ({
    kpiResults: [],
    kpiProfiles: []
  }),
  getters: {
    isProfilesEmpty(state): boolean {
      return state.kpiProfiles.length === 0;
    }
  },
  actions: {
    async fetch() {
      try {
        const scenariosStore = useScenariosStore();
        if (!scenariosStore.selectedScenario) return;
        const response = await ApiService.keyPerformanceIndicators.getAll(scenariosStore.selectedScenario.id);
        this.kpiResults = response.data.kpiResults;
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    async fetchProfiles(): Promise<string[]> {
      if (this.kpiProfiles.length === 0) {
        try {
          const response = await ApiService.keyPerformanceIndicators.getProfiles();
          this.kpiProfiles = response.data.kpiProfileNames;
        } catch (e) {
          const errorsStore = useErrorsStore();
          errorsStore.handleError(e);
          throw e;
        }
      }
      return this.kpiProfiles;
    },
    async calculateKpiValues(command: CalculateKpiValuesCommand): Promise<void> {
      try {
        await ApiService.keyPerformanceIndicators.calculate(command);
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    async deleteKpiResult(id: string): Promise<void> {
      try {
        const scenariosStore = useScenariosStore();
        if (!scenariosStore.selectedScenario) return;

        await ApiService.keyPerformanceIndicators.delete(id, scenariosStore.selectedScenario.id);
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    async deleteByScenarioId(scenarioId: string): Promise<void> {
      try {
        await ApiService.keyPerformanceIndicators.deleteByScenarioId(scenarioId);
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    async deleteByCalculationId(calculationId: string): Promise<void> {
      try {
        const scenariosStore = useScenariosStore();
        if (!scenariosStore.selectedScenario) return;

        const command = {
          calculationId,
          scenarioId: scenariosStore.selectedScenario.id
        } as DeleteKpiResultByCalculationIdCommand;


        await ApiService.keyPerformanceIndicators.deleteByCalculationId(command);
      } catch (e) {
        const errorsStore = useErrorsStore();
        errorsStore.handleError(e);
        throw e;
      }
    },
    getKpiCalculationIds(): string[] {
      const calculationIds = this.kpiResults.map((kpiResult) => kpiResult.calculationId);
      const uniqueCalculationIds = [...new Set(calculationIds)];
      return uniqueCalculationIds;
    },
    getKpiScenarioIds(): string[] {
      const scenarioIds = this.kpiResults.map((kpiResult) => kpiResult.scenarioId);
      const uniqueScenarioIds = [...new Set(scenarioIds)];
      return uniqueScenarioIds;
    }
  }
});
