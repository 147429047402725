﻿<template>
  <div v-if="step" class="fill-height pa-4 bg-material-design-grey-lighten-4 d-flex flex-column">
    <v-tabs v-model="tab" density="compact" size="small" selected-class="v-slide-group-item--active text-primary bg-white" class="app-tabs align-end">
      <v-tab :theme="theme.name" value="specifications" variant="elevated" class="mr-1 text-disabled" density="compact" size="small">
        {{ $t("task-taskDetails-specification-label", { $: "Specification" }) }}
      </v-tab>
      <v-tab :theme="theme.name" value="capacities" variant="elevated" class="mr-1 text-disabled" density="compact" size="small">
        {{ $t("task-taskDetails-capacities-label", { $: "Capacities" }) }}
      </v-tab>
    </v-tabs>
    <v-sheet elevation="4" class="flex-fill pa-4" rounded>
      <v-window v-model="tab" :theme="theme.name" class="fill-height">
        <v-window-item value="specifications" class="fill-height">
          <step-resource-spec-grid :step="step" @manage-model-instances="manageModelInstances" />
        </v-window-item>
        <v-window-item value="capacities" class="fill-height">
          <task-resource-capacities-grid :step="step" />
        </v-window-item>
      </v-window>
      <div v-if="editModelInstanceAssignmentDialog">
        <model-instance-assignment-editor
          task-assignment
          :model-instances="spec.modelInstances"
          :opened="editModelInstanceAssignmentDialog"
          @cancel="editModelInstanceAssignmentDialog = false"
          @save="saveModelInstanceAssignment"
        >
          <task-label
            :spec-resource-type="getResourceType()"
            :spec-type="getAssignmentType()"
            :step-name="step.name"
            :step-position="step.position"
            :task-external-id="task.businessId"
            :task-name="task.name"
            type="Step Resource Specification"
          />
        </model-instance-assignment-editor>
      </div>
    </v-sheet>
  </div>
</template>

<script>
import StepResourceSpecGrid from "@/components/Tasks/StepResourceSpecGrid.vue";
import TaskResourceCapacitiesGrid from "@/components/Tasks/TaskResourceCapacitiesGrid.vue";
import ModelInstanceAssignmentEditor from "@/components/ModelInstances/ModelInstanceAssignment/ModelInstanceAssignmentEditor";
import TaskLabel from "@/components/ModelInstances/ModelInstanceAssignment/TaskLabel";
import SystemEnumService from "@/services/system-enum.service";
import { mapActions, mapState } from "pinia";
import { useProductionTasksStore } from "@/store/ProductionTasksStore";

export default {
  name: "TaskStepDetails",
  components: {
    TaskLabel,
    TaskResourceCapacitiesGrid,
    StepResourceSpecGrid,
    ModelInstanceAssignmentEditor
  },
  props: {
    theme: {
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      step: null,
      task: null,
      spec: null,
      tab: null,
      editModelInstanceAssignmentDialog: false,
      refreshGridAction: null
    };
  },
  methods: {
    ...mapActions(useProductionTasksStore, ["updateModelInstanceAssignment", "fetchDetail"]),
    manageModelInstances(spec, refreshGridAction) {
      this.refreshGridAction = refreshGridAction;
      this.spec = spec;
      this.editModelInstanceAssignmentDialog = true;
    },
    async saveModelInstanceAssignment(instances) {
      await this.updateModelInstanceAssignment({
        taskId: this.step.taskId,
        stepId: this.step.id,
        stepResourceSpecId: this.spec.id,
        modelInstances: instances.map((x) => ({
          modelInstanceId: x.id,
          revisionNumber: x.revisionNumber
        }))
      });
      this.refreshGridAction();
      this.editModelInstanceAssignmentDialog = false;
    },
    getAssignmentType() {
      return SystemEnumService.stepResourceAssignmentType(this.spec.assignmentType);
    },
    getResourceType() {
      return SystemEnumService.resourceType(this.spec.resourceType);
    },
    async loadStepDetails(taskStep, taskDetail) {
      if (taskStep) {
        this.step = taskStep;
        if (taskDetail) {
          this.task = taskDetail;
        } else {
          this.task = await this.fetchDetail(taskStep.taskId);
        }
      } else {
        this.step = undefined;
        this.task = undefined;
      }
    }
  }
};
</script>

<style lang="scss">
@import "vuetify/lib/styles/settings/colors";
//
.task-steps-details {
  display: flex;
  flex-direction: row;

  .v-slide-group__content {
    //background-color: #e5e5e5;
    .v-btn {
      text-align: left;
      justify-content: start;
    }

    .v-btn:not(.v-tab--selected) {
      //color: rgba(map-get($shades, "black"), 0.5) !important;
    }

    .v-btn {
      //background-color: white;
      //color: black;
      border-right: unset;
    }
  }

  .v-window {
    flex-grow: 1;
    //background-color: white;
  }

  //&.theme--dark {
  //  .v-tabs-bar__content {
  //    background-color: unset;
  //    .v-tab:not(.v-tab--active) {
  //      color: rgba(map-get($shades, "white"), 0.5) !important;
  //    }
  //    .v-tab--active {
  //      background-color: #666666;
  //      color: white;
  //      border-right: unset;
  //    }
  //  }
  //}
  //.v-window.v-item-group {
  //  background-color: white;
  //  &.theme--dark {
  //    background-color: #666666;
  //  }
  //  flex-grow: 1;
  //}
}
</style>
